import React from 'react';
import { Text } from "components/typography";
import styles from './styles.module.scss';
import { Input } from "components/form-elements/inputs/Input";

export const BankIdSecondSignature = ({value, onChange, isError}) => {

  return (
    <div className={styles.secondSignature}>
      <Text
        className={styles.description}
        intl="secondSignature"
        size={14}
      />

      <div className={styles.action}>
        <Input
          value={value}
          onChange={e => onChange(e.target.value)}
        />
        {isError && <Text intl={'invalidEmail'} error /> }
      </div>
    </div>
  )
}
