import React from 'react';

export const ComputerChipIcon = () => (
    <svg id="computer-chip" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path id="Path_7982" data-name="Path 7982" d="M2.029,11H.833a.833.833,0,0,0,0,1.667h1.2a.833.833,0,1,0,0-1.667Z" transform="translate(0 -1.833)"  />
        <path id="Path_7983" data-name="Path 7983" d="M2.029,16H.833a.833.833,0,1,0,0,1.667h1.2a.833.833,0,1,0,0-1.667Z" transform="translate(0 -2.667)" />
        <path id="Path_7984" data-name="Path 7984" d="M2.029,6.5H.833a.833.833,0,1,0,0,1.667h1.2a.833.833,0,1,0,0-1.667Z" transform="translate(0 -1.083)" />
        <path id="Path_7985" data-name="Path 7985" d="M22.594,11H21.4a.833.833,0,0,0,0,1.667h1.2a.833.833,0,1,0,0-1.667Z" transform="translate(-3.428 -1.833)" />
        <path id="Path_7986" data-name="Path 7986" d="M21.4,8.167h1.2a.833.833,0,1,0,0-1.667H21.4a.833.833,0,0,0,0,1.667Z" transform="translate(-3.428 -1.083)"/>
        <path id="Path_7987" data-name="Path 7987" d="M22.594,16H21.4a.833.833,0,0,0,0,1.667h1.2a.833.833,0,1,0,0-1.667Z" transform="translate(-3.428 -2.667)"/>
        <path id="Path_7988" data-name="Path 7988" d="M11.833,2.862a.833.833,0,0,0,.833-.833V.833A.833.833,0,0,0,11,.833v1.2A.833.833,0,0,0,11.833,2.862Z" transform="translate(-1.833 0)"/>
        <path id="Path_7989" data-name="Path 7989" d="M7.333,2.862a.833.833,0,0,0,.833-.833V.833A.833.833,0,1,0,6.5.833v1.2A.833.833,0,0,0,7.333,2.862Z" transform="translate(-1.083 0)"  />
        <path id="Path_7990" data-name="Path 7990" d="M16.333,2.862a.833.833,0,0,0,.833-.833V.833a.833.833,0,0,0-1.667,0v1.2A.833.833,0,0,0,16.333,2.862Z" transform="translate(-2.583 0)"/>
        <path id="Path_7991" data-name="Path 7991" d="M11.833,20.566A.833.833,0,0,0,11,21.4v1.195a.833.833,0,0,0,1.667,0V21.4A.833.833,0,0,0,11.833,20.566Z" transform="translate(-1.833 -3.428)" />
        <path id="Path_7992" data-name="Path 7992" d="M16.333,20.566a.833.833,0,0,0-.833.833v1.195a.833.833,0,0,0,1.667,0V21.4A.833.833,0,0,0,16.333,20.566Z" transform="translate(-2.583 -3.428)" />
        <path id="Path_7993" data-name="Path 7993" d="M7.333,20.566A.833.833,0,0,0,6.5,21.4v1.195a.833.833,0,0,0,1.667,0V21.4A.833.833,0,0,0,7.333,20.566Z" transform="translate(-1.083 -3.428)" />
        <path id="Path_7994" data-name="Path 7994" d="M15.806,4.5H5.694A1.2,1.2,0,0,0,4.5,5.695V15.806A1.2,1.2,0,0,0,5.694,17H15.806A1.2,1.2,0,0,0,17,15.806V5.695A1.2,1.2,0,0,0,15.806,4.5ZM14.5,13.667a.833.833,0,0,1-.833.833H11.583a.833.833,0,0,1,0-1.667h2.083A.833.833,0,0,1,14.5,13.667Z" transform="translate(-0.75 -0.75)" />
    </svg>
);