import React from 'react';
import { RentIcon } from 'assets/icons/brikks/RentIcon';
import Draggable from 'components/drag-and-drop/Draggable';
import { Modal } from 'components/brikks/home/Invoices/components/Modal';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { InvoicesPreview } from 'components/brikks/home/Invoices/components/InvoicesPreview';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { useInvoices } from 'services/invoices/invoices/hook';

export const Invoices = ({ position, code, name, status }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const { invoices, isLoading, isError } = useInvoices();

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          disabled={isError}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<RentIcon />}
            heading="invoicesBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <InvoicesPreview invoices={invoices} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              disabled={!!isError || !invoices.length}
              onClick={handleOpenModal}
              brikkNameIntl="invoices"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Rent info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        twoGrid
        isOpen={isModalOpen}
        heading="invoices"
        onClose={() => setIsModalOpen(false)}
      >
        <Modal />
      </BrikkModal>
    </>
  );
};
