import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { createDateKey } from 'utils/water-usage';
import { mapDataToClient } from './mapData';
import { getWaterUsageAPI } from './api';

const WATER_USAGE = 'waterUsage';

export function useWaterUsage(reportAfter, reportBefore) {
  const dateKey = createDateKey({ reportAfter, reportBefore });
  
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [WATER_USAGE, tenantId, dateKey],
    () => getWaterUsageAPI(reportAfter, reportBefore),
  );

  return { 
    waterUsage: data || mapDataToClient({}),
    isLoading: isLoading || isFetching,
    isError,
  };
}

