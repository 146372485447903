import { getUserAttributes, setUserAttributes } from 'utils/local-storage/attributes';

export const ENGLISH = 'en-US';
export const SWEDISH = 'sv-SE';

export function getLocaleFromStorage() {
  return getUserAttributes().language;
}

export function setLocaleInStorage(locale) {
  const userAttributes = getUserAttributes();
  setUserAttributes({ ...userAttributes, language: locale })
}
