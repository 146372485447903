import React from 'react';

export const RabbitIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="rabbit-running" transform="translate(-0.25 -0.5)">
                <path id="Path_7694" data-name="Path 7694" d="M7.646,19.067A.211.211,0,0,0,7.493,19H6.526a1.3,1.3,0,0,0,0,2.609H8.972a.212.212,0,0,0,.185-.111.222.222,0,0,0,0-.219,10.772,10.772,0,0,0-1.507-2.211Z" transform="translate(-0.746 -2.413)"  />
                <path id="Path_7695" data-name="Path 7695" d="M19.238,9.794h0L10.511.882a1.257,1.257,0,0,0-1.806,0,1.325,1.325,0,0,0,0,1.845l5.105,5.232a1.325,1.325,0,0,1,0,1.845,1.257,1.257,0,0,1-1.806,0L8.731,6.456A4.906,4.906,0,0,0,5.2,5.037a4.354,4.354,0,0,0-1.588.287A1.707,1.707,0,0,0,1.845,3.982,1.719,1.719,0,0,0,.259,5.539,1.738,1.738,0,0,0,1.5,7.395a4.512,4.512,0,0,0,1.835,4.989c1.7,1.2,4.148,2.464,5.46,4.638.647,1.07,1.623,2.7,1.967,3.27a.424.424,0,0,0,.362.208H15.99a.87.87,0,0,0,0-1.739h-3.1a.426.426,0,0,1-.4-.294c-.334-1-1.428-4.27-1.787-5.371a.655.655,0,0,1,.411-.809.635.635,0,0,1,.8.4c.222.687.681,2.1,1.068,3.276a.214.214,0,0,0,.136.138.211.211,0,0,0,.19-.03l2.257-1.658h2.127c1.7,0,2.553-.87,2.553-2.174A3.026,3.026,0,0,0,19.238,9.794ZM17.48,12.456a.87.87,0,1,1,.851-.87A.86.86,0,0,1,17.48,12.456Z" transform="translate(0 0)" />
            </g>
        </svg>
    )
}