import React from 'react';

export const UserMapPinIcon = () => {
    return (
        <svg id="travel-user-pin" xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
            <path id="Path_7672" data-name="Path 7672" d="M18.167,11A4.172,4.172,0,0,0,14,15.167c0,2.642,3.714,6.387,3.872,6.545a.417.417,0,0,0,.59,0c.157-.158,3.872-3.9,3.872-6.545A4.172,4.172,0,0,0,18.167,11Zm0,5.625a1.458,1.458,0,1,1,1.458-1.458,1.458,1.458,0,0,1-1.458,1.458Z" transform="translate(-2.333 -1.833)" />
            <path id="Path_7673" data-name="Path 7673" d="M17,4.583a.208.208,0,0,1-.208.208H15.75a.625.625,0,0,0-.442,1.067l2.083,2.083a.623.623,0,0,0,.883,0l2.083-2.083a.625.625,0,0,0-.442-1.067H18.875a.208.208,0,0,1-.208-.208A3.333,3.333,0,0,0,15.333,1.25h-3.75a.833.833,0,0,0,0,1.667h3.75A1.667,1.667,0,0,1,17,4.583Z" transform="translate(-1.792 -0.208)" />
            <circle id="Ellipse_352" data-name="Ellipse 352" cx="2.917" cy="2.917" r="2.917" transform="translate(1.25)" />
            <path id="Path_7674" data-name="Path 7674" d="M4.167,8A4.172,4.172,0,0,0,0,12.167V15.5a.417.417,0,0,0,.417.417H1.5a.209.209,0,0,1,.207.193l.371,4.839a.417.417,0,0,0,.417.385H5.833a.417.417,0,0,0,.417-.385l.372-4.839a.209.209,0,0,1,.207-.193H7.917a.417.417,0,0,0,.417-.417V12.167A4.172,4.172,0,0,0,4.167,8Z" transform="translate(0 -1.333)" />
        </svg>
    )
}