import { MaintenanceProductsActions } from './actions';

const initialState = {
  products: {},
  loading: {},
  error: {},
  calledOnce: {},
}

export function createProductKey({ type, unit, measure }) {
  return type + '__' + unit + '__' + measure;
}

export function maintenanceProductsReducer(state = initialState, action) {
  let productKey;
  if (action.payload) {
    productKey = createProductKey(action.payload);
  }

  switch (action.type) {
    case MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET:
      return {
        ...state,
        loading: {
          ...state.loading,
          [productKey]: true,
        },
        error: {
          ...state.error,
          [productKey]: '',
        },
        calledOnce: {
          ...state.calledOnce,
          [productKey]: true,
        },
      }
    case MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET_SUCCESS:
      return  {
        ...state,
        loading: {
          ...state.loading,
          [productKey]: false,
        },
        products: {
          ...state.products,
          [productKey]: action.payload.products,
        },
      }
    case MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET_FAIL:
      return {
        ...state,
        loading: {
          ...state.loading,
          [productKey]: false,
        },
        error: {
          ...state.error,
          [productKey]: action.payload.error,
        },
      }
    default:
        return state;
  }
}
