import React from 'react';
import { Text } from 'components/typography/Text';
import { StatusDot } from 'components/StatusDot';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const StatusText = ({ intl, small, active = true }) => (
  <div className={classNames(styles.statusLine, small && styles.small)}>
    <Text
      intl={intl}
      intlValues={{
        bold: (...chunks) => (
          <Text
            element="span"
            bold
          >
            {chunks}
          </Text>
        ),
      }}
    />
    
    <StatusDot
      active={active}
      small={small}
    /> 
  </div>
);
