const MEASURES = {
  Exchange: "Utbyte",
  Sanded: "Slipas/lackas",
  Painted: "Målas",
  Change: "Bytes",
  Wallpapered: "Tapetseras"
};

const TYPES = {
  KITCHEN: 'Kök',
  KITCHENETTE: 'Kokvrå',
  HALLWAY: 'Hall',
  BATHROOM: 'Badrum',
  LIVING_ROOM: 'Vardagsrum',
  BED_ROOM: 'Sovrum',
}

const NOW = new Date();

const advancedMaintenance = (plannedDate) => NOW < new Date(plannedDate);

const shouldDisplayWallpaperInfo = (type, measure) => {
  return (type === TYPES.KITCHEN || type === TYPES.KITCHENETTE || type === TYPES.HALLWAY) && measure === MEASURES.Wallpapered;
}

export { MEASURES, advancedMaintenance, shouldDisplayWallpaperInfo }
