import React from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntl } from 'react-intl';
import { Card } from 'components/cards/Card';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import { LabeledTimePicker } from 'components/form-elements/pickers/LabeledTimePicker';
import { Radio } from 'components/form-elements/radios/Radio';
import { Form } from 'components/form-elements/Form';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { SubmitButton } from 'components/form-elements/buttons/SubmitButton';
import { LabeledWeekdayPicker } from 'components/form-elements/pickers/LabeledWeekdayPicker';
import { Grid } from 'components/layout/Grid';
import { DeleteScenario, LightningDevice } from
'components/brikks/smartness/Lights/components/LightningScenarios/components';
import { useUpdateLightningScenario } from 'services/lightning/update-scenario';
import { initialTime } from 'utils/timeAndDate';
import { convertCronToTime, getCronDays } from 'utils/cron';
import { updateLightningScenarioSchema } from 'utils/validation/schemas';
import styles from './styles.module.scss';

export const EditScenario = ({ selectedScenario, setSelectedScenario }) => {

  const intl = useIntl();

  const initialScenario = {
    id: selectedScenario.value,
    enabled: selectedScenario.enabled,
    onTime: selectedScenario.cronStart ? convertCronToTime(selectedScenario.cronStart) : initialTime,
    offTime: selectedScenario.cronEnd ? convertCronToTime(selectedScenario.cronEnd) : initialTime,
    days: selectedScenario.cronStart ? getCronDays(selectedScenario.cronStart) : [],
    devices: selectedScenario.devices,
  };

  const { control, handleSubmit, reset, getValues, formState } = useForm({
    mode: 'onSubmit',
    defaultValues: initialScenario,
    resolver: yupResolver(updateLightningScenarioSchema),
  });

  const { fields } = useFieldArray({
    control,
    name: 'devices',
  });

  React.useEffect(() => {
    reset(initialScenario);
    // eslint-disable-next-line
  }, [selectedScenario]);

  const { updateLightningScenario, isLoading } = useUpdateLightningScenario();

  function onDayClick(day) {
    const days = getValues('days');
    if (days.includes(day)) {
      return days.filter(selectedDay => selectedDay !== day);
    } return [...days, day];
  }

  function onSubmit(scenario) {
    updateLightningScenario(scenario);
  }

  return (
    <Card
      grey
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <DeleteScenario
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
        />

        <Grid
          oneColumn
          noPadding
          style={{ justifyItems: 'left' }}
        >
          <Controller
            name="enabled"
            control={control}
            render={({ field }) => (
              <Radio
                toggle
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
                label={intl.formatMessage({ id: field.value ? 'enabled' : 'disabled' })}
              />
            )}
          />

          <LabeledInput
            labelIntl="roomOrGroup"
            disabled
            value={selectedScenario?.lightGroupName}
          />

          <Grid
            className={styles.timePickers}
            twoEqualColumns
            noPadding
          >
            <Controller
              name="onTime"
              control={control}
              render={({ field }) => (
                <LabeledTimePicker
                  title="onTime"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  timeInterval={15}
                />
              )}
            />

            <Controller
              name="offTime"
              control={control}
              render={({ field }) => (
                <LabeledTimePicker
                  title="offTime"
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                  timeInterval={15}
                />
              )}
            />
          </Grid>

          <ShouldRender condition={formState.errors.onTime && formState.errors.offTime}>
            <Text
              intl="invalidOffTime"
              error
            />
          </ShouldRender>

          <Controller
            name="days"
            control={control}
            render={({ field }) => (
              <LabeledWeekdayPicker
                title="inputDays"
                selectedDays={field.value}
                onClick={(e) => field.onChange(onDayClick(parseInt(e.target.value, 10)))}
              />
            )}
          />
        </Grid>

        <ShouldRender condition={formState.errors.days}>
          <Text
            intl="selectDays"
            error
          />
        </ShouldRender>

        {fields.map((field, index) => {
          const fieldName = `devices[${index}]`;
          return (
            <LightningDevice
              key={index}
              field={field}
              fieldName={fieldName}
              control={control}
            />
          );
        })}

        <SubmitButton isLoading={isLoading} />
      </Form>
    </Card>
  );
};
