import React from 'react';
import userSrc from 'assets/media/user.png';
import { Text } from 'components/typography/Text';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { StatusDot } from 'components/StatusDot';
import { useUserDetails } from 'services/user/details';
import { useUserAvatar } from 'services/user/avatar/hook';
import styles from './styles.module.scss';

export const UserDetailsPreview = () => {
  const { userDetails } = useUserDetails();

  const { avatar } = useUserAvatar();

  return (
    <>
      <CircularImage
        src={avatar || userSrc}
        alt="User avatar profile"
      />

      <div className={styles.nameLine}>
        <Text bold>
          {userDetails.firstName}
        </Text>

        <StatusDot active />
      </div>
    </>
  );
};
