export const BankIDLoginActions = {
  BANK_ID_LOGIN: '@bankID/login',
  BANK_ID_LOGIN_INIT_SUCCESS: '@bankID/login/init/success',
  BANK_ID_LOGIN_SUCCESS: '@bankID/login/success',
  BANK_ID_LOGIN_UPDATE_STATUS: '@bankID/login/update/status',
  BANK_ID_LOGIN_FAIL: '@bankID/login/fail',
  BANK_ID_LOGIN_GET_CREDENTIALS: '@bankID/login/get/credentials',

  BANK_ID_LOGIN_RESET: '@bankID/login/reset',
}

export function bankIDLogin(personalNumber) {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN,
    payload: personalNumber,
  }
}

export function bankIDLoginInitSuccess(orderRef) {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN_INIT_SUCCESS,
    payload: orderRef,
  }
}

export function bankIDLoginSuccess() {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN_SUCCESS
  }
}

export function bankIDLoginFail(error) {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN_FAIL,
    payload: error,
  }
}

export function bankIDLoginGetCredentials() {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN_GET_CREDENTIALS,
  }
}

export function bankIDLoginReset() {
  return {
    type: BankIDLoginActions.BANK_ID_LOGIN_RESET,
  }
}
