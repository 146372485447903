import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Button, Header, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from 'components/typography/Text';
import { ShouldRender } from 'components/ShouldRender';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { useTheme } from 'global/themes';
import { useForm, Controller } from 'react-hook-form'; 
import { emailValidationSchema } from 'utils/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import {
  FORGOT_PASS_VERIFY_PAGE, LOGIN_PAGE,
} from 'global/routes';
import classNames from 'classnames';
import FormErrors from '../../components/FormErrors';
import styles from './styles.module.scss';

export const CreatePassword = () => {

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(emailValidationSchema),
    mode: 'onSubmit',
  });

  const [cognitoError, setCognitoError] = useState(null);

  const intl = useIntl();
  const history = useHistory();
  const userData = watch();
  const { theme } = useTheme();

  const onSubmit = async () => {

    setCognitoError(null);

    // AWS Cognito integration
    try {
      await Auth.forgotPassword(userData.email);
      history.push(FORGOT_PASS_VERIFY_PAGE);
    } catch (error) {
      let err = null;
      // eslint-disable-next-line no-unused-expressions
      !error.message ? err = { message: error } : err = error;
      setCognitoError(err);
    }
  };

  return (
    <BasicPageLayout intlTitle="createPassword">
      <Form
        size="large"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header as="h1">
          <FormattedMessage id="createPasswordTitle" />
        </Header>

        <div className={styles.subtitle}>
          <Text
            size={16}
            gray
            intl="createPasswordSubtitle"
          />
        </div>

        <FormErrors formerrors={{ cognitoError }} />

        <Controller
          defaultValue=""
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Input
              className={classNames(theme.textColor, !!errors.email && 'error')}
              fluid
              id="email"
              name="email"
              label={intl.formatMessage({ id: 'email' })}
              placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
              icon="mail"
              iconPosition="left"
              type="email"
              aria-describedby="emailHelp"
              value={value}
              onChange={onChange}
            />
          )}
        />

        <ShouldRender condition={!!errors.email}>
          <Message negative>
            <Message.Content>
              <FormattedMessage 
                id={errors.email?.message}
                defaultMessage=""
              />
            </Message.Content>
          </Message>
        </ShouldRender>

        <div className={styles.buttons}>
          <a
            href={LOGIN_PAGE}
            onClick={(e) => {
              e.preventDefault();
              history.push(LOGIN_PAGE);
            }}
          >
            <FormattedMessage id="backToLogin" />
          </a>

          <Button
            name="send-recovery-code"
            type="submit"
            size="large"
          >
            <FormattedMessage id="sendCode" />
          </Button>
        </div>
      </Form>
    </BasicPageLayout>
  );
};
