import React, { useCallback, useEffect, useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { Brikk } from 'components/Brikk';
import { RSSFeedPreview, RSSFeedModal, AddRSSFeedModal } from 'components/brikks/society/RSSFeed/components';
import { useRSSFeed } from 'services/rss-feed/rss-feed';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { MyNewsIcon } from 'assets/icons/brikks/MyNewsIcon';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const RSSFeed = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { rssFeed, isLoading } = useRSSFeed();

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setSelectedArticle(null);
    }
  }, [isModalOpen, setSelectedArticle]);

  const onSelectArticle = useCallback((article) => {
    setSelectedArticle(article);
    setIsModalOpen(true);
  }, [setSelectedArticle, setIsModalOpen]);

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<MyNewsIcon />}
            heading="myNewsBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <RSSFeedPreview
              feed={rssFeed}
              onSelectArticle={onSelectArticle}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              disabled={!rssFeed.length}
              onClick={handleOpenModal}
              brikkNameIntl="myNews"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="My news info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        twoGrid
        isOpen={isModalOpen}
        heading="news"
        onClose={() => setIsModalOpen(false)}
      >
        {rssFeed.length ? (
          <RSSFeedModal
            preSelectedArticle={selectedArticle}
          />
        ) : <AddRSSFeedModal />}
      </BrikkModal>
    </>
  );
};
