import React from 'react';
import { Select } from 'components/form-elements/selects/Select';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';

export const LabeledSelect = ({
  placeholder, 
  options, 
  onChange, 
  className, 
  title, 
  description,
  hasError,
  isDisabled,
  ...props
}) => (
  <LabeledElement
    label={title}
    description={description}
  >
    <Select
      className={className}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      hasError={hasError}
      isDisabled={isDisabled}
      {...props}
    />
  </LabeledElement>
);

