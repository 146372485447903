import React from 'react';

export const HouseTemperatureIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.67" viewBox="0 0 20 16.67">
            <g id="smart-house-temperature" transform="translate(-6.001 0)">
                <path id="Path_6733" data-name="Path 6733" d="M14.667,4.5A.667.667,0,0,0,14,5.167V9.5a.334.334,0,0,0,.334.334H15a.334.334,0,0,0,.334-.334V5.167A.667.667,0,0,0,14.667,4.5Z" transform="translate(0.786 0.136)" />
                <path id="Path_6734" data-name="Path 6734" d="M25.75,7.648,16.464.167a.725.725,0,0,0-.925,0L6.253,7.648a.7.7,0,0,0-.21.778.715.715,0,0,0,.667.462H8.778a.556.556,0,0,1,.556.556v6.519a.71.71,0,0,0,.715.707H21.955a.709.709,0,0,0,.715-.7V9.446a.556.556,0,0,1,.556-.556h2.064a.715.715,0,0,0,.667-.462.7.7,0,0,0-.206-.78ZM15.446,15a2.223,2.223,0,0,1-1.728-3.62.272.272,0,0,0,.061-.173V5.557a1.667,1.667,0,1,1,3.334,0V11.21a.279.279,0,0,0,.061.173A2.223,2.223,0,0,1,15.446,15Zm4.445-5H18.78a.556.556,0,1,1,0-1.111h1.111a.556.556,0,1,1,0,1.111Zm0-2.223H18.78a.556.556,0,1,1,0-1.111h1.111a.556.556,0,1,1,0,1.111Z" transform="translate(0 0)" />
            </g>
        </svg>
    )
}