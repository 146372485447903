import React from 'react';
import Helmet from 'react-helmet';
import { useIntl } from 'react-intl';

export const IntlPageTitle = ({ intlTitle }) => {
  const intl = useIntl();

  return (
    <Helmet>
      <title>
        {intl.formatMessage({ id: 'pageTitle' })} - {intl.formatMessage({ id: intlTitle })}
      </title>
    </Helmet>
  )
};
