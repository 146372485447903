import { Text } from 'components/typography';
import React from 'react';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import { Card } from 'components/cards/Card';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { FocusableElement } from 'components/FocusableElement';
import styles from './styles.module.scss';

export function InternetSpeed({ upload, download, description, active, onClick }) {
  return (
    <FocusableElement onClick={onClick}>
      <Card
        grey
        className={classNames(styles.card, active && styles.active)}
      >
        <div>
          <Text
            bold
            uppercase
            size={25}
          >
            {upload}/{download} mbps
          </Text>

          <Text
            size={15}
          >
            {description}
          </Text>
        </div>

        <ShouldRender condition={active}>
          <CheckMarkIcon
            size={15}
            active
          />
        </ShouldRender>
      </Card>
    </FocusableElement>
  );
}
