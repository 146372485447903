import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { tenantConfig } from 'global/tenant-config';
import { mapDataToClient } from './mapData';

export async function getWeatherAPI(tenant) {
  const lat = tenant ? tenantConfig.tenants[tenant]?.openweathermapLat : tenantConfig.openweathermapLat;
  const lon = tenant ? tenantConfig.tenants[tenant]?.openweathermapLon : tenantConfig.openweathermapLon;
  
  const url = `${AWS_API_URL}/weather?lat=${lat}&lon=${lon}`;
  const data = await getRequest(url, await authorize());
  
  return mapDataToClient(data);
}
