export const mapDataForBackend = (customer) => ({
  email: customer.email,
  workemail: customer.workEmail,
  mobilephone: customer.mobileNumber,
  phone: customer.homeNumber,
  workphone: customer.workNumber,
  contactperson: customer.contactperson,
  contactperson2: customer.contactperson2,
  contactperson3: customer.contactperson3,
});
