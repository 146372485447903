import { subDays } from 'date-fns';
/**
 * Returns a full coverage period of current month.
 * @returns {{reportAfter: string, reportBefore: string}}
 */
export const currentMonthPeriod = () => {
  const dateNow = new Date();

  const newReportAfterDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
  const newReportBeforeDate = new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 1);

  newReportBeforeDate.setUTCMilliseconds(-1); // Change to the last ms.

  return {
    reportAfter: newReportAfterDate.toISOString(),
    reportBefore: newReportBeforeDate.toISOString(),
  };
};

/**
 * Returns a full coverage period of previous month.
 * Works with nay date and time of current month (using "reportAfter" for calculations).
 *
 * Example input:   { reportAfter: "2020-01-01T00:00:00.000Z", reportBefore: "2020-02-01T00:00:00.000Z" }
 * Example output:  { reportAfter: '2019-12-01T00:00:00.000Z', reportBefore: '2019-12-31T23:59:59.999Z' }
 *
 * Example2 input:  { reportAfter: "2020-03-01T13:50:33.333Z", reportBefore: "2020-03-31T23:00:00.000Z" }
 * Example2 output: { reportAfter: '2020-02-01T00:00:00.000Z', reportBefore: '2020-02-29T23:59:59.999Z' }
 *
 * @param   {{reportAfter: string, reportBefore: string}}   period  Defaults to current month period.
 * @return  {{reportAfter: string, reportBefore: string}}
 */
export const previousMonthPeriod = (period = currentMonthPeriod()) => {
  const reportAfterDate = new Date(period.reportAfter);

  const newReportAfterDate = new Date(reportAfterDate.getFullYear(), reportAfterDate.getMonth() - 1, 1);
  const newReportBeforeDate = new Date(reportAfterDate.getFullYear(), reportAfterDate.getMonth(), 1);

  newReportBeforeDate.setUTCMilliseconds(-1); // Change to the last ms of prev month.

  return {
    reportAfter: newReportAfterDate.toISOString(),
    reportBefore: newReportBeforeDate.toISOString(),
  };
};

/**
 * Returns a full coverage period of next month.
 * @param   {{reportAfter: string, reportBefore: string}}   period
 * @returns {{reportAfter: string, reportBefore: string}}
 */
export const nextMonthPeriod = (period) => {
  const reportAfterDate = new Date(period.reportAfter);

  const newReportAfterDate = new Date(reportAfterDate.getFullYear(), reportAfterDate.getMonth() + 1, 1);
  const newReportBeforeDate = new Date(reportAfterDate.getFullYear(), reportAfterDate.getMonth() + 2, 1);

  newReportBeforeDate.setUTCMilliseconds(-1); // Change to the last ms of next month.
 
  return {
    reportAfter: newReportAfterDate.toISOString(),
    reportBefore: newReportBeforeDate.toISOString(),
  };
};

/**
 * Returns a timeline of the last 30 days
 * @returns {{reportAfter: string, reportBefore: string}}
 */
export const getLast30Days = () => {
  const reportBefore = new Date();
  const reportAfter = subDays(reportBefore, 30);

  const newReportAfterDate = new Date(reportAfter.getFullYear(), reportAfter.getMonth(), reportAfter.getDate());
  const newReportBeforeDate = new Date(reportBefore.getFullYear(), reportBefore.getMonth(), reportBefore.getDate() + 1);

  newReportBeforeDate.setUTCMilliseconds(-1); 

  return {
    reportAfter: newReportAfterDate.toISOString(),
    reportBefore: newReportBeforeDate.toISOString(),
  };
};

/**
 * Returns a full coverage period of the previous day.
 * @returns {{reportAfter: string, reportBefore: string}}
 */
export const getYesterdayPeriod = () => {
  const reportBefore = new Date();

  const newReportAfterDate = new Date(reportBefore.getFullYear(), reportBefore.getMonth(), reportBefore.getDate() - 1);
  const newReportBeforeDate = new Date(reportBefore.getFullYear(), reportBefore.getMonth(), reportBefore.getDate());

  newReportBeforeDate.setUTCMilliseconds(-1);

  return {
    reportAfter: newReportAfterDate.toISOString(),
    reportBefore: newReportBeforeDate.toISOString(),
  };
};
