import bathingAndShowerSrc from 'assets/media/brikks/water-saver/bath-and-showers.jpg';
import brushingSrc from 'assets/media/brikks/water-saver/brushing.jpg';
import cookingSrc from 'assets/media/brikks/water-saver/cooking.jpg';
import dishwasherSrc from 'assets/media/brikks/water-saver/dishwasher.jpg';
import dishwashingSrc from 'assets/media/brikks/water-saver/dishwashing.jpg';
import laundrySrc from 'assets/media/brikks/water-saver/laundry.jpg';
import carSrc from 'assets/media/brikks/water-saver/the-car.jpg';
import toiletSrc from 'assets/media/brikks/water-saver/toilet.jpg';
import gardenPlotSrc from 'assets/media/brikks/water-saver/garden-plot.jpg';
import { WaterSavingTip } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal/components/WaterSavingTip';

export const HomeTabOptions = [
  { 
    menu: 'bathroom',
    submenus: [
      { title: 'bathingAndShower', content: WaterSavingTip, intl: 'bathingAndShowerDescription', contentImage: bathingAndShowerSrc },
      { title: 'toilet', content: WaterSavingTip, intl: 'toiletDescription', contentImage: toiletSrc },
      { title: 'brushing', content: WaterSavingTip, intl: 'brushingDescription', contentImage: brushingSrc },
    ],
  },
  {
    menu: 'kitchen',
    submenus: [
      { title: 'dishwashing', content: WaterSavingTip, intl: 'dishwashingDescription', contentImage: dishwashingSrc },
      { title: 'dishwasher', content: WaterSavingTip, intl: 'dishwasherDescription', contentImage: dishwasherSrc },
      { title: 'cooking', content: WaterSavingTip, intl: 'cookingDescription', contentImage: cookingSrc },
    ],
  },
  {
    menu: 'laundryRoom',
    submenus: [{ title: 'laundry', content: WaterSavingTip, intl: 'laundryRoomDescription', contentImage: laundrySrc }],
  },
  {
    menu: 'outside',
    submenus: [
      { title: 'gardenPlot', content: WaterSavingTip, intl: 'gardenDescription', contentImage: gardenPlotSrc },
      { title: 'theCar', content: WaterSavingTip, intl: 'carDescription', contentImage: carSrc },
    ],
  },
];
