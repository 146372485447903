import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { RIB, BRE } from 'global/tenant-config/tenants';
import { getLightningGroupsAPI } from './api';

const LIGHTNING_GROUPS = 'lightningGroups';

export function useLightningGroups() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [LIGHTNING_GROUPS, tenantId],
    () => getLightningGroupsAPI(),
    {
      enabled: [RIB, BRE].includes(tenantId),
    },
  );

  return {
    lightningGroups: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
