import React from 'react';
import { Input } from 'components/form-elements/inputs/Input';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';

export const LabeledInput = ({ labelIntl, id, description, className, hasError, disabled, ...props }) => (
  <LabeledElement
    label={labelIntl}
    description={description}
    id={id}
  >
    <Input
      className={className}
      hasError={hasError}
      disabled={disabled}
      {...props}
    />
  </LabeledElement>
);
