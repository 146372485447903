import React from 'react';
import classNames from 'classnames';
import {Card} from "semantic-ui-react";
import { useTheme } from 'global/themes';
import { FormattedMessage, useIntl } from 'react-intl';

const BrikkCalendar = (props) => {

  let months = [ "january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december" ];

  const { theme } = useTheme();

  const intl = useIntl();

  return (
    <div className='brikk-calendar'>
      <Card className={theme.boxShadow}>
        <Card.Content className='header'>
          <div>
            <FormattedMessage
              id={intl.formatMessage({ id: months[parseInt(props.month) - 1] }).substr(0, 3)}
            />
          </div>
        </Card.Content>

        <Card.Content className={classNames('body', theme.iconBackgroundSecondaryColor)}>
          <div className={classNames('calendar-date', theme.textColor)}>
            {props.date}
          </div>

          <div className={classNames('calendar-text text-1')}>
            {props.text1}
          </div>

          <div className={classNames('calendar-text text-2')}>
            {props.text2}
          </div>

          <div className={classNames('calendar-text text-3')}>
            {props.text3}
          </div>
        </Card.Content>
      </Card>
    </div>
  );
};

export default BrikkCalendar;
