import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { HomeTabOptions, GeneralTabOptions } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal/tabOptions';

export const WaterTips = () => (
  <MultiTabMenu
    title="waterTips"
    defaultTab="atHome"
    tabs={{
      atHome: {
        title: 'atHome',
        menus: HomeTabOptions },
      inGeneral: {
        title: 'inGeneral',
        menus: GeneralTabOptions,
      },
    }}
  />
);
