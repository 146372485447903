import React, { useContext } from "react";
import { Button } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import styles from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/Tabs/styles.module.scss";
import { CleaningAndMaintenanceModalContext } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/index";

export const Tabs = ({ activeTab, setActiveTab }) => {
  const { setActiveMenu } = useContext(CleaningAndMaintenanceModalContext);

  const handleChangeTab = (tab) => {
    if (tab === "checkList") {
      setActiveTab(tab);
      setActiveMenu('kitchen');
    } else {
      setActiveTab(tab);
      setActiveMenu('');
    }
  };
  return (
    <Button.Group className={styles.tabs}>
      <Button
        className={activeTab === "checkList" ? styles.button : ""}
        positive={activeTab === "checkList"}
        onClick={() => handleChangeTab("checkList")}
      >
        <FormattedMessage id={"checkList"} />
      </Button>
      <Button.Or className={styles.or} />
      <Button
        className={activeTab === "generalInfo" ? styles.button : ""}
        positive={activeTab === "generalInfo"}
        onClick={() => handleChangeTab("generalInfo")}
      >
        <FormattedMessage id={"generalInfo"} />
      </Button>
    </Button.Group>
  );
};
