import React from 'react';
import { Grid } from 'components/layout/Grid';
import { AddGroup, EditGroup } from 'components/brikks/smartness/Lights/components/LightningGroups/components';

export const ManageGroups = () => (
  <Grid
    oneColumn
    noPadding
  >
    <AddGroup />

    <EditGroup />
  </Grid>
);
