import * as Yup from 'yup';

export const lightningScenarioSchema = Yup.object().shape({
  name: Yup.string()
    .required(''),
  group: Yup.object()
    .required('')
    .shape({
      label: Yup.string().required(''),
      value: Yup.string().required(''),
    })
    .nullable(), 
});
