import React, { useState } from 'react';
import { Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ToggleSwitch } from 'components/form-elements/toggles/ToggleSwitch';
import { Text } from 'components/typography/Text';
import { EmailLogin } from './components/EmailLogin';
import { BankIDLogin } from './components/BankIDLogin';
import styles from './styles.module.scss';

export const LoginForm = () => {
  const [shouldLoginWithBankID, setShouldLoginWithBankID] = useState(false);

  return (
    <>
      <Header
        className={styles.header}
        as="h1"
      >
        <FormattedMessage
          id="loginTitle"
          values={{
            span: (...chunks) => (
              <span className={styles.strong}>
                {chunks}
              </span>
            ),
          }}
        />
      </Header>

      <div className={styles.subtitle}>
        <Text
          size={16}
          gray
          intl="loginSubtitle"
          intlValues={{
            a: (...chunks) => (
              <a
                href="https://minasidor.lkf.se/User/MyPagesLogin.aspx?cmguid=c80865e7-dea9-4e5d-ad2c-ca43073f16c4"
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>

      <div className={styles.loginForm}>
        <ToggleSwitch
          option1="email"
          option2="bankID"
          option1AriaLabel="Login via email"
          option2AriaLabel="Login via BankID"
          isOption1Active={!shouldLoginWithBankID}
          onSelectOption1={() => setShouldLoginWithBankID(false)}
          onSelectOption2={() => setShouldLoginWithBankID(true)}
        />

        <div className={styles.form}>
          {shouldLoginWithBankID ? <BankIDLogin /> : <EmailLogin />}
        </div>
      </div>
    </>
  );
};
