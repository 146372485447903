import React, { useContext, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { DragAndDropContext } from '../DragAndDrop';

export const Draggable = ({ type = 'Draggable', position, children }) => {
  const ref = useRef(null);
  const { changePosition, enabled } = useContext(DragAndDropContext);

  const [, drop] = useDrop({
    accept: type,
    hover(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.position;
      const hoverIndex = position;

      if (dragIndex === hoverIndex) {
        return;
      }

      changePosition(dragIndex, hoverIndex);
      item.position = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: { position },
    canDrag: enabled,
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      draggable={false}
      ref={ref}
    >
      {React.cloneElement(children, { isDragging, isDraggingEnabled: enabled })}
    </div>
  );
};

export default Draggable;
