import React from 'react';

export const CoolingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.628"
      height="14.5"
      viewBox="0 0 14.628 14.5"
    >
      <g id="ice-water" transform="translate(-4.686)">
        <path
          id="Path_8956"
          data-name="Path 8956"
          d="M5.711,6.641a.974.974,0,0,0,.26-.035L7.861,6.1a.253.253,0,0,1,.19.025l1.573.908a.251.251,0,0,1,0,.434l-1.573.908a.253.253,0,0,1-.19.025l-1.89-.506a1,1,0,1,0-.518,1.931l1.183.317a.25.25,0,0,1,.177.307L6.5,11.632a1,1,0,0,0,.7,1.224.974.974,0,0,0,.26.035,1,1,0,0,0,.965-.742l.506-1.89a.251.251,0,0,1,.117-.152L10.625,9.2A.25.25,0,0,1,11,9.415v1.817a.249.249,0,0,1-.073.177L9.543,12.793a1,1,0,1,0,1.414,1.414l.866-.866a.25.25,0,0,1,.354,0l.866.866a1,1,0,1,0,1.414-1.414l-1.384-1.384A.249.249,0,0,1,13,11.232V9.415a.25.25,0,0,1,.375-.216l1.574.908a.251.251,0,0,1,.117.152l.506,1.89a1,1,0,0,0,.965.742.974.974,0,0,0,.26-.035,1,1,0,0,0,.707-1.224l-.317-1.183a.25.25,0,0,1,.177-.307l1.183-.317a1,1,0,1,0-.518-1.931l-1.89.506a.253.253,0,0,1-.19-.025l-1.573-.908a.251.251,0,0,1,0-.434l1.573-.908a.253.253,0,0,1,.19-.025l1.89.506a.974.974,0,0,0,.26.035,1,1,0,0,0,.258-1.966l-1.183-.317a.25.25,0,0,1-.177-.307L17.5,2.868a1,1,0,1,0-1.932-.517l-.506,1.89a.251.251,0,0,1-.117.152l-1.57.907A.25.25,0,0,1,13,5.085V3.268a.249.249,0,0,1,.073-.177l1.384-1.384A1,1,0,1,0,13.043.293l-.866.866a.25.25,0,0,1-.354,0L10.957.293A1,1,0,1,0,9.543,1.707l1.384,1.384A.249.249,0,0,1,11,3.268V5.085a.25.25,0,0,1-.375.216L9.051,4.393a.251.251,0,0,1-.117-.152l-.506-1.89A1,1,0,0,0,6.5,2.868l.317,1.183a.25.25,0,0,1-.177.307l-1.183.317a1,1,0,0,0,.258,1.966Z"
        />
      </g>
    </svg>
  );
};
