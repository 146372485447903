import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { LKF } from 'global/tenant-config/tenants';
import { maintenanceData } from './mockData';
import { mapDataToClient } from './mapData';
import { getMaintenanceAPI } from './api';

const MAINTENANCE = 'maintenance';

export function useMaintenance() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [MAINTENANCE, tenantId],
    () => getMaintenanceAPI(),
    {
      enabled: tenantId !== LKF,
    },
  );

  return {
    maintenance: tenantId === LKF ? mapDataToClient(maintenanceData) : data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
