import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getLocale, updateBrowserLocale, updateLocale } from './actions';

export function useLocale(isAuthenticated = false) {
  const { locale, calledOnce } = useSelector(state => state.locale);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!calledOnce) {
      dispatch(getLocale())
    }
  }, [calledOnce, dispatch]);

  return {
    locale,
    setLocale: (locale) => isAuthenticated ? dispatch(updateLocale(locale)) : dispatch(updateBrowserLocale(locale)),
  }
}
