import { BankIDSigningActions } from './actions';

const initialState = {
  isSuccessful: false,
  isInitSuccessful: false,
  loading: false,
  orderRef: '',
  error: '',
}

export function bankIDSigningReducer(state = initialState, action) {
  switch (action.type) {
    case BankIDSigningActions.BANK_ID_SIGNING:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case BankIDSigningActions.BANK_ID_SIGNING_INIT_SUCCESS:
      return {
        ...state,
        isInitSuccessful: true,
        loading: false,
        orderRef: action.payload,
      }
    case BankIDSigningActions.BANK_ID_SIGNING_SUCCESS:
      return {
        ...state,
        isSuccessful: true,
        loading: false,
        isInitSuccessful: false,
      }
    case BankIDSigningActions.BANK_ID_SIGNING_FAIL:
      return {
        ...state,
        loading: false,
        isInitSuccessful: false,
        error: action.payload,
      }
    case BankIDSigningActions.BANK_ID_SIGNING_RESET:
      return initialState;
    default:
      return state;
  }
}
