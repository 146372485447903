import React, { useContext } from 'react';
import { Text } from 'components/typography/Text';
import { Radio } from 'components/form-elements/radios/Radio';
import { AppContext } from 'AppContext';
import { LKF, GRT_LIF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const OnboardingSettings = () => {

  const { userSettings: { runOnboarding, updateUserSettings }, tenant } = useContext(AppContext);
  const tenantId = getTenantIDBasedOnURL();

  return (
    <ShouldRender condition={tenantId !== LKF && (!tenant || tenant === GRT_LIF)}>
      <div className={styles.onboardingSettings}>
        <div className={styles.headingWrapper}>
          <Text
            element="h3"
            thin
            className={styles.heading}
            medium
            uppercase
            white
            intl="onboarding"
          />
        </div>

        <div className={styles.settings}>
          <div className={styles.checkbox}>
            <Text
              white
              intl="runOnboarding"
            />

            <Radio
              toggle
              checked={!!runOnboarding}
              onChange={() => updateUserSettings({ runOnboarding: !runOnboarding })}
            />
          </div>
        </div>
      </div>
    </ShouldRender>
  );
};

