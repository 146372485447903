import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { getSensorsAPI } from './api';
import { mapDataToClient } from './mapData';

const SENSORS = 'sensors';

export function useSensors() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [SENSORS, tenantId],
    () => getSensorsAPI(),
  );

  return {
    sensors: mapDataToClient(data?.humidityAndTemperature) || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
