import React from 'react';
import * as ReactIntl from 'react-intl';
import { Image } from 'semantic-ui-react';
import { TooltipModal } from 'components/ContentField/components/TooltipModal';
import { Table } from 'components/Table';
import { Text } from 'components/typography';
import Loader from 'components/loaders/Loader';
import { InternetProviderContext } from 'components/brikks/store/InternetProviders/components/InternetProvidersModal';
import { extractNumbers } from 'utils/format/extractNumbers';
import { useInternetProviders } from 'services/internet-providers/providers';
import { parseTextToHtml } from 'utils/format';
import { Campaign } from '../Campaign';
import styles from './styles.module.scss';

export function InternetSuppliers() {

  const { internetProvider, setInternetProvider, selectedSpeed } = React.useContext(InternetProviderContext);
  const { internetProviders, activationDate, isLoading } = useInternetProviders();
  const intl = ReactIntl.useIntl();

  const columns = [
    {
      label: 'Provider',
      Header: intl.formatMessage({ id: 'provider' }),
      accessor: 'logoUrl',
      Cell: ({ cell: { value } }) => (
        <Image
          src={value}
          alt="Internet Provider Logo"
          className={styles.img}
        />
      ) },
    {
      label: 'Supplier Info',
      Header: intl.formatMessage({ id: 'supplierInfo' }),
      Cell: ({ cell: { row } }) => (
        <>
          {
            row.original.campaign && (
            <Campaign
              offer={intl.formatMessage({ id: 'deal' })}
            />
            )
          }

          <TooltipModal
            image={row.original.logoUrl}
            content={parseTextToHtml(row.original.campaign && row.original.campaignDescription
              ? row.original.campaignDescription : row.original.description)}
          />
        </>
      ),
    },
    {
      label: 'Cost',
      Header: intl.formatMessage({ id: 'monthlyCost' }),
      accessor: 'cost',
      Cell: ({ cell: { row } }) => (
        row.original.campaign && row.original.cost !== row.original.campaign?.defaultCost ? (
          <Campaign
            offer={`${row.original.cost} kr`}
            value={`${row.original.campaign?.defaultCost} kr`}
          />
        ) : `${row.original.cost} kr`
      ),
    },
    {
      label: 'Setup of Account',
      Header: intl.formatMessage({ id: 'setupOfAccount' }),
      accessor: 'entryFee',
      Cell: ({ cell: { row } }) => (
        row.original.campaign && row.original.entryFee !== row.original.campaign?.defaultEntryFee ? (
          <Campaign
            offer={`${row.original.entryFee} kr`}
            value={`${row.original.campaign?.defaultEntryFee} kr`}
          />
        ) : `${row.original.entryFee} kr`
      ),
    },
    {
      label: 'Duration',
      Header: intl.formatMessage({ id: 'duration' }),
      accessor: 'duration',
      Cell: ({ cell: { row } }) => (
        row.original.campaign && row.original.duration !== row.original.campaign?.durationOfCampaign ? (
          <Campaign
            offer={`${extractNumbers(row.original.campaign?.durationOfCampaign)} month(s)`}
            value={`${extractNumbers(row.original.duration)} month(s)`}
            textDecoration="none"
          />
        ) : `${extractNumbers(row.original.duration)} month(s)`
      ),
    },
  ];
  
  if (isLoading) return <Loader />;

  const filteredData = selectedSpeed ? internetProviders.filter((item) => item.upSpeed === selectedSpeed.upload && item.downSpeed === selectedSpeed.download) : internetProviders;

  return (
    filteredData.length
      ? (
        <Table
          data={filteredData}
          columns={columns}
          clickedRow={internetProvider}
          activationDate={activationDate}
          onRowClick={setInternetProvider}
        />
      ) : (
        <Text
          secondary
          center
          intl="noSuppliers"
          size={16}
        />
      )
  ); 
}
