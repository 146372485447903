import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Form, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useConfirmEmail } from 'services/auth/confirm-email/hook';
import { ShouldRender } from 'components/ShouldRender';
import { PasswordLogin } from './components/PasswordLogin';
import { LoginContext } from '../../../../index';
import { PrimaryButton } from 'components/form-elements/buttons/PrimaryButton';

export const EmailLogin = () => {
  const { email, setEmail } = useContext(LoginContext);
  const [emailError, setEmailError] = useState('');

  const intl = useIntl();

  const { isEmailConfirmed, loading, error, onConfirmEmail } = useConfirmEmail();

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
    setEmailError('');
  }, [setEmail, setEmailError]);

  const onValidateEmail = useCallback(() => {
    if (!email.trim().length) {
      setEmailError('fieldRequired');
    } else {
      onConfirmEmail(email);
    }
  }, [email, setEmailError, onConfirmEmail]);

  return (
    <div className={styles.emailLogin}>
      <Form.Input
        className={classNames(styles.field, emailError && 'error')}
        fluid
        id="username"
        name="email"
        label={intl.formatMessage({ id: 'yourEmail' })}
        placeholder={intl.formatMessage({ id: 'emailPlaceholder' })}
        value={email}
        onChange={onChangeEmail}
      />

      <ShouldRender condition={!isEmailConfirmed}>
        <PrimaryButton
          name="login"
          className={styles.login}
          loading={loading}
          disabled={loading}
          onClick={onValidateEmail}
        >
          <FormattedMessage id="confirm" />
        </PrimaryButton>
      </ShouldRender>

      <ShouldRender condition={isEmailConfirmed}>
        <PasswordLogin
          email={email}
          emailError={emailError}
          setEmailError={setEmailError}
        />
      </ShouldRender>

      <ShouldRender condition={!!emailError || !!error}>
        <Message
          negative
          className={styles.field}
        >
          <FormattedMessage id={emailError || error} />
        </Message>
      </ShouldRender>
    </div>
  )
};
