import React from 'react';
import styles from './styles.module.scss';

export const PDFIcon = ({ size = 24 }) => (
  <svg
    className={styles.icon}
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
          d="M8.451,12.322c-.1-.046-.326-.048-.326.164v3.978a.238.238,0,0,0,.327.21,2.375,2.375,0,0,0,0-4.352Z"/>
    <path
          d="M4,12.123H3.377a.252.252,0,0,0-.252.252v1.246a.252.252,0,0,0,.252.252H4a.875.875,0,1,0,0-1.75Z"/>
    <path
          d="M18,19V10a1,1,0,0,0-1-1H1a1,1,0,0,0-1,1v9a1,1,0,0,0,1,1H17A1,1,0,0,0,18,19ZM4,15.123H3.377a.252.252,0,0,0-.252.252V17.5a.625.625,0,0,1-1.25,0v-6a.625.625,0,0,1,.625-.625H4a2.125,2.125,0,0,1,0,4.25Zm3.5,3a.625.625,0,0,1-.625-.625v-6a.625.625,0,0,1,.625-.625,3.625,3.625,0,1,1,0,7.25Zm8-6h-2a.375.375,0,0,0-.375.375v1.123a.252.252,0,0,0,.252.252H14.5a.625.625,0,0,1,0,1.25H13.377a.252.252,0,0,0-.252.252V17.5a.625.625,0,0,1-1.25,0v-5A1.627,1.627,0,0,1,13.5,10.873h2a.625.625,0,0,1,0,1.25Z"/>
    <path
          d="M23.707,5.7,18.293.291A1,1,0,0,0,17.585,0H6A2,2,0,0,0,4,2V7.75A.25.25,0,0,0,4.25,8h1.5A.25.25,0,0,0,6,7.748V2.5A.5.5,0,0,1,6.5,2H16.75a.25.25,0,0,1,.25.25V5a2,2,0,0,0,2,2h2.75a.25.25,0,0,1,.25.25V21.5a.5.5,0,0,1-.5.5H6.25A.25.25,0,0,1,6,21.75v-.5A.248.248,0,0,0,5.751,21H4.25a.25.25,0,0,0-.25.25V22a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V6.412a1,1,0,0,0-.293-.707Z"/>
  </svg>
);
