import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography';

export function Campaign({ value, offer, textDecoration = 'line-through' }) {
  return (
    <Grid
      oneColumn
      noPadding
      style={{ gridGap: 20 }}
    >
      <Text
        red
        bold
        size={14}
      >
        {offer}
      </Text>

      <Text textDecoration={textDecoration}>
        {value}
      </Text>
    </Grid>
  );
}
