import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { ALREADY_EXISTS } from 'utils/http';
import { createLightningScenarioAPI } from './api';

const LIGHTNING_SCENARIOS = 'lightningScenarios';

export function useCreateLightningScenario({ onSuccess }) {
  const { openModal } = useModal();
  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (scenario) => createLightningScenarioAPI(scenario),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'createScenarioSuccess',
          },
        });
        queryClient.invalidateQueries([LIGHTNING_SCENARIOS, tenantId]);
        onSuccess();
      },
      onError: (error) => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: error.errorMessage === ALREADY_EXISTS ? 'scenarioAlreadyExists' : 'createScenarioFail',
          },
        });
      },
    },
  );

  return {
    createLightningScenario: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
