import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { LightModeIcon } from 'assets/icons/brikks/LightModeIcon';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { LightsModal } from './components/LightsModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { LightsPreview } from './components/LightsPreview';

export const Lights = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<LightModeIcon />}
            heading="lightsBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <LightsPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="lights"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Lights info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="lights"
        onClose={() => setIsModalOpen(false)}
      >
        <LightsModal />
      </BrikkModal>
    </>
  );
};
