import React from 'react';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';
import { TimePicker } from '../TimePicker';

export const LabeledTimePicker = ({ caption, value, onChange, timeInterval, title }) => (
  <LabeledElement
    label={title}
  >
    <TimePicker 
      caption={caption}
      value={value}
      onChange={onChange}
      timeInterval={timeInterval}
    />
  </LabeledElement>
);
