import React from 'react';
import styles from './styles.module.scss';

export const ImageContent = ({ imageSrc, children }) => (
  <div className={styles.imageContent}>
    <div
      className={styles.image}
      style={{ backgroundImage: `url(${imageSrc})` }}
    />

    <div className={styles.content}>
      {children}
    </div>
  </div>
);
