import React from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { tenantConfig } from 'global/tenant-config';
import { ImageContentCard } from 'components/cards/ImageContentCard';
import { AppHeader } from '../../headers/AppHeader';
import styles from './styles.module.scss';

export const BasicPageLayout = ({ children, intlTitle }) => (
  <div>
    <IntlPageTitle intlTitle={intlTitle} />

    <AppHeader />

    <div className={styles.content}>
      <ImageContentCard imageSrc={tenantConfig.assets.welcome}>
        {children}
      </ImageContentCard>

    </div>
  </div>
);
