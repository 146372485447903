import React from 'react';
import { useQueryClient } from 'react-query';
import { LaundryIcon } from 'assets/icons/brikks/LaundryIcon';
import laundrySrc from 'assets/media/laundry.png';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { LinkBrikkContent } from 'components/brikks/sub-components/LinkBrikkContent';
import { ShouldRender } from 'components/ShouldRender';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import LaundryServiceModal from 'components/brikks/home/LaundryService/components/LaundryServiceModal';
import { useLaundryLink } from 'services/laundry/link';
import { getBaseUrl } from 'utils/http';
import { BRE } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';

const LAUNDRY_LINK = 'laundryLink';

export function LaundryService({ position, status, code, name }) {

  const { laundryLink, isLoading, isError } = useLaundryLink();

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const tenantId = getTenantIDBasedOnURL();
  const queryClient = useQueryClient();

  const laundryPreviewLink = React.useMemo(() => (
    tenantId !== BRE ? getBaseUrl(laundryLink) : 'aptus.se'
  ), [tenantId, laundryLink]);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    queryClient.invalidateQueries(LAUNDRY_LINK);
    setIsModalOpen(true);
  };

  function handleInfoModal(e) {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  }

  return (
    <ShouldRender condition={!isError}>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<LaundryIcon />}
            heading="laundryBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content>
            <CircularImage
              src={laundrySrc}
              alt="Illustrations of a washing machine"
            />

            <LinkBrikkContent link={laundryPreviewLink} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="laundryBrikk"
            />

            <InfoButton
              showModal={isInfoModalOpen}
              onClick={(e) => handleInfoModal(e)}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Laundry service info"
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <LaundryServiceModal
        isOpen={isModalOpen}
        onClose={() => { setIsModalOpen(false); }}
      />
    </ShouldRender>
  );
}
