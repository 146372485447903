import React from 'react';
import { useIntl } from 'react-intl';
import { DeleteButton } from 'components/form-elements/buttons/DeleteButton';
import Loader from 'components/loaders/Loader';
import { useDeleteLightningScenario } from 'services/lightning/delete-scenario';
import { useModal } from 'store/modals';

export const DeleteScenario = ({ selectedScenario, setSelectedScenario }) => {
  const intl = useIntl();
  const { openModal } = useModal();

  const { deleteLightningScenario, isLoading } = useDeleteLightningScenario({
    onSuccess: () => {
      setSelectedScenario(null);
    },
  });

  function onDeleteScenario() {
    openModal({
      modalType: 'ConfirmationModal',
      modalProps: {
        title: intl.formatMessage(
          {
            id: 'deleteScenarioConfirm',
          },
          {
            group: selectedScenario.label,
          },
        ),
        onConfirm: () => deleteLightningScenario(selectedScenario.value),
      },
    });
  }

  if (isLoading) return <Loader />;

  return (
    <DeleteButton
      intl="deleteScenario"
      onClick={() => onDeleteScenario()}
    />
  );
};
