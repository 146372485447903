import React from 'react';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const FocusableElement = ({
  element = 'div',
  className,
  children,
  style,
  onClick = () => {},
  onBlur = () => {},
  onFocus = () => {},
  role,
  ariaLabel,
}) => {
  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  return (
    React.createElement(element, {
      className: classNames(styles.focusableElement, className),
      onClick,
      onKeyPress,
      tabIndex: 0,
      role,
      onBlur,
      onFocus,
      style,
      'aria-label': ariaLabel,
    }, children)
  );
};
