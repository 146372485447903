import React, {useState} from 'react';
import {Button, Form, Icon} from "semantic-ui-react";
import {EyeIconClosed} from "assets/icons/EyeIconClosed";
import {EyeIcon} from "assets/icons/EyeIcon";
import PropTypes from 'prop-types';

/**
 * @component Password input field with an eye button to disclose the password to the user
 * @param {number|string} [iconHeight = 24] - Optional
 * @param {string} [eyeButtonOffsetRight = '20px'] - Optional
 * @param {string} [icon] - Icon for the input form from Semantic-UI, not the eye icon.
 * @param submitRef - Required to refocus on the submit button after clicking the reveal(eye) button.
 * Wrap the Submit Button component with the <Ref innerRef={submitRef}> tag from Semantic-UI.
 * @param type - This exists just to catch if "type" is send by mistake.
 * @param props
 * @returns {JSX.Element}
 */
export const PasswordInput = ({iconHeight = 24, eyePositionRight: eyeButtonOffsetRight = '20px', icon, type, submitRef, ...props}) => {
  const [passwordFieldType, setPasswordFieldType] = useState('password');

  const handleToggleShowPassword = event => {
    event.preventDefault();
    setPasswordFieldType(prevState => prevState === 'password' ? 'text' : 'password');
    submitRef && submitRef.current.focus();
  }

  const button =
    <Button
      onClick={handleToggleShowPassword}
      type='button'
      style={{
        backgroundColor: 'transparent',
        padding: '0',
        margin: '0',
        position: 'absolute',
        top: `calc(50% - (${iconHeight}px / 2))`,
        right: `${eyeButtonOffsetRight}`,
      }}
    >
      {passwordFieldType === 'password' ? <EyeIconClosed/> : <EyeIcon/>}
    </Button>;

  return (
    <Form.Input
      type={passwordFieldType}
      {...props}
    >
      <input/>
      {icon ? <Icon name={icon}/> : null}
      {button}
    </Form.Input>
  )
}

PasswordInput.propTypes = {
  submitRef: PropTypes.object.isRequired
}
