import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

export async function getDevicesAPI(spaceId) {

  const url = `${AWS_API_URL}/get-units/${spaceId}`;

  try {
    const agreements = await getRequest(url, await authorize());

    return Promise.resolve(
      agreements.map((device) => mapDataToClient(device)),
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

