import React from 'react';
import { LaundryIcon } from 'assets/icons/brikks/LaundryIcon';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { Image } from 'components/media/Image';
import { DemoModal } from 'components/modals/DemoModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import brikkPreviewSrc from './assets/laundry-brikk-preview.png';
import brikkModalSrc from './assets/laundry-modal.png';

export function Laundry({ position, status, code, name }) {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  function handleInfoModal(e) {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  }

  function handleOpenModal(e) {
    e.stopPropagation();
    setIsModalOpen(true);
  }

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<LaundryIcon />}
            heading="laundryBookingsBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={(e) => handleOpenModal(e)}
              brikkNameIntl="laundryBookingsBrikk"
            />

            <InfoButton
              showModal={isInfoModalOpen}
              onClick={(e) => handleInfoModal(e)}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Laundry info"
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <DemoModal
        isOpen={isModalOpen}
        heading="laundryBookingsBrikk"
        onClose={() => setIsModalOpen(false)}
      >
        <Image src={brikkModalSrc} />
      </DemoModal>
    </>
  );
}
