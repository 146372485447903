import { AWS_API_URL } from 'global/environment';
import { authorize, getRequest, postRequest } from 'utils/http';

export async function getUserSettingsAPI() {
  const url = `${AWS_API_URL}/user-settings`;
  return getRequest(url, await authorize());
}

export async function updateUserSettingsAPI(userSettings) {
  const url = `${AWS_API_URL}/user-data`;

  if (userSettings.brikksOrder) {
    userSettings.brikksOrder = JSON.stringify(userSettings.brikksOrder);
  }


  return postRequest(url, userSettings, await authorize());
}
