import React from 'react';
import styles from 'components/brikks/neighbourhood/Weather/components/WeatherPreview/styles.module.scss';
import { WeatherIcon } from 'assets/icons/brikks/WeatherIcon';
import { Text } from 'components/typography/Text';
import WeatherSlider from 'components/brikks/neighbourhood/Weather/components/WeatherPreview/components/WeatherSlider';

export const WeatherPreview = ({ weather, error }) => (
  <div>
    {!error ? (
      <WeatherSlider weather={weather} />
    ) : (
      <>
        <div className={styles.noWeatherData}>
          <WeatherIcon />
        </div>

        <Text
          center
          bold
          gray
          large
          intl="weatherDataNotFound"
        />
      </>
    )}
  </div>
);
