import React from 'react';
import * as ReactIntl from 'react-intl';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { MultiStepContext } from 'components/MultiStep';
import { PrevStep } from 'components/form-elements/buttons/PrevStep';
import { Text } from 'components/typography';
import { InternetProviderContext } from 'components/brikks/store/InternetProviders/components/InternetProvidersModal';
import { Image } from 'components/media/Image';
import { parseTextToHtml } from 'utils/format';
import { StepProgress } from '../StepProgress';
import { InternetSpeed } from '../InternetProviders/components/InternetSpeed';
import { InternetConfirmation } from './InternetConfirmation';

export function Checkout() {
  const { onPrevStep } = React.useContext(MultiStepContext);
  const { internetProvider, selectedSpeed } = React.useContext(InternetProviderContext);
  const intl = ReactIntl.useIntl();

  return (
    <div>
      <Grid brikkLayout>
        <Grid.Column>
          <Brikk.Heading heading="supplier" />

          <Image
            src={internetProvider.logoUrl} 
            alt={internetProvider.name}
          />

          <Text
            size={16}
          >
            {
              parseTextToHtml(internetProvider.campaign && internetProvider.campaignDescription
                ? internetProvider.campaignDescription : internetProvider.description)
            }
          </Text>
          
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="chosenOption" />

          <InternetSpeed
            key={selectedSpeed.id}
            description={intl.formatMessage({ id: selectedSpeed.description })}
            download={selectedSpeed.download}
            upload={selectedSpeed.upload}
            active
          />

          <Text
            intl={`internetSpeed${selectedSpeed.upload}`}
            size={16}
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="confirm" />

          <InternetConfirmation internetProvider={internetProvider} />
        </Grid.Column>
      </Grid>

      <PrevStep onClick={onPrevStep} />

      <StepProgress />
    </div>
  );
}
