
import React from 'react';

export const PowerButtonIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.194" height="20" viewBox="0 0 18.194 20">
    <g id="power-button" transform="translate(-1.089)">
      <path id="Path_7929" data-name="Path 7929" d="M10.5,9.1a1.25,1.25,0,1,0,2.5,0V1.25a1.25,1.25,0,1,0-2.5,0Z" transform="translate(-1.567 0)" />
      <path id="Path_7930" data-name="Path 7930" d="M1.182,10.142A9.091,9.091,0,1,0,14.406,3.364a1.25,1.25,0,1,0-1.2,2.192,6.708,6.708,0,0,1,3.5,4.938,6.6,6.6,0,1,1-13.058,0,6.7,6.7,0,0,1,3.5-4.936,1.25,1.25,0,1,0-1.2-2.193,9.181,9.181,0,0,0-4.767,6.774Z" transform="translate(0 -0.53)"/>
    </g>
  </svg>
);