import React from 'react';

export const DimmerIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60.431 60.431"
    >
      <g
        id="Group_6512"
        data-name="Group 6512"
        transform="translate(-737.446 -785.231)"
      >
        <g
          id="browser-user-help-message"
          transform="translate(782.946 818.367)"
        >
          <circle
            id="_13465ec411588c767533ccf3f196d100"
            data-name="13465ec411588c767533ccf3f196d100"
            cx="30.216"
            cy="30.216"
            r="30.216"
            transform="translate(-45.5 -33.136)"
            fill="#3a3a3a"
          />

          <g
            id="ceiling-lamp"
            transform="translate(-31.326 -20.308)"
          >
            <path
              id="Path_8273"
              data-name="Path 8273"
              d="M12.449,20.5A1.449,1.449,0,0,0,11,21.949v2.174a1.449,1.449,0,0,0,2.9,0V21.949A1.449,1.449,0,0,0,12.449,20.5Z" transform="translate(3.591 9.206)"
              fill="#00b72b"
            />

            <path
              id="Path_8274"
              data-name="Path 8274"
              d="M18.7,19.885a1.45,1.45,0,1,0-2.511,1.449l1.087,1.884a1.45,1.45,0,0,0,2.511-1.449Z"
              transform="translate(5.835 8.604)"
              fill="#00b72b"
            />

            <path
              id="Path_8275"
              data-name="Path 8275"
              d="M8.511,19.354a1.449,1.449,0,0,0-1.981.53L5.443,21.768a1.45,1.45,0,0,0,2.513,1.449l1.087-1.884A1.449,1.449,0,0,0,8.511,19.354Z"
              transform="translate(1.008 8.604)"
              fill="#00b72b"
            />

            <path
              id="Path_8276"
              data-name="Path 8276"
              d="M21.292,10.506a1.449,1.449,0,0,1-.869-1.329V7.97a4.327,4.327,0,0,0-2.511-3.912.725.725,0,0,1-.423-.658V1.449a1.449,1.449,0,1,0-2.9,0V3.4a.725.725,0,0,1-.417.656A4.327,4.327,0,0,0,11.662,7.97V9.177a1.449,1.449,0,0,1-.869,1.329,12,12,0,0,0-7.777,9.6,1.437,1.437,0,0,0,.355,1.139,1.464,1.464,0,0,0,1.088.49H27.622a1.452,1.452,0,0,0,1.448-1.63,12.007,12.007,0,0,0-7.777-9.6Z"
              transform="translate(0)"
              fill="#00b72b"
            />

            <path
              id="Path_8277"
              data-name="Path 8277"
              d="M14.292,20.709a10.184,10.184,0,0,0,7.057-2.837A1.087,1.087,0,0,0,20.6,16H19.485a1.091,1.091,0,0,0-.669.232,7.268,7.268,0,0,1-9.048,0A1.091,1.091,0,0,0,9.1,16H7.984a1.085,1.085,0,0,0-.749,1.872A10.181,10.181,0,0,0,14.292,20.709Z"
              transform="translate(1.748 7.185)"
              fill="#00b72b"
            />
          </g>
        </g>
      </g>
    </svg>
  )
};
