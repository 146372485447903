export class UserSettings {
  constructor(userSettings) {
    if (userSettings.theme) {
      this.theme = userSettings.theme;
    }

    if (userSettings.background) {
      this.background = userSettings.background;
    }

    if (userSettings.onboardingPassed !== undefined) {
      this.onboardingPassed = userSettings.onboardingPassed
    }

    if (userSettings.runOnboarding !== undefined) {
      this.runOnboarding = userSettings.runOnboarding;
    }

    if (userSettings.brikksOrder) {
      this.brikksOrder = userSettings.brikksOrder
    }

    if (userSettings.movingOutCheckList) {
      this.movingOutCheckList = userSettings.movingOutCheckList
    }
  }
}
