import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CircledCheckmarkIcon = ({ size = 31, disabled }) => (
  <svg
    className={
      classNames(styles.icon, {
        [styles.disabled]: disabled,
      })
    }
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
  >
    <g id="Group_5965" data-name="Group 5965" transform="translate(-718.5 -659.5)">
      <circle id="Ellipse_353" data-name="Ellipse 353" cx="15" cy="15" r="15" transform="translate(719 660)" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
      <path id="check-circle-1" d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z" transform="translate(722 663)" fill="#fff"/>
    </g>
  </svg>
);
