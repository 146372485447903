import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import styles from './styles.module.scss';

export const NavLink = ({ children, to, activeClassName }) => (
  <Link
    className={styles.navLink}
    activeClassName={activeClassName}
    to={to}
  >
    {children}
  </Link>
);
