export const LocaleActions = {
  GET_LOCALE: '@locale/get',
  GET_LOCALE_SUCCESS: '@locale/get/success',

  UPDATE_LOCALE: '@locale/update',
  UPDATE_BROWSER_LOCALE: '@locale/update/browser'
};

export function getLocale() {
  return {
    type: LocaleActions.GET_LOCALE,
  }
}

export function getLocaleSuccess(locale) {
  return {
    type: LocaleActions.GET_LOCALE_SUCCESS,
    payload: locale,
  }
}

export function updateLocale(locale) {
  return {
    type: LocaleActions.UPDATE_LOCALE,
    payload: locale,
  }
}

export function updateBrowserLocale(locale) {
  return {
    type: LocaleActions.UPDATE_BROWSER_LOCALE,
    payload: locale,
  }
}
