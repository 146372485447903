import React from 'react';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { FormattedMessage } from 'react-intl';
import { MovingOutPreviewIcon } from 'assets/icons/brikks/MovingOutPriviewIcon';
import { useAgreements } from 'services/agreements/agreements';
import { useUserSettings } from 'services/user/settings/hook';
import { formatNumber } from 'utils/format/numbers';
import { BrikkPlaceholder } from 'components/BrikkPlaceholder';
import { CheckListOptions } from 'components/brikks/home/MovingOut/components/MovingOutModal/tabOptions';

export const MovingOutPreview = () => {
  const { agreements, loading } = useAgreements();
  const { movingOutCheckList } = useUserSettings();

  const getLeftItems = () => {
    let leftItems = agreements.filter(item => item.status === 'active'
      || (item.status === 'pending' && item.secondPersonSignedTermination)).length;

    if (movingOutCheckList) {
      for (const [, value] of Object.entries(CheckListOptions)) {
        if (!movingOutCheckList[value.menu]?.checked && value?.menu !== 'terminateAgreements') leftItems++;
      }
    } else leftItems += Object.keys(CheckListOptions).length - 1;

    return leftItems;
  };

  if (loading) {
    return <BrikkPlaceholder />;
  }

  return (
    <IconNumText
      uppercase
      icon={<MovingOutPreviewIcon />}
      text={<FormattedMessage id="itemsForChecklist" />}
      number={formatNumber(getLeftItems())}
    />
  );
};
