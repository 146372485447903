import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { useOrderModes } from 'services/order/order-mode/hook';
import { FocusableElement } from 'components/FocusableElement';
import { FormattedMessage } from 'react-intl';
import { ArrangeIcon } from 'assets/icons/ArrangeIcon';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const ArrangeBrikks = () => {
  const {
    shouldReorderBrikks,
    setShouldReorderBrikks,
  } = useContext(AppContext);
    
  const { orderMode } = useOrderModes();

  return (
    <FocusableElement
      className={classNames(styles.menuItem, orderMode && styles.disabled)}
      onClick={
      !orderMode
        ? () => setShouldReorderBrikks(!shouldReorderBrikks)
        : undefined
    }
    >
      <span
        className={styles.menuText}
      >
        {shouldReorderBrikks ? (
          <FormattedMessage id="lockBrikks" />
        ) : (
          <FormattedMessage id="arrangeBrikks" />
        )}
      </span>

      <ArrangeIcon
        isActive={shouldReorderBrikks}
        disabled={orderMode}
      />
    </FocusableElement>
  );
};
