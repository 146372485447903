import React from 'react';

export const LaundryIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <desc>Illustration of a washing machine</desc>
    <g transform="translate(-39.667 0.898)">
      <g transform="translate(39.667 -0.898)">
        <path d="M18.75,0H1.25A1.25,1.25,0,0,0,0,1.25V4.375a.417.417,0,0,0,.417.417h4.07a.188.188,0,0,0,.1-.035,9.783,9.783,0,0,1,10.819,0,.185.185,0,0,0,.1.035h4.07A.417.417,0,0,0,20,4.375V1.25A1.25,1.25,0,0,0,18.75,0ZM14.792,3.125a.625.625,0,1,1,.625-.625A.625.625,0,0,1,14.792,3.125Zm2.5,0a.625.625,0,1,1,.625-.625A.625.625,0,0,1,17.292,3.125Z"
              transform="translate(0)"/>
        <path d="M19.583,6.922h-4.07a1.43,1.43,0,0,1-.8-.246,8.673,8.673,0,0,0-9.431,0,1.434,1.434,0,0,1-.8.246H.417A.417.417,0,0,0,0,7.339V19.63a1.25,1.25,0,0,0,1.25,1.25h17.5A1.25,1.25,0,0,0,20,19.63V7.339A.417.417,0,0,0,19.583,6.922ZM10,18.38a5.417,5.417,0,1,1,5.417-5.417A5.417,5.417,0,0,1,10,18.38Z"
              transform="translate(0 -0.88)"/>
        <path d="M11.25,10A3.75,3.75,0,1,0,15,13.75,3.75,3.75,0,0,0,11.25,10Zm0,5.833a2.083,2.083,0,1,1,2.083-2.083A2.083,2.083,0,0,1,11.25,15.833Z"
              transform="translate(-1.25 -1.667)"/>
      </g>
    </g>
  </svg>
);
