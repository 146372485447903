import React from 'react';

export const FaceIDIcon = () => {
    return (
        <svg id="face-id" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_8119" data-name="Path 8119" d="M6.5,22H2.251A.249.249,0,0,1,2,21.75V17.5a1,1,0,1,0-2,0V22a2,2,0,0,0,2,2H6.5a1,1,0,0,0,0-2Z" />
            <path id="Path_8120" data-name="Path 8120" d="M1,7.5a1,1,0,0,0,1-1V2.25A.249.249,0,0,1,2.251,2H6.5a1,1,0,1,0,0-2H2A2,2,0,0,0,0,2V6.5a1,1,0,0,0,1,1Z" />
            <path id="Path_8121" data-name="Path 8121" d="M23,16.5a1,1,0,0,0-1,1v4.25a.25.25,0,0,1-.25.25H17.5a1,1,0,0,0,0,2H22a2,2,0,0,0,2-2V17.5A1,1,0,0,0,23,16.5Z" />
            <path id="Path_8122" data-name="Path 8122" d="M22,0H17.5a1,1,0,0,0,0,2h4.25a.25.25,0,0,1,.25.25V6.5a1,1,0,0,0,2,0V2A2,2,0,0,0,22,0Z" />
            <path id="Path_8123" data-name="Path 8123" d="M15.772,14.453a1,1,0,1,1,1.676,1.094,6.5,6.5,0,0,1-10.9,0,1,1,0,0,1,1.676-1.094,4.5,4.5,0,0,0,7.544,0Z" />
            <path id="Path_8124" data-name="Path 8124" d="M8.5,11.5a1,1,0,0,1-1-1v-3a1,1,0,0,1,2,0v3A1,1,0,0,1,8.5,11.5Z" />
            <path id="Path_8125" data-name="Path 8125" d="M15.5,11.5a1,1,0,0,1-1-1v-3a1,1,0,0,1,2,0v3A1,1,0,0,1,15.5,11.5Z"/>
        </svg>
    )
}