import { useMutation, useQueryClient } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useModal } from 'store/modals';
import { updateLightningScenarioAPI } from './api';

const LIGHTNING_SCENARIOS = 'lightningScenarios';

export function useUpdateLightningScenario() {

  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { openModal } = useModal();
  const { mutate, isLoading, isSuccess, isError } = useMutation(

    (scenario) => updateLightningScenarioAPI(scenario),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'updateScenarioSuccess',
          },
        });
        queryClient.invalidateQueries([LIGHTNING_SCENARIOS, tenantId]);
      },

      onError: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: 'updateScenarioFail',
          },
        });
      },
    },
  );
  
  return {
    updateLightningScenario: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
