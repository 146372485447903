import React from 'react';

export const BeeIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_5570" data-name="Group 5570" transform="translate(0.004)">
                <ellipse id="Ellipse_349" data-name="Ellipse 349" cx="0.833" cy="0.833" rx="0.833" ry="0.833" transform="translate(3.749 6.667)"/>
                <g id="Group_5569" data-name="Group 5569" transform="translate(-0.004 0)">
                    <path id="Path_7638" data-name="Path 7638" d="M17.023,14.167a.833.833,0,0,1-.637-.278,3.536,3.536,0,0,0-1.806-1.083.833.833,0,0,1-.5-.346,4.142,4.142,0,0,0-1.936-1.55.833.833,0,0,1-.457-.432,4.156,4.156,0,0,0-.216-.417.833.833,0,0,1-.1-.571,5.417,5.417,0,0,0-1.286-4.55.417.417,0,0,1,0-.558.333.333,0,0,1,.417-.063,1.875,1.875,0,1,0-.9-1.856.417.417,0,0,1-.167.28,3.954,3.954,0,0,0-.737.71.417.417,0,0,1-.492.121,5.428,5.428,0,0,0-3.51-.282.417.417,0,0,1-.434-.15,4.292,4.292,0,0,0-.434-.486A.417.417,0,0,1,3.7,2.269,1.868,1.868,0,1,0,2.253,3.712a.417.417,0,0,1,.364.1.36.36,0,0,1-.013.547,5.417,5.417,0,0,0,.568,8.778.833.833,0,0,1,.354.462,4.339,4.339,0,0,0,3,2.912.833.833,0,0,1,.5.382,4.189,4.189,0,0,0,4.033,2.046.833.833,0,0,1,.583.159,3.538,3.538,0,0,0,3.325.481.833.833,0,0,1,.695.056,2.917,2.917,0,1,0,1.362-5.465ZM6.04,12.292a3.75,3.75,0,1,1,3.75-3.75,3.75,3.75,0,0,1-3.75,3.75Zm4.583,5a2.5,2.5,0,0,1-1.513-.514.205.205,0,0,1-.079-.2.208.208,0,0,1,.134-.163A4.392,4.392,0,0,0,12.006,13.1a.209.209,0,0,1,.141-.16.207.207,0,0,1,.207.048,2.5,2.5,0,0,1-1.731,4.307Zm6.356,1.042a.208.208,0,0,1-.167-.312,3.523,3.523,0,0,0,.472-1.883.208.208,0,0,1,.292-.2,1.25,1.25,0,0,1-.6,2.393Z" transform="translate(0.004 0)" />
                    <ellipse id="Ellipse_350" data-name="Ellipse 350" cx="0.833" cy="0.833" rx="0.833" ry="0.833" transform="translate(6.669 6.667)" />
                    <path id="Path_7639" data-name="Path 7639" d="M8.287,11.313a.625.625,0,0,0-.766.44.682.682,0,0,1-.647.3.683.683,0,0,1-.647-.3.625.625,0,1,0-1.207.328,1.847,1.847,0,0,0,1.853,1.226,1.845,1.845,0,0,0,1.853-1.228.624.624,0,0,0-.441-.766Z" transform="translate(-0.83 -1.882)" />
                </g>
            </g>
        </svg>
    )
}