import React from 'react';

export const LightningIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.001"
      height="20"
      viewBox="0 0 10.001 20"
    >
      <path
        id="flash"
        d="M15.585,6.668H12.168L15.938.636A.417.417,0,0,0,15.585,0H8.917a.417.417,0,0,0-.4.316l-2.5,10a.417.417,0,0,0,.4.518H9.2l-2.356,8.64a.417.417,0,0,0,.229.489.424.424,0,0,0,.519-.148l8.335-12.5a.417.417,0,0,0-.347-.647Z"
        transform="translate(-6)"
      />
    </svg>
  );
};
