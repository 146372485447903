import React from 'react';
import { useDownloadInvoice } from 'services/invoices/download-invoice/hook';
import { DownloadIcon } from 'assets/icons/DownloadIcon';

export const Invoice = ({ invoiceNumber, invoiceType }) => {
  const { loading, error, onDownloadInvoice } = useDownloadInvoice(invoiceNumber, invoiceType);

  return (
    <DownloadIcon
      loading={loading}
      error={error}
      onClick={onDownloadInvoice}
    />
  )
};
