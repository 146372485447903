import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import documentsSrc from 'assets/media/documents.svg';
import { Text } from 'components/typography/Text';

export const MyDocumentsPreview = () => (
  <>
    <CircularImage
        objectFit="none"
        src={documentsSrc}
    />

    <Text
        bold
        intl="myDocumentsHeading"
    />
  </>
);
