import React from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';

export const Icon = ({ name, size, className }) => (
  <SemanticIcon
    name={name}
    size={size}
    className={className}
  />
);
