import React from 'react';
import { DropletIcon } from 'assets/icons/DropletIcon';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';
import WaterUsageSlider from './components/WaterUsageSlider';

export const WaterUsagePreview = ({ waterUsage, error }) => (
  <div className={styles.content}>
    {!error ? (
      <WaterUsageSlider waterUsage={waterUsage} />
    ) : (
      <>
        <div className={styles.noWaterUsage}>
          <DropletIcon />
        </div>

        <Text
          center
          bold
          gray
          large
          intl="waterUsageNotFound"
        />
      </>
    )}
  </div>
);
