import React, { useContext } from 'react';
import { Grid } from 'components/layout/Grid';
import { UpgradeItems } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeItems';
import { UpgradeProducts } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeProducts';
import { UpgradeSummary } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary';
import { Overlay } from 'components/layout/Overlay';
import { ProductDetails } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails';
import { PrevStep } from 'components/form-elements/buttons/PrevStep';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { MultiStepContext } from 'components/MultiStep';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { StepProgress } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/StepProgress';

export const UpgradePlan = () => {
  const { onPrevStep, onNextStep } = useContext(MultiStepContext);
  const { selectedProduct, setSelectedProduct, selectedPurchases } = useContext(UpgradeContext);

  return (
    <div>
      <Grid brikkLayout>
        <Grid.Column>
          <UpgradeItems />
        </Grid.Column>

        <Grid.Column>
          <UpgradeProducts />
        </Grid.Column>

        <Grid.Column twoRow>
          <UpgradeSummary onNextStep={onNextStep} />
        </Grid.Column>
      </Grid>

      <Overlay
        isOpen={!!selectedProduct}
        onClose={() => setSelectedProduct(null)}
      >
        <ProductDetails />
      </Overlay>

      <PrevStep onClick={onPrevStep} />
      <StepProgress />
      <NextStep disabled={!Object.keys(selectedPurchases).length} onClick={onNextStep} />
    </div>
  );
}
