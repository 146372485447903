import React from 'react';
import { Button } from 'semantic-ui-react';
import { Icon } from 'components/form-elements/Icon';
import { Text } from 'components/typography/Text';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const NavigationAnimatedButton = ({ className, intl, onClick, direction = 'arrow left' }) => (
  <Button
    className={classNames(styles.button, className)}
    onClick={onClick}
    animated
  >
    <Button.Content
      visible
      className={styles.buttonContent}
    >
      <Text
        size={20}
        intl={intl}
        className={styles.title}
      />
    </Button.Content>

    <Button.Content
      hidden
      className={styles.buttonContent}
    >
      <Icon name={direction} />
    </Button.Content>
  </Button>
);
