import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const SubmitButton = ({ isLoading, onClick, disabled }) => (
  <div className={styles.button}>
    <Button
      className={styles.submitButton}
      type="submit"
      name="submit-button"
      disabled={isLoading || disabled}
      loading={isLoading}
      size="large"
      onClick={onClick}
    >
      <FormattedMessage id="save" />
    </Button>
  </div>
);
