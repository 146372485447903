import React from 'react';
import { MyHome } from 'components/brikks/home/MyHome';
import { MyInfo } from 'components/brikks/home/MyInfo';
import { ServiceReport } from 'components/brikks/home/ServiceReport';
import { ServiceStatus } from 'components/brikks/home/ServiceStatus';
import { MyDocuments } from 'components/brikks/home/MyDocuments';
import { Upgrade } from 'components/brikks/home/Upgrade';
import { RSSFeed } from 'components/brikks/society/RSSFeed';
import { HousingQueue } from 'components/brikks/home/HousingQueue';
import { InternetProviders } from 'components/brikks/store/InternetProviders';
import { ContactUs } from 'components/brikks/home/ContactUs';
import { WaterUsage } from 'components/brikks/sustainability/WaterUsage';
import { Weather } from 'components/brikks/neighbourhood/Weather';
import { Invoices } from 'components/brikks/home/Invoices';
import { LaundryService } from 'components/brikks/home/LaundryService';
import { MobilityOurGreenCar } from 'components/brikks/neighbourhood/MobilityOurGreenCar';
import { MobilityEC2B } from 'components/brikks/neighbourhood/MobilityEC2B';
import { Radio } from 'components/brikks/society/Radio';
import { Tv } from 'components/brikks/society/Tv';
import { City } from 'components/brikks/society/City';
import { Schools } from 'components/brikks/society/Schools';
import { Daycare } from 'components/brikks/society/Daycare';
import { CitizensHub } from 'components/brikks/society/CitizensHub';
import { Administration } from 'components/brikks/society/Administration';
import { Healthcare } from 'components/brikks/society/Healthcare';
import { HighSchools } from 'components/brikks/society/HighSchools';
import { Universities } from 'components/brikks/society/Universities';
import { Work } from 'components/brikks/society/Work';
import { VolunteerTime } from 'components/brikks/sustainability/VolunteerTime';
import { FindVolunteer } from 'components/brikks/sustainability/FindVolunteer';
import { FarmersMarket } from 'components/brikks/sustainability/FarmersMarket';
import { SharedSportsBank } from 'components/brikks/sustainability/SharedSportsBank';
import { SharingIsCaring } from 'components/brikks/sustainability/SharingIsCaring';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { Community } from 'components/brikks/neighbourhood/Community';
import { Groups } from 'components/brikks/neighbourhood/Groups';
import { Event } from 'components/brikks/neighbourhood/Event';
import { Spaces } from 'components/brikks/neighbourhood/Spaces';
import { Resources } from 'components/brikks/neighbourhood/Resources';
import { Recreation } from 'components/brikks/neighbourhood/Recreation';
import { Mobility } from 'components/brikks/neighbourhood/Mobility';
import { Laundry } from 'components/brikks/neighbourhood/Laundry';
import { Polling } from 'components/brikks/neighbourhood/Polling';
import { AccessStatus } from 'components/brikks/smartness/AccessStatus';
import { AccessControl } from 'components/brikks/smartness/AccessControl';
import { SecurityControl } from 'components/brikks/smartness/SecurityControl';
import { Scenarios } from 'components/brikks/smartness/Scenarios';
import { Mood } from 'components/brikks/smartness/Mood';
import { Lights } from 'components/brikks/smartness/Lights';
import { Smoke } from 'components/brikks/smartness/sensors/Smoke';
import { Motion } from 'components/brikks/smartness/sensors/Motion';
import { Water } from 'components/brikks/smartness/sensors/Water';
import { Climate } from 'components/brikks/smartness/Climate';
import { Electricity } from 'components/brikks/smartness/Electricity';
import { Appliances } from 'components/brikks/smartness/Appliances';
import { Audio } from 'components/brikks/smartness/Audio';
import { Delivery } from 'components/brikks/smartness/Delivery';
import { Wifi } from 'components/brikks/smartness/devices/Wifi';
import { Switch } from 'components/brikks/smartness/devices/Switch';
import { UPS } from 'components/brikks/smartness/devices/UPS';
import { Privacy } from 'components/brikks/smartness/Privacy';
import { Recycling } from 'components/brikks/sustainability/Recycling';
import { Temperature } from 'components/brikks/sustainability/indoorClimate/Temperature';
import { Humidity } from 'components/brikks/sustainability/indoorClimate/Humidity';
import { YourHome } from 'components/brikks/sustainability/footprint/YourHome';
import { TheBuilding } from 'components/brikks/sustainability/footprint/TheBuilding';
import { TheOffice } from 'components/brikks/sustainability/footprint/TheOffice';
import { Monthly } from 'components/brikks/sustainability/footprint/Monthly';
import { Garage } from 'components/brikks/sustainability/electricity/Garage';
import { MonthlyElectricity } from 'components/brikks/sustainability/electricity/MonthlyElectricity';
import { FindLocalBusinesses } from 'components/brikks/sustainability/FindLocalBusinesses';
import { Democracy } from 'components/brikks/society/Democracy';
import { SchoolFood } from 'components/brikks/society/SchoolFood';
import { RegionalMobility } from 'components/brikks/society/RegionalMobility';
import { Culture } from 'components/brikks/society/Culture';
import { Sports } from 'components/brikks/society/Sports';
import { KidsActivities } from 'components/brikks/society/KidsActivities';
import { DoorControl } from 'components/brikks/care/DoorControl';
import { DoorBell } from 'components/brikks/care/DoorBell';
import { VisitorIdentity } from 'components/brikks/care/VisitorIdentity';
import { Assistance } from 'components/brikks/care/Assistance';
import { Schedule } from 'components/brikks/care/Schedule';
import { HomeCare } from 'components/brikks/care/HomeCare';
import { PersonalCare } from 'components/brikks/care/PersonalCare';
import { FoodDelivery } from 'components/brikks/care/FoodDelivery';
import { SpeedDial } from 'components/brikks/care/SpeedDial';
import { Reminders } from 'components/brikks/care/Reminders';
import { Apothecary } from 'components/brikks/care/Apothecary';
import { MedicalAssistance } from 'components/brikks/care/MedicalAssistance';
import { MotionSensor } from 'components/brikks/care/MotionSensor';
import { SmokeDetector } from 'components/brikks/care/SmokeDetector';
import { Scenario } from 'components/brikks/care/Scenario';
import { NewsCare } from 'components/brikks/care/News';
import { Streaming } from 'components/brikks/store/Streaming';
import { TechSupportStore } from 'components/brikks/store/TechSupportStore';
import { Insurance } from 'components/brikks/store/Insurance';
import { Groceries } from 'components/brikks/store/Groceries';
import { HomeCareStore } from 'components/brikks/store/HomeCareStore';
import { DomesticServices } from 'components/brikks/store/DomesticServices';
import { Supplies } from 'components/brikks/store/Supplies';
import { HealthCare } from 'components/brikks/store/HealthCare';
import { Wellness } from 'components/brikks/store/Wellness';
import { Security } from 'components/brikks/store/Security';
import { Maintenance } from 'components/brikks/store/Maintenance';
import { SuppliersOverview } from 'components/brikks/economy/SuppliersOverview';
import { ServicesOverview } from 'components/brikks/economy/ServicesOverview';
import { FinanceInsurance } from 'components/brikks/economy/FinanceInsurance';
import { FinanceGroceries } from 'components/brikks/economy/FinanceGroceries';
import { CleaningServices } from 'components/brikks/economy/CleaningServices';
import { DomesticServicesFinance } from 'components/brikks/economy/DomesticServicesFinance';
import { SuppliesFinance } from 'components/brikks/economy/SuppliesFinance';
import { HealthcareFinance } from 'components/brikks/economy/HealthcareFinance';
import { WellnessFinance } from 'components/brikks/economy/WellnessFinance';
import { InternetProvidersFinance } from 'components/brikks/economy/InternetProvidersFinance';
import { SecurityFinance } from 'components/brikks/economy/SecurityFinance';
import { MaintenanceFinance } from 'components/brikks/economy/MaintenanceFinance';
import { StreamingFinance } from 'components/brikks/economy/StreamingFinance';
import { TechSupport } from 'components/brikks/economy/TechSupport';
import { SuppliersOverviewStore } from 'components/brikks/store/SuppliersOverviewStore';
import { ServicesOverviewStore } from 'components/brikks/store/ServicesOverviewStore';
import { MovingOut } from 'components/brikks/home/MovingOut';
import { TerminateAgreement } from 'components/brikks/home/TerminateAgreement';
import { Upgrades } from 'components/brikks/home/Upgrades';
import { CleaningAndMaintenance } from 'components/brikks/home/CleaningAndMaintenance';
import { ChangeAdress } from 'components/brikks/home/ChangeAdress';
import { RealEstateSales } from 'components/brikks/store/RealEstateSales';
import { ElectricityStore } from 'components/brikks/store/ElectricityStore';
import { Heating } from 'components/brikks/store/Heating';
import { Cooling } from 'components/brikks/store/Cooling';
import { ThingsAndResources } from 'components/brikks/home/ThingsAndResources';
import { WaterSaving } from 'components/brikks/sustainability/WaterSaving';
import { AccessApp } from '../components/brikks/home/AccessApp';
import { Intercom } from '../components/brikks/home/Intercom';
 
/* eslint-disable max-len */
/* eslint-disable react/jsx-max-props-per-line */
export const BRIKKS = [
  { id: 'A01', category: MultiGrid.Home, component: position => <MyHome position={position} key="A01" code="LA01" status="Ready to integrate" name="My home" /> },
  { id: 'A02', category: MultiGrid.Home, component: position => <MyInfo position={position} key="A02" code="LA02" status="Ready to integrate" name="My info" /> },
  { id: 'A03', category: MultiGrid.Home, component: position => <MyDocuments position={position} key="A03" code="LA03" status="Ready to integrate" name="My documents" /> },
  { id: 'A05', category: MultiGrid.Home, component: position => <Upgrade position={position} key="A05" code="LA05" status="Ready to integrate" name="Maintenance" /> },
  { id: 'A06', category: MultiGrid.Home, component: position => <ServiceReport position={position} key="A06" code="LA06" status="Ready to integrate" name="Service report" /> },
  { id: 'A07', category: MultiGrid.Home, component: position => <ServiceStatus position={position} key="A07" code="LA07" status="Ready to integrate" name="My cases" /> },
  { id: 'A08', category: MultiGrid.Home, component: position => <ContactUs position={position} key="A08" code="LA08" status="Ready to integrate" name="Contact us" /> },
  { id: 'A09', category: MultiGrid.Home, component: position => <Invoices position={position} key="A09" code="LA09" status="Ready to integrate" name="Rent" /> },
  { id: 'A11', category: MultiGrid.Home, component: position => <HousingQueue position={position} key="A11" code="LA11" status="Ready to integrate" name="Housing queue" /> },
  { id: 'A12', category: MultiGrid.Home, component: position => <LaundryService position={position} key="A12" code="LA12" status="Ready to integrate" name="Laundry services" /> },
  { id: 'A13', category: MultiGrid.Home, component: position => <TerminateAgreement position={position} key="A13" code="LA13" status="Ready to integrate" name="Terminate agreements" /> },
  { id: 'A14', category: MultiGrid.Home, component: position => <MovingOut position={position} key="A14" code="LA14" status="Ready to integrate" name="Moving out" /> },
  { id: 'A15', category: MultiGrid.Home, component: position => <Upgrades position={position} key="A15" code="LA15" status="Ready to integrate" name="Upgrades" /> },
  { id: 'A16', category: MultiGrid.Home, component: position => <ThingsAndResources position={position} key="A16" code="LA16" status="Concept and Design" name="Things and Resources" /> },
  { id: 'A17', category: MultiGrid.Home, component: position => <AccessApp position={position} key="A17" code="LA17" status="Concept and Design" name="Access App" /> },
  { id: 'A18', category: MultiGrid.Home, component: position => <CleaningAndMaintenance position={position} key="A18" code="LA18" status="Ready to integrate" name="Cleaning and maintenance" /> },
  { id: 'A19', category: MultiGrid.Home, component: position => <ChangeAdress position={position} key="A19" code="LA19" status="Ready to integrate" name="Change adress" /> },
  { id: 'A20', category: MultiGrid.Home, component: position => <Intercom position={position} key="A20" code="A20" status="Ready to integrate" name="Intercom" /> },
  { id: 'B01', category: MultiGrid.Neighbourhood, component: position => <Community position={position} key="B01" code="LB01" status="Concept and Design" name="Community" /> },
  { id: 'B02', category: MultiGrid.Neighbourhood, component: position => <Groups position={position} key="B02" code="LB02" status="Concept and Design" name="Groups" /> },
  { id: 'B03', category: MultiGrid.Neighbourhood, component: position => <Event position={position} key="B03" code="LB03" status="Concept and Design" name="Event" /> },
  { id: 'B04', category: MultiGrid.Neighbourhood, component: position => <Spaces position={position} key="B04" code="LB04" status="Concept and Design" name="Spaces" /> },
  { id: 'B05', category: MultiGrid.Neighbourhood, component: position => <Resources position={position} key="B05" code="LB05" status="Concept and Design" name="Resources" /> },
  { id: 'B06', category: MultiGrid.Neighbourhood, component: position => <Recreation position={position} key="B06" code="LB06" status="Concept and Design" name="Recreation" /> },
  { id: 'B07', category: MultiGrid.Neighbourhood, component: position => <Mobility position={position} key="B07" code="LB07" status="Concept and Design" name="Mobility" /> },
  { id: 'B08', category: MultiGrid.Neighbourhood, component: position => <Laundry position={position} key="B08" code="LB08" status="Concept and Design" name="Laundry" /> },
  { id: 'B09', category: MultiGrid.Neighbourhood, component: position => <Weather position={position} key="B09" code="LB09" status="Ready to integrate" name="Weather" /> },
  { id: 'B10', category: MultiGrid.Neighbourhood, component: position => <Polling position={position} key="B10" code="LB10" status="Concept and Design" name="Polling" /> },
  { id: 'B11', category: MultiGrid.Neighbourhood, component: position => <MobilityOurGreenCar position={position} key="B11" code="LB11" status="Ready to integrate" name="Our Green Car" /> },
  { id: 'B12', category: MultiGrid.Neighbourhood, component: position => <MobilityEC2B position={position} key="B12" code="LB12" status="Ready to integrate" name="EC2B" /> },
  { id: 'C01A', category: MultiGrid.Society, component: position => <Radio position={position} key="C01A" code="LC01A" status="Ready to integrate" name="Public service - Radio" /> },
  { id: 'C01B', category: MultiGrid.Society, component: position => <Tv position={position} key="C01B" code="LC01B" status="Ready to integrate" name="Public service - TV" /> },
  { id: 'C02A', category: MultiGrid.Society, component: position => <City position={position} key="C02A" code="LC02A" status="Ready to integrate" name="Municipality - The city" /> },
  { id: 'C02B', category: MultiGrid.Society, component: position => <Democracy position={position} key="C02B" code="LC02B" status="Concept and Design" name="Municipality - Democracy" /> },
  { id: 'C02C', category: MultiGrid.Society, component: position => <Schools position={position} key="C02C" code="LC02C" status="Ready to integrate" name="Municipality - Schools" /> },
  { id: 'C02D', category: MultiGrid.Society, component: position => <SchoolFood position={position} key="C02D" code="LC02D" status="Concept and Design" name="Municipality - School Food" /> },
  { id: 'C02E', category: MultiGrid.Society, component: position => <Daycare position={position} key="C02E" code="LC02E" status="Ready to integrate" name="Municipality - Daycare" /> },
  { id: 'C02F', category: MultiGrid.Society, component: position => <CitizensHub position={position} key="C02F" code="LC02F" status="Ready to integrate" name="International Citizens Hub" /> },
  { id: 'C03A', category: MultiGrid.Society, component: position => <Administration position={position} key="C03A" code="LC03A" status="Ready to integrate" name="Regional Administration" /> },
  { id: 'C03B', category: MultiGrid.Society, component: position => <RegionalMobility position={position} key="C03B" code="LC03B" status="Concept and Design" name="Regional Mobility" /> },
  { id: 'C04', category: MultiGrid.Society, component: position => <Healthcare position={position} key="C04" code="LC04" status="Ready to integrate" name="Healthcare" /> },
  { id: 'C05A', category: MultiGrid.Society, component: position => <HighSchools position={position} key="C05A" code="LC05A" status="Ready to integrate" name="Education - High Schools" /> },
  { id: 'C05B', category: MultiGrid.Society, component: position => <Universities position={position} key="C05B" code="LC05B" status="Ready to integrate" name="Education - Universities" /> },
  { id: 'C06', category: MultiGrid.Society, component: position => <Work position={position} key="C06" code="LC06" status="Ready to integrate" name="Work" /> },
  { id: 'C07', category: MultiGrid.Society, component: position => <RSSFeed position={position} key="C07" code="LC07" status="Ready to integrate" name="My news" /> },
  { id: 'C08', category: MultiGrid.Society, component: position => <Culture position={position} key="C08" code="LC08" status="Concept and Design" name="Culture" /> },
  { id: 'C09', category: MultiGrid.Society, component: position => <Sports position={position} key="C09" code="LC09" status="Concept and Design" name="Sports" /> },
  { id: 'C10', category: MultiGrid.Society, component: position => <KidsActivities position={position} key="C10" code="LC10" status="Concept and Design" name="Kids Activities" /> },
  { id: 'D01', category: MultiGrid.Sustainability, component: position => <WaterUsage position={position} key="D01" code="LD01" status="Ready to integrate" name="Water Consumption" /> },
  { id: 'D02A', category: MultiGrid.Sustainability, component: position => <Garage position={position} key="D02A" code="LD02A" status="Concept and Design" name="Garage" /> },
  { id: 'D02B', category: MultiGrid.Sustainability, component: position => <MonthlyElectricity position={position} key="D02B" code="LD02B" status="Concept and Design" name="Monthly Electricity" /> },
  { id: 'D03', category: MultiGrid.Sustainability, component: position => <Recycling position={position} key="D03" code="LD03" status="Concept and Design" name="Recycling" /> },
  { id: 'D04A', category: MultiGrid.Sustainability, component: position => <Temperature position={position} key="D04A" code="LD04A" status="Concept and Design" name="Temperature" /> },
  { id: 'D04B', category: MultiGrid.Sustainability, component: position => <Humidity position={position} key="D04B" code="LD04B" status="Concept and Design" name="Humidity" /> },
  { id: 'D05A', category: MultiGrid.Sustainability, component: position => <YourHome position={position} key="D05A" code="LD05A" status="Concept and Design" name="Your Home" /> },
  { id: 'D05B', category: MultiGrid.Sustainability, component: position => <TheBuilding position={position} key="D05B" code="LD05B" status="Concept and Design" name="The Building" /> },
  { id: 'D05C', category: MultiGrid.Sustainability, component: position => <TheOffice position={position} key="D05C" code="LD05C" status="Concept and Design" name="The Office" /> },
  { id: 'D05D', category: MultiGrid.Sustainability, component: position => <Monthly position={position} key="D05D" code="LD05D" status="Concept and Design" name="Monthly" /> },
  { id: 'D06A', category: MultiGrid.Sustainability, component: position => <VolunteerTime position={position} key="D06A" code="LD06A" status="Concept and Design" name="Volunteer Time" /> },
  { id: 'D06B', category: MultiGrid.Sustainability, component: position => <FindVolunteer position={position} key="D06B" code="LD06B" status="Concept and Design" name="Find Volunteer" /> },
  { id: 'D07', category: MultiGrid.Sustainability, component: position => <FarmersMarket position={position} key="D07" code="LD07" status="Concept and Design" name="Farmers Market" /> },
  { id: 'D08', category: MultiGrid.Sustainability, component: position => <SharedSportsBank position={position} key="D08" code="LD08" status="Concept and Design" name="Shared Sports Bank" /> },
  { id: 'D09', category: MultiGrid.Sustainability, component: position => <SharingIsCaring position={position} key="D09" code="LD09" status="Concept and Design" name="Sharing Is Caring" /> },
  { id: 'D10', category: MultiGrid.Sustainability, component: position => <FindLocalBusinesses position={position} key="D10" code="LD10" status="Concept and Design" name="Find Local Businesses" /> },
  { id: 'D11', category: MultiGrid.Sustainability, component: position => <WaterSaving position={position} key="D11" code="LD11" status="Ready to integrate" name="Water Saving" /> },
  { id: 'E01A', category: MultiGrid.Smartness, component: position => <AccessStatus position={position} key="E01A" code="LE01A" status="Concept and Design" name="Access Status" /> },
  { id: 'E01B', category: MultiGrid.Smartness, component: position => <AccessControl position={position} key="E01B" code="LE01B" status="Concept and Design" name="Access Control" /> },
  { id: 'E02', category: MultiGrid.Smartness, component: position => <SecurityControl position={position} key="E02" code="LE02" status="Concept and Design" name="Security Control" /> },
  { id: 'E03', category: MultiGrid.Smartness, component: position => <Scenarios position={position} key="E03" code="LE03" status="Concept and Design" name="Scenarios" /> },
  { id: 'E04A', category: MultiGrid.Smartness, component: position => <Mood position={position} key="E04A" code="LE04A" status="Concept and Design" name="Mood" /> },
  { id: 'E04B', category: MultiGrid.Smartness, component: position => <Lights position={position} key="E04B" code="LE04B" status="Concept and Design" name="Lights" /> },
  { id: 'E05', category: MultiGrid.Smartness, component: position => <Smoke position={position} key="E05" code="LE05" status="Concept and Design" name="Smoke" /> },
  { id: 'E06A', category: MultiGrid.Smartness, component: position => <Motion position={position} key="E06A" code="LE06A" status="Concept and Design" name="Motion" /> },
  { id: 'E06B', category: MultiGrid.Smartness, component: position => <Water position={position} key="E06B" code="LE06B" status="Concept and Design" name="Water" /> },
  { id: 'E06C', category: MultiGrid.Smartness, component: position => <Climate position={position} key="E06C" code="LE06C" status="Concept and Design" name="Climate" /> },
  { id: 'E07A', category: MultiGrid.Smartness, component: position => <Electricity position={position} key="E07A" code="LE07A" status="Concept and Design" name="Electricity" /> },
  { id: 'E07B', category: MultiGrid.Smartness, component: position => <Audio position={position} key="E07B" code="LE07B" status="Concept and Design" name="Audio" /> },
  { id: 'E08', category: MultiGrid.Smartness, component: position => <Appliances position={position} key="E08" code="LE08" status="Concept and Design" name="Appliances" /> },
  { id: 'E11', category: MultiGrid.Smartness, component: position => <Delivery position={position} key="E11" code="LE11" status="Concept and Design" name="Delivery" /> },
  { id: 'E12A', category: MultiGrid.Smartness, component: position => <Wifi position={position} key="E12A" code="LE12A" status="Concept and Design" name="Wifi" /> },
  { id: 'E12B', category: MultiGrid.Smartness, component: position => <Switch position={position} key="E12B" code="LE12B" status="Concept and Design" name="Switch" /> },
  { id: 'E12C', category: MultiGrid.Smartness, component: position => <UPS position={position} key="E12C" code="LE12C" status="Concept and Design" name="UPS" /> },
  { id: 'E13', category: MultiGrid.Smartness, component: position => <Privacy position={position} key="E13" code="LE13" status="Concept and Design" name="Privacy" /> },
  { id: 'F01A', category: MultiGrid.Care, component: position => <DoorControl position={position} key="F01A" code="LF01A" status="Concept and Design" name="Door Control" /> },
  { id: 'F01B', category: MultiGrid.Care, component: position => <DoorBell position={position} key="F02B" code="LF02B" status="Concept and Design" name="Door Bell" /> },
  { id: 'F02', category: MultiGrid.Care, component: position => <VisitorIdentity position={position} key="F02" code="LF02" status="Concept and Design" name="Visitor Identity" /> },
  { id: 'F03', category: MultiGrid.Care, component: position => <Assistance position={position} key="F03" code="LF03" status="Concept and Design" name="Assistance" /> },
  { id: 'F04', category: MultiGrid.Care, component: position => <Schedule position={position} key="F04" code="LF04" status="Concept and Design" name="Schedule" /> },
  { id: 'F05', category: MultiGrid.Care, component: position => <HomeCare position={position} key="F05" code="LF05" status="Concept and Design" name="Home Care" /> },
  { id: 'F06', category: MultiGrid.Care, component: position => <PersonalCare position={position} key="F06" code="LF06" status="Concept and Design" name="Personal Care" /> },
  { id: 'F07', category: MultiGrid.Care, component: position => <FoodDelivery position={position} key="F07" code="LF07" status="Concept and Design" name="Food Delivery" /> },
  { id: 'F08', category: MultiGrid.Care, component: position => <SpeedDial position={position} key="F08" code="LF08" status="Concept and Design" name="Speed Dial" /> },
  { id: 'F09', category: MultiGrid.Care, component: position => <Reminders position={position} key="F09" code="LF09" status="Concept and Design" name="Reminders" /> },
  { id: 'F10', category: MultiGrid.Care, component: position => <Apothecary position={position} key="F10" code="LF10" status="Concept and Design" name="Apothecary" /> },
  { id: 'F11', category: MultiGrid.Care, component: position => <MedicalAssistance position={position} key="F11" code="LF11" status="Concept and Design" name="Medical Assistance" /> },
  { id: 'F12', category: MultiGrid.Care, component: position => <MotionSensor position={position} key="F12" code="LF12" status="Concept and Design" name="Motion Sensor" /> },
  { id: 'F13', category: MultiGrid.Care, component: position => <SmokeDetector position={position} key="F13" code="LF13" status="Concept and Design" name="Smoke Detector" /> },
  { id: 'F14', category: MultiGrid.Care, component: position => <Scenario position={position} key="F14" code="LF14" status="Concept and Design" name="Scenario" /> },
  { id: 'F15', category: MultiGrid.Care, component: position => <NewsCare position={position} key="F15" code="LF15" status="Concept and Design" name="NewsCare" /> },
  { id: 'G01', category: MultiGrid.Finances, component: position => <SuppliersOverview position={position} key="G01" code="LG01" status="Concept and Design" name="Suppliers Overview" /> },
  { id: 'G02', category: MultiGrid.Finances, component: position => <ServicesOverview position={position} key="G02" code="LG02" status="Concept and Design" name="Services Overview" /> },
  { id: 'G03', category: MultiGrid.Finances, component: position => <FinanceInsurance position={position} key="G03" code="LG03" status="Concept and Design" name="Finance Insurance" /> },
  { id: 'G04', category: MultiGrid.Finances, component: position => <FinanceGroceries position={position} key="G04" code="LG04" status="Concept and Design" name="Finance Groceries" /> },
  { id: 'G05', category: MultiGrid.Finances, component: position => <CleaningServices position={position} key="G05" code="LG05" status="Concept and Design" name="Cleaning Services" /> },
  { id: 'G06', category: MultiGrid.Finances, component: position => <DomesticServicesFinance position={position} key="G06" code="LG06" status="Concept and Design" name="Domestic Services Finance" /> },
  { id: 'G07', category: MultiGrid.Finances, component: position => <SuppliesFinance position={position} key="G07" code="LG07" status="Concept and Design" name="Supplies Finance" /> },
  { id: 'G08', category: MultiGrid.Finances, component: position => <HealthcareFinance position={position} key="G08" code="LG08" status="Concept and Design" name="Healthcare Finance" /> },
  { id: 'G09', category: MultiGrid.Finances, component: position => <WellnessFinance position={position} key="G09" code="LG09" status="Concept and Design" name="Wellness Finance" /> },
  { id: 'G10', category: MultiGrid.Finances, component: position => <InternetProvidersFinance position={position} key="G10" code="LG10" status="Concept and Design" name="Internet Providers Finance" /> },
  { id: 'G11', category: MultiGrid.Finances, component: position => <SecurityFinance position={position} key="G11" code="LG11" status="Concept and Design" name="Security Finance" /> },
  { id: 'G12', category: MultiGrid.Finances, component: position => <MaintenanceFinance position={position} key="G12" code="LG12" status="Concept and Design" name="Maintenance Finance" /> },
  { id: 'G13', category: MultiGrid.Finances, component: position => <StreamingFinance position={position} key="G13" code="LG13" status="Concept and Design" name="Streaming Finance" /> },
  { id: 'G14', category: MultiGrid.Finances, component: position => <TechSupport position={position} key="G14" code="LG14" status="Concept and Design" name="Tech Support" /> },
  { id: 'H01', category: MultiGrid.Store, component: position => <SuppliersOverviewStore position={position} key="H01" code="LH01" status="Concept and Design" name="Suppliers Overview Store" /> },
  { id: 'H02', category: MultiGrid.Store, component: position => <ServicesOverviewStore position={position} key="H02" code="LH02" status="Concept and Design" name="Services Overview Store" /> },
  { id: 'H03', category: MultiGrid.Store, component: position => <Insurance position={position} key="H03" code="LH03" status="Concept and Design" name="Insurance" /> },
  { id: 'H04', category: MultiGrid.Store, component: position => <Groceries position={position} key="H04" code="LH04" status="Concept and Design" name="Groceries" /> },
  { id: 'H05', category: MultiGrid.Store, component: position => <HomeCareStore position={position} key="H05" code="LH05" status="Concept and Design" name="Home Care Store" /> },
  { id: 'H06', category: MultiGrid.Store, component: position => <DomesticServices position={position} key="H06" code="LH06" status="Concept and Design" name="Domestic Services" /> },
  { id: 'H07', category: MultiGrid.Store, component: position => <Supplies position={position} key="H07" code="LH07" status="Concept and Design" name="Supplies" /> },
  { id: 'H08', category: MultiGrid.Store, component: position => <HealthCare position={position} key="H08" code="LH08" status="Concept and Design" name="HealthCare" /> },
  { id: 'H09', category: MultiGrid.Store, component: position => <Wellness position={position} key="H09" code="LH09" status="Concept and Design" name="Wellness" /> },
  { id: 'H10', category: MultiGrid.Store, component: position => <InternetProviders position={position} key="H10" code="LH10" status="Ready to integrate" name="Internet Providers" /> },
  { id: 'H11', category: MultiGrid.Store, component: position => <Security position={position} key="H11" code="LH11" status="Concept and Design" name="Security" /> },
  { id: 'H12', category: MultiGrid.Store, component: position => <Maintenance position={position} key="H12" code="LH12" status="Concept and Design" name="Maintenance" /> },
  { id: 'H13', category: MultiGrid.Store, component: position => <Streaming position={position} key="H13" code="LH13" status="Concept and Design" name="Streaming" /> },
  { id: 'H14', category: MultiGrid.Store, component: position => <TechSupportStore position={position} key="H14" code="LH14" status="Concept and Design" name="Tech Support Store" /> },
  { id: 'H20', category: MultiGrid.Store, component: position => <RealEstateSales position={position} key="H20" code="LH20" status="Concept and Design" name="Real Estate Sales" /> },
  { id: 'H21', category: MultiGrid.Store, component: position => <ElectricityStore position={position} key="H21" code="LH21" status="Concept and Design" name="Electricity" /> },
  { id: 'H22', category: MultiGrid.Store, component: position => <Heating position={position} key="H22" code="LH22" status="Concept and Design" name="Heating" /> },
  { id: 'H23', category: MultiGrid.Store, component: position => <Cooling position={position} key="H23" code="LH23" status="Concept and Design" name="Cooling" /> },
];
