import React, { useContext } from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { LinkModal } from 'components/modals/LinkModal';
import { WaterSavingModal } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal';
import { LinkModalButton } from 'components/form-elements/buttons/LinkModalButton';
import { TrendContext } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/components/TrendProvider';
import { WaterUsageContext } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/index';
import { TrendCard } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/components/WaterUsageCards/components/TrendCard';
import { WaterUsageCard } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/components/WaterUsageCards/components/WaterUsageCard';
import styles from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/components/WaterUsageCards/styles.module.scss';

export const WaterUsageCards = () => {

  const {
    loading24Hours,
    waterUsage24Hours: { totalWater, coldWater, hotWater },
  } = useContext(WaterUsageContext);

  const {
    totalTrend,
    coldTrend,
    hotTrend,
    loading,
  } = useContext(TrendContext);

  return (
    <div className={styles.cards}>
      <div className={styles.cardGroup}>
        <WaterUsageCard
          loading={loading24Hours}
          variant="total"
          value={totalWater}
          title="waterUsage"
        />

        <TrendCard
          value={totalTrend}
          loading={loading}
          variant="total"
        />
      </div>

      <ShouldRender condition={loading24Hours || hotWater.total > 0}>
        <div className={styles.cardGroup}>
          <WaterUsageCard
            loading={loading24Hours}
            variant="hotWater"
            value={hotWater.total}
            title="hotWater"
          />

          <TrendCard
            value={hotTrend}
            loading={loading}
            variant="hotWater"
          />
        </div>

        <div className={styles.cardGroup}>
          <WaterUsageCard
            loading={loading24Hours}
            variant="coldWater"
            value={coldWater.total}
            title="coldWater"
          />

          <TrendCard
            value={coldTrend}
            loading={loading}
            variant="coldWater"
          />
        </div>
      </ShouldRender>

      <div className={styles.proceedButton}>
        <LinkModal
          intl="learnMore"
          buttonTriggerModal={LinkModalButton}
          brikkModal={WaterSavingModal}
        />
      </div>
    </div>
  );
}; 
