import React from "react";
import { Brikk } from "components/Brikk";
import { AgreementItem } from "components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/CheckListTab/Agreements/components/AgreementItem";
import { useAgreements } from "services/agreements/agreements";

export const Agreements = () => {
  const { agreements } = useAgreements()
  return (
    <Brikk.Heading
      id="status"
      heading="status"
    >
      {
        agreements.map(agreement => <AgreementItem key={agreement.agreementNumber} agreement={agreement}/>)
      }
    </Brikk.Heading>
  );
};
