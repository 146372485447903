import React from 'react';

export const WaterUsageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.002" viewBox="0 0 20 20.002">
    <desc>Illustration of a water droplet with a magnifying glass</desc>
    <g transform="translate(-72 -211)">
      <path d="M12.175,10.2A5.816,5.816,0,0,1,8.382,2.316a.417.417,0,0,0-.022-.371A17.027,17.027,0,0,0,7.349.331a.911.911,0,0,0-1.365,0C4.754,1.944,0,11.087,0,13.754,0,17.025,3.178,20,6.667,20s6.667-2.979,6.667-6.25a11.2,11.2,0,0,0-.881-3.3.417.417,0,0,0-.278-.252ZM6.443,17.55a.624.624,0,0,1-.75.471A4.361,4.361,0,0,1,2.75,15.7a.625.625,0,1,1,1.119-.557A3.113,3.113,0,0,0,5.976,16.8.625.625,0,0,1,6.443,17.55Z"
            transform="translate(72 210.998)"/>
      <path d="M21.485,9.267l-1.99-2a.417.417,0,0,1-.072-.493,4.545,4.545,0,0,0,.545-2.167A4.608,4.608,0,1,0,15.36,9.216a4.548,4.548,0,0,0,2.167-.545.417.417,0,0,1,.492.072l2,1.99a1.037,1.037,0,1,0,1.467-1.466ZM15.36,7.55A2.942,2.942,0,1,1,18.3,4.608,2.942,2.942,0,0,1,15.36,7.55Z"
            transform="translate(70.209 211.001)"/>
    </g>
  </svg>
);
