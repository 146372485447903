import ReactGA from 'react-ga';

export const AppTracking = {
  ...ReactGA,
  authEvent: ({ action, label }) => ReactGA.event({ category: 'Authentication', action, label }),
  submissionEvent: ({ action, label }) => ReactGA.event({ category: 'Submissions', action, label }),
  downloadEvent: ({ action, label }) => ReactGA.event({ category: 'Downloads', action, label }),
  settingsEvent: ({ action, label }) => ReactGA.event({ category: 'Settings', action, label }),
  onboardingEvent: ({ action, label }) => ReactGA.event({ category: 'Onboarding', action, label }),
};
