import React, { useContext } from 'react';
import { Brikk } from 'components/Brikk';
import { MultiTabContext } from 'components/menus/MultiTabMenu';
import { DocumentList } from 'components/lists/DocumentList';

// Third column of the modal
export const MenuContent = () => {
  const { activeMenu, activeSubMenu } = useContext(MultiTabContext);

  const ContentComp = activeMenu?.submenus.length > 0 && activeSubMenu !== null
    ? activeMenu?.submenus[activeSubMenu]?.content
    : activeMenu?.content;

  if (!ContentComp) {
    return null;
  }

  return (
    <Brikk.Heading
      id="officeDocuments"
      heading={
        activeSubMenu !== null
          ? activeMenu.submenus[activeSubMenu].title
          : activeMenu.menu
      }
    >
      <DocumentList>
        <ContentComp
          contentHeader={activeMenu.submenus[activeSubMenu]?.contentHeader}
          intl={activeMenu.submenus[activeSubMenu]?.intl}
          intlSecondHalf={activeMenu.submenus[activeSubMenu]?.intlSecondHalf}
          contentImage={activeMenu.submenus[activeSubMenu]?.contentImage}
          name={activeMenu.submenus[activeSubMenu]?.name}
          category={activeMenu.submenus[activeSubMenu]?.category}
          menu={activeMenu?.menu}
        />
      </DocumentList>
    </Brikk.Heading>
  );
};
