import React from 'react';
import { FormattedMessage } from 'react-intl';
import LightsImage from 'assets/media/brikks/smartness/lights/lights.png';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { LightningControls } from 'components/brikks/smartness/Lights/components/LightsModal/components';

export const LightsModal = () => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="lights" />

    <Grid.Column>
      <div> 
        <Brikk.Heading
          main
          heading="lights"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={LightsImage}
              alt="Lights"
            />

            <ModalIntro.Text>
              <FormattedMessage id="lightsIntro" />
            </ModalIntro.Text>
          </ModalIntro>
        </Brikk.Heading>
      </div>
    </Grid.Column>

    <LightningControls />
  </Grid>
);
