import React from 'react';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { Image } from 'components/media/Image';
import bathroomImg from 'assets/media/bathroom-cleaning-and-maintenance.png';

export const Bathroom = () => {
  return (
    <Brikk.Heading id="bathroom" heading="bathroom">
      <Image src={bathroomImg} />
      <Text size={14} intl={'bathroomDescription'} />
    </Brikk.Heading>
  );
};
