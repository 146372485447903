import React from 'react';
import classNames from 'classnames';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { Text } from 'components/typography/Text';
import { FocusableElement } from 'components/FocusableElement';
import { ORDER_PAGE } from 'global/routes';
import { useOrderModes } from 'services/order/order-mode/hook';
import { AppContext } from 'AppContext';
import styles from './styles.module.scss';

export const OrderMode = () => {
  const history = useHistory();

  const { orderMode, handleOrderMode } = useOrderModes();
  const { shouldReorderBrikks, setShouldReorderBrikks } = React.useContext(
    AppContext,
  );
  const isOrderPage = useRouteMatch({ path: ORDER_PAGE });

  const exitOrderMode = () => {
    if (isOrderPage) {
      handleOrderMode();
      history.push('/');
    } else {
      if (shouldReorderBrikks) {
        setShouldReorderBrikks(false);
      }
      handleOrderMode();
    }
  };

  return (
    <FocusableElement
      className={styles.orderMode}
      onClick={exitOrderMode}
    >
      <div className={styles.text}>
        <Text
          size={14}
          intl="orderMode"
        />
      </div>

      <Icon
        name="plus square"
        size="big"
        className={classNames(styles.icon, orderMode ? styles.activeIcon : '')}
      />
    </FocusableElement>
  );
};
