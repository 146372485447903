import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RedirectIcon } from 'assets/icons/RedirectIcon';
import { Button } from 'semantic-ui-react';
import { AppTracking } from 'global/appTracking';
import styles from './styles.module.scss';

export const NewPageButton = ({ to, label, brikkNameIntl, disabled }) => (
  <AppTracking.OutboundLink
    eventLabel={label}
    to={to}
    target="_blank"
    className={disabled ? styles.disabled : ''}
  >
    <Button
      className={styles.button}
      disabled={disabled}
    >
      <div className={styles.text}>
        <FormattedMessage id="goTo" /> <b className={styles.goToText}><FormattedMessage id={brikkNameIntl} /></b>
      </div>

      <div className={styles.icon}>
        <RedirectIcon />
      </div>
    </Button>
  </AppTracking.OutboundLink>
);
