import React from "react";
import { ExternalLink } from "components/links/ExternalLink";

export const BankID = () => (
  <ExternalLink
    href="https://www.bankid.com/privat/skaffa-bankid"
  >
    BankID
  </ExternalLink>
);
