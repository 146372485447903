import { TenantConfig } from 'global/tenant-config/tenantConfig';
import { Tenants } from 'global/tenant-config/tenants';

export function getTenantIDBasedOnURL() {
  const domainURL = window.location.hostname;

  const tenantKeys = Object.keys(Tenants);

  for (let i = 0; i < tenantKeys.length; i++) {
    const { aliases, tenantId } = Tenants[tenantKeys[i]];

    if (aliases.find(alias => domainURL.includes(alias))) {
      return tenantId;
    }
  }

  return process.env.REACT_APP_TENANT_ID;
}

export const tenantConfig = TenantConfig[getTenantIDBasedOnURL()];

