import React from 'react';
import minaAvtalSrc from 'assets/media/agreement.png';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import { AgreementTable } from 'components/brikks/home/MyDocuments/components/MyDocumentsModal/components/AgreementTable';
import styles from 'components/brikks/home/MyDocuments/components/MyDocumentsModal/styles.module.scss';

export const MyDocumentsModal = () => (
  <Grid
    brikkLayout
    twoColumns
  >
    <IntlPageTitle intlTitle="myDocuments" />

    <Grid.Column justifySelf="center">
      <Brikk.Heading
        main
        heading="myDocuments"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={minaAvtalSrc}
            alt="Person signing an agreement"
          />

          <ModalIntro.Text intl="myDocumentsDescription" />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="agreements">
        <div className={styles.table}>
          <AgreementTable />
        </div>
      </Brikk.Heading>
    </Grid.Column>
  </Grid>
);
