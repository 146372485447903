import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { HousingQueueIcon } from 'assets/icons/brikks/HousingQueueIcon';
import { HousingQueuePreview } from 'components/brikks/home/HousingQueue/components/HousingQueuePreview';
import { useUserDetails } from 'services/user/details/hook';
import { tenantConfig } from 'global/tenant-config';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';

export const HousingQueue = ({ position, status, code, name }) => {
  const { userDetails, isLoading } = useUserDetails();
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<HousingQueueIcon />}
            heading="housingQueueBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content center>
            <HousingQueuePreview queuePoints={userDetails?.queuePoints} />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="Koplats Link"
              to={tenantConfig.housingQueue.link}
              brikkNameIntl="housingQueue"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="Housing queue info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />
    </>
  );
};
