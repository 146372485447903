import { combineReducers } from 'redux';
import { userAvatarReducer } from 'services/user/avatar/reducer';
import { bankIDLoginReducer } from 'services/auth/bank-id-login/reducer';
import { confirmEmailReducer } from 'services/auth/confirm-email/reducer';
import { loginWithEmailReducer } from 'services/auth/login-email/reducer';
import { initPassResetReducer } from 'services/auth/init-pass-reset/reducer';
import { resetPassReducer } from 'services/auth/reset-pass/reducer';
import { downloadAgreementReducer } from 'services/agreements/download-agreement/reducer';
import { downloadInvoiceReducer } from 'services/invoices/download-invoice/reducer';
import { agreementPropertiesReducer } from 'services/agreements/agreement-properties/reducer';
import { localeReducer } from 'services/user/locale/reducer';
import { userSettingsReducer } from 'services/user/settings/reducer';
import { bankIDSigningReducer } from 'services/auth/bank-id-signing/reducer';
import { maintenanceProductsReducer } from 'services/maintenance/maintenance-products/reducer';
import { bankIDOrderStatusReducer } from 'services/auth/bank-id-order-status/reducer';
import { submitMaintenanceOrderReducer } from 'services/maintenance/submit-maintenance-order/reducer';
import { multiGridReducer } from 'services/multi-grid/reducer';
import { submitCreateOrderReducer } from 'services/order/create-order/reducer';

export const rootReducer = combineReducers({
  userAvatar: userAvatarReducer,
  bankIDLogin: bankIDLoginReducer,
  bankIDSigning: bankIDSigningReducer,
  confirmEmail: confirmEmailReducer,
  loginWithEmail: loginWithEmailReducer,
  initPassReset: initPassResetReducer,
  resetPass: resetPassReducer,
  downloadAgreement: downloadAgreementReducer,
  downloadInvoice: downloadInvoiceReducer,
  agreementProperties: agreementPropertiesReducer,
  locale: localeReducer,
  userSettings: userSettingsReducer,
  maintenanceProducts: maintenanceProductsReducer,
  bankIDOrderStatus: bankIDOrderStatusReducer,
  submitMaintenanceOrder: submitMaintenanceOrderReducer,
  multiGrid: multiGridReducer,
  createOrder: submitCreateOrderReducer,
});
