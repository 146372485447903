import React, { useState } from 'react';
import { Header } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';
import { ToggleSwitch } from 'components/form-elements/toggles/ToggleSwitch';
import { BankIDLogin } from '../LoginForm/components/BankIDLogin';
import { ActivationForm } from './components/ActivationForm';

export const ActivateAccount = () => {
  const [shouldLoginWithBankID, setShouldLoginWithBankID] = useState(false);

  return (
    <>
      <Header as='h2'>
        <FormattedMessage id="activateAccountTitle" />

        <Header.Subheader>
          <FormattedMessage id="activateAccountSubtitle" />
        </Header.Subheader>
      </Header>

      <div className={styles.activateAccount}>
        <ToggleSwitch
          option1="email"
          option2="bankID"
          isOption1Active={!shouldLoginWithBankID}
          onSelectOption1={() => setShouldLoginWithBankID(false)}
          onSelectOption2={() => setShouldLoginWithBankID(true)}
        />

        <div className={styles.form}>
          {shouldLoginWithBankID ? <BankIDLogin /> : <ActivationForm />}
        </div>
      </div>
    </>
  )
};
