import React from 'react';

export const FireSafetyIcon = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="24.021"
          viewBox="0 0 17 24.021"
        >
            <g
              id="safety-fire-alarm"
              transform="translate(-3.5)"
            >
                <path
                  id="Path_8142"
                  data-name="Path 8142"
                  d="M10.575,8h2.85a.5.5,0,0,0,.491-.4l.55-2.75a.5.5,0,0,0-.491-.6h-3.95a.5.5,0,0,0-.491.6l.55,2.75A.5.5,0,0,0,10.575,8Z"
                />

                <path
                  id="Path_8143"
                  data-name="Path 8143"
                  d="M19.5,0H4.5a1,1,0,0,0-1,1V2a3.116,3.116,0,0,0,.059.546.249.249,0,0,0,.246.2H20.2a.249.249,0,0,0,.245-.2A3.116,3.116,0,0,0,20.5,2V1A1,1,0,0,0,19.5,0Z"
                />

                <path
                  id="Path_8144"
                  data-name="Path 8144"
                  d="M6.5,8H8.025a.5.5,0,0,0,.491-.6l-.55-2.75a.5.5,0,0,0-.491-.4H4.9a.5.5,0,0,0-.464.686L5.35,7.222A1.3,1.3,0,0,0,6.5,8Z"
                />

                <path
                  id="Path_8145"
                  data-name="Path 8145"
                  d="M19.1,4.25H16.525a.5.5,0,0,0-.491.4l-.55,2.75a.5.5,0,0,0,.491.6H17.5a1.3,1.3,0,0,0,1.15-.778l.914-2.286A.5.5,0,0,0,19.1,4.25Z"
                />

                <path
                  id="Path_8146"
                  data-name="Path 8146"
                  d="M7.751,18.716a1.68,1.68,0,0,1-.361-2.333,1,1,0,1,0-1.627-1.162,3.667,3.667,0,0,0,.788,5.094A1.693,1.693,0,0,1,7,22.5a1,1,0,1,0,1.74,1,3.693,3.693,0,0,0-.989-4.784Z"
                />

                <path
                  id="Path_8147"
                  data-name="Path 8147"
                  d="M12.6,18.716a1.68,1.68,0,0,1-.361-2.333,1,1,0,1,0-1.628-1.162,3.668,3.668,0,0,0,.789,5.094,1.692,1.692,0,0,1,.453,2.189,1,1,0,1,0,1.736.992,3.693,3.693,0,0,0-.989-4.78Z"
                />

                <path
                  id="Path_8148"
                  data-name="Path 8148"
                  d="M17.449,18.716a1.679,1.679,0,0,1-.361-2.333,1,1,0,1,0-1.627-1.162,3.666,3.666,0,0,0,.788,5.094A1.693,1.693,0,0,1,16.7,22.5a1,1,0,0,0,.372,1.364.988.988,0,0,0,.5.132,1,1,0,0,0,.869-.5A3.693,3.693,0,0,0,17.449,18.716Z"
                />

                <path
                  id="Path_8149"
                  data-name="Path 8149"
                  d="M6.9,9.76a.5.5,0,0,0,.017.509A5.959,5.959,0,0,0,12,12.5a5.961,5.961,0,0,0,5.083-2.232.5.5,0,0,0-.422-.768H7.339A.5.5,0,0,0,6.9,9.76Z"
                />
            </g>
        </svg>
    )
}
