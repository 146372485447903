import React from 'react';
import styles from './styles.module.scss';

export const Image = ({ src, label }) => (
  <img
    className={styles.img}
    src={src}
    alt={label}
  />
);
