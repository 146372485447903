import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { Document, Page } from 'react-pdf';
import { DocumentPreviewContext } from '../../index';
import { ErrorBoundary } from 'react-error-boundary';
import { Text } from 'components/typography/Text';

export const Preview = () => {
  const { url } = useContext(DocumentPreviewContext);

  return (
    <div className={styles.preview}>
      <ErrorBoundary FallbackComponent={<Text intl="genericErrorMessage" />}>
        <Document
          file={url}
          loading={<Text center dark intl="loadingDocument" />}
        >
          <Page
            pageNumber={1}
            width={110}
            height={148}
          />
        </Document>
      </ErrorBoundary>
    </div>
  );
}
