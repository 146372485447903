import React, { useContext } from "react";
import { DocumentPreviewContext } from "../../index";
import { IFrame } from "./../../../IFrame";
import styles from './styles.module.scss';

export const VideoPreview = () => {
  const { url } = useContext(DocumentPreviewContext);

  return (
    <div className={styles.preview}>
      <IFrame 
        src={url} 
        width={"100%"} 
        height={"100%"}
      />
    </div>
  )
};
