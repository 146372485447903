import lkfEnglish from 'translations/lkf/english.json';
import lkfSwedish from 'translations/lkf/swedish.json';
import lkfLogoWhite from 'assets/media/lkf/logo-white.svg';
import lkfWelcome from 'assets/media/lkf/welcome.png';
import lkfFavicon from 'assets/media/lkf/favicon.ico';
import lkfThemes from 'global/tenant-themes/lkf-themes';
import { LKFOnboarding } from 'pages/onboarding/LKFOnboarding';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { LKFLivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LKFLivingInfo';
import { WHITE_BG } from 'services/user/settings/utils';

export const lkfTenant = {
  assets: {
    logoWhite: lkfLogoWhite,
    logoDark: lkfLogoWhite,
    favicon: lkfFavicon,
    welcome: lkfWelcome,
  },
  intl: {
    english: lkfEnglish,
    swedish: lkfSwedish,
  },
  components: {
    onboarding: LKFOnboarding,
  },
  initialBackground: WHITE_BG,
  themes: lkfThemes,
  sizes: {
    brikk: '300px',
    'brikks-spacing': '12px',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
  areas: {
    1101: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1201: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1203: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1205: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1206: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1207: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1208: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1211: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    1221: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    2501: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    2503: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    2511: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://www.viaeuropa.se/login.aspx',
        changeSpeed: 'https://www.viaeuropa.se/login.aspx',
      },
    },
    4211: {
      brikks: ['A01', 'A02', 'A03', 'A05', 'A06', 'A07', 'A08', 'A09', 'A11', 'A12', 'A19', 'B09', 'B11', 'B12', 'C07', 'H10'],
      safety: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/hemforsakring/',
        title: 'Hemförsäkring Länsförsäkringar',
      },
      communication: {
        link: 'https://www.lkf.se/ar-hyresgast/hyresgastinformation/tv-bredband-och-telefoni/',
        title: 'Tele 2 TV-utbud',
      },
      store: {
        updateService: 'https://fiber.lkf.se/login.aspx',
        changeSpeed: 'https://fiber.lkf.se/login.aspx',
      },
    },
  },
  city: 'Lund',
  openweathermapLat: '55.7058',
  openweathermapLon: '13.1932',
  officeLocation: 'https://www.hitta.se/kartan!~55.71815,13.23598,15.608082685906231z/tr!i=5x7zRclq/geocode!l=55.71882:13.23417',
  housingQueue: {
    link: 'https://www.lkf.se/att-hyra-hos-oss/boko-och-kopoang/',
  },
  myHome: {
    components: {
      livingInfo: LKFLivingInfo,
    },
    generalInfo: {
      link: 'https://www.lkf.se/',
      title: 'Lunds Kommuns Fastighetsbolag (LKF)',
      areas: 'https://www.lkf.se/vara-omraden/',
    },
  },
  rpName: 'lunds-kommuns-fastighets-ab',
  gaTracking: '',
};
