import React from "react";

export const CameraIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="17"
    viewBox="0 0 35.09 19"
  >
    <g
      id="meeting-camera"
      transform="translate(-0.25 -5.499)"
    >
      <rect
        id="Rectangle_3635"
        data-name="Rectangle 3635"
        width="22"
        height="19"
        rx="1"
        transform="translate(0.25 5.499)"
        fill="black"
      />

      <path
        id="Path_8010"
        data-name="Path 8010"
        d="M26.848,6.111a.747.747,0,0,0-.727-.033l-8.959,4.48a.747.747,0,0,0-.412.669v7.466a.747.747,0,0,0,.412.667l8.959,4.48a.747.747,0,0,0,1.081-.667V6.747A.747.747,0,0,0,26.848,6.111Z"
        transform="translate(8.138 0.247)"
        fill="black"
      />
    </g>
  </svg>
);
