import { useQuery } from 'react-query';
import { getDevicesAPI } from 'services/service-report/devices/api';

const DEVICES = 'devices';

export function useDevices(spaceId) {

  const { data, isLoading, isError } = useQuery(
    [DEVICES, spaceId],
    () => getDevicesAPI(spaceId),
    { enabled: !!spaceId },
  );

  return {
    devices: data || [],
    loading: isLoading,
    error: isError,
  };
}
