import React from 'react';

export const UpgradesIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <path
      id="Path_8286"
      data-name="Path 8286"
      d="M14.631,8.133a.417.417,0,0,0,.306-.125l.978-1A3.159,3.159,0,0,0,16.737,5V1.667A1.667,1.667,0,0,0,15.07,0h0L11.522,0A3.122,3.122,0,0,0,9.511.837L.487,9.862a1.667,1.667,0,0,0,0,2.357l4.03,4.032a1.643,1.643,0,0,0,1.172.485H5.7a1.646,1.646,0,0,0,1.175-.5l.863-.881a.417.417,0,0,0,.118-.308,6.647,6.647,0,0,1,6.776-6.913Zm-4.144-3.55A1.667,1.667,0,1,1,12.154,6.25a1.667,1.667,0,0,1-1.667-1.667Z"
      transform="translate(0)"
    />

    <path
      id="Path_8287"
      data-name="Path 8287" d="M16.417,11a5.417,5.417,0,1,0,5.417,5.417A5.417,5.417,0,0,0,16.417,11Zm-.833,7.917V16.625a.208.208,0,0,0-.208-.208H14.75a.417.417,0,0,1-.326-.677l1.667-2.083a.433.433,0,0,1,.652,0l1.667,2.083a.417.417,0,0,1-.326.677h-.625a.208.208,0,0,0-.208.208v2.292a.833.833,0,1,1-1.667,0Z"
      transform="translate(-1.834 -1.833)"
    />
  </svg>
);
