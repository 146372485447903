import { VXB } from 'global/tenant-config/tenants';

const officeHours = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  2: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  6: null,
};

const officeHoursVXB = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  2: {
    from: {
      h: 10,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  6: null,
};

export const serviceOfficeHoursByDays = (tenantId) => {
  switch (tenantId) {
    case VXB:
      return officeHoursVXB;
    default:
      return officeHours;
  }
};
