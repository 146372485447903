import React from 'react';

export const InsuranceHouseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.457"
      height="20.458"
      viewBox="0 0 20.457 20.458"
    >
      <g id="real-estate-insurance-house" transform="translate(0 0)">
        <rect
          id="Rectangle_3917"
          data-name="Rectangle 3917"
          width="3.41"
          height="7.672"
          rx="1"
          transform="translate(0 11.983)"
        />
        <path
          id="Path_8259"
          data-name="Path 8259"
          d="M20.96,18.475a.426.426,0,0,0,.11-.682A2.758,2.758,0,0,0,18.1,16.83c-.049.014-1.677.535-1.725.551a.426.426,0,0,0-.3.413,1.918,1.918,0,0,1-1.917,1.952H9.688a.426.426,0,0,1,0-.852h4.475a1.066,1.066,0,0,0,0-2.131l-3.032.006A.421.421,0,0,1,10.9,16.7c-.1-.066-.244-.17-.365-.256a5.827,5.827,0,0,0-3.4-1.387h-1.7A.426.426,0,0,0,5,15.484v5.029a.426.426,0,0,0,.289.4c.6.2,1.143.392,1.634.563a15.554,15.554,0,0,0,4.618,1.2c1.384,0,2.6-.632,5.233-2.034C17.866,20.066,19.226,19.343,20.96,18.475Z"
          transform="translate(-0.738 -2.223)"
        />
        <path
          id="Path_8260"
          data-name="Path 8260"
          d="M17.8,2.488a.426.426,0,0,0,.709-.319V.852A.852.852,0,0,0,17.66,0H16.126a.426.426,0,0,0-.283.745Z"
          transform="translate(-2.317 0)"
        />
        <path
          id="Path_8261"
          data-name="Path 8261"
          d="M5.426,6.393h1.7a.426.426,0,0,1,.426.426v4.262a.852.852,0,0,0,.852.852H9.688a.852.852,0,0,0,.852-.852V8.95a.426.426,0,0,1,.426-.426h1.7a.426.426,0,0,1,.426.426v2.131a.852.852,0,0,0,.852.852h1.279a.852.852,0,0,0,.852-.852V6.819a.426.426,0,0,1,.426-.426h1.7a.426.426,0,0,0,.283-.745L12.385.215h0a.852.852,0,0,0-1.132,0L5.143,5.648a.426.426,0,0,0,.283.745Z"
          transform="translate(-0.738 0)"
        />
      </g>
    </svg>
  );
};
