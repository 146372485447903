import * as Yup from 'yup';

export const submitInternetSubscriptionSchema = Yup.object().shape({
  activationDate: Yup.date()
    .required(),
  termsAndConditions: Yup.boolean()
    .required()
    .oneOf([true]),
});

export const submitInternetSubscriptionSchemaFree = Yup.object().shape({
  activationDate: Yup.date()
    .required(),
});
