import React from 'react';
import NetworkSrc from 'assets/media/brikks/internet-access/network.png';
import InternetAccessSrc from 'assets/media/brikks/internet-access/internet-initial.png';
import { Brikk } from 'components/Brikk';
import { Grid } from 'components/layout/Grid';
import { ModalIntro } from 'components/layout/ModalIntro';
import { ModalCard } from 'components/cards/ModalCard';
import { Text } from 'components/typography/Text';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { BgImage } from 'components/media/BgImage';
import { MultiStepContext } from 'components/MultiStep';
import { Card } from 'components/cards/Card';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { StepProgress } from '../StepProgress';
import styles from './styles.module.scss';

export const InternetProvidersOverview = () => {
  const { onNextStep } = React.useContext(MultiStepContext);

  return (
    <ModalCard>
      <BgImage
        bgImage={InternetAccessSrc}
      >
        <OnboardingGrid>
          <Grid.Column className={styles.internetContent}>
            <ModalIntro.LargeImage 
              src={NetworkSrc}
            />
          </Grid.Column> 

          <Grid.Column className={styles.internetContent}>
            <Brikk.Heading className={styles.onboardingInternetHeading}>
              <Text
                className={styles.title}
                intl="internetAccess"
                white
                bold
              />

              <Card className={styles.onboardingInternetCard}>
                <Text
                  size={20}
                  intl="onboardingInternetDescription"
                  dark
                /> 
              </Card>
          
            </Brikk.Heading>
          </Grid.Column>

          <NextStep
            onClick={onNextStep}
          />

          <StepProgress />

        </OnboardingGrid>
      </BgImage>
    </ModalCard>
  );
};
