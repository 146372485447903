import React from 'react';
import styles from './styles.module.scss';

export const EnglishFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 33.563 19.577" className={styles.icon}>
    <g transform="translate(-356.259 -256.105)">
      <g className="b">
        <rect className={styles.white} width="33.563" height="19.577" transform="translate(356.259 256.105)"/>
        <path className={styles.line} d="M378.731,263.184l11.09-6.208v-.87h-.765l-12.645,7.078Z"/>
        <path className={styles.line} d="M378.208,269l11.613,6.5v-1.3l-9.293-5.2Z"/>
        <path className={styles.line} d="M356.259,257.6l9.935,5.588h2.321L356.259,256.3Z"/>
        <path className={styles.line} d="M367.988,269l-11.729,6.572v.113h2.119L370.31,269Z"/>
        <path className={styles.background} d="M387.524,256.105H375.773v6.581Z"/>
        <path className={styles.background} d="M370.585,256.105h-11.7l11.7,6.581Z"/>
        <path className={styles.background} d="M389.821,263.184v-4.491l-7.972,4.491Z"/>
        <path className={styles.background} d="M389.821,273.44V269H381.85Z"/>
        <path className={styles.background} d="M359.545,275.683h11.04V269.5Z"/>
        <path className={styles.background} d="M375.773,275.683h11.075L375.773,269.5Z"/>
        <path className={styles.background} d="M356.259,269v4.643L364.508,269Z"/>
        <path className={styles.background} d="M356.259,263.184h8.249l-8.249-4.65Z"/>
        <path className={styles.line}
              d="M371.623,256.105v8.241H356.259v3.49h15.364v7.846h3.112v-7.846h15.086v-3.49H374.735v-8.241Z"/>
      </g>
    </g>
  </svg>
);
