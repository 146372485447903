import React from 'react';
import brikkPreviewSrc from 'assets/media/climate-preview.png';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';

export const ClimatePreview = () => (
  <CircularImage
    src={brikkPreviewSrc}
    alt="Climate preview image"
  />
);
