import React from 'react';
import { IntlPageTitle } from "components/intl/IntlPageTitle";
import { Grid } from "components/layout/Grid";
import { Brikk } from "components/Brikk";
import { ModalIntro } from "components/layout/ModalIntro";
import terminateReasonImgSrc from "assets/media/terminate-reason.png";
import { Modal } from "semantic-ui-react";
import { TerminateReasons } from "components/brikks/home/TerminateAgreement/components/TerminateDetails/components/TerminateReasons";

export const TerminateDetails = ({isOpen, setIsOpen, setTerminationDetails, setIsSigningPopupOpen, finalTerminationDate}) => {

  const onClose = () => {
    setIsSigningPopupOpen(true);
    setIsOpen(false);
  };

  return (
    <Modal
      open={isOpen}
      size={"large"}
      closeIcon
      onClose={() => onClose()}
    >
      <Grid twoEqualColumns>
        <IntlPageTitle intlTitle="terminateDetails" />

        <Grid.Column>
          <Brikk.Heading main heading="terminateDetails">
            <ModalIntro>
              <ModalIntro.Image
                src={terminateReasonImgSrc}
                alt="Flowers"
              />

              <ModalIntro.Text intl="terminateDetailsDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <TerminateReasons
            onClose={onClose}
            setTerminationDetails={setTerminationDetails}
            finalTerminationDate={finalTerminationDate}
          />
        </Grid.Column>
      </Grid>
    </Modal>
  )
}
