import React, { createContext } from 'react';
import styles from './styles.module.scss';
import { DocumentTitle } from './components/DocumentTitle';
import { Preview } from './components/Preview';
import { VideoPreview } from './components/VideoPreview';
import { Actions } from './components/Actions';

export const DocumentPreviewContext = createContext(null);

export const DocumentPreview = ({ url, name, intl, children, downloadName }) => (
  <DocumentPreviewContext.Provider value={{ url, name, intl, downloadName }}>
    <div className={styles.documentPreview}>
      {children}
    </div>
  </DocumentPreviewContext.Provider>
);

DocumentPreview.DocumentTitle = DocumentTitle;
DocumentPreview.Preview = Preview;
DocumentPreview.VideoPreview = VideoPreview;
DocumentPreview.Actions = Actions;

