import React from 'react';
import { Text } from 'components/typography/Text';
import { Segment } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const ShowErrors = ({ errors }) => {

    return (
        <Segment className={styles.warningBox}>
            {errors.map((err, index) =>
                err && <Text
                    key={index}
                    secondary
                    intl={err}
                    size={16}
                />)}
        </Segment>
    )
}