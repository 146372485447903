import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import ourGreenCarSrc from 'assets/media/our-green-car.png';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { MobilityOurGreenCarIcon } from 'assets/icons/brikks/MobilityOurGreenCarIcon';
import { LinkBrikkContent } from "components/brikks/sub-components/LinkBrikkContent";
import { CircularImage } from "components/brikks/sub-components/CircularImage";
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const MobilityOurGreenCar = ({ position, status, code, name }) => {

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }

  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<MobilityOurGreenCarIcon />}
            heading="ourGreenCar"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={ourGreenCarSrc}
              alt="Illustrations of a car"
            />

            <LinkBrikkContent link="xplorion.ourgreencar.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="OurGreenCar Link"
              to="https://xplorion.ourgreencar.se/"
              brikkNameIntl="ourGreenCar"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>

      </Draggable>
      <BrikkInfoModal
        title="Our green car info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />

    </>
  )
};
