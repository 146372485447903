import React, { useMemo } from 'react';
import { useSubmitContactInfo } from 'services/contact/submit-contact-info';
import { Button } from 'components/form-elements/buttons/Button';
import styles from './styles.module.scss';

export const Submit = ({ description, telNumber, telType, reset }) => {
  const {
    isLoading,
    submitContactInfo,
  } = useSubmitContactInfo({
    onSuccess: () => {
      reset();
    },
  });

  const isSaveButtonDisabled = useMemo(() => !description.trim() || !telNumber.trim(), [description, telNumber]);

  return (
    <Button
      className={styles.button}
      name="submit-message"
      type="submit"
      loading={isLoading}
      floated="right"
      disabled={isSaveButtonDisabled || isLoading}
      onClick={() => submitContactInfo({ description, telNumber, telType })}
      intl="send"
    />
  );
};
