import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Menu } from './components/Menu';
import { Step } from './components/Step';

export const NavigationMenu = ({ children, fixed }) => (
  <nav
    className={classNames(
      styles.navigationMenu, {
        [styles.fixed]: fixed,
      }
    )}
  >
    {children}
  </nav>
);

NavigationMenu.Menu = Menu;
NavigationMenu.Step = Step;
