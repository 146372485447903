import React from 'react';
import { components } from 'react-select';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';
import { Select } from 'components/form-elements/selects/Select';

const Option = (props) => {
  const { isSelected, label } = props;
  
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={() => null}
        />{' '}
        <label>{label}</label>
      </components.Option>
    </div>
  );
};

export const MultiSelect = ({ options, title, description, className, onChange, value, placeholder, isLoading }) => (
  <LabeledElement
    label={title}
    description={description}
  >
    <Select
      options={options}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      components={{
        Option,
      }}
      onChange={onChange}
      allowSelectAll
      value={value}
      className={className}
      placeholder={placeholder}
      isLoading={isLoading}
    />
  </LabeledElement>
);

