import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import regionalAdministration from 'assets/media/regional-administration.jpg';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { MapIcon } from 'assets/icons/brikks/MapIcon';
import { LinkBrikkContent } from "components/brikks/sub-components/LinkBrikkContent";
import { CircularImage } from "components/brikks/sub-components/CircularImage";
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const Administration = ({ position, status, code, name }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }
  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<MapIcon />}
            heading="administrationBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={regionalAdministration}
              alt="Regional Administration"
            />

            <LinkBrikkContent link="skane.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="Regional Administration Link"
              to="https://skane.se/"
              brikkNameIntl="Region"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="Regional Administration info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />
    </>
  )
};
