import { useDispatch, useSelector } from 'react-redux';
import { bankIDSigning, bankIDSigningReset } from './actions';
import { useEffect } from 'react';

export function useBankIDSigning() {
  const {
    isSuccessful,
    loading,
    error,
    orderRef,
    isInitSuccessful,
  } = useSelector(state => state.bankIDSigning);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(bankIDSigningReset());
  }, [dispatch])

  return {
    isSuccessful,
    loading,
    error,
    orderRef,
    isInitSuccessful,
    onSignWithBankID: (personalNumber, userVisibleData) => dispatch(bankIDSigning(personalNumber, userVisibleData)),
  }
}
