import React from 'react';

export const BabyFaceIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.171" viewBox="0 0 20 19.171">
            <g id="baby-1" transform="translate(0 -0.5)">
                <circle id="Ellipse_363" data-name="Ellipse 363" cx="1.25" cy="1.25" r="1.25" transform="translate(5.698 8.834)"  />
                <circle id="Ellipse_364" data-name="Ellipse 364" cx="1.25" cy="1.25" r="1.25" transform="translate(11.803 8.834)"  />
                <path id="Path_7777" data-name="Path 7777" d="M17.918,8.81V8.7A8.1,8.1,0,0,0,10.543.518a7.925,7.925,0,0,0-8.46,7.9v.257a.437.437,0,0,1-.222.358,2.917,2.917,0,0,0,.658,5.609.2.2,0,0,1,.157.12,7.917,7.917,0,0,0,14.649,0,.2.2,0,0,1,.157-.12A2.917,2.917,0,0,0,18.056,9a.2.2,0,0,1-.138-.19ZM17.084,13h-.313a.833.833,0,0,0-.819.678,4.308,4.308,0,0,1-1.51,2.75C10.175,19.619,5.26,17.608,4.025,13.6a.833.833,0,0,0-.8-.588H2.917a1.25,1.25,0,1,1,.018-2.5.823.823,0,0,0,.816-.833V8.417A6.25,6.25,0,0,1,9.864,2.173l.017.008a1.648,1.648,0,0,1,1.048.726A1.407,1.407,0,0,1,10.651,4.9a1.061,1.061,0,0,1-1.407-.255.834.834,0,0,0-1.5.728A2.811,2.811,0,0,0,10.025,6.75a2.9,2.9,0,0,0,2.763-2.238,3.242,3.242,0,0,0,.012-1.3A.209.209,0,0,1,13.107,3a6.245,6.245,0,0,1,3.144,5.415v1.25a.823.823,0,0,0,.816.833,1.25,1.25,0,1,1,.018,2.5Z" transform="translate(0)" />
                <path id="Path_7778" data-name="Path 7778" d="M13.841,15.843a3.016,3.016,0,0,1-3.682,0,1,1,0,0,0-1.318,1.5,4.9,4.9,0,0,0,6.318,0,1,1,0,1,0-1.318-1.5Z" transform="translate(-2.009 -2.876)" />
            </g>
        </svg>
    )
}