import React from 'react';
import { FormattedMessage } from 'react-intl';
import InternetAccessSrc from 'assets/media/brikks/internet-access/internet-circular.png';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { SupplierDetails } from './components/SupplierDetails';

export const SubscriptionDetails = () => (
  <Grid brikkLayout>
    <Grid.Column>
      <Brikk.Heading
        main
        heading="internet"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={InternetAccessSrc}
            alt="Internet Access"
          />

          <ModalIntro.Text>
            <FormattedMessage id="onboardingInternetDescription" />
          </ModalIntro.Text>
        </ModalIntro>
      </Brikk.Heading>

    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="supplier" />

      <SupplierDetails />
    </Grid.Column>
  </Grid>

);
