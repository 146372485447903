import { useQuery } from 'react-query';
import { downloadFloorPlan } from 'utils/download';
import { GRT, RIB } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { getApartmentDrawingAPI } from './api';

const FLOOR_PLAN = 'floorPlan';

export function useFloorPlan() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [FLOOR_PLAN, tenantId],
    () => getApartmentDrawingAPI(),
    {
      enabled: ![GRT, RIB].includes(tenantId),
    },
  );

  return { 
    onDownloadFloorPlan: () => downloadFloorPlan(data.link),
    floorPlan: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
