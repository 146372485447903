import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';

export const SmallOverlay = ({ intl }) => (
  <div className={styles.smallOverlay}>
    <Text
      white
      bold
      element="h4"
      size={10}
      intl={intl}
    />
  </div>
);
