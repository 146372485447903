import React from 'react';
import styles from './styles.module.scss'
import { ProceedButton } from '../ProceedButton';

export const NextStep = ({ onClick, disabled }) => (
  <div className={styles.nextStep}>
    <ProceedButton onClick={disabled ? undefined : onClick} disabled={disabled}>
      <ProceedButton.Button intl="nextStep" />
      <ProceedButton.CircledArrow dark />
    </ProceedButton>
  </div>
);
