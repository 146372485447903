import { useMutation, useQueryClient } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useModal } from 'store/modals';
import { deleteLightningGroupAPI } from './api';

const LIGHTNING_GROUPS = 'lightningGroups';
const LIGHTNING_SCENARIOS = 'lightningScenarios';

export function useDeleteLightningGroup({ onSuccess }) {
  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { openModal } = useModal();

  const { mutate, isLoading } = useMutation(
    (groupId) => deleteLightningGroupAPI(groupId),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'deleteGroupSuccess',
          },
        });
        queryClient.invalidateQueries([LIGHTNING_GROUPS, tenantId]);
        queryClient.invalidateQueries([LIGHTNING_SCENARIOS, tenantId]);
        onSuccess();
      },
      onError: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: 'deleteGroupFail',
          },
        });
      },
    },
  );

  return {
    deleteLightningGroup: mutate,
    isLoading,
  };
}
