import { getIdToken } from './token';

export async function getUserFromToken() {
  try {
    const token = await getIdToken();
    if (!token) {
      return null;
    }

    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('')
      .map(c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`).join(''));

    return JSON.parse(jsonPayload);
  } catch (e) {
    return null;
  }
}
