import React, { useContext } from "react";
import { CleaningAndMaintenanceModalContext } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/index";
import { Kitchen } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/CheckListTab/Kitchen";
import { Bathroom } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/CheckListTab/Bathroom";

// Third column of the modal
export const MenuContent = () => {
  const { activeMenu } = useContext(CleaningAndMaintenanceModalContext);

  const content = () => {
    switch(activeMenu){
      case 'kitchen':
        return <Kitchen />;
      case 'bathroom':
        return <Bathroom />;
      default: 
        return null;
    }
  }
  
  return content();
}
