import React from 'react';

export const SunFaceIcon = () => {
    return (
        <svg id="face-id-8" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_7565" data-name="Path 7565" d="M14.208,12.876a.834.834,0,1,1,1.4.912,5.417,5.417,0,0,1-9.083,0,.834.834,0,1,1,1.4-.912,3.75,3.75,0,0,0,6.287,0Z" transform="translate(-1.064 -2.083)" />
            <path id="Path_7566" data-name="Path 7566" d="M9.333,10.333A.833.833,0,0,1,8.5,9.5V7.833a.833.833,0,1,1,1.667,0V9.5A.833.833,0,0,1,9.333,10.333Z" transform="translate(-1.417 -1.167)" />
            <path id="Path_7567" data-name="Path 7567" d="M14.333,10.333A.833.833,0,0,1,13.5,9.5V7.833a.833.833,0,1,1,1.667,0V9.5A.833.833,0,0,1,14.333,10.333Z" transform="translate(-2.25 -1.167)" />
            <path id="Path_7568" data-name="Path 7568" d="M18.315,18.315a.833.833,0,0,1,1.178,0l.589.589A.833.833,0,1,1,18.9,20.083l-.589-.589A.833.833,0,0,1,18.315,18.315Z" transform="translate(-3.012 -3.012)" />
            <path id="Path_7569" data-name="Path 7569" d="M11.833,21.5a.833.833,0,0,1,.833.833v.417a.833.833,0,1,1-1.667,0v-.417A.833.833,0,0,1,11.833,21.5Z" transform="translate(-1.833 -3.583)" />
            <path id="Path_7570" data-name="Path 7570" d="M17.251,6.121a.833.833,0,0,1,0-1.179l1.473-1.472A.833.833,0,1,1,19.9,4.648l-1.47,1.473a.833.833,0,0,1-1.182,0Z" transform="translate(-2.834 -0.538)" />
            <path id="Path_7571" data-name="Path 7571" d="M11.833,2.917A.833.833,0,0,1,11,2.083V.833a.833.833,0,0,1,1.667,0v1.25A.833.833,0,0,1,11.833,2.917Z" transform="translate(-1.833 0)" />
            <path id="Path_7572" data-name="Path 7572" d="M20.5,11.833A.833.833,0,0,1,21.333,11h1.25a.833.833,0,1,1,0,1.667h-1.25A.833.833,0,0,1,20.5,11.833Z" transform="translate(-3.417 -1.833)" />
            <path id="Path_7573" data-name="Path 7573" d="M5.234,5.234a.833.833,0,0,1-1.178,0l-.589-.589A.833.833,0,1,1,4.645,3.466l.589.589A.833.833,0,0,1,5.234,5.234Z" transform="translate(-0.537 -0.537)" />
            <path id="Path_7574" data-name="Path 7574" d="M4.939,18.668a.833.833,0,0,1,0,1.179l-.294.294a.833.833,0,1,1-1.178-1.178l.294-.295a.833.833,0,0,1,1.178,0Z" transform="translate(-0.537 -3.071)" />
            <path id="Path_7575" data-name="Path 7575" d="M2.083,11.833a.833.833,0,0,1-.833.833H.833A.833.833,0,0,1,.833,11H1.25A.833.833,0,0,1,2.083,11.833Z" transform="translate(0 -1.833)" />
        </svg>

    )
}