import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

export async function getRSSFeedAPI() {

  const url = `${AWS_API_URL}/user/rss-feeds`;

  try {
    const data = await getRequest(url, await authorize());

    return Promise.resolve(
      data.map((data) => mapDataToClient(data)),
    );
  } catch (error) {
    return Promise.reject(error);
  }
}

