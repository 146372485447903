import React from 'react';
import serviceReportImgSrc from 'assets/media/error-report-preview.png';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { OfficeAndCallCenterStatus } from 'components/brikks/sub-components/OfficeAndCallCenterStatus';
import { tenantConfig } from 'global/tenant-config';

export const ServiceReportPreview = () => (
  <>
    <CircularImage
      src={tenantConfig?.serviceReport?.assets?.previewImage || serviceReportImgSrc}
      alt="Male with headphones sitting in front of a computer in an office"
    />

    <OfficeAndCallCenterStatus />
  </>
);

