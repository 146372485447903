import React from 'react';
import { Button, Modal } from 'semantic-ui-react'

export const BrikkInfoModal = ({ onClick, showModal, title, info }) => (
    <Modal
        size={"small"}
        open={showModal}
        onClose={onClick}
    >
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
            <p>Lorem ipsum dolor sit amet,
            consectetur adipiscing elit,
            sed do eiusmod tempor incididunt
            ut labore et dolore magna aliqua.
            Ut enim ad minim veniam,
            quis nostrud exercitation ullamco
            laboris nisi ut aliquip ex ea commodo consequat.
            Duis aute irure dolor in reprehenderit
            in voluptate velit esse cillum dolore eu
            fugiat nulla pariatur. Excepteur sint occaecat
            cupidatat non proident, sunt in culpa qui officia
                 deserunt mollit anim id est laborum.</p>
        </Modal.Content>
        <Modal.Actions>
            <Button negative onClick={onClick}>
                Close
          </Button>
        </Modal.Actions>
    </Modal >
);
