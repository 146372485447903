import React from 'react';
import { useIntl } from 'react-intl';
import { LightIcon } from 'assets/icons/LightIcon';
import { Radio } from 'components/form-elements/radios/Radio';
import { Text } from 'components/typography/Text';
import { Card } from 'components/cards/Card';
import Loader from 'components/loaders/Loader';
import { RangeSlider } from 'components/form-elements/RangeSlider';
import { useUpdateLightningDevice } from 'services/lightning/update-device';
import { ShouldRender } from 'components/ShouldRender';
import { Grid } from 'components/layout/Grid';
import { LabeledRadio } from 'components/form-elements/radios/LabeledRadio';
import { SHELLY_DIMMER_2 } from 'utils/types/lightningDevice';
import styles from './styles.module.scss';

export function LightningDevice({ source }) {

  const [brightness, setBrightness] = React.useState([source?.status?.brightness]);
  const { updateLightningDevice, isLoading } = useUpdateLightningDevice();

  const intl = useIntl();
  
  function onStatusUpdate(relay) {
    source.settings = {
      turn: (Array.isArray(source?.status?.isOn) ? !source?.status?.isOn[relay] : !source?.status?.isOn)
        ? 'on' : 'off',
      ...(Array.isArray(source?.status?.isOn) && { relay }),
    };
    updateLightningDevice(source);
  }

  function onBrightnessUpdate(value) {
    source.settings = {
      brightness: value,
    };
    updateLightningDevice(source);
  }

  if (isLoading) return <Loader />;

  return (
    <Card className={styles.card}>
      <div className={styles.data}>
        <LightIcon size={30} />

        <Grid
          oneColumn
          noPadding
          className={styles.actions}
        >
          <Text
            bold
            uppercase
            size={14}
          >
            {source?.displayName}
          </Text>

          <ShouldRender condition={typeof source?.status?.isOn === 'boolean'}>
            <Radio
              toggle
              name={source?.name}
              checked={source?.status?.isOn}
              onChange={() => onStatusUpdate()}
              label={intl.formatMessage({ id: source?.status?.isOn ? 'on' : 'off' })}
            />
          </ShouldRender>

          <ShouldRender condition={Array.isArray(source?.status?.isOn)}>
            <Grid
              oneColumn
              noPadding
              style={{ width: 'fit-content', gap: 20 }}
            >
              <LabeledRadio
                intl="relay1"
                toggle
                name={source?.name}
                checked={source?.status?.isOn[0]}
                onChange={() => onStatusUpdate(0)}
                label={intl.formatMessage({ id: source?.status?.isOn[0] ? 'on' : 'off' })}
              />

              <LabeledRadio
                intl="relay2"
                toggle
                name={source?.name}
                checked={source?.status?.isOn[1]}
                onChange={() => onStatusUpdate(1)}
                label={intl.formatMessage({ id: source?.status?.isOn[1] ? 'on' : 'off' })}
              />
            </Grid>
          </ShouldRender>
        </Grid>
      </div>

      <ShouldRender condition={typeof source?.status?.brightness === 'number'}>
        <div className={styles.slider}>
          <RangeSlider
            min={source?.deviceType === SHELLY_DIMMER_2 ? 1 : 0}
            value={brightness}
            onChange={(values) => setBrightness(values)}
            onFinalChange={(values) => onBrightnessUpdate(values[0])}
          />
        </div>
      </ShouldRender>
    </Card>
  );
}
