import React from 'react';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import styles from './styles.module.scss';

export const TemperatureDisplay = ({ title, value, unit, small, medium, large, blue, className }) => (
  <div className={styles.content}>
    <ShouldRender condition={!!title}>
      <Text
        size={12}
        intl={title}
        thin
        gray
      />
    </ShouldRender>

    <div className={classNames(
      styles.weatherSlide,
      small && styles.small,
      medium && styles.medium,
      large && styles.large,
      blue && styles.blue,
    )}
    >
      <div className={classNames(styles.valueWrapper, className)}>
        <span className={styles.Value}>
          {value || '/'}
        </span>

        <ShouldRender condition={!!value}>
          <span className={styles.unit}>
            {unit}
          </span>
        </ShouldRender>
      </div>
    </div>
  </div>
);
