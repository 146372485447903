import React from 'react';
import { getTenantIDBasedOnURL, tenantConfig } from 'global/tenant-config';
import { ExternalLink } from 'components/links/ExternalLink';
import { ShouldRender } from 'components/ShouldRender';
import { LKF } from 'global/tenant-config/tenants';
import { Grid } from 'components/layout/Grid';
import { ReportLink } from 'components/links/ReportLink';

export function GeneralInfo() {
  const { generalInfo } = tenantConfig.myHome;
  const tenantId = getTenantIDBasedOnURL();

  return (
    <>
      <ShouldRender condition={tenantId !== LKF && generalInfo}>
        <ExternalLink
          href={generalInfo?.link}
        >
          {generalInfo?.title}
        </ExternalLink>
      </ShouldRender>
      
      <ShouldRender condition={tenantId === LKF}>
        <Grid
          noPadding
          twoEqualColumns
          style={{ overflowY: 'hidden' }}
        >
          <ReportLink
            externalLink={generalInfo?.link}
            externalLinkIntl="website"
          />

          <ReportLink
            externalLink={generalInfo?.areas}
            externalLinkIntl="ourAreas"
          />
        </Grid>
      </ShouldRender>
    </>
  ); 
}
