import React from 'react';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { MultiStepContext } from 'components/MultiStep';
import { PrevStep } from 'components/form-elements/buttons/PrevStep';
import { InternetProviderContext } from 'components/brikks/store/InternetProviders/components/InternetProvidersModal';
import { StepProgress } from '../StepProgress';
import { ChooseSpeed } from './components/ChooseSpeed';
import { InternetSuppliers } from './components/InternetSuppliers';

export const InternetProviders = () => {
  const { onNextStep, onPrevStep } = React.useContext(MultiStepContext);
  const { internetProvider, selectedSpeed } = React.useContext(InternetProviderContext);

  return (
    <div>
      <Grid
        brikkLayout
        twoColumns
      >
        <IntlPageTitle intlTitle="internetProviders" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="internetProviders"
          />

          <Brikk.Heading heading="internetSpeed" />

          <ChooseSpeed />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading
            heading="suppliers"
          />

          <InternetSuppliers />
        </Grid.Column>
      </Grid>

      <PrevStep onClick={onPrevStep} />

      <NextStep
        onClick={onNextStep} 
        disabled={!internetProvider || !selectedSpeed} 
      />

      <StepProgress />
    </div>
  );
};
