import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { DropletIcon } from 'assets/icons/DropletIcon';
import { SlideIcon } from 'assets/icons/SlideIcon';
import { useTheme } from 'global/themes';
import classNames from 'classnames';
import { Text } from 'components/typography/Text';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

const WaterUsageSlider = ({ waterUsage: { hotWater, coldWater, totalWater } }) => {
  const hasHot = hotWater.total > 0;
  const hasCold = coldWater.total > 0;
  
  return (
    <CarouselProvider
      naturalSlideWidth={1}
      naturalSlideHeight={0.7}
      totalSlides={(hasHot && hasCold) ? 3 : 1}
      currentSlide={(hasHot && hasCold) ? 1 : 0}
      className={styles.WaterUsageCarousel}
    >
      <Slider>

        <ShouldRender condition={hasHot}>
          <CustomCardSlide
            index={0}
            icon="hotWater"
            value={hotWater.total}
          />
        </ShouldRender>

        <ShouldRender condition={(hasHot && hasCold)}>
          <CustomCardSlide
            index={1}
            icon="total"
            value={totalWater}
          />
        </ShouldRender>

        <ShouldRender condition={hasCold}>
          <CustomCardSlide
            index={2}
            icon="coldWater"
            value={coldWater.total}
          />
        </ShouldRender>

      </Slider>

      <ShouldRender condition={(hasHot && hasCold)}>
        <ButtonBack>
          <SlideIcon direction="left" />
        </ButtonBack>
        <ButtonNext>
          <SlideIcon />
        </ButtonNext>

        <DotGroup />
      </ShouldRender>

    </CarouselProvider>
  );
};

export default WaterUsageSlider;

const CustomCardSlide = ({ index, ...props }) => {
  const { theme } = useTheme();

  return (
    <Slide
      index={index}
      className={styles.Slide}
    >
      <div className={styles.SlideWrapper}>
        <div className={styles.icon}>
          <DropletIcon variant={props.icon} />
        </div>

        <div className={classNames(styles.ValueWrapper, theme.iconColor)}>
          {props.value}
        </div>

        <div className={classNames(styles.LabelWrapper, theme.iconColor)}>
          <Text intl="litresThisMonth" />
        </div>
      </div>
    </Slide>
  );
};
