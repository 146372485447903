import { convertToCron } from 'utils/cron';

export const mapDataForBackend = (scenario) => ({
  enabled: scenario.enabled,
  cronStart: convertToCron(scenario.onTime, scenario.days),
  cronEnd: convertToCron(scenario.offTime, scenario.days),
  devices: scenario.devices.map(item => (
    {
      id: item.deviceId,
      brightness: item?.status?.brightness,
      state: typeof item?.status?.isOn === 'boolean' ? `${item?.status?.isOn ? 'on' : 'off'}`
        : [item?.status?.relay1 ? 'on' : 'off', item?.status?.relay2 ? 'on' : 'off'],
    }
  )),
});

