import React from 'react';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { AppContext } from 'AppContext';
import { getWeatherAPI } from './api';

const WEATHER = 'weather';

export function useWeather() {
  const tenantId = getTenantIDBasedOnURL();
  const { tenant } = React.useContext(AppContext);

  const { data, isLoading, isFetching, isError } = useQuery(
    [WEATHER, tenantId],
    () => getWeatherAPI(tenant),
  );

  return {
    weather: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
