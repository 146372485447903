import { removeAuthToken } from 'utils/auth-token';
import { LOGIN_PAGE } from 'global/routes';

export function handleHttpError(error) {
  if (error.response) {
    if (error.response.status === 401) {
      removeAuthToken();
      window.location.href = LOGIN_PAGE;
    } else if (error.response.data) {
      return {
        ...error.response.data,
        statusCode: error.response.status,
        message: error.response.errorMessage || 'genericErrorMessage',
      };
    }
  }
  return {
    timeout: true,
    message: 'genericErrorMessage',
  };
}
