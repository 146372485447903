import { parseAddress } from 'utils/parser';

export const mapDataForClient = (userDetails) => ({
  firstName: userDetails.firstname || '',
  lastName: userDetails.lastname || '',
  fullName: userDetails.fullname || '',
  address: parseAddress(userDetails.address) || '',
  postalZipCode: userDetails.postalzipcode || '',
  postalCity: userDetails.postalcity || '',
  apartmentNumber: userDetails.apartmentno || '',
  email: userDetails.email || '',
  workEmail: userDetails.workemail || '',
  mobileNumber: userDetails.mobilephone || '',
  homeNumber: userDetails.phone || '',
  workNumber: userDetails.workphone || '',
  queuePoints: userDetails.appoint || '',
  realestate: userDetails.realestate || '',
  area: userDetails.area || '',
  stairwell: userDetails.stairwell || '',
  building: userDetails.building || '',
  object: userDetails.object || '',
  personalNumber: userDetails.personalNumber,
  tenant: userDetails.tenant,
});
