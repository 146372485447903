import React from 'react';
import { DatePicker } from 'components/form-elements/pickers/DatePicker';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';

export const LabeledDatepicker = ({ intl, minDate, maxDate, onChange, selectedDate }) => (
  <LabeledElement label={intl}>
    <DatePicker
      selectedDate={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={selectedDate => onChange(selectedDate)}
    />
  </LabeledElement>
);

