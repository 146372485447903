import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import lundRedCastle from 'assets/media/lund-red-castle.png';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { TeacherIcon } from 'assets/icons/brikks/TeacherIcon';
import { LinkBrikkContent } from "components/brikks/sub-components/LinkBrikkContent";
import { CircularImage } from "components/brikks/sub-components/CircularImage";
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const HighSchools = ({ position, status, code, name }) => {

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }

  return (

    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<TeacherIcon />}
            heading="highSchoolsBrikk"
            brikkKey={code} 
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={lundRedCastle}
              alt="Lund Municipality Logo"
            />

            <LinkBrikkContent link="lund.se" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="High schools Link"
              to="https://lund.se/"
              brikkNameIntl="Highschools"
            />
             <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="High schools info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />

    </>
  )



};
