import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FocusableElement } from 'components/FocusableElement';

export const ClickableIcon = ({ onClick, className, children, ignoreTheme, size, height = size }) => (
  <FocusableElement
    className={classNames(styles.clickableIcon, className, ignoreTheme && styles.ignoreTheme)}
    style={{ width: size, height }}
    onClick={onClick}
  >
    {children}
  </FocusableElement>
);
