import React from 'react';
import { useUserDetails } from 'services/user/details/hook';
import { useUpdateUserDetails } from 'services/user/update-details/hook';
import { Form } from 'components/form-elements/Form';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { LKF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { ShouldRender } from 'components/ShouldRender';
import { Controller, useForm } from 'react-hook-form';
import { SaveChanges } from './components/SaveChanges';
import styles from './styles.module.scss';

export const UserForm = () => {
  const { userDetails } = useUserDetails();

  const { control, handleSubmit, watch, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: userDetails,
  });

  const onReset = () => {
    reset(userDetails);
  };

  const { updateUserDetails, isLoading } = useUpdateUserDetails({
    onSuccess: () => {
      onReset();
    },
  });

  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const newUserDetails = watch();

  const onSubmit = (data) => {
    updateUserDetails(data);
  };

  return (
    <Form
      className={styles.form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.equalColumns}>
        <LabeledInput
          disabled
          labelIntl="firstName"
          id="first-name"
          value={userDetails.firstName}
        />

        <LabeledInput
          disabled
          labelIntl="familyName"
          id="family-name"
          value={userDetails.lastName}
        />
      </div>

      <div className={styles.smallBigColumn}>
        <LabeledInput
          disabled
          labelIntl="aptNo"
          id="apartment-number"
          value={userDetails.apartmentNumber}
        />
 
        <LabeledInput
          disabled
          labelIntl="address"
          id="address"
          value={userDetails.address} 
        />

        <ShouldRender condition={tenantId === LKF}> 
          <LabeledInput
            disabled
            labelIntl="postalZipCode"
            id="postalZipCode"
            value={userDetails.postalZipCode}
          />
        
          <LabeledInput
            disabled
            labelIntl="postalCity"
            id="postalCity"
            value={userDetails.postalCity}
          />
        </ShouldRender>
      </div>

      <LabeledInput
        disabled
        labelIntl="emailPrivate"
        id="email-private"
        type="email"
        value={userDetails.email}
      />

      <Controller
        name="mobileNumber"
        control={control}
        render={({ field: { ref, onChange, value } }) => (
          <LabeledInput
            labelIntl="mobilePhone"
            value={value}
            inputRef={ref}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="workNumber"
        control={control}
        render={({ field: { ref, onChange, value } }) => (
          <LabeledInput
            labelIntl="phoneWork"
            value={value}
            inputRef={ref}
            onChange={onChange}
          />
        )}
      />

      <Controller
        name="homeNumber"
        control={control}
        render={({ field: { ref, onChange, value } }) => (
          <LabeledInput
            labelIntl="phoneHome"
            value={value}
            inputRef={ref}
            onChange={onChange}
          />
        )}
      />

      <SaveChanges
        oldUserDetails={{ ...userDetails }}
        newUserDetails={{ ...newUserDetails }}
        isLoading={isLoading}
      />
    </Form>
  );
};
