import React from 'react';
import { useFloorPlan } from 'services/living-info/floor-plan';
import { Image } from 'components/media/Image';
import { ShouldRender } from 'components/ShouldRender';
import PlanOverviewSrc from 'assets/media/lkf/MyHome/planOverview.png';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { ExternalLink } from 'components/links/ExternalLink';
import Loader from 'components/loaders/Loader';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { Text } from 'components/typography';
import { AppTracking } from 'global/appTracking';

export const FloorPlan = () => {
  const { floorPlan, isLoading } = useFloorPlan();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  if (isLoading) return <Loader />;

  return (
    <>
      <ShouldRender condition={tenantId !== LKF}>
        {
          floorPlan.type === 'buffer' ? (
            <Image
              id="floorPlan"
              src={floorPlan.link || './placeholder-image_square.png'}
              alt="The floor plan of your living apartment"
            />
          ) : (
            <AppTracking.OutboundLink
              eventLabel="Open Floor Plan"
              to={floorPlan.link}
              target="_blank"
            >
              <Text
                underline
                element="span"
                intl="floorPlan"
              />
            </AppTracking.OutboundLink>
          )
        }
      </ShouldRender>
  
      <ShouldRender condition={tenantId === LKF}>
        <Image src={PlanOverviewSrc} />

        <ExternalLink href={floorPlan.link}>
          <ProceedButton>
            <ProceedButton.Button intl="floorPlan" />
            <ProceedButton.Redirect />
          </ProceedButton>
        </ExternalLink>
      </ShouldRender>
    </>
  );
};

