import React from 'react';
import styles from 'components/brikks/neighbourhood/Weather/components/WeatherModal/components/ForecastItem/styles.module.scss'
import { FormattedMessage } from 'react-intl';

export const ForecastItem = ({ label, labelIntl, icon, temp, tempMin }) => (
  <div className={styles.ForecastItem}>

    <div className={styles.label}>
      { !!labelIntl
        ? <FormattedMessage id={labelIntl} />
        : label
      }
    </div>

    <div className={styles.icon}>
      {icon}
    </div>

    <div className={styles.temp}>
      {temp + '°'}
    </div>

    { !!tempMin &&
      <div className={styles.tempMin}>
        {tempMin}°
      </div>
    }

  </div>
);
