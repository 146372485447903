import React from 'react';
import * as ReactIntl from 'react-intl';
import { InternetProviderContext } from 'components/brikks/store/InternetProviders/components/InternetProvidersModal';
import { useInternetProviders } from 'services/internet-providers/providers';
import { InternetSpeed } from '../InternetSpeed';

export function ChooseSpeed() {
  const { selectedSpeed, setSelectedSpeed, setInternetProvider } = React.useContext(InternetProviderContext);
  const { internetSpeeds } = useInternetProviders();

  const intl = ReactIntl.useIntl();

  React.useEffect(() => {
    if (internetSpeeds && !selectedSpeed) setSelectedSpeed(internetSpeeds[0]);
    // eslint-disable-next-line
  }, [internetSpeeds]);
  
  return (
    <>
      {
        internetSpeeds.map(internetSpeed => (
          <InternetSpeed
            key={internetSpeed.id}
            description={intl.formatMessage({ id: internetSpeed.description })}
            download={internetSpeed.download}
            upload={internetSpeed.upload}
            onClick={() => {
              setSelectedSpeed(internetSpeed);
              setInternetProvider(false);
            }}
            active={selectedSpeed && selectedSpeed.id === internetSpeed.id}
          />
        ))
      }
    </>
  );
}
