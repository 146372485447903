import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { FocusableElement } from 'components/FocusableElement';
import { CircledArrow } from './components/CircledArrow';
import { CircledArrowTop } from './components/CircledArrowTop';
import { Button } from './components/Button';
import { Text } from './components/Text';
import { CartIcon } from './components/CartIcon';
import { SignIn } from './components/SignIn';
import { Redirect } from './components/Redirect';

export const ProceedButton = ({ children, disabled, ariaLabel, stacked, onClick = () => {} }) => (
  <FocusableElement
    role="button"
    ariaLabel={ariaLabel}
    className={classNames(styles.proceedButton, disabled && styles.disabled, stacked && styles.stacked)}
    onClick={disabled ? undefined: onClick}
  >
    {children}
  </FocusableElement>
);

ProceedButton.Button = Button;
ProceedButton.Text = Text;
ProceedButton.CircledArrow = CircledArrow;
ProceedButton.CircledArrowTop = CircledArrowTop;
ProceedButton.CartIcon = CartIcon;
ProceedButton.SignIn = SignIn;
ProceedButton.Redirect = Redirect;
