import React from 'react';
import { useSubscriptions } from 'services/service-report/subscriptions';
import { SingleDropdown } from 'components/dropdowns/SingleDropdown';
import Loader from 'components/loaders/Loader';
import { ShouldRender } from 'components/ShouldRender';
import { INTERNET_PROVIDER, LIGHTS, CLIMATE } from 'utils/types';
import { FormattedMessage } from 'react-intl';
import { isBrikkAdded } from 'utils/tenant';
import { SubscriptionCategories } from '../SubscriptionCategories';
import styles from './styles.module.scss';

export function Subscriptions(
  { isServiceProperty, selectedService, onServiceSelect, selectedCategory, onCategorySelect, setDescriptionDisabled },
) {

  const { subscriptions, isLoading } = useSubscriptions();

  React.useEffect(() => {
    if (isLoading) {
      setDescriptionDisabled(true);
    } else if (subscriptions) {
      setDescriptionDisabled(!!subscriptions.length);
    } else {
      setDescriptionDisabled(false);
    }
    //eslint-disable-next-line
  }, [isServiceProperty, subscriptions, isLoading]);

  if (isLoading) return <Loader />;

  return (
    <div className={styles.items}>
      {
        subscriptions && subscriptions.map(subscription => (
          <SingleDropdown key={subscription.productId}>
            <SingleDropdown.Item
              item={subscription.productId}
              selectedItem={selectedService}
              onClick={() => onServiceSelect(subscription.productId)}
            >
              {subscription.type === INTERNET_PROVIDER ? <FormattedMessage id="internetProvider" />
                : subscription.type }

            </SingleDropdown.Item>

            <SingleDropdown.Content
              item={subscription.productId}
              selectedItem={selectedService}
            >
              <SubscriptionCategories
                item={subscription.type}
                selectedCategory={selectedCategory}
                onCategorySelect={onCategorySelect}
              />
            
            </SingleDropdown.Content>
          </SingleDropdown>
        ))
      }

      <ShouldRender condition={subscriptions?.length === 0}>
        <SingleDropdown>
          <SingleDropdown.Item
            item={INTERNET_PROVIDER}
            selectedItem={selectedService}
            onClick={() => onServiceSelect(INTERNET_PROVIDER)}
            intl="internetProvider"
          />

          <SingleDropdown.Content
            item={INTERNET_PROVIDER}
            selectedItem={selectedService}
          >
            <SubscriptionCategories
              item={INTERNET_PROVIDER}
              selectedCategory={selectedCategory}
              onCategorySelect={onCategorySelect}
            />
            
          </SingleDropdown.Content>
        </SingleDropdown>
      </ShouldRender>

      <ShouldRender condition={isBrikkAdded('E04B')}>
        <SingleDropdown>
          <SingleDropdown.Item
            item={LIGHTS}
            selectedItem={selectedService}
            onClick={() => onServiceSelect(LIGHTS)}
            intl="lights"
          />

          <SingleDropdown.Content
            item={LIGHTS}
            selectedItem={selectedService}
          >
            <SubscriptionCategories
              item={LIGHTS}
              selectedCategory={selectedCategory}
              onCategorySelect={onCategorySelect}
            />
          </SingleDropdown.Content>
        </SingleDropdown>
      </ShouldRender>

      <ShouldRender condition={isBrikkAdded('E06C')}>
        <SingleDropdown>
          <SingleDropdown.Item
            item={CLIMATE}
            selectedItem={selectedService}
            onClick={() => onServiceSelect(CLIMATE)}
            intl="climate"
          />

          <SingleDropdown.Content
            item={CLIMATE}
            selectedItem={selectedService}
          >
            <SubscriptionCategories
              item={CLIMATE}
              selectedCategory={selectedCategory}
              onCategorySelect={onCategorySelect}
            />
          </SingleDropdown.Content>
        </SingleDropdown>
      </ShouldRender>
    </div>
  );
}
