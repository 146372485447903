import { takeEvery, call, put, select } from 'redux-saga/effects';
import { getMaintenanceProductsAPI } from './api';
import { getMaintenanceProductsFail, getMaintenanceProductsSuccess, MaintenanceProductsActions } from './actions';

function* getMaintenanceProductsEffect({ payload: { type, unit, measure } }) {
  try {
    const { locale } = yield select(state => state.locale);
    const products = yield call(getMaintenanceProductsAPI, type, unit, measure, locale);
    yield put(getMaintenanceProductsSuccess({ type, unit, measure, products }));
  } catch (error) {
    yield put(getMaintenanceProductsFail({ type, unit, measure, error: error.message }));
  }
}

export const maintenanceProductsEffects = [
  takeEvery(MaintenanceProductsActions.MAINTENANCE_PRODUCTS_GET, getMaintenanceProductsEffect),
];
