import React from 'react';
import classNames from 'classnames';
import { Button } from 'components/form-elements/buttons/Button';
import styles from './styles.module.scss';

const weekdays = [
  { name: 'monStart', value: 1 },
  { name: 'tueStart', value: 2 },
  { name: 'wedStart', value: 3 },
  { name: 'thrStart', value: 4 },
  { name: 'friStart', value: 5 },
  { name: 'satStart', value: 6 },
  { name: 'sunStart', value: 0 },
];

export const WeekdayPicker = ({ onClick, selectedDays }) => (
  <div className={styles.weekdays}>
    {weekdays?.map((day, index) => (
      <Button
        key={index}
        type="button"
        value={day.value}
        onClick={onClick}
        className={classNames(styles.button, selectedDays.includes(day.value) && styles.clickedButton)}
        intl={day.name}
      />
    ))}
  </div>
);
