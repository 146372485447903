export const PRIVATE = 'private';
export const PUBLIC = 'public';
export const SERVICE = 'service';
export const HOME = 'home';
export const BUILDING = 'building';
export const NEIGHBORHOOD = 'neighborhood';
export const INTERNET_PROVIDER = 'Internet Provider';
export const LIGHTS = 'lights';
export const CLIMATE = 'indoor-climate';

