import React, { useContext } from 'react';
import { UserProfile } from 'components/headers/UserProfile';
import { ShouldRender } from 'components/ShouldRender';
import { tenantConfig } from 'global/tenant-config';
import { AppContext } from 'AppContext';
import { useRouteMatch } from 'react-router-dom';
import { HOME_PAGE } from 'global/routes';
import { Settings } from '../Settings';
import { ArrangeBrikks } from '../ArrangeBrikks';
import styles from './styles.module.scss';

export const Menu = () => {
  const {
    isAuthenticated,
  } = useContext(AppContext);

  const OrderMode = tenantConfig?.components?.orderMode;
  const { isExact: isHomePage } = useRouteMatch({ path: HOME_PAGE });

  return (
    <menu className={styles.menu}>
      <UserProfile />

      <ShouldRender condition={isAuthenticated}>
        {OrderMode && <OrderMode />} 
      </ShouldRender>

      <ShouldRender condition={isHomePage && isAuthenticated}>
        <ArrangeBrikks />
      </ShouldRender>

      <div className={styles.menuItem}>
        <ShouldRender condition={isAuthenticated}>
          <Settings />
        </ShouldRender>
        
      </div>
    </menu>
  );
};
