import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ClickableIcon } from 'assets/icons/ClickableIcon';

export const DiscardCrossIcon = ({ size = 44, white, ignoreTheme, onClick = () => {} }) => (
  <ClickableIcon
    size={size}
    onClick={onClick}
    ignoreTheme={ignoreTheme}
  >
    <svg
      style={{ width: size }}
      className={classNames(
        styles.icon,
        white && styles.white,
      )}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
    >
      <path
          className={styles.discard}
          d="M28.5,9.615,26.385,7.5,18,15.885,9.615,7.5,7.5,9.615,15.885,18,7.5,26.385,9.615,28.5,18,20.115,26.385,28.5,28.5,26.385,20.115,18Z"
          transform="translate(4 4)"/>
    </svg>
  </ClickableIcon>
);
