import React, { useContext } from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/styles.module.scss';
import { useMaintenance } from 'services/maintenance/planned-maintenance/hook';
import { unionBy, orderBy } from 'lodash';
import { Dropdown } from 'components/dropdowns/Dropdown';
import { ItemName } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/ItemName';
import { UpgradeTable } from "components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/UpgradeTable";
import { UpgradeContext } from "components/brikks/home/Upgrade/components/UpgradeModal/index";
import { formatCurrency } from "utils/format";

export const UpgradeItemsPreview = () => {
  const { maintenance: { plannedMaintenance } } = useMaintenance();

  const { selectedSurface } = useContext(UpgradeContext);

  const sortedMaintenance = orderBy(plannedMaintenance, ['type', 'residualValue'], ['asc', 'asc']);
  const rooms = unionBy(sortedMaintenance.map(item => ({ type: item.type, residualValue: item.residualValue })), 'type');

  return (
    <div className={styles.maintenanceItems}>
      {rooms.map(room => (
        <Dropdown
            key={room.type}
            open={selectedSurface?.type === room.type} // Open selected room when redirected from step 1.
        >
          <Dropdown.Item>
            <ItemName
              residualValue={formatCurrency(room.residualValue)}
              name={room.type}
            />
          </Dropdown.Item>

          <Dropdown.Content>
            <UpgradeTable
                maintenanceItems={plannedMaintenance.filter(item => item.type === room.type)}
                type={room.type}
            />
          </Dropdown.Content>
        </Dropdown>
      ))}
    </div>
  )
}
