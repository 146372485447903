import React, { useContext } from 'react';
import { InfoCard } from 'components/cards/InfoCard';
import { DropletIcon } from 'assets/icons/DropletIcon';
import { WaterUsageContext } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal/index';

export const WaterUsageCard = ({ variant, value, title, loading }) => {
  const { error24Hours } = useContext(WaterUsageContext);

  return (
      <InfoCard
        icon={<DropletIcon variant={variant} />}
        value={value}
        label={"litresPer24HShort"}
        title={title}
        loading={loading}
        error={error24Hours}
      />
  )
}
