import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';

export const ActionButton = ({ children, intl }) => (
  <div className={styles.actionButton}>
    {children}

    <Text
      intl={intl}
      size={10}
      underline
    />
  </div>
);

