/*
* Confirms whether the email exists
 */

import { ConfirmEmailActions } from './actions';

const initialState = {
  isEmailConfirmed: false,
  loading: false,
  error: '',
};

export function confirmEmailReducer(state = initialState, action) {
  switch (action.type) {
    case ConfirmEmailActions.CONFIRM_EMAIL:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ConfirmEmailActions.CONFIRM_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailConfirmed: true,
        loading: false,
      };
    case ConfirmEmailActions.CONFIRM_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ConfirmEmailActions.CONFIRM_EMAIL_RESET:
      return initialState;
    default:
      return state;
  }
}
