import React from 'react'; 
import { Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';

function FormErrors(props) {
  if (
    props.formerrors
        && (props.formerrors.blankfieldError)
  ) {
    return (
      <Message negative>
        <Message.Content> {props.formerrors.blankfieldError
          ? <FormattedMessage id="fieldsRequired" />
          : ''}
        </Message.Content>

      </Message>
    );
  } if (props.apierrors) {
    return (
      <Message negative>
        <Message.Content>{props.apierrors}</Message.Content>
      </Message>
    );
  } if (props.formerrors && props.formerrors.cognitoError) {
    if (props.formerrors.cognitoError.name === 'NotAuthorizedException') {
      return (
        <Message negative>
          <Message.Content><FormattedMessage id="invalidCurrentPassword" /></Message.Content>
        </Message>
      );
    }
    if (props.formerrors.cognitoError.name === 'UserNotFoundException') {
      return (
        <Message negative>
          <Message.Content><FormattedMessage id="emailNotRegistered" /></Message.Content>
        </Message>
      );
    }
    return (
      <Message negative>
        <Message.Content> {props.formerrors.cognitoError.message} </Message.Content>
      </Message>
    );
  } 
  return <div />;
    
}

export default FormErrors;
