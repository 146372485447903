import React from "react";
import styles from "./styles.module.scss";
import { Card, Placeholder } from "semantic-ui-react";
import { useTheme } from "global/themes";
import classNames from "classnames";
import { Text } from "components/typography/Text";
import { ShouldRender } from "../../ShouldRender";

export const InfoCard = ({ icon, value, loading, label, title, error }) => {
  const { theme } = useTheme();

  return (
    <Card className={classNames(styles.Card, theme.cardBackgroundColor)}>
      <Card.Content className={styles.Content}>
        <Card.Description className={styles.ValueWrapper}>
          <div className={styles.Icon}>{icon}</div>

          {!loading ? (
            <>
              <div className={classNames(styles.Value, theme.iconColor)}>
                {error ? <Text small intl={error} /> : value}
              </div>

              <ShouldRender condition={label && !error}>
                <div className={classNames(styles.ValueLabel, theme.iconColor)}>
                  <Text intl={label} />
                </div>
              </ShouldRender>
            </>
          ) : (
            <div className={styles.placeholder}>
              <Placeholder>
                <Placeholder.Line className={theme.backgroundColor} />
                <Placeholder.Line className={theme.backgroundColor} />
                <Placeholder.Line className={theme.backgroundColor} />
              </Placeholder>
            </div>
          )}
        </Card.Description>

        <Card.Header className={styles.Title} content={<Text intl={title} />} />
      </Card.Content>
    </Card>
  );
};
