import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { RibLivingInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingInfo';
import { GeneralInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/RIBGeneralInfo';

export const RIBLivingInfo = () => (
  <MultiTabMenu
    defaultTab="yourApartment"
    defaultMenu={RibLivingInfoOptions[0]}
    tabs={{
      yourApartment: { title: 'yourApartment', menus: RibLivingInfoOptions },
      generalInfo: { title: 'generalInfo', menus: GeneralInfoOptions },
    }}
  />
);
