import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import myHomeImgSrc from 'assets/media/my-home.png';
import { tenantConfig } from 'global/tenant-config';

export const LivingInfoModal = () => {
  const LivingInfo = tenantConfig.myHome.components.livingInfo;

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="myAccommodations" />

      <Grid.Column>
        <Brikk.Heading
          main
          heading="myHomeHeading"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={myHomeImgSrc}
              alt="Man sits at a computer at home and pets a dog"
            />

            <ModalIntro.Text intl="myHomeDescription" />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <LivingInfo />
    </Grid>
  );
};
