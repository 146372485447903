import React from "react";
import { CheckMarkIcon } from "assets/icons/CheckMarkIcon";
import { Text } from 'components/typography/Text';
import styles from 'components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/CheckListTab/Agreements/components/AgreementItem/styles.module.scss';
import { LinkModal } from "components/modals/LinkModal";
import { TerminateAgreementModal } from "components/brikks/home/TerminateAgreement/components/TerminateModal";
import { MyDocumentsModal } from "components/brikks/home/MyDocuments/components/MyDocumentsModal";
import classNames from 'classnames';
import { AgreementAction } from "components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/CheckListTab/Agreements/components/AgreementAction";

export const AgreementItem = ({agreement}) => {
  const isAgreementSignedBySecondPerson = agreement.status === 'pending' && 
  agreement.secondPersonSignedTermination;

  const isActive = agreement.status === 'active' || isAgreementSignedBySecondPerson;

  const agreementStatus = () => {
    const diff = new Date() - new Date(agreement.movingOutDate);

    if(agreement.status === 'active'){
      return 'active';
    }
    if(agreement.status === 'pending'){
      return 'requestedTerminationInProgress'
    }
    if(agreement.status === 'completed'){
      if(diff > 0){
        return 'terminated';
      } else {
        return 'requestedTermination';
      }
    } 
  }

  return (
    <div className={styles.agreement}>
      <div className={styles.status}>
        <CheckMarkIcon
          activeGrey={isActive}
          activeGreen={!isActive}
        />
      </div>


      <div className={styles.description}>
        <Text size={14} bold>{agreement.productType}</Text>

        <div className={styles.inline}>
          <Text intl="agreementStatus" className={styles.paddingRight} size={14} />
          <>
            <Text intl={agreementStatus()} size={14} />
            <span
              className={classNames(styles.statusIcon, {
                [styles.active]: agreement.status === 'active',
                [styles.pending]: agreement.status === 'pending',
                [styles.inactive]: agreement.status === 'completed',
              })}
            />
          </>
        </div>
      </div>

      <div className={styles.action}>
        <LinkModal
          buttonTriggerModal={AgreementAction}
          brikkModal={isActive ? TerminateAgreementModal : MyDocumentsModal}
          modalHeading={isActive ? 'terminateAgreements' : 'myDocuments'}
          selectedAgreement={agreement.agreementNumber}
          isActive={isActive} />
      </div>
    </div>
  );
};
