import React from 'react';
import { Text } from 'components/typography/Text';
import { Image } from 'components/media/Image';
import axisCommunicationsImage from 'assets/media/intercom/axisCommunications.png';
import iosDownloadBadge from 'assets/media/ios-download-badge.svg';
import androidDownloadBadge from 'assets/media/google-play-badge.png';
import downloadForWindowsBadge from 'assets/media/download-for-windows.png';
import { ExternalLink } from 'components/links/ExternalLink';
import styles from 'components/brikks/home/Intercom/components/IntercomModal/components/Download/styles.module.scss';

export const Download = () => (
  <>
    <Text
      intl="accessAppDownloadDescription"
      size={18}
    />

    <Image
      src={axisCommunicationsImage}
      className={styles.downloadSectionImage}
    />

    <div className={styles.downloadBadges}>
      <ExternalLink href="https://download.companion.connect.axis.com/AXISCompanionSetup.exe">
        <Image
          src={downloadForWindowsBadge}
          className={styles.iconFullWidth}
        />
      </ExternalLink>

      <ExternalLink href="https://apps.apple.com/us/app/axis-companion-4/id1385994562">
        <Image
          src={iosDownloadBadge}
          className={styles.icon}
        />
      </ExternalLink>

      <ExternalLink href="https://play.google.com/store/apps/details?id=com.axis.companion">
        <Image
          src={androidDownloadBadge}
          className={styles.iconFullWidth}
        />
      </ExternalLink>
    </div>
  </>
);
