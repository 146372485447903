import React from 'react';
import { TabMenu } from './components/TabMenu';
import { MenuContainer } from './components/MenuContainer';

export const Menus = ({ menus }) => (
  <MenuContainer>
    {menus.map((item) => (
      <TabMenu
        key={item.menu}
        item={item}
        subMenus={item.submenus}
        disabled={false}
        subIntl={item.subIntl}
      />
    ))}
  </MenuContainer>
);
