import React, { useEffect, useContext } from 'react';
import styles from 'components/brikks/home/TerminateAgreement/components/TerminateModal/components/SignAgreement/Terminate/styles.module.scss';
import { useTerminateAgreement } from 'services/agreements/terminate-agreement/hook';
import { AppContext } from 'AppContext';
import { Text } from 'components/typography/Text';
import Loader from 'components/loaders/Loader';
import { ShouldRender } from 'components/ShouldRender';
import { Button } from 'components/form-elements/buttons/Button';

export const Terminate = ({ orderRef, agreementNumber, selectedDate, endDate, productType, terminationDetails }) => {
  const { error, loading, terminateAgreements } = useTerminateAgreement();

  const { locale: { locale } } = useContext(AppContext);

  useEffect(() => {
    terminateAgreements({ agreementNumber, orderRef, selectedDate, endDate, locale, productType, ...terminationDetails });
  }, [terminateAgreements, orderRef, selectedDate, agreementNumber, endDate, locale, productType, terminationDetails])

  return (
    <div className={styles.maintenanceOrder}>
      <ShouldRender condition={!!loading}>
        <div className={styles.loading}>
          <Text
            center
            intl="terminateAgreementLoading"
            size={16}
          />

          <Loader />
        </div>
      </ShouldRender>

      <ShouldRender condition={!!error}>
        <div className={styles.error}>
          <Text error intl={error} />

          <Button
            name="terminate-agreement"
            intl="tryAgain"
            onClick={() => terminateAgreements({ agreementNumber, orderRef, selectedDate, endDate, locale, productType, ...terminationDetails })}
          />
        </div>
      </ShouldRender>
    </div>
  );
};
