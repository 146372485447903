import { takeLatest, call, put } from 'redux-saga/effects';
import { LoginEmailActions, loginEmailFail, loginEmailSuccess } from './actions';
import { loginEmailAPI } from './api';
import { initPassReset } from '../init-pass-reset/actions';
import { AppTracking } from 'global/appTracking';
import { TENANT_ID } from 'global/environment';

function* loginEmailEffect({ payload: { email, password } }) {
  try {
    const user = yield call(loginEmailAPI, email, password);

    if (JSON.parse(user.attributes['custom:data'])[TENANT_ID]) {
      yield put(loginEmailSuccess(user));
      AppTracking.authEvent({ action: 'Login', label: 'Email' });
    } else {
      yield put(loginEmailFail('incorrectCredentials'));
    }
  } catch (e) {
    if (e.code === 'PasswordResetRequiredException') {
      yield put(initPassReset(email));
    } else {
      yield put(loginEmailFail('incorrectCredentials'));
    }
  }
}

export const loginEmailEffects = [
  takeLatest(LoginEmailActions.LOGIN_EMAIL, loginEmailEffect),
];
