import React from 'react';

export const InternetProvidersIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <desc>Illustration of a globe that resembles Internet</desc>
    <g transform="translate(-0.005 -0.032)">
      <path
          d="M15.169,11.908a.208.208,0,0,0-.067-.2,1.868,1.868,0,0,1-.641-1.4,1.78,1.78,0,0,1,.07-.483.209.209,0,0,0-.075-.223A7.323,7.323,0,0,0,10.5,8.217a.61.61,0,0,1-.068,0,.208.208,0,0,0-.223.151,2.085,2.085,0,0,1-2,1.524.038.038,0,0,0-.038.031,10.265,10.265,0,0,0-.078,3.225.209.209,0,0,0,.173.179,17.711,17.711,0,0,1,5.3,1.77.21.21,0,0,0,.267-.066A8.443,8.443,0,0,0,15.169,11.908Z"
          transform="translate(-1.332 -1.345)"/>
      <path
          d="M12.783,17.64a.208.208,0,0,0-.053-.34,16.387,16.387,0,0,0-3.87-1.321.209.209,0,0,0-.239.272,8.594,8.594,0,0,0,.457,1.11,8.932,8.932,0,0,0,1.349,1.905.207.207,0,0,0,.267.037,24.277,24.277,0,0,0,2.089-1.662Z"
          transform="translate(-1.433 -2.621)"/>
      <path
          d="M5.252,13.2a.209.209,0,0,0,.221-.229,10.929,10.929,0,0,1,.171-3.38h0a.052.052,0,0,0-.018-.058,2.088,2.088,0,0,1-.648-.821.208.208,0,0,0-.226-.125A9.16,9.16,0,0,0,.084,10.937a.213.213,0,0,0-.063.136C.015,11.186,0,11.3,0,11.412A9.973,9.973,0,0,0,.21,13.4a.214.214,0,0,0,.093.135.207.207,0,0,0,.16.027A16.568,16.568,0,0,1,5.252,13.2Z"
          transform="translate(0 -1.406)"/>
      <path
          d="M7.83,20.035a.209.209,0,0,0,.083-.328,9.634,9.634,0,0,1-1.25-1.854A10.242,10.242,0,0,1,5.9,15.792a.207.207,0,0,0-.183-.156,15.694,15.694,0,0,0-4.743.277.208.208,0,0,0-.138.105.213.213,0,0,0-.01.175,10.062,10.062,0,0,0,3.444,4.473.211.211,0,0,0,.125.04,9.444,9.444,0,0,0,3.432-.67Z"
          transform="translate(-0.135 -2.554)"/>
      <path
          d="M17.806,12.939l-.005-.006a.207.207,0,0,0-.223-.069,1.83,1.83,0,0,1-.341.069.209.209,0,0,0-.182.167c-.485,2.223-1.773,4.469-4.792,6.553a.208.208,0,0,0-.022.327A23.918,23.918,0,0,0,14.46,21.75a.205.205,0,0,0,.174.031,10.01,10.01,0,0,0,5.381-3.658.208.208,0,0,0,.04-.157A11.3,11.3,0,0,0,17.806,12.939Z"
          transform="translate(-2.026 -2.108)"/>
      <path
          d="M4.833,5.9A.212.212,0,0,0,5,5.765,2.075,2.075,0,0,1,6.731,4.388.078.078,0,0,0,6.8,4.336h0A16.5,16.5,0,0,1,9.343.375.209.209,0,0,0,9.167.033,10.016,10.016,0,0,0,.5,7.184a.209.209,0,0,0,.312.235A10.746,10.746,0,0,1,4.833,5.9Z"
          transform="translate(-0.081 0)"/>
      <path
          d="M10.3,22.041A.207.207,0,0,0,10.069,22a10.485,10.485,0,0,1-1.972.7.209.209,0,0,0-.018.4,9.916,9.916,0,0,0,3.245.543c.083,0,.167,0,.25-.006a.209.209,0,0,0,.123-.371C11.258,22.91,10.78,22.5,10.3,22.041Z"
          transform="translate(-1.321 -3.609)"/>
      <path
          d="M18.6,7.324a.208.208,0,0,0,.124.19,1.877,1.877,0,0,1,1.128,1.716,1.837,1.837,0,0,1-.192.812.209.209,0,0,0,.035.235l.006.007a12.557,12.557,0,0,1,2.1,3.844.207.207,0,0,0,.389.015A10,10,0,0,0,18.157,1.7a.208.208,0,0,0-.311.229A23.785,23.785,0,0,1,18.6,7.324Z"
          transform="translate(-2.969 -0.27)"/>
      <path
          d="M9.609,4.509a.209.209,0,0,0,.068.258,2.1,2.1,0,0,1,.667.782.207.207,0,0,0,.25.106.525.525,0,0,1,.172-.028A8.526,8.526,0,0,1,15.508,7.29a.209.209,0,0,0,.223.02c.038-.02.078-.039.118-.056a.21.21,0,0,0,.126-.192A25.109,25.109,0,0,0,14.9.652a.209.209,0,0,0-.134-.137A9.919,9.919,0,0,0,12.924.082a.21.21,0,0,0-.178.064A18.761,18.761,0,0,0,9.609,4.509Z"
          transform="translate(-1.596 -0.008)"/>
    </g>
  </svg>
);
