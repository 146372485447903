import React from 'react';
import { FormattedMessage } from 'react-intl';
import indoorClimateImage from 'assets/media/brikks/smartness/indoor-climate/climate.png';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { ClimateControlSections } from './components';

export const ClimateModalContext = React.createContext();

export const ClimateModal = () => {
  const [selectedSensor, setSelectedSensor] = React.useState();

  return (
    <ClimateModalContext.Provider value={{ selectedSensor, setSelectedSensor }}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="indoorClimate" />

        <Grid.Column>
          <div>
            <Brikk.Heading
              main
              heading="indoorClimate"
            >
              <ModalIntro>
                <ModalIntro.Image
                  src={indoorClimateImage}
                  alt="indoor climate"
                />

                <ModalIntro.Text>
                  <FormattedMessage id="indoorClimateIntro" />
                </ModalIntro.Text>
              </ModalIntro>
            </Brikk.Heading>
          </div>
        </Grid.Column>

        <ClimateControlSections />
      </Grid>
    </ClimateModalContext.Provider>
  );
};
