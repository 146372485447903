import React from 'react';
import { Form } from 'semantic-ui-react';
import { ShouldRender } from 'components/ShouldRender';
import { useTheme } from '../../../../../global/themes';
import { Radio } from 'components/form-elements/radios/Radio';

export const Phone = ({ disabled, name, telNumber, activeTelephone, phoneType, onChange }) => {
  const { theme } = useTheme();

  return (
    <ShouldRender condition={!!telNumber}>
      <Form.Field disabled={disabled}>
        <Radio
          className={theme.textColor}
          toggle
          name={name}
          value={telNumber}
          label={telNumber}
          checked={activeTelephone === phoneType}
          onChange={() => onChange(phoneType, telNumber)}
        />
      </Form.Field>
    </ShouldRender>
  );
};
