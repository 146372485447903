import React from 'react';

export const WeatherIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.999" viewBox="0 0 20 19.999">
      <path d="M8.083,2.5a.833.833,0,0,0,.833-.833V.833a.833.833,0,1,0-1.667,0v.833A.833.833,0,0,0,8.083,2.5Z"
            transform="translate(-1.209 0)"/>
      <path d="M2.957,4.135A.833.833,0,0,0,4.135,2.956l-.59-.589A.833.833,0,1,0,2.367,3.546Z"
            transform="translate(-0.354 -0.354)"/>
      <path d="M.833,8.917h.833a.833.833,0,1,0,0-1.667H.833a.833.833,0,1,0,0,1.667Z"
            transform="translate(0 -1.209)"/>
      <path d="M13.682,2.368a.833.833,0,0,0-1.179,0l-.59.589a.833.833,0,0,0-.243.558.207.207,0,0,0,.281.2,7.121,7.121,0,0,1,1.771-.41.21.21,0,0,0,.182-.16.833.833,0,0,0-.222-.78Z"
            transform="translate(-1.945 -0.354)"/>
      <path d="M4.9,8.3A.207.207,0,0,0,5,8.065,2.5,2.5,0,0,1,8.337,5.091a.207.207,0,0,0,.206-.032,7.036,7.036,0,0,1,1.146-.767.208.208,0,0,0,.021-.356A4.167,4.167,0,0,0,3.537,8.923a.208.208,0,0,0,.333.083A5.891,5.891,0,0,1,4.9,8.3Z"
            transform="translate(-0.543 -0.542)"/>
      <path d="M18.936,10.894a.207.207,0,0,1-.083-.16,5.833,5.833,0,0,0-11.38-1.7.207.207,0,0,1-.183.144,4.6,4.6,0,0,0-4.133,3.481.21.21,0,0,0,.035.175.207.207,0,0,0,.167.083h1.3a.207.207,0,0,0,.2-.139,2.917,2.917,0,0,1,3.123-1.917.828.828,0,0,0,.93-.694,4.167,4.167,0,0,1,8.28.667c0,.122-.006.244-.016.365a.833.833,0,0,0,.417.794,2.5,2.5,0,0,1-1.234,4.674H11.775a.208.208,0,0,0-.1.024,2.653,2.653,0,0,1-.24.117.207.207,0,0,0-.1.294,3.317,3.317,0,0,1,.385,1.06.207.207,0,0,0,.2.172h4.428a4.167,4.167,0,0,0,2.578-7.44Z"
            transform="translate(-0.525 -0.834)"/>
      <path d="M.833,17.167h8.75a2.083,2.083,0,0,0,0-4.167.833.833,0,1,0,0,1.667.417.417,0,0,1,0,.833H.833a.833.833,0,1,0,0,1.667Z"
            transform="translate(0 -2.167)"/>
      <path d="M7.916,19H.833a.833.833,0,1,0,0,1.667H7.916a.417.417,0,0,1,0,.833.833.833,0,1,0,0,1.667,2.083,2.083,0,0,0,0-4.167Z"
            transform="translate(0 -3.167)"/>
    </svg>
);
