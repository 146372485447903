export const isEmptyField = (value) => {
    if (!value.trim().length) {
        return true;
    }
    return false;
}

export const isEmptyArray = (array) => {
    if (!array.length) {
        return true;
    }
    return false;
}