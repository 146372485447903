/*
 User's settings to display application state
 */
import { FLAT_THEME } from 'global/themes';
import { tenantConfig } from 'global/tenant-config';
import { defaultBrikksOrder, WHITE_BG } from './utils';
import { UserSettingsActions } from './actions';

const initialState = {
  theme: FLAT_THEME,
  background: tenantConfig?.initialBackground || WHITE_BG,
  onboardingPassed: false,
  brikksOrder: defaultBrikksOrder,
  loading: false,
  calledOnce: false,
};

export function userSettingsReducer(state = initialState, action) {
  switch (action.type) {
    case UserSettingsActions.GET_USER_SETTINGS:
      return {
        ...state,
        loading: true,
        calledOnce: true,
      };
    case UserSettingsActions.GET_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UserSettingsActions.UPDATE_USER_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
