import React from 'react';
import { useMaintenance } from 'services/maintenance/planned-maintenance/hook';
import { FormattedMessage } from 'react-intl';
import BrikkCalendar from 'components/brikks/sub-components/BrikkCalendar';

export const UpgradePreview = () => {
  const { maintenance } = useMaintenance();

  const upcomingMaintenance = maintenance.upcomingMaintenance;
  const [date, month] = upcomingMaintenance.plannedDate.split('-');
  const text1 = <FormattedMessage id="nextMaintenance"/>;
  const text2 = upcomingMaintenance.type;
  const text3 = upcomingMaintenance.unit;

  return (
    <BrikkCalendar {...{ month, date, text1, text2, text3 }} />
  );
};
