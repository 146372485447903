import React from 'react';
import { useModal } from 'store/modals';
import { Modals } from './modals';

export const ModalContainer = () => {
  const { modalType, modalProps } = useModal();

  if (!modalType) {
    return null;
  }

  const OpenedModal = Modals[modalType];

  return (
    <OpenedModal {...modalProps} />
  );
};
