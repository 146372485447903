import React from 'react';
import { AppContext } from 'AppContext';
import { PageLoader } from 'components/loaders/PageLoader';
import { GRT_LIF, LKF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { ONBOARDING_WELCOME } from 'global/routes';
import { useRedirect } from 'utils/redirect';

export const OnboardingGuard = ({ children }) => {
  
  const { isAuthenticated, userSettings: { onboardingPassed, loading }, tenant } = React.useContext(AppContext);
  const tenantId = getTenantIDBasedOnURL();

  const shouldOnboard = tenantId !== LKF && (!tenant || tenant === GRT_LIF)
    && !onboardingPassed && !loading && isAuthenticated;

  useRedirect(ONBOARDING_WELCOME, shouldOnboard);

  return (loading && isAuthenticated) ? <PageLoader /> : <>{children}</>;
};
