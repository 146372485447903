import React from 'react';
import styles from './styles.module.scss';
import { useTheme } from 'global/themes';
import classNames from 'classnames';

export const SlideIcon = ({ direction = 'right' }) => {
  const { theme } = useTheme();

  return (
      <svg className={classNames(styles.icon, theme.iconColor)} xmlns="http://www.w3.org/2000/svg" width="10.703" height="14.984" viewBox="0 0 10.703 14.984">
        <path className="a"
              d="M10.7.4V14.583a.4.4,0,0,1-.4.4H8.7a.4.4,0,0,1-.4-.4v-5.9L1.756,14.737A1.072,1.072,0,0,1,0,13.914V1.07A1.072,1.072,0,0,1,1.756.248L8.295,6.265V.4A.4.4,0,0,1,8.7,0H10.3A.4.4,0,0,1,10.7.4Z"
              transform={direction === 'left' ? "translate(10.703 14.984) rotate(180)" : ''} />
      </svg>
  );
}
