import React from 'react';
import { Text } from 'components/typography';
import { useModal } from 'store/modals';
import { Modal } from 'semantic-ui-react';
import { Button } from 'components/form-elements/buttons/Button';
import styles from './styles.module.scss';

export const ConfirmationModal = ({ title, onConfirm }) => {
  const { closeModal } = useModal();

  const handleConfirm = () => {
    closeModal();
    onConfirm();
  };

  return (
    <Modal
      size="small"
      centered
      dimmer="inverted"
      open
    >
      <Modal.Header>
        <Text
          center
          size={20}
        >
          {title}
        </Text>
      </Modal.Header>

      <Modal.Actions>
        <div className={styles.actions}>
          <Button
            intl="no"
            onClick={closeModal}
          />

          <Button
            intl="yes"
            onClick={handleConfirm}
          />
        </div>
      </Modal.Actions>
    </Modal>
  ); 
};
