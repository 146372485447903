import React from 'react';

export const CleaningSprayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.583" height="20" viewBox="0 0 19.583 20">
            <g id="cleaning-spray" transform="translate(-0.25)">
                <path id="Path_7779" data-name="Path 7779" d="M10.4,10.033a1.244,1.244,0,0,0-.96-.45H9a.417.417,0,0,1-.417-.417V7.917A.417.417,0,0,0,8.167,7.5H6.5a.417.417,0,0,0-.417.417v1.25a.417.417,0,0,1-.417.417H5.223a1.244,1.244,0,0,0-.96.45L1.339,13.542a4.7,4.7,0,0,0,3.61,7.708H9.718a4.7,4.7,0,0,0,3.61-7.708Z" transform="translate(0 -1.25)"  />
                <circle id="Ellipse_365" data-name="Ellipse 365" cx="0.833" cy="0.833" r="0.833" transform="translate(15.25 2.5)" />
                <circle id="Ellipse_366" data-name="Ellipse 366" cx="0.833" cy="0.833" r="0.833" transform="translate(18.167 2.5)" />
                <circle id="Ellipse_367" data-name="Ellipse 367" cx="0.833" cy="0.833" r="0.833" transform="translate(18.167)" />
                <circle id="Ellipse_368" data-name="Ellipse 368" cx="0.833" cy="0.833" r="0.833" transform="translate(18.167 5)" />
                <path id="Path_7780" data-name="Path 7780" d="M14.75,3a.833.833,0,0,0-.833-.833H13.5a.417.417,0,0,1-.417-.417V1.333A.833.833,0,0,0,12.25.5H6.833A4.588,4.588,0,0,0,2.25,5.083a.417.417,0,0,0,.417.417H9.443a.417.417,0,0,1,.406.324,3.324,3.324,0,0,0,3.234,2.592.833.833,0,1,0,0-1.667,1.662,1.662,0,0,1-1.5-.952.208.208,0,0,1,.188-.3h.477a.833.833,0,0,0,.833-.833V4.25a.417.417,0,0,1,.417-.417h.417A.833.833,0,0,0,14.75,3Z" transform="translate(-0.333 -0.083)" />
            </g>
        </svg>
    )
}