import React from 'react';
import { PageContainer } from 'components/PageContainer';
import { SideMenu } from 'components/menus/SideMenu';
import { GlobalProvider } from 'app/GlobalProvider';
import { Routes } from 'app/Routes';
import './assets/styles/Main.scss';
import { ModalContainer } from 'components/modals/ModalContainer';

function App() {
  return (
    <GlobalProvider>
      <PageContainer>
        <SideMenu />
        
        <Routes />
        
        <ModalContainer />
      </PageContainer>
    </GlobalProvider>
  );
} 

export default App;
