import React from 'react';
import * as Rhf from 'react-hook-form';
import { useIntl } from 'react-intl';
import { LightIcon } from 'assets/icons/LightIcon';
import { Card } from 'components/cards/Card';
import { Text } from 'components/typography';
import { Radio } from 'components/form-elements/radios/Radio';
import { ShouldRender } from 'components/ShouldRender';
import { RangeSlider } from 'components/form-elements/RangeSlider';
import { Grid } from 'components/layout/Grid';
import { LabeledRadio } from 'components/form-elements/radios/LabeledRadio';
import { SHELLY_DIMMER_2 } from 'utils/types/lightningDevice';
import styles from './styles.module.scss';

export function LightningDevice({ field, fieldName, control }) {

  const intl = useIntl();

  return (
    <Card
      className={styles.card}
    >
      <div className={styles.data}>
        <LightIcon size={30} />

        <Grid
          oneColumn
          noPadding
          className={styles.actions}
        >
          <Text
            bold
            uppercase
            size={14}
          >
            {field?.displayName}
          </Text>

          <ShouldRender condition={typeof field?.status?.isOn === 'boolean'}>
            <Rhf.Controller
              name={`${fieldName}.status.isOn`}
              control={control}
              render={({ field }) => (
                <Radio
                  toggle
                  checked={field.value}
                  onChange={() => field.onChange(!field.value)}
                  label={intl.formatMessage({ id: field.value ? 'on' : 'off' })}
                />
              )}
            />
          </ShouldRender>

          <ShouldRender condition={typeof field?.status?.relay1 === 'boolean'}>
            <Grid
              oneColumn
              noPadding
              style={{ width: 'fit-content', gap: 20 }}
            >
              <Rhf.Controller
                name={`${fieldName}.status.relay1`}
                control={control}
                render={({ field }) => (
                  <LabeledRadio
                    intl="relay1"
                    toggle
                    checked={field.value}
                    onChange={() => field.onChange(!field.value)}
                    label={intl.formatMessage({ id: field.value ? 'on' : 'off' })}
                  />
                )}
              />

              <Rhf.Controller
                name={`${fieldName}.status.relay2`}
                control={control}
                render={({ field }) => (
                  <LabeledRadio
                    intl="relay2"
                    toggle
                    checked={field.value}
                    onChange={() => field.onChange(!field.value)}
                    label={intl.formatMessage({ id: field.value ? 'on' : 'off' })}
                  />
                )}
              />
            </Grid>
          </ShouldRender>
        </Grid>
      </div>

      <ShouldRender condition={typeof field?.status?.brightness === 'number'}>
        <div className={styles.slider}>
          <Rhf.Controller
            name={`${fieldName}.status.brightness`}
            control={control}
            defaultValue={[field?.status?.brightness]}
            render={({ field }) => (
              <RangeSlider
                min={field?.deviceType === SHELLY_DIMMER_2 ? 1 : 0}
                value={[field.value]}
                onChange={(values) => field.onChange(values[0])}
              />
            )}
          />
        </div>
      </ShouldRender>
    </Card>
  );
}
