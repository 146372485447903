import { useDispatch, useSelector } from 'react-redux';
import { bankIDLogin, bankIDLoginReset } from './actions';
import { useEffect } from 'react';

export function useBankIDLogin() {
  const {
    isSuccessful,
    loading,
    error,
    orderRef,
    isInitSuccessful,
  } = useSelector(state => state.bankIDLogin);

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(bankIDLoginReset());
  }, [dispatch])

  return {
    isSuccessful,
    loading,
    error,
    orderRef,
    isInitSuccessful,
    onLoginWithBankID: (personalNumber) => dispatch(bankIDLogin(personalNumber)),
  }
}
