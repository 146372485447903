import React, { useContext } from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Dashboard } from 'components/Dashboard';
import { ONBOARDING_PAGE, ONBOARDING_WELCOME } from 'global/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AppContext } from 'AppContext';
import { useRedirect } from 'utils/redirect';
import { useUserDetails } from 'services/user/details/hook';
import { AppHeader } from 'components/headers/AppHeader';
import styles from './styles.module.scss';

export const Onboarding = ({ children }) => {
  const { userSettings: { onboardingPassed } } = useContext(AppContext);

  useRedirect('/', onboardingPassed);

  useUserDetails();

  return (
    <Dashboard>
      <IntlPageTitle intlTitle="introduction" />

      <AppHeader />

      <div className={styles.onboarding}>
        <Route
          path={ONBOARDING_PAGE}
          render={() => (
            <Switch>
              {children}

              <Redirect to={ONBOARDING_WELCOME} />
            </Switch>
          )}
        />
      </div>
    </Dashboard>
  );
};
