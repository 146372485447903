import React from 'react';

export const AgreementActionIcon = () => {
  return (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
    <g id="Group_6371" data-name="Group 6371" transform="translate(-1677.583 -429)">
      <g id="Group_6369" data-name="Group 6369" transform="translate(259.083 35)">
        <path id="Path_8250" data-name="Path 8250" d="M0,0H31V31H0Z" transform="translate(1449.5 425) rotate(180)" fill="#0272ce"/>
      </g>
      <g id="Group_6360" data-name="Group 6360" transform="translate(0 36)">
        <g id="app-window-link" transform="translate(1703.782 417.934) rotate(180)">
          <path id="Path_8248" data-name="Path 8248" d="M19.565,11.18v5.59a1.4,1.4,0,0,1-1.4,1.4H1.4A1.4,1.4,0,0,1,0,16.77V1.4A1.4,1.4,0,0,1,1.4,0h5.59" transform="translate(0 0)" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
          <path id="Path_8249" data-name="Path 8249" d="M0,0H19.565" transform="translate(0 13.975)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="1.5"/>
        </g>
        <g id="logout-alternate" transform="translate(1682.382 407.457)">
          <path id="Path_6670" data-name="Path 6670" d="M4.908,9.578v1.816a.491.491,0,0,0,.278.442.508.508,0,0,0,.519-.059L8.9,9.224a.491.491,0,0,0,0-.766L5.705,5.906a.491.491,0,0,0-.8.384V8.106H.736a.736.736,0,1,0,0,1.472Z" transform="translate(0 -2.953)" fill="#fff"/>
          <path id="Path_6671" data-name="Path 6671" d="M6.377,0A5.9,5.9,0,0,0,1,3.481a.491.491,0,1,0,.9.4,4.908,4.908,0,1,1,.01,4.036.491.491,0,1,0-.893.406A5.889,5.889,0,1,0,6.377,0Z" transform="translate(0.083)" fill="#fff"/>
        </g>
      </g>
    </g>
  </svg>
  )
}
