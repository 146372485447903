import { takeLatest, call, put } from 'redux-saga/effects';
import {
  getAgreementProperties,
  GetAgreementPropertiesActions, getAgreementPropertiesFail, getAgreementPropertiesSuccess,
} from './actions';
import { getAgreementPropertiesAPI } from './api';
import { AgreementProperties } from './models/AgreementProperties';

function* getAgreementPropertiesEffect() {
  try {
    const agreementProperties = yield call(getAgreementPropertiesAPI);
    yield put(getAgreementPropertiesSuccess(new AgreementProperties(agreementProperties)));
  } catch (e) {
    if (e.timeout) {
      yield put(getAgreementProperties());
    } else {
      yield put(getAgreementPropertiesFail(e.message));
    }
  }
}

export const agreementPropertiesEffects = [
  takeLatest(GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES, getAgreementPropertiesEffect),
];
