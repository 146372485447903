import React, { useEffect } from 'react';
import { Button, Message } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography/Text';
import { useDeleteRSSFeed } from 'services/rss-feed/delete-rss-feed';
import styles from './styles.module.scss';

export const DeleteFeed = ({ feed: { id, title }, onDelete }) => {
  const { isSuccess, isLoading, isError, error, deleteRSSFeed } = useDeleteRSSFeed();

  useEffect(() => {
    if (isSuccess) {
      onDelete();
    }
  }, [onDelete, isSuccess]);

  return (
    <div className={styles.deleteFeed}>
      <Text large>
        <FormattedMessage
          id="deleteFeed"
          values={{
            title,
            span: (...chunks) => (
              <Text
                element="span"
                bold
                large
                gray
              >
                {chunks}
              </Text>
            ),
            strong: (...chunks) => (
              <strong>
                {chunks}
              </strong>
            ),
          }}
        />
      </Text>

      <Button
        loading={isLoading}
        disabled={isLoading}
        onClick={() => deleteRSSFeed(id)}
      >
        <FormattedMessage id="delete" />
      </Button>

      <ShouldRender condition={!!isError}>
        <Message negative>
          <Message.Content>
            <FormattedMessage id={error} />
          </Message.Content>
        </Message>
      </ShouldRender>
    </div>
  );
};
