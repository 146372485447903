import React from 'react';
import styles from './styles.module.scss';

export const ExternalLink = ({ children, href }) => (
  <a
    className={styles.externalLink}
    href={href}
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </a>
);
