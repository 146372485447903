import React, { useState } from 'react';
import contactUsSmImgSrc from 'assets/media/contact-us-sm.png';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { ContactUsIcon } from 'assets/icons/brikks/ContactUsIcon';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { OfficeAndCallCenterStatus } from 'components/brikks/sub-components/OfficeAndCallCenterStatus';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { useRedirect } from 'utils/redirect';
import { useLocation } from 'react-router-dom';
import Draggable from 'components/drag-and-drop/Draggable';
import ContactUsModal from 'components/brikks/home/ContactUs/components/ContactUsModal';

export const ContactUs = ({ position, status, code, name }) => {
  const location = useLocation();
  const initialModalValue = location.pathname.includes('/contact');
  const [isModalOpen, setIsModalOpen] = useState(initialModalValue);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { onRedirect } = useRedirect('/');

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >

          <Brikk.Header
            icon={<ContactUsIcon />}
            heading="contactUsBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content>
            <CircularImage
              src={contactUsSmImgSrc}
              alt="Male with headphones sitting in front of a computer in an office"
            />

            <OfficeAndCallCenterStatus />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="contactUs"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Contact us info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="contactUs"
        onClose={() => {
          setIsModalOpen(false);
          onRedirect();
        }}
      >
        <ContactUsModal />
      </BrikkModal>
    </>
  );
};
