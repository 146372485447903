import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Table } from 'semantic-ui-react';
import { useAgreements } from 'services/agreements/agreements';
import { SignInIcon } from 'assets/icons/brikks/SignInIcon';
import { LinkModal } from 'components/modals/LinkModal';
import { TerminateAgreementModal } from 'components/brikks/home/TerminateAgreement/components/TerminateModal';
import { isProductNumberEqual } from 'utils/agreement';
import { ShouldRender } from 'components/ShouldRender';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF, RIB } from 'global/tenant-config/tenants';
import { Agreement } from 'components/brikks/home/MyDocuments/components/MyDocumentsModal/components/AgreementTable/components/Agreement';

export const AgreementTable = () => {
  const { agreements } = useAgreements();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  return (
    <Table
      singleLine
      striped
      unstackable
    >
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <FormattedMessage id="product" />
          </Table.HeaderCell>

          <Table.HeaderCell>
            <FormattedMessage id="address" />
          </Table.HeaderCell>

          <Table.HeaderCell>
            <FormattedMessage id="cost" />
          </Table.HeaderCell>

          <Table.HeaderCell>
            <FormattedMessage id="download" />
          </Table.HeaderCell>

          <ShouldRender condition={![LKF, RIB].includes(tenantId)}>
            <Table.HeaderCell>
              <FormattedMessage id="terminate" />
            </Table.HeaderCell>
          </ShouldRender>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {agreements.map((item, index) => (
          <Table.Row key={index}>
            <Table.Cell>
              {item.productType || '-'} {isProductNumberEqual(agreements, item) && `- ${item.productNumber}`}
            </Table.Cell>

            <Table.Cell>
              {item.address || '-'}
            </Table.Cell>

            <Table.Cell>{item.groundRent
              ? (<>{item.groundRent} <FormattedMessage id="sekPerMonth" /></>) : '-'}
            </Table.Cell>

            <Table.Cell>
              {item.hasAgreementPdf ? <Agreement agreementNumber={item.agreementNumber} /> : '-'}
            </Table.Cell>

            <ShouldRender condition={![LKF, RIB].includes(tenantId)}>
              <Table.Cell>
                <LinkModal
                  selectedAgreement={item.agreementNumber}
                  buttonTriggerModal={SignInIcon}
                  brikkModal={TerminateAgreementModal}
                  isDisabled={
                    !(item.status === 'active' || (item.status === 'pending' && item.secondPersonSignedTermination))
                  }
                  modalHeading="terminateAgreements"
                />
              </Table.Cell>
            </ShouldRender>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};
