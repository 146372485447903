import React from 'react';
import { FormattedMessage } from 'react-intl';
import SecurityImage from 'assets/media/brikks/smartness/security/security.png';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { SecurityControls } from './SecurityControls';

export const SecurityControlModal = () => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="security" />

    <Grid.Column>
      <Brikk.Heading
        main
        heading="security"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={SecurityImage}
            alt="Security"
          />

          <ModalIntro.Text>
            <FormattedMessage id="securityIntro" />
          </ModalIntro.Text>
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading
        main
        heading="statusAndControl"
      />

      <SecurityControls />
    </Grid.Column>
  </Grid>
);
