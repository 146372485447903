import React from 'react';
import styles from "components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/UpgradeTable/styles.module.scss";
import { Table } from "semantic-ui-react";
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { ItemSelectButton } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/UpgradeTable/components/ItemSelectButton';
import { formatCurrency } from "utils/format";

export const UpgradeTable = ({ maintenanceItems, type }) => (
    <div className={styles.maintenanceTable}>
        <Table singleLine striped unstackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>
                        <FormattedMessage id="unit" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id="lastChanged" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id="residualValue" />
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                        <FormattedMessage id="timeForChange" />
                    </Table.HeaderCell>
                    <Table.HeaderCell/>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {maintenanceItems.map((item, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>
                            <div className={styles.unitName}>
                                <ShouldRender condition={item.residualValue === 0}>
                                    <div className={styles.greenDot} />
                                </ShouldRender>
                                {item.surface}
                            </div>
                        </Table.Cell>
                        <Table.Cell>{item.performedDate?.substring(0, item.performedDate.indexOf('T'))}</Table.Cell>
                        <Table.Cell>{item.residualValue === 0 ? '-' : `${formatCurrency(item.residualValue)} kr`}</Table.Cell>
                        <Table.Cell>{item.plannedDate}</Table.Cell>
                        <Table.Cell>
                            <ItemSelectButton maintenanceItem={item}/>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
        <ShouldRender condition={type === 'Hall' || type === 'Kök'}>
            {
                maintenanceItems.filter(item => item.measure === 'Målas*').length ? (
                  <p style={{ fontSize: 11, paddingBottom: 10 }}>
                      <FormattedMessage id="maintenanceInfo" />
                  </p>
                ) : ''
            }
        </ShouldRender>
    </div>
);
