import React from 'react';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const Heading = ({ main, id, heading, children, className }) => (
  <div
    id={id}
    className={className}
  >
    <Text
      className={styles.heading}
      size={main ? 38 : 22}
      uppercase={!main}
      element={main ? 'h1' : 'h3'}
      intl={heading}
    />

    {children}
  </div>
);
