import React from 'react';
import SecurityOverviewSrc from 'components/brikks/store/Security/assets/safety-overview.png';
import { ModalCard } from 'components/cards/ModalCard';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { MultiStepContext } from 'components/MultiStep';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { Image } from 'components/media/Image';
import { StepProgress } from 'components/brikks/sub-components/StepProgress';

export const SecurityOverview = () => {
  const { onNextStep } = React.useContext(MultiStepContext);

  return (
    <ModalCard>
      <Image
        src={SecurityOverviewSrc}
      />

      <OnboardingGrid>
        <NextStep
          onClick={onNextStep}
        />

        <StepProgress />
      </OnboardingGrid>
    </ModalCard>
  );
};
