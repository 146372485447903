import React from 'react';
import styles from './styles.module.scss'; 

export const LightIcon = ({ size=40 }) => (
    <svg 
      className={styles.icon} 
      xmlns="http://www.w3.org/2000/svg" 
      height="41.976" 
      width={size}
      viewBox="0 0 31.438 31.976"
    >
        <g 
          id="Group_5937" 
          data-name="Group 5937"
          transform="translate(108.799 13)"
        >

          <path 
            id="Path_6750" 
            data-name="Path 6750" 
            d="M14.124,4.827a8.669,8.669,0,0,0-8.514,8.518,8.3,8.3,0,0,0,5.184,7.832.344.344,0,0,0,.121.023h1.9a.333.333,0,0,0,.333-.333V16.505a.336.336,0,0,0-.1-.236l-2.237-2.234a.983.983,0,0,1,1.39-1.384l1.689,1.689a.332.332,0,0,0,.472,0l1.689-1.689a.983.983,0,0,1,1.39,1.39L15.2,16.275a.336.336,0,0,0-.1.236v4.362a.333.333,0,0,0,.333.333h1.9a.33.33,0,0,0,.12-.023,8.3,8.3,0,0,0,5.181-7.832,8.669,8.669,0,0,0-8.514-8.524Z" 
            transform="translate(-107.204 -11.396)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6751" 
            data-name="Path 6751" 
            d="M11.017,1.31v2.5a1.31,1.31,0,1,0,2.619,0V1.31a1.31,1.31,0,1,0-2.619,0Z" 
            transform="translate(-105.406 -13)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6752" 
            data-name="Path 6752" 
            d="M4.512,9.743h-3a1.31,1.31,0,0,0,0,2.619h3a1.31,1.31,0,0,0,0-2.619Z" 
            transform="translate(-109 -9.763)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6753" 
            data-name="Path 6753" 
            d="M7.48,5.342l-2.5-2.5A1.309,1.309,0,1,0,3.133,4.694l2.5,2.5A1.31,1.31,0,0,0,7.48,5.342Z" 
            transform="translate(-108.153 -12.184)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6754" 
            data-name="Path 6754" 
            d="M23.891,9.743h-3a1.31,1.31,0,1,0,0,2.619h3a1.31,1.31,0,1,0,0-2.619Z" 
            transform="translate(-102.561 -9.763)" 
            fill="#3a3a3a"
            />

          <path 
            id="Path_6755" 
            data-name="Path 6755" 
            d="M22.143,2.842a1.31,1.31,0,0,0-1.852,0l-2.5,2.5a1.309,1.309,0,1,0,1.852,1.852l2.5-2.5a1.31,1.31,0,0,0-.005-1.852Z" 
            transform="translate(-103.282 -12.184)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6756" 
            data-name="Path 6756" 
            d="M16.418,18.337H9.869a1.31,1.31,0,0,0,0,2.619h6.549a1.31,1.31,0,1,0,0-2.619Z" 
            transform="translate(-106.222 -6.908)" 
            fill="#3a3a3a"
          />

          <path 
            id="Path_6757" 
            data-name="Path 6757" 
            d="M16.418,21.05H9.869a1.31,1.31,0,1,0,0,2.619h1.665a.332.332,0,0,1,.326.266,1.31,1.31,0,0,0,2.566,0,.333.333,0,0,1,.326-.266h1.665a1.31,1.31,0,0,0,0-2.619Z" 
            transform="translate(-106.222 -6.007)" 
            fill="#3a3a3a"
          />

        </g>
  </svg>
  
);
