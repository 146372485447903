import React from 'react';
import classNames from 'classnames';
import styles from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/FeedList/styles.module.scss';
import { Text } from 'components/typography/Text';
import { AddFeedButton } from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/AddFeedButton';
import { FeedArticle } from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/FeedArticle';
import { FocusableElement } from 'components/FocusableElement';

export const FeedList = ({ selectedFeed, feed, selectAllArticles, setSelectedFeed }) => (
  <div className={styles.feedList}>
    <div className={styles.section}>
      <Text
        intl="allArticles"
        bold
        uppercase
        large
        dark
      />

      <div className={styles.sectionList}>
        <FocusableElement
          element="span"
          onClick={selectAllArticles}
        >
          <Text
            intl="latest"
            dark
            large
            clickable
            primary={!selectedFeed.title}
          />
        </FocusableElement>
      </div>
    </div>

    <div className={styles.section}>
      <Text
        intl="subscriptions"
        bold
        uppercase
        large
        dark
      />

      <div className={classNames(styles.sectionList, styles.scrollable)}>
        {feed.map(feedArticle => (
          <FeedArticle
            key={`${feedArticle.id}-${feedArticle.title}`}
            selectedFeed={selectedFeed}
            feedArticle={feedArticle}
            setSelectedFeed={setSelectedFeed}
            selectAllArticles={selectAllArticles}
          />
        ))}
      </div>
    </div>

    <div className={styles.section}>
      <AddFeedButton />
    </div>
  </div>
);
