import React, { useState } from 'react';
import { useAgreements } from 'services/agreements/agreements';
import { Brikk } from 'components/Brikk';
import Draggable from "components/drag-and-drop/Draggable";
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { TerminatePreview } from 'components/brikks/home/TerminateAgreement/components/TerminatePreview';
import { TerminateAgreementModal } from 'components/brikks/home/TerminateAgreement/components/TerminateModal';
import { BrikkModal } from 'components/modals/BrikkModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { PenWriteIcon } from 'assets/icons/brikks/PenWriteIcon';
import { useRedirect } from 'utils/redirect';
import { useLocation } from 'react-router-dom';

export const TerminateAgreement = ({ position, status, code, name }) => {
  const location = useLocation();
  const initialModalValue = location.pathname.includes('/agreement');
  const [isModalOpen, setIsModalOpen] = useState(initialModalValue);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { agreements, loading, isFetching } = useAgreements();
  const { onRedirect } = useRedirect('/');

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  }

  return (
    <>
      <Draggable position={position}>
        <Brikk isLoading={loading} brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<PenWriteIcon />}
            heading="terminateAgreementBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content center>
            <TerminatePreview
              agreements={agreements}
              loading={loading}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton onClick={handleOpenModal} brikkNameIntl="terminate" />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Terminate Agreement info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />

      <BrikkModal
        twoGrid
        isLoading={loading || isFetching}
        isOpen={isModalOpen}
        heading="terminateAgreements"
        onClose={() => {
          setIsModalOpen(false);
          onRedirect();
        }}
      >
        <TerminateAgreementModal />
      </BrikkModal>
    </>
  )
};
