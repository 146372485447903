import React, { useContext } from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { MultiTabContext } from 'components/menus/MultiTabMenu';
import { useLocale } from 'services/user/locale/hook';
import { ENGLISH } from 'services/user/locale/utils';
import styles from './styles.module.scss';

export const Tabs = ({ tabs }) => {
  const { activeTab, setActiveTab } = useContext(MultiTabContext);
  const { locale } = useLocale();

  return (
    <Button.Group className={styles.tabs}>
      {Object.keys(tabs).map((tab, index) => (
        <React.Fragment key={tab}>
          {index > 0 && locale === ENGLISH && <Button.Or className={styles.or} />}
          <Button
            className={activeTab === tab ? styles.button : ''}
            positive={activeTab === tab}
            onClick={() => setActiveTab(tab)}
          >
            <FormattedMessage id={tab} />
          </Button>
        </React.Fragment>
      ))}
    </Button.Group>
  );
};
