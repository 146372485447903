import React from 'react';

export const UpgradeIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <desc>Illustration of a magic wand</desc>
    <g transform="translate(0)">
      <path d="M9.68,10.555.3,19.93A1.042,1.042,0,0,0,1.778,21.4l9.375-9.375A1.042,1.042,0,1,0,9.68,10.555Z"
            transform="translate(0 -1.708)"/>
      <path d="M22.333,7h-2.5a.833.833,0,0,0,0,1.667h2.5a.833.833,0,1,0,0-1.667Z"
            transform="translate(-3.167 -1.167)"/>
      <path d="M19.422,5.089l1.667-1.667a.833.833,0,1,0-1.178-1.178L18.244,3.911a.833.833,0,1,0,1.178,1.178Z"
            transform="translate(-3 -0.333)"/>
      <path d="M15.833,0A.833.833,0,0,0,15,.833v2.5a.833.833,0,1,0,1.667,0V.833A.833.833,0,0,0,15.833,0Z"
            transform="translate(-2.5)"/>
      <path d="M11.422,2.244a.833.833,0,1,0-1.178,1.178l1.667,1.667a.833.833,0,1,0,1.178-1.178Z"
            transform="translate(-1.667 -0.333)"/>
      <path d="M8.833,8.667h2.5a.833.833,0,0,0,0-1.667h-2.5a.833.833,0,0,0,0,1.667Z"
            transform="translate(-1.333 -1.167)"/>
      <path d="M15.833,11a.833.833,0,0,0-.833.833v2.5a.833.833,0,1,0,1.667,0v-2.5A.833.833,0,0,0,15.833,11Z"
            transform="translate(-2.5 -1.833)"/>
      <path d="M19.422,10.244a.833.833,0,1,0-1.178,1.178l1.667,1.667a.833.833,0,1,0,1.178-1.178Z"
            transform="translate(-3 -1.667)"/>
    </g>
  </svg>
);
