import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainPage from 'pages/MainPage';
import LogIn from 'pages/auth/LogIn';
import { ForgotPassword } from 'pages/ForgotPassword';
import { CreatePassword } from 'pages/CreatePassword/';
import { ForgotPasswordVerify } from 'pages/ForgotPasswordVerify';
import { WelcomeScreen } from 'pages/WelcomeScreen';
import { ActivateAccount } from 'pages/ActivateAccount';
import { PrivateRoute } from 'components/PrivateRoute';
import { tenantConfig } from 'global/tenant-config';
import ChangePasswordConfirm from 'pages/auth/ChangePasswordConfirm';
import { PageNotFound } from 'pages/PageNotFound'; 
import { ChangePassword } from 'pages/ChangePassword';
import {
  HOME_PAGE,
  WELCOME_SCREEN,
  LOGIN_PAGE,
  ACTIVATE_ACCOUNT_PAGE,
  CREATE_PASS_PAGE,
  FORGOT_PASS_PAGE,
  FORGOT_PASS_VERIFY_PAGE,
  CHANGE_PASS_CONFIRMATION_PAGE,
  CHANGE_PASS_PAGE,
  ONBOARDING_PAGE,
  ORDER_PAGE,
  AGREEMENT,
  CONTACT,
} from 'global/routes';

export const Routes = () => {
  const Onboarding = tenantConfig?.components?.onboarding;
  const Order = tenantConfig?.components?.order;

  return (
    <Switch>
      <Route
        exact
        path={LOGIN_PAGE}
        component={(props) => <LogIn {...props} />}
      />

      <Route
        exact
        path={ACTIVATE_ACCOUNT_PAGE}
        component={(props) => <ActivateAccount {...props} />}
      />

      <Route
        exact
        path={CREATE_PASS_PAGE}
        component={(props) => <CreatePassword {...props} />}
      />
      <Route
        exact
        path={FORGOT_PASS_PAGE}
        component={(props) => <ForgotPassword {...props} />}
      />

      <Route
        exact
        path={FORGOT_PASS_VERIFY_PAGE}
        component={(props) => <ForgotPasswordVerify {...props} />}
      />

      <Route
        exact
        path={CHANGE_PASS_CONFIRMATION_PAGE}
        component={(props) => <ChangePasswordConfirm {...props} />}
      />

      <Route
        exact
        path={WELCOME_SCREEN}
        component={WelcomeScreen}
      />

      <PrivateRoute
        exact
        path={CHANGE_PASS_PAGE}
        component={(props) => <ChangePassword {...props} />}
      />

      <PrivateRoute
        exact
        path={[AGREEMENT, CONTACT, HOME_PAGE]}
        component={MainPage}
      />

      <PrivateRoute
        path={ONBOARDING_PAGE}
        component={Onboarding}
      />

      {Order && (
      <PrivateRoute
        exact
        path={ORDER_PAGE}
        component={Order}
      />
      )}

      <Route component={(props) => <PageNotFound {...props} />} />
      
    </Switch>
  );
};
