
import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

export async function getLivingInfoAPI() {

  const url = `${AWS_API_URL}/get-living-information`;
  const data = await getRequest(url, await authorize());
  
  return mapDataToClient(data);
}
