import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Input, Message } from 'semantic-ui-react';
import { ShouldRender } from 'components/ShouldRender';
import { useAddRSSFeed } from 'services/rss-feed/add-rss-feed';
import styles from './styles.module.scss';

export const AddFeed = ({ onAdd }) => {
  const [feedUrl, setFeedUrl] = useState('');

  const { isSuccess, isLoading, isError, error, addRSSFeed } = useAddRSSFeed();

  const intl = useIntl();

  useEffect(() => {
    if (isSuccess) {
      onAdd();
    }
  }, [isSuccess, onAdd]);

  const onAddRSSFeed = useCallback(() => {
    if (feedUrl) {
      addRSSFeed(feedUrl);
    }
  }, [feedUrl, addRSSFeed]);

  return (
    <div className={styles.addFeed}>
      <Input
        label={intl.formatMessage({ id: 'url' })}
        name="rss-feed-url"
        value={feedUrl}
        onChange={(e) => setFeedUrl(e.target.value)}
      />

      <ShouldRender condition={!!isError}>
        <Message negative>
          <Message.Content>
            <FormattedMessage id={error} />
          </Message.Content>
        </Message>
      </ShouldRender>

      <Button
        name="add-rss-feed"
        loading={isLoading}
        disabled={isLoading}
        onClick={() => onAddRSSFeed()}
      >
        <FormattedMessage id="add" />
      </Button>
    </div>
  );
};
