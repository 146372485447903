import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { BRE, VXB } from 'global/tenant-config/tenants';
import { getSubscriptionsAPI } from './api';

const SUBSCRIPTIONS = 'subscriptions';

export function useSubscriptions() {

  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [SUBSCRIPTIONS, tenantId],
    () => getSubscriptionsAPI(),
    {
      enabled: ![BRE, VXB].includes(tenantId),
    },
  );

  return {
    subscriptions: data?.results || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
