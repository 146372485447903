import React from 'react';
import classNames from 'classnames';
import { FocusableElement } from 'components/FocusableElement';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography/Text';
import { ChecklistButton } from 'components/form-elements/buttons/ChecklistButton';
import styles from './styles.module.scss';

export const DropdownButton = ({ children, active, onClick, intl, isCheckList }) => (
  <FocusableElement
    className={classNames(
      styles.dropdownButton, {
        [styles.active]: active,
      },
    )}
    onClick={onClick}
  >
    <ShouldRender condition={!!intl}>
      <div className={styles.dropdownAction}>
        <ShouldRender condition={!!isCheckList}>
          <ChecklistButton
            className={styles.checkList}
            intl={intl}
          />
        </ShouldRender>

        <Text
          white={active}
          size={16}
          intl={intl}
          fontWeight={500}
        />
      </div>
    </ShouldRender>

    {children}
  </FocusableElement>
);
