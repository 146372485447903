import React from 'react';
import styles from './styles.module.scss';

export const HouseTemperatureIcon = () => (
  <div className={styles.icon}>
   <svg id="smart-house-temperature" xmlns="http://www.w3.org/2000/svg" width="33.997" height="28.336" viewBox="0 0 33.997 28.336">
  <path id="Path_6733" data-name="Path 6733" d="M15.134,4.5A1.134,1.134,0,0,0,14,5.634v7.371a.567.567,0,0,0,.567.567H15.7a.567.567,0,0,0,.567-.567V5.634A1.134,1.134,0,0,0,15.134,4.5Z" transform="translate(0.933 3.381)" fill="#3a3a3a"/>
  <path id="Path_6734" data-name="Path 6734" d="M39.572,13,23.787.283a1.232,1.232,0,0,0-1.572,0L6.43,13a1.186,1.186,0,0,0-.357,1.322,1.215,1.215,0,0,0,1.133.786h3.516a.945.945,0,0,1,.945.945V27.135a1.207,1.207,0,0,0,1.215,1.2h20.24a1.205,1.205,0,0,0,1.215-1.2V16.057a.945.945,0,0,1,.945-.945h3.508a1.215,1.215,0,0,0,1.133-.786A1.186,1.186,0,0,0,39.572,13ZM22.057,25.5a3.778,3.778,0,0,1-2.938-6.153.463.463,0,0,0,.1-.295V9.445a2.834,2.834,0,1,1,5.667,0v9.61a.474.474,0,0,0,.1.295A3.778,3.778,0,0,1,22.057,25.5ZM29.613,17H27.724a.945.945,0,0,1,0-1.889h1.889a.945.945,0,1,1,0,1.889Zm0-3.778H27.724a.945.945,0,1,1,0-1.889h1.889a.945.945,0,1,1,0,1.889Z" transform="translate(-6.001 0)" fill="#3a3a3a"/>
</svg>
</div>
);
