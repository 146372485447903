import React, { useState } from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import Loader from 'components/loaders/Loader';
import { useRSSFeed } from 'services/rss-feed/rss-feed';
import { FeedArticles, SelectedArticle } from './components';
import styles from './styles.module.scss';

export const RSSFeedModal = ({ preSelectedArticle }) => {
  const [selectedArticle, setSelectedArticle] = useState(preSelectedArticle);
  const { rssFeed, isLoading } = useRSSFeed();

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles.modal}>
      <IntlPageTitle intlTitle="myNews" />

      <div className={styles.feedArticles}>
        <FeedArticles
          feed={rssFeed}
          onSelectArticle={setSelectedArticle}
        />
      </div>

      <div className={styles.selectedArticle}>
        <SelectedArticle
          selectedArticle={selectedArticle}
        />
      </div>
    </div>
  );
};
