import { takeLatest, call, put } from 'redux-saga/effects';
import { ConfirmEmailActions, confirmEmailFail, confirmEmailSuccess } from './actions';
import { confirmEmailAPI } from './api';
import { initPassReset } from 'services/auth/init-pass-reset/actions';

function* confirmEmailEffect({ payload }) {
  try {
    yield call(confirmEmailAPI, payload);
  } catch (e) {
    switch (e.code) {
      case 'UserNotFoundException':
        return yield put(confirmEmailFail('emailNotFound'));
      case 'NotAuthorizedException':
        return yield put(confirmEmailSuccess());
      case 'PasswordResetRequiredException':
        return yield put(initPassReset(payload));
      default:
        return yield put(confirmEmailFail('genericErrorMessage'));
    }
  }
}

export const confirmEmailEffects = [
  takeLatest(ConfirmEmailActions.CONFIRM_EMAIL, confirmEmailEffect),
];
