import React, { createContext, useState } from 'react';
import { MultiStep } from 'components/MultiStep';
import { MaintenanceUpgrade } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade';
import { UpgradePlan } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan';
import { Checkout } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/Checkout';

export const UpgradeContext = createContext(null);

export const UpgradeModal = () => {
  const [selectedSurface, setSelectedSurface] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedPurchases, setSelectedPurchases] = useState({});

  const onAddProduct = () => {
    const surfaceObject = selectedSurface;
    const selectedSurfaceIdentifier = `${surfaceObject.type} - ${surfaceObject.unit} - ${surfaceObject.measure}`;

    setSelectedPurchases(selectedPurchases => (
      { ...selectedPurchases, [selectedSurfaceIdentifier]: { selectedSurface: surfaceObject, selectedProduct } })
    );
    setSelectedProduct(null);
  }

  return (
    <UpgradeContext.Provider
      value={{
        selectedSurface,
        setSelectedSurface,
        selectedProduct,
        setSelectedProduct,
        selectedPurchases,
        setSelectedPurchases,
        onAddProduct
      }}
    >
      <MultiStep>
        <MultiStep.Step>
          <MaintenanceUpgrade />
        </MultiStep.Step>

        <MultiStep.Step>
          <UpgradePlan />
        </MultiStep.Step>

        <MultiStep.Step>
          <Checkout />
        </MultiStep.Step>
      </MultiStep>
    </UpgradeContext.Provider>
  );
}
