import React from 'react';

export const FaceIcon = () => {
    return (
        <svg id="face-id-1-alternate" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_7561" data-name="Path 7561" d="M10,0A10,10,0,1,0,20,10,10,10,0,0,0,10,0Zm0,18.333A8.333,8.333,0,1,1,18.333,10,8.333,8.333,0,0,1,10,18.333Z" />
            <path id="Path_7562" data-name="Path 7562" d="M15.362,14.135a.833.833,0,0,0-1.153.243,3.75,3.75,0,0,1-6.287,0,.834.834,0,1,0-1.4.912,5.417,5.417,0,0,0,9.083,0,.833.833,0,0,0-.247-1.154Z" transform="translate(-1.065 -2.333)" />
            <path id="Path_7563" data-name="Path 7563" d="M8.333,10.667a.833.833,0,0,0,.833-.833v-2.5a.833.833,0,1,0-1.667,0v2.5A.833.833,0,0,0,8.333,10.667Z" transform="translate(-1.25 -1.083)" />
            <path id="Path_7564" data-name="Path 7564" d="M15.333,10.667a.833.833,0,0,0,.833-.833v-2.5a.833.833,0,1,0-1.667,0v2.5A.833.833,0,0,0,15.333,10.667Z" transform="translate(-2.417 -1.083)" />
        </svg>
    )
}