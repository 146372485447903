import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUserDetails } from 'services/user/details/hook';
import styles from './styles.module.scss';
import { OtherPhone, Phone } from './components';

export const TelephonePicker = ({ disabled, onChange }) => {
  const { userDetails } = useUserDetails();

  const [activeTelephone, setActiveTelephone] = useState('');

  const onToggleTelButton = useCallback((telType, telNumber) => {
    setActiveTelephone(telType);
    onChange(telNumber, telType);
  }, [onChange]);

  useEffect(() => {
    if (disabled) {
      setActiveTelephone('');
    }
  }, [disabled]);

  const phoneOptions = useMemo(() => [...new Set(
    [
      'Annat',
      userDetails.homeNumber,
      userDetails.mobileNumber,
      userDetails.workNumber,
      userDetails.email,
    ],
  )], [userDetails]);

  return (
    <div className={styles.telephonePicker}>
      <Phone
        disabled={disabled}
        telNumber={phoneOptions[1]}
        activeTelephone={activeTelephone}
        phoneType="phone"
        name="home-phone-number"
        onChange={onToggleTelButton}
      />

      <Phone
        disabled={disabled}
        telNumber={phoneOptions[2]}
        activeTelephone={activeTelephone}
        phoneType="mobilephone"
        name="mobile-number"
        onChange={onToggleTelButton}
      />

      <Phone
        disabled={disabled}
        telNumber={phoneOptions[3]}
        activeTelephone={activeTelephone}
        phoneType="workphone"
        name="work-phone-number"
        onChange={onToggleTelButton}
      />

      <Phone
        disabled={disabled}
        telNumber={phoneOptions[4]}
        activeTelephone={activeTelephone}
        phoneType="email"
        name="email"
        onChange={onToggleTelButton}
      />

      <OtherPhone
        disabled={disabled}
        activeTelephone={activeTelephone}
        onActivate={onToggleTelButton}
        onChange={onChange}
      />
    </div>
  );
};
