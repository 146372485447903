import React from 'react';

export const ButterflyIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20.001" viewBox="0 0 20 20.001">
            <g id="flying-insect-butterfly" transform="translate(0 0.001)">
                <path id="Path_7751" data-name="Path 7751" d="M9.008,17.779a2.922,2.922,0,0,1-1.925-2.742V6.425a.417.417,0,0,0-.214-.364l-.536-.3A4.261,4.261,0,0,0,0,9.489c0,2.673,1.86,3.574,4.245,4.77a.208.208,0,0,1,.008.368A3.289,3.289,0,0,0,2.558,17.5a3.22,3.22,0,0,0,3.275,3.376,3.478,3.478,0,0,0,2.583-1.14,4.125,4.125,0,0,0,.842-1.417.417.417,0,0,0-.251-.535Zm-5.05-7.117a1.457,1.457,0,1,1,1.031-.427,1.458,1.458,0,0,1-1.031.427Z" transform="translate(0 -0.871)" />
                <path id="Path_7752" data-name="Path 7752" d="M17.9,14.259c2.385-1.2,4.245-2.1,4.245-4.77A4.261,4.261,0,0,0,15.81,5.763l-.537.3a.417.417,0,0,0-.214.364v8.612a2.922,2.922,0,0,1-1.925,2.743.417.417,0,0,0-.25.532,4.138,4.138,0,0,0,.842,1.417,3.478,3.478,0,0,0,2.583,1.142A3.22,3.22,0,0,0,19.588,17.5a3.289,3.289,0,0,0-1.7-2.867.208.208,0,0,1,.008-.368ZM16.727,9.2a1.457,1.457,0,1,1,.427,1.031A1.458,1.458,0,0,1,16.727,9.2Z" transform="translate(-2.143 -0.871)" />
                <path id="Path_7753" data-name="Path 7753" d="M6.261,1.438,9.25,4.26a.417.417,0,0,1,.122.391A1.644,1.644,0,0,0,9.333,5V6.458a.417.417,0,0,0,.417.417h2.5a.417.417,0,0,0,.417-.417V5a1.652,1.652,0,0,0-.037-.348.417.417,0,0,1,.122-.391l2.987-2.82A.834.834,0,0,0,14.594.227l-3.173,3a.417.417,0,0,1-.314.107,1.683,1.683,0,0,0-.215,0,.417.417,0,0,1-.315-.112L7.406.227A.833.833,0,1,0,6.261,1.438Z" transform="translate(-1 0)" />
                <rect id="Rectangle_3606" data-name="Rectangle 3606" width="3.333" height="2.917" rx="0.5" transform="translate(8.333 8.125)" />
                <path id="Path_7754" data-name="Path 7754" d="M13.333,16.625V15.167a.417.417,0,0,0-.417-.417h-2.5a.417.417,0,0,0-.417.417v1.458a1.667,1.667,0,0,0,3.333,0Z" transform="translate(-1.667 -2.458)" />
            </g>
        </svg>
    )
}

