import React, { useContext } from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { Text } from 'components/typography/Text';
import { UserDetails } from 'components/user-components/UserDetails';
import { Profile } from 'components/user-components/Profile';
import { AppContext } from 'AppContext';
import { useRedirect } from 'utils/redirect';
import { HOME_PAGE } from 'global/routes';
import { NextStep } from 'components/form-elements/buttons/NextStep';

export const CheckYourInfo = () => {
  const { userSettings: { updateUserSettings } } = useContext(AppContext);
  const { onRedirect } = useRedirect(HOME_PAGE);

  const onCompleteOnboarding = () => {
    updateUserSettings({ onboardingPassed: true });
    onRedirect();
  };

  return (
    <ModalCard>
      <Grid
        paddingTop
        brikkLayout
      >
        <Grid.Column>
          <Brikk.Heading
            main
            heading="onboardingYourInfoTitle"
          >
            <Text
              size={16}
              intl="onboardingYourInfoDescription"
            />
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <UserDetails />
        </Grid.Column>

        <Grid.Column>
          <Profile />
        </Grid.Column>

        <NextStep onClick={onCompleteOnboarding} />
      </Grid>
    </ModalCard>
  );

};
