import { useMutation, useQueryClient } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { updateLightningDeviceAPI } from './api';

const LIGHTNING_GROUPS = 'lightningGroups';

export function useUpdateLightningDevice() {
  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (device) => updateLightningDeviceAPI(device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([LIGHTNING_GROUPS, tenantId]);
      },
    },
  );

  return {
    updateLightningDevice: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
