import React from 'react';
import { PDFPreview } from 'components/media/PDFPreview';
import Loader from 'components/loaders/Loader';
import { Grid } from 'components/layout/Grid';
import { useAssets } from 'services/assets';

export function LivingDocuments({ category }) {
  const { assets, isLoading } = useAssets();

  if (isLoading) return <Loader />;

  return (
    <Grid
      twoEqualColumns
      noPadding
    >
      {assets && assets[category]?.map(document => (
        <PDFPreview
          key={document.name}
          name={document.name}
          downloadName={document.downloadName}
          url={document.url}
        />
      ))}
    </Grid>
  ); 
}
