import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { MyHomeIcon } from 'assets/icons/brikks/MyHomeIcon';
import { IntercomPreview } from 'components/brikks/home/Intercom/components/IntercomPreview';
import { IntercomModal } from 'components/brikks/home/Intercom/components/IntercomModal';

export const Intercom = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Draggable position={position}>
        <Brikk>
          <Brikk.Header
            icon={<MyHomeIcon />}
            heading="intercomBrikk"
          />

          <Brikk.Content>
            <IntercomPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="axisCompanion"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="axisCompanion"
        onClose={() => setIsModalOpen(false)}
      >
        <IntercomModal />
      </BrikkModal>
    </>
  );
};
