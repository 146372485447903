import * as Yup from 'yup';
import { PASSWORD_PATTERN, OLD_PASSWORD_PATTERN } from 'utils/patterns';

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('requiredOldPassword')
    .matches(
      OLD_PASSWORD_PATTERN,
      ('invalidCurrentPasswordError'),
    ),
  newPassword: Yup.string()
    .required('requiredNewPassword')
    .matches(
      PASSWORD_PATTERN,
      ('passPatternError'),
    ),
  confirmNewPassword: Yup.string()
    .required('requiredConfirmPassword')
    .matches(
      PASSWORD_PATTERN,
      ('passPatternError'),
    )
    .oneOf([Yup.ref('newPassword')], ('passMustMatch')),
});
