import ribEnglish from 'translations/rib/english.json';
import ribSwedish from 'translations/rib/swedish.json';
import ribLogoWhite from 'assets/media/rib/logo-white.svg';
import ribFavicon from 'assets/media/rib/favicon.ico';
import ribWelcome from 'assets/media/rib/welcome.png';
import riksBrfThemes from 'global/tenant-themes/riksBrf-themes.json';
import { RIBOnboarding } from 'pages/onboarding/RIBOnboarding';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { RIBLivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/RIBLivingInfo';
import { WHITE_BG } from 'services/user/settings/utils';
 
export const ribTenant = {
  assets: {
    logoWhite: ribLogoWhite,
    logoDark: ribLogoWhite,
    favicon: ribFavicon,
    welcome: ribWelcome,
  },
  intl: {
    english: ribEnglish,
    swedish: ribSwedish,
  },
  components: {
    onboarding: RIBOnboarding,
  },
  initialBackground: WHITE_BG,
  themes: riksBrfThemes,
  sizes: {
    brikk: '300px',
    'brikks-spacing': '12px',
  },
  store: {
    updateService: 'https://fiber.riksbyggen.se/login.aspx',
    changeSpeed: 'https://fiber.riksbyggen.se/login.aspx',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Sustainability, MultiGrid.Smartness, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A06', 'A07', 'A17', 'A20', 'B09', 'C07', 'D11', 'E04B', 'E06C', 'H10'],
  city: 'Helsingborg',
  openweathermapLat: '56.0467',
  openweathermapLon: '12.6944',
  officeLocation: 'https://www.hitta.se/kartan!~55.7005386595884,13.195187103290397,16z/geocode!l=55.7005386595884:13.195187103290397!z=16',
  myHome: {
    components: {
      livingInfo: RIBLivingInfo,
    },
    generalInfo: {
      link: '',
      title: 'Riksbyggen',
    },
  },
  rpName: '',
  gaTracking: '',
};
