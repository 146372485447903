import React from 'react';
import { Radio as SemanticRadio } from 'semantic-ui-react';
import { useTheme } from 'global/themes';
import { FocusableElement } from 'components/FocusableElement';

export const Radio = ({ toggle, checked, onChange, name, value, label, disabled = false }) => {
  const { theme } = useTheme();

  return (
    <FocusableElement onClick={(e) => onChange(e, { value, checked: !checked })}>
      <SemanticRadio
        className={theme.textColor}
        style={{ zIndex: 1 }}
        name={name}
        value={value}
        label={label}
        toggle={toggle}
        checked={checked} 
        onChange={onChange}
        disabled={disabled}
      />
    </FocusableElement>
  ); 
};
