import React, { useContext } from 'react';
import { NavigationMenu } from 'components/menus/NavigationMenu';
import { MultiStepContext } from 'components/MultiStep';

export const StepProgress = () => {
  const { activeStep } = useContext(MultiStepContext);

  return (
    <NavigationMenu fixed>
      <NavigationMenu.Step
        isActive={activeStep === 0}
        textIntl="overview"
      />

      <NavigationMenu.Step
        isActive={activeStep === 1}
        textIntl="options"
      />

      <NavigationMenu.Step
        isActive={activeStep === 2}
        textIntl="checkout"
      />
    </NavigationMenu>
  );
};
