import {
  HomeInsurance,
  FixItYourself,
  Boportalen,
  GoodNeighbours,
} from '../VXBLivingInfo/components';
import {
  ApartmentTable,
  PlanOverview,
} from '../LivingInfo/components';

export const LivingInfoOptions = [
  {
    menu: 'apartmentInfo',
    submenus: [],
    content: ApartmentTable,
  },
  {
    menu: 'planOverview',
    submenus: [],
    content: PlanOverview,
  },
  {
    menu: 'homeInsurance',
    submenus: [],
    content: HomeInsurance,
  },
  {
    menu: 'fixItYourself',
    submenus: [],
    content: FixItYourself,
  },
  {
    menu: 'boportalen',
    submenus: [],
    content: Boportalen,
  },
  {
    menu: 'goodNeighbours',
    submenus: [],
    content: GoodNeighbours,
  },
];
