import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import React from 'react';

export const LinkModalButton = ({ onClick, intl, disabled }) => (
  <ProceedButton
    onClick={onClick}
    disabled={disabled}
  >
    <ProceedButton.Button
      size={14}
      intl={intl}
    />

    <ProceedButton.SignIn />
  </ProceedButton>
);
