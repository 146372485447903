import React, { useMemo } from 'react';
import { Card } from 'components/cards/Card';
import { Form } from 'components/form-elements/Form';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { SubmitButton } from 'components/form-elements/buttons/SubmitButton';
import { useIntl } from 'react-intl';
import { MultiSelect } from 'components/form-elements/selects/MultiSelect';
import { Grid } from 'components/layout/Grid';
import { useLightningDevices } from 'services/lightning/devices';
import { useForm, Controller } from 'react-hook-form';
import { useCreateLightningGroup } from 'services/lightning/create-group';

const initialGroupValues = {
  name: '',
  devices: [],
};

export const AddGroup = () => {
  const intl = useIntl();
  const { lightningDevices, isLoading: isLoadingDevices } = useLightningDevices();

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: initialGroupValues,
  });

  const { createLightningGroup, isLoading: isLoadingCreate } = useCreateLightningGroup({
    onSuccess: () => {
      reset();
    },
  });
  
  const sources = useMemo(() => lightningDevices.map(item => ({
    value: item.deviceId,
    label: item.displayName,
  })), [lightningDevices]);
  
  function onSubmit(data) {
    createLightningGroup(data);
  }

  return (
    <Card
      grey
      header="createGroup"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          oneColumn
          noPadding
        >
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <LabeledInput
                labelIntl="createNewGroup"
                description="createGroupDesc"
                placeholder={intl.formatMessage({ id: 'name' })}
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.name}
              />
            )}
          />

          <Controller
            name="devices"
            control={control}
            render={({ field }) => (
              <MultiSelect
                placeholder={intl.formatMessage({ id: 'chooseSourcePlaceholder' })}
                options={sources}
                title="chooseSourceTitle"
                value={field.value}
                onChange={field.onChange}
                description="chooseSourceDesc"
                isLoading={isLoadingDevices}
              />
            )}
          />
        </Grid>

        <SubmitButton isLoading={isLoadingCreate} />
      </Form>
    </Card>
  );
};
