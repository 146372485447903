import React from 'react';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { ExternalLink } from 'components/links/ExternalLink';
import { ShouldRender } from 'components/ShouldRender';
import { LivingDocuments } from 'components/LivingDocuments';
import { useLivingInfo } from 'services/living-info/living-info';
import { tenantConfig } from 'global/tenant-config';

export const Safety = () => {
  const { livingInfo } = useLivingInfo();

  const safety = React.useMemo(() => (tenantConfig?.areas && tenantConfig.areas[livingInfo.propertyNumber] 
    ? tenantConfig?.areas[livingInfo.propertyNumber]?.safety
    : null),
  [livingInfo]);

  return (
    <>
      <LivingDocuments category="safety" />
      
      <ShouldRender condition={!!safety}>
        <ExternalLink href={safety?.link}>
          <ProceedButton>
            <ProceedButton.Button title={safety?.title} />
            <ProceedButton.Redirect />
          </ProceedButton>
        </ExternalLink>
      </ShouldRender>
    </>
  );
};
