import React from 'react';
import styles from './styles.module.scss';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';
import cityOfLundSrc from 'assets/media/lkf/city-of-lund.png';
import citizenHubSrc from 'assets/media/lkf/citizen-hub.png';
import scienceVillageSrc from 'assets/media/lkf/science-village.png';
import xplorionSrc from 'assets/media/lkf/xplorion.png';
import { GalleryImage } from './components/GalleryImage';
import { ONBOARDING_WELCOME } from 'global/routes';
import { LKFOnboardingRoutes } from 'pages/onboarding/LKFOnboarding/routes';
import { OnboardingImage } from 'pages/onboarding/OnboardingImage';

export const ImageGalleryOverlay = ({ imageSrc, overlayIntl }) => (
  <OnboardingImage
    className={styles.mainImage}
    imageSrc={imageSrc}
  >
    <ImageWithOverlay.MainOverlay intl={overlayIntl} />

    <div className={styles.imageGallery}>
      <GalleryImage
        activeRoute={ONBOARDING_WELCOME}
        imageSrc={cityOfLundSrc}
        overlayIntl="onboardingWelcomeTitle"
      />

      <GalleryImage
        activeRoute={LKFOnboardingRoutes.CITIZEN_HUB}
        imageSrc={citizenHubSrc}
        overlayIntl="onboardingCitizenHubTitle"
      />

      <GalleryImage
        activeRoute={LKFOnboardingRoutes.SCIENCE_VILLAGE}
        imageSrc={scienceVillageSrc}
        overlayIntl="onboardingScienceVillageTitle"
      />

      <GalleryImage
        activeRoute={LKFOnboardingRoutes.XPLORION}
        imageSrc={xplorionSrc}
        overlayIntl="onboardingXplorionTitle"
      />
    </div>
  </OnboardingImage>
);
