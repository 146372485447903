import React from 'react';

export const MovingOutIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.167" viewBox="0 0 20 18.67">
      <path id="Path_8284" data-name="Path 8284" d="M4.125,9.336H6.23a.632.632,0,0,1,.632.632V14.81a.211.211,0,0,0,.211.211H19.493a.632.632,0,0,1,.632.632V17a1.388,1.388,0,0,1-1.386,1.387h-.007a.211.211,0,0,1-.2-.151,2.728,2.728,0,0,0-5.233,0,.211.211,0,0,1-.2.151H8a.211.211,0,0,1-.2-.151,2.728,2.728,0,0,0-5.233,0,.211.211,0,0,1-.2.151H1.511A1.388,1.388,0,0,1,.125,17V13.336A4,4,0,0,1,4.125,9.336ZM1.809,13.757H3.914a.421.421,0,0,0,.421-.421V11.231a.421.421,0,0,0-.421-.421,2.526,2.526,0,0,0-2.526,2.526A.421.421,0,0,0,1.809,13.757Z" transform="translate(-0.125 -2.245)" />
      <ellipse id="Ellipse_424" data-name="Ellipse 424" cx="1.895" cy="1.895" rx="1.895" ry="1.895" transform="translate(13.895 14.88)" />
      <ellipse id="Ellipse_425" data-name="Ellipse 425" cx="1.895" cy="1.895" rx="1.895" ry="1.895" transform="translate(3.158 14.88)" />
      <path id="Path_8285" data-name="Path 8285" d="M12.664,6.533H11.612a.211.211,0,0,0-.211.211v5.263a.421.421,0,0,1-.421.421H8.875a.421.421,0,0,1-.421-.421V9.48a.842.842,0,0,0-.842-.842H6.348a.842.842,0,0,0-.842.842v2.526a.421.421,0,0,1-.421.421H2.98a.421.421,0,0,1-.421-.421V6.743a.211.211,0,0,0-.211-.211H1.3A.421.421,0,0,1,1.017,5.8h0L6.5,1.124h0a.842.842,0,0,1,1.108,0l5.337,4.67a.421.421,0,0,1-.277.739Z" transform="translate(6.283 -0.916)" />
  </svg>
);
