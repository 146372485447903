import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';
import accessAppImg from 'assets/media/access-app/access-phone-girl.png';
import { Manuals } from 'components/brikks/home/Intercom/components/IntercomModal/components/Manuals';
import { Download } from 'components/brikks/home/Intercom/components/IntercomModal/components/Download';

export const IntercomModal = () => (
  <Grid brikkLayout>
    <IntlPageTitle intlTitle="intercom" />

    <Grid.Column>
      <Brikk.Heading
        main
        heading="intercom"
      >
        <ModalIntro>
          <ModalIntro.Image
            src={accessAppImg}
            alt="intercom"
          />

          <ModalIntro.Text intl="intercomDescription" />
        </ModalIntro>
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="list">
        <Manuals />
      </Brikk.Heading>
    </Grid.Column>

    <Grid.Column>
      <Brikk.Heading heading="download">
        <Download />
      </Brikk.Heading>
    </Grid.Column>

  </Grid>
);
