import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { DiscardIcon } from 'assets/icons/DiscardIcon';
import { BaseModal } from 'components/modals/BaseModal';
import { Card } from 'components/cards/Card';
import { ShouldRender } from 'components/ShouldRender';
import { useTheme } from 'global/themes';

export const Overlay = ({ children, isOpen, onClose }) => {

  const { theme } = useTheme();

  return (
    <ShouldRender condition={isOpen}>
      <BaseModal
        onClose={onClose}
        isOpen={isOpen}
      >
        <Card className={classNames(styles.innerModal, theme.cardBackgroundColor)}>
          <div className={styles.header}>
            <div className={styles.closeButton}>
              <DiscardIcon onClick={onClose} />
            </div>
          </div>

          <div className={styles.content}>
            {children}
          </div>
        </Card>
      </BaseModal>
    </ShouldRender>
  );
}
