import React, { useContext } from 'react';
import { AppContext } from 'AppContext';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { LOGIN_PAGE } from 'global/routes';
import { PageLoader } from 'components/loaders/PageLoader';

export const PrivateRoute = ({ exact, path, component }) => {
  const { isAuthenticated, authLoading } = useContext(AppContext);
  const location = useLocation();

  if(authLoading){
    return <PageLoader />
  }

  return (
    isAuthenticated ? (
      <Route
        exact={exact}
        path={path}
        component={component}
      />
    ) : <Redirect to={{
          search: `?redirect_url=${location.pathname}`,
          pathname: LOGIN_PAGE
        }} 
      />
  );
};
