import React, { useContext } from 'react';
import { NavLink } from 'components/links/NavLink';
import { useTheme } from 'global/themes';
import { AppContext } from 'AppContext';
import { ShouldRender } from 'components/ShouldRender';
import brfRibLogo from 'assets/media/brfRib/logo-white.svg';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { RIB } from 'global/tenant-config/tenants';
import { HOME_PAGE } from 'global/routes';
import styles from './styles.module.scss';

export const Logo = () => {
  const { images } = useTheme();
  const { isAuthenticated } = useContext(AppContext);
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  return (
    <NavLink to={HOME_PAGE}>
      <div className={styles.logoWrapper}>
        <img
          alt="Logotype"
          src={images.logo}
          className={tenantId === RIB ? styles.logoRib : styles.logo}
        />

        <ShouldRender condition={isAuthenticated && tenantId === RIB}>
          <span className={styles.verticalLine} />

          <img
            alt="brfRibLogo"
            src={brfRibLogo}
            className={styles.logo}
          />
        </ShouldRender>
      </div>
    </NavLink>
  );
};
