import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { ApartmentRooms } from './ApartmentRooms';
import { ClimateControls } from './ClimateControls';
import { ClimateNotifications } from './ClimateNotifications';

export const ClimateControlSections = () => (
  <MultiTabMenu
    title="overview"
    defaultTab="yourApartment"
    tabs={{
      yourApartment: {
        title: 'yourApartment',
        component: ApartmentRooms,
        menus: [],
        tabComponent: ClimateControls,
      },
      notifications: {
        title: 'notifications',
        menus: [],
        component: ClimateNotifications,
      },
    }}
  />
);
