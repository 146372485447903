import { ResetPassActions } from './actions';

const initialState = {
  isResetSuccessful: false,
  loading: false,
  error: '',
};

export function resetPassReducer(state, action) {
  switch (action.type) {
    case ResetPassActions.RESET_PASS:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ResetPassActions.RESET_PASS_SUCCESS:
      return {
        ...state,
        isResetSuccessful: true,
        loading: false,
      };
    case ResetPassActions.RESET_PASS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ResetPassActions.RESET_PASS_RESET:
    default:
      return initialState;
  }
}
