import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

const url = `${AWS_API_URL}/maintenance`;

export async function getMaintenanceAPI() {
  const data = await getRequest(url, await authorize());

  return mapDataToClient(data);
}
