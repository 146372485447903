import React from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductInfo/styles.module.scss';
import { Text } from 'components/typography/Text';

export const ProductInfo = ({ label, value, bold }) => {

  return (
    <div className={styles.info}>
      <Text
        bold={bold}
        intl={label}
        className={styles.label}
      />

      <Text>
        {value}
      </Text>
    </div>
  );
};
