import React from 'react';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const MainOverlay = ({ intl, bgColor, textIsWhite: textIsBlack }) => (
  <div
    className={styles.overlay}
    style={bgColor ? { backgroundColor: bgColor } : null}
  >
    <Text
      className={styles.overlayText}
      fontWeight={400}
      element="h1"
      size={32}
      white={!textIsBlack}
      intl={intl} 
    />
  </div>
);
