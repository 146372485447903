import React from 'react';

export const PlayIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
            <g id="video-player-alternate" transform="translate(0 -2)">
                <path id="Path_7664" data-name="Path 7664" d="M19,2H5A5.006,5.006,0,0,0,0,7V17a5.006,5.006,0,0,0,5,5H19a5.006,5.006,0,0,0,5-5V7A5.006,5.006,0,0,0,19,2Zm3,15a3,3,0,0,1-3,3H5a3,3,0,0,1-3-3V7A3,3,0,0,1,5,4H19a3,3,0,0,1,3,3Z"  />
                <path id="Path_7665" data-name="Path 7665" d="M15.935,10.658,10.171,7.776A1.5,1.5,0,0,0,8,9.118v5.764a1.5,1.5,0,0,0,2.171,1.342l5.764-2.882a1.5,1.5,0,0,0,0-2.684Z" />
            </g>
        </svg>
    )
}