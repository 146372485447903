import React from 'react';
import { Card } from 'semantic-ui-react';
import { HouseTemperatureIcon } from 'assets/icons/HouseTemperatureIcon';
import { Text } from 'components/typography/Text';
import { Grid } from 'components/layout/Grid';
import { Heading } from 'components/Brikk/components/Heading';
import { ClimateModalContext } from 'components/brikks/smartness/Climate/components/ClimateModal';
import { ShouldRender } from 'components/ShouldRender';
import { useWeather } from 'services/weather';
import {
  TemperatureDisplay, 
} from './components';
import styles from './styles.module.scss';

export function ClimateControls() {

  const { selectedSensor } = React.useContext(ClimateModalContext);
  const { weather: { temperature, humidity } } = useWeather();

  return (
    <Grid.Column>
      <ShouldRender condition={selectedSensor}>
        <Heading heading="controls" />
        
        <Card className={styles.card}>
          <Card.Content textAlign="right">
            <HouseTemperatureIcon />

            <Text
              size={14}
              bold
              dark
              uppercase
              intl="climate"
            />             
          </Card.Content>

          <Text
            size={16}
            thin
            uppercase
            center
            bold
          >
            {selectedSensor?.displayName.split('-')[1]?.trim()}
          </Text>

          <div className={styles.temperatureDisplay}>
            <TemperatureDisplay
              value={selectedSensor?.status?.celsius}
              unit="°C"
              large
              className={styles.temperatureDisplayValue}
            />
          </div>

          <Grid
            twoEqualColumns
            style={{ padding: '2em 5em' }}
          >
            <Grid
              oneColumn
              noPadding
              style={{ gap: '1em' }}
            >
              <Text
                size={14}
                intl="general"
                bold
              />

              <TemperatureDisplay
                title="temperature"
                value={selectedSensor?.status?.celsius}
                unit="°C"
                small
                className={styles.temperatureDisplayValueSmall}
              />

              <TemperatureDisplay
                title="airHumidity"
                value={selectedSensor?.status?.humidity}
                unit="%"
                small
                blue
                className={styles.temperatureDisplayValueSmall}
              />
            </Grid>

            <Grid
              oneColumn
              noPadding
              style={{ gap: '1em' }}
            >
              <Text
                size={14}
                intl="outside"
                bold
              />
              
              <TemperatureDisplay
                title="temperature"
                value={temperature}
                unit="°C"
                small
                className={styles.temperatureDisplayValueSmall}
              />

              <TemperatureDisplay
                title="airHumidity"
                value={humidity}
                unit="%"
                small
                blue
                className={styles.temperatureDisplayValueSmall}
              />
            </Grid>
          </Grid>
        </Card>
      </ShouldRender>
    </Grid.Column> 
  );
}
