import React from 'react';
import styles from './styles.module.scss';
import { useTheme } from 'global/themes';
import classNames from 'classnames';

export const RedirectIcon = ({white}) => {
  const { theme } = useTheme();

  return (
      <svg className={classNames(styles.icon, white ? styles.white : theme.iconColor)} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
            d="M19.284,2.99A.723.723,0,0,0,19.206,3H15.126a.7.7,0,1,0-.019,1.391h2.513l-7.157,7.157a.7.7,0,1,0,.963,1l.02-.02L18.6,5.371V7.864a.7.7,0,0,0,1.391.019v-4.1A.7.7,0,0,0,19.4,3,.8.8,0,0,0,19.284,2.99ZM5.391,5.778A1.4,1.4,0,0,0,4,7.169V17.6A1.4,1.4,0,0,0,5.391,18.99h10.43A1.4,1.4,0,0,0,17.212,17.6V8.853l-1.391,1.391V17.6H5.391V7.169h7.355l1.391-1.391H5.391Z"
            transform="translate(-4 -2.99)"/>
      </svg>
  );
};
