import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CircledWarningIcon = ({ size = 31, disabled }) => (
  <svg
    className={
      classNames(styles.icon, {
      [styles.disabled]: disabled,
    })}
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
  >
    <g id="Group_5964" data-name="Group 5964" transform="translate(-718.5 -608.5)">
      <circle id="Ellipse_353" data-name="Ellipse 353" cx="15" cy="15" r="15" transform="translate(719 609)" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
      <text id="_" data-name="!" transform="translate(734 631)" fill="#fff" fontSize="20" fontFamily="SegoeUI-Bold, Segoe UI" fontWeight="700"><tspan x="-3.271" y="0">!</tspan></text>
    </g>
  </svg>

);
