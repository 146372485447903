import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { FocusableElement } from 'components/FocusableElement';

export const ToggleSwitch = ({
  option1,
  option2,
  option1AriaLabel,
  option2AriaLabel,
  isOption1Active,
  onSelectOption1,
  onSelectOption2,
}) => {

  return (
    <div className={styles.toggleSwitch}>
      <FocusableElement
        role="button"
        ariaLabel={option1AriaLabel}
        className={classNames(styles.option, isOption1Active && styles.active)}
        onClick={onSelectOption1}
      >
        <Text
          secondary
          white={isOption1Active}
          intl={option1}
        />
      </FocusableElement>

      <FocusableElement
        role="button"
        ariaLabel={option2AriaLabel}
        className={classNames(styles.option, !isOption1Active && styles.active)}
        onClick={onSelectOption2}
      >
        <Text
          secondary
          white={!isOption1Active}
          intl={option2}
        />
      </FocusableElement>
    </div>
  )
};
