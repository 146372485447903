import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ShouldRender } from 'components/ShouldRender';
import { TYPE_IMAGE, UploadFile } from 'components/form-elements/UploadFile';
import { ErrorMessage } from 'components/messages/ErrorMessage';
import { Text } from 'components/typography/Text';
import { Button } from 'components/form-elements/buttons/Button';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import { UploadedImage } from './components/UploadedImage';
import styles from './styles.module.scss';

export const MultipleImageUpload = ({ maxSize = 3, disabled, onUpload, reset }) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  const [error, setError] = useState('');
  const { theme } = useTheme();

  const onUploadImage = (uploadedImage) => {
    setUploadedImages((images) => [...images, uploadedImage]);
  };

  const calculateImagesSize = () => {
    if (uploadedImages.reduce(
      (total, image) => total + image.size, 0,
    ) < 5000000 && !!error) { setError(null); } 
  };
  const onRemoveImage = (imageName) => {
    setUploadedImages((images) => images.filter((image) => image.filename !== imageName));
    calculateImagesSize(); 
  };

  useEffect(() => {
    onUpload(uploadedImages);
  }, [uploadedImages, onUpload]);

  useEffect(() => {
    setUploadedImages([]);
  }, [reset]);

  return (
    <div
      className={classNames(
        styles.multipleImage,
        disabled && styles.disabled,
        theme.backgroundColor,
      )}
    >
      {uploadedImages.length ? (
        <div className={styles.images}>
          <div className={styles.imagesList}>
            {uploadedImages.map((image) => (
              <UploadedImage
                key={image.filename}
                name={image.filename}
                size={image.size}
                content={image.fileContent}
                onRemove={onRemoveImage}
              />
            ))}
          </div>

          <Text small>
            {`${uploadedImages.length}/${maxSize} `}
            <FormattedMessage id="pictures" />
          </Text>
        </div>
      ) : (
        <Text 
          center 
          gray 
          large 
          bold 
          intl="uploadReportImagePrompt" 
        />
      )}

      <ShouldRender condition={!!error}>
        <ErrorMessage message={error} />
      </ShouldRender>

      <div className={styles.prompt}>
        <label
          className={styles.imageUpload}
          htmlFor="multipleUpload"
        >
          <Button
            disabled={uploadedImages.length >= maxSize}
            intl="selectPictures"
          />

          <UploadFile
            disabled={disabled || uploadedImages.length >= maxSize}
            uploadedImages={uploadedImages}
            name="property-image"
            bytes
            multiple={false}
            maxSize={maxSize}
            id="multipleUpload"
            className={styles.upload}
            type={TYPE_IMAGE}
            onChange={onUploadImage}
            onError={setError}
          />
        </label>
        
        <Text 
          gray 
          center 
          intl="acceptedImageFormat"
        />
      </div>
    </div>
  );
};
