import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { ReactComponent as HousingQueueIcon } from 'assets/media/housing-queue.svg';
import { tenantConfig } from 'global/tenant-config';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { Text } from 'components/typography/Text';
import { formatNumber } from "utils/format/numbers";

export const HousingQueuePreview = ({ queuePoints }) => {
  const housingQueueSrc = tenantConfig.housingQueue.previewImage;

  if (housingQueueSrc) {
    return (
      <>
        <CircularImage
          src={housingQueueSrc}
          alt="Illustration of a house symbolizing housing queue"
        />

        <Text
          center
          bold
          intl="queuePoints"
        />
      </>
    )
  }

  return (
    <IconNumText
      icon={<HousingQueueIcon />}
      text={<FormattedMessage id="queuePoints" />}
      number={queuePoints ? formatNumber(queuePoints) : '00'}
    />
  );
}
