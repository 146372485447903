import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import { useTheme } from '../../global/themes';

export const BrikkPlaceholder = () => {
  const { theme } = useTheme();

  return (
    <div
      className="brikk-body-placeholder"
      style={{ margin: '1em auto', width: '80%' }}
    >
      <Placeholder>
        <Placeholder.Line className={theme.backgroundColor} />
        <Placeholder.Line className={theme.backgroundColor} />
        <Placeholder.Line className={theme.backgroundColor} />
        <Placeholder.Line className={theme.backgroundColor} />
      </Placeholder>
    </div>
  );
};
