import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import frejaeIDLogoSrc from 'assets/media/frejae-id.png';
import { Image } from 'components/media/Image';

export const FrejaeIDLogo = ({ className, framed, centered }) => (
  <div className={classNames(framed && styles.framed,  centered && styles.centered)}>
    <Image
      className={classNames(styles.frejaeIDLogo, className)}
      src={frejaeIDLogoSrc}
      alt="FrejaeID Logotype"
    />
  </div>
);
