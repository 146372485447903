import { Chart } from 'react-chartjs-2';
import { isEmpty, get } from 'lodash';

export const makeChartBarRoundedTop = () => {
  // Border-radius issue: https://github.com/jerairrest/react-chartjs-2/issues/364
  Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
    draw() {
      const { ctx } = this._chart;
      const vm = this._view;
      const { borderWidth } = vm;

      let left; let right; let top; let bottom; let borderSkipped; let 
        radius;

      // If radius is less than 0 or is large enough to cause drawing errors a max
      //      radius is imposed. If cornerRadius is not defined set it to 0.
      let { cornerRadius } = this._chart.config.options;
      if (cornerRadius < 0) { cornerRadius = 0; }

      if (typeof cornerRadius === 'undefined') { cornerRadius = 0; }

      // bar
      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      borderSkipped = vm.borderSkipped || 'bottom';

      ctx.beginPath();
      ctx.fillStyle = vm.backgroundColor;
      ctx.strokeStyle = vm.borderColor;
      ctx.lineWidth = borderWidth;

      // Corner points, from bottom-left to bottom-right clockwise
      // | 1 2 |
      // | 0 3 |
      const corners = [
        [left, bottom],
        [left, top],
        [right, top],
        [right, bottom],
      ];

      // Find first (starting) corner with fallback to 'bottom'
      const borders = ['bottom', 'left', 'top', 'right'];
      let startCorner = borders.indexOf(borderSkipped, 0);
      if (startCorner === -1) {
        startCorner = 0;
      }

      function cornerAt(index) {
        return corners[(startCorner + index) % 4];
      }

      // Draw rectangle from 'startCorner'
      let corner = cornerAt(0);
      ctx.moveTo(corner[0], corner[1]);

      for (let i = 1; i < 4; i += 1) {
        corner = cornerAt(i);
        let nextCornerId = i + 1;
        if (nextCornerId === 4) {
          nextCornerId = 0;
        }

        const width = corners[2][0] - corners[1][0];
        const height = corners[0][1] - corners[1][1];
        const x = corners[1][0];
        const y = corners[1][1];

        radius = cornerRadius;
        // Fix radius being too large
        if (radius > Math.abs(height) / 2) {
          radius = Math.floor(Math.abs(height) / 2);
        }
        if (radius > Math.abs(width) / 2) {
          radius = Math.floor(Math.abs(width) / 2);
        }

        // Draw a line with rounded top.
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height);
        ctx.lineTo(x, y + height);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
      }

      ctx.fill();
      if (borderWidth) {
        ctx.stroke();
      }

    },

  });
};

// Filter Water usage data for chart and exclude future dates of current month.
export const excludeFutureDates = (waterData) => waterData.filter(item => {
  const itemDate = new Date(item.y);
  return new Date() >= itemDate;
});

// Custom plugin to offset current period chart bars.
export const getOffsetPlugin = (currentPeriodBarsOffset = 0) => ({
  afterUpdate(chart) {
    const dataset = chart.config.data.datasets[0];
    let datasetData = [];

    if (!!dataset && !!dataset._meta[0]) {
      datasetData = get(dataset._meta[0], 'data', []);
    } else {
      datasetData = get(dataset._meta[1], 'data', []);
    }

    for (let i = 0; i < datasetData.length; i++) {
      const model = datasetData[i]._model;
      model.x += currentPeriodBarsOffset;
      model.controlPointNextX += currentPeriodBarsOffset;
      model.controlPointPreviousX += currentPeriodBarsOffset;
    }

  },
});

export const processWaterUsage = (waterUsage) => {
  let coldWaterUsage = [];
  let hotWaterUsage = [];
  const totalWaterUsage = [];

  // Generate label-value pairs for chart.
  if (!isEmpty(get(waterUsage, 'coldWater.values', null))) {
    coldWaterUsage = waterUsage.coldWater.values.map((val, index) => {
      const waterValue = Math.round(val.x) || 0;
      totalWaterUsage[index] = !totalWaterUsage[index] ? waterValue : totalWaterUsage[index] += waterValue;
      return waterValue;
    });
  }
  if (!isEmpty(get(waterUsage, 'hotWater.values', null))) {
    hotWaterUsage = waterUsage.hotWater.values.map((val, index) => {
      const waterValue = Math.round(val.x) || 0;
      totalWaterUsage[index] = !totalWaterUsage[index] ? waterValue : totalWaterUsage[index] += waterValue;
      return waterValue;
    });
  }

  return { coldWaterUsage, hotWaterUsage, totalWaterUsage };
};
