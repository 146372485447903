import React from 'react';
import { BaseModal } from 'components/modals/BaseModal';
import { ModalCard } from 'components/cards/ModalCard';

export const Modal = ({ children, isOpen, className, onClose }) => (
  <BaseModal
    closeOnOutsideClick
    isOpen={isOpen}
    onClose={onClose}
  >
    <ModalCard className={className}>
      {children}
    </ModalCard>
  </BaseModal>
);
