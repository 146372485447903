import React from 'react';
import { DndProvider } from 'react-dnd';
import { isTablet } from 'react-device-detect';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { orderBy } from 'lodash';
import { usePrevious } from '../../../utils/hooks/usePrevious';

export const DragAndDropContext = React.createContext(null);

export const DragAndDrop = ({ enabled, draggables, order = [], onChange, isOrderList }) => {
  const [draggablesOrder, setDraggablesOrder] = React.useState(order);
  const prevEnabled = usePrevious(enabled);
  const backend = isTablet ? TouchBackend : HTML5Backend;

  React.useEffect(() => {
    if (order.length) {
      setDraggablesOrder(order);
    }
  }, [order]);

  React.useEffect(() => {
    if (prevEnabled && !enabled) {
      onChange(draggablesOrder);
    }
  });

  const positionedDraggables = React.useMemo(() => draggables.map((draggable, index) => {
    let position = index;
    if (draggablesOrder.length) {
      const element = draggablesOrder.find(el => el.id === draggable.id);
      if (element) {
        position = element.position;
      }
    }
    return {
      ...draggable,
      position,
    };
  }), [draggables, draggablesOrder]);

  const orderedDraggables = React.useMemo(() => orderBy(positionedDraggables, 'position').map((item, index) => item.component(index)), [positionedDraggables]);

  const changePosition = React.useCallback((prevPos, newPos) => {
    const changedPos = positionedDraggables.map(draggable => {
      if (draggable.position === prevPos) {
        return { ...draggable, position: newPos };
      }
      if (draggable.position === newPos) {
        return { ...draggable, position: prevPos };
      }

      return draggable;
    });
    setDraggablesOrder(changedPos);
    if (isOrderList) {
      onChange(changedPos);
    }
        
  }, [positionedDraggables, onChange, isOrderList]);

  return (
    <DndProvider backend={backend}>
      <DragAndDropContext.Provider value={{ changePosition, enabled }}>
        {orderedDraggables}
      </DragAndDropContext.Provider>
    </DndProvider>
  );
};
