import React from 'react';
import { getSplashScreenBoolean } from 'utils/local-storage/welcome-screen';
import { useRedirect } from 'utils/redirect';
import { WELCOME_SCREEN } from 'global/routes';

export const WelcomeScreenGuard = ({ children }) => {
  const hasSeenWelcomeScreen = getSplashScreenBoolean();

  useRedirect(WELCOME_SCREEN, !hasSeenWelcomeScreen);

  return (
    <>
      {children}
    </>
  );
};
