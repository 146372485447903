import React from 'react';
import { Text } from 'components/typography/Text';
import { ShouldRender } from 'components/ShouldRender';

export const InternetProviderPreview = ({ selectedProvider }) => (
  <>
    <ShouldRender condition={!selectedProvider}>
      <Text
        intl="chooseSupplier"
        uppercase
        size={12}
      />

      <Text
        intl="internet"
        bold
        size={14}
      />
    </ShouldRender>

    <ShouldRender condition={!!selectedProvider}>
      <Text
        intl="suppliedBy"
        uppercase
        size={12}
        white
      />

      <Text
        bold
        size={14}
        white
      >
        {selectedProvider?.provider?.name}
      </Text>
    </ShouldRender>
  </>
);
