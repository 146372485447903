import React from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { AppHeader } from 'components/headers/AppHeader';
import { OrderMode } from 'components/headers/OrderMode';
import { OrderList } from 'pages/Order/components/OrderList';

const Order = () => {
    return (
        <div className='mainPage'>
            <IntlPageTitle intlTitle="orderMode" />
            <AppHeader />
            <OrderMode isOrderList={true} />
            <OrderList />
        </div>
    );
};

export default Order;
