import React from 'react';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { FormattedMessage } from 'react-intl';
import { UpgradesPreviewIcon } from "assets/icons/brikks/UpgradesPreviewIcon";
import { upgrades } from "components/brikks/home/Upgrades/components/UpgradesModal/data";
import { formatNumber } from "utils/format/numbers";

export const UpgradesPreview = () => {

  return (
    <IconNumText
      uppercase={true}
      icon={<UpgradesPreviewIcon/>}
      text={<FormattedMessage id="availableUpgrades" />}
      number={formatNumber(upgrades.length)}
    />
  )
}
