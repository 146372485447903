import React from 'react';
import { DocumentPreview } from 'components/media/DocumentPreview';

export const VideoPreview = ({ url, name, intl }) => (
  <DocumentPreview
    url={url}
    name={name}
    intl={intl}
  >
    <DocumentPreview.DocumentTitle type="video" />
    <DocumentPreview.VideoPreview />

    <DocumentPreview.Actions
      view
    />
  </DocumentPreview>
);
