import React from 'react';
import internetProvidersSrc from 'assets/media/internet-providers.png';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { InternetProvidersIcon } from 'assets/icons/brikks/InternetProvidersIcon';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { LinkBrikkContent } from 'components/brikks/sub-components/LinkBrikkContent';
import { InternetProviderPreview } from 'components/brikks/store/InternetProviders/components/InternetProviderPreview';
import { InternetProvidersModal } from 'components/brikks/store/InternetProviders/components/InternetProvidersModal';
import { tenantConfig } from 'global/tenant-config';
import { useSubscriptions } from 'services/service-report/subscriptions';
import { INTERNET_PROVIDER } from 'utils/types';
import styles from './styles.module.scss';

export const InternetProviders = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const { subscriptions, isLoading } = useSubscriptions();

  const selectedProvider = React.useMemo(
    () => (subscriptions ? subscriptions.find(item => item.type === INTERNET_PROVIDER) : null),
    [subscriptions],
  );

  const internetProvider = tenantConfig?.internetProvider;

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          name={name}
          status={status}
          disabled={!!internetProvider && !internetProvider?.link}
        >
          <Brikk.Header
            icon={<InternetProvidersIcon />}
            heading="internetProvidersStoreBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content className={!!selectedProvider && styles.provider}>
            <CircularImage
              src={selectedProvider?.provider?.logoUrl || internetProvidersSrc}
              alt="An illustration of a globe that represents Internet."
            />

            {
              !tenantConfig?.internetProvider ? (
                <InternetProviderPreview selectedProvider={selectedProvider} />
              ) : (
                <LinkBrikkContent link={internetProvider?.linkPreview}/>
              )
            } 
          </Brikk.Content>

          {
            !tenantConfig?.internetProvider ? (
              <Brikk.Button>
                <SeeMoreButton
                  onClick={handleOpenModal}
                  label="Internet providers Link"
                  brikkNameIntl="internet"
                />
              </Brikk.Button>
            ) : (
              <Brikk.Button>
                <NewPageButton
                  label="Internet providers Link"
                  to={internetProvider?.link}
                  brikkNameIntl="internetProviders"
                  disabled={!!internetProvider && !internetProvider?.link}
                />
                <InfoButton
                  showModal={isInfoModalOpen}
                  onClick={handleInfoModal}
                />
              </Brikk.Button>
            )
          }
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Internet providers info"
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="internetProviders"
        onClose={() => setIsModalOpen(false)}
      >
        <InternetProvidersModal selectedProvider={selectedProvider} />
      </BrikkModal> 
    </>
  );
}
