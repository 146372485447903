import { convertToTimeZone } from 'date-fns-timezone';

const getWeatherConditionByCode = (code) => {
  if (!code) {
    return '';
  }

  const weatherId = code.substring(0, 2);

  // https://openweathermap.org/weather-conditions
  const weatherMapping = {
    '01': 'clearSky',
    '02': 'fewClouds',
    '03': 'scatteredClouds',
    '04': 'brokenClouds',
    '09': 'showerRain',
    10: 'rain',
    11: 'thunderstorm',
    13: 'snow',
    50: 'mist',
  };

  return weatherMapping[weatherId] || '';
};

const filterDailyWeatherData = (dailyWeather) => {
  const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

  return dailyWeather.map(item => {
    const day = new Date(item.dt * 1000).getDay();
    const weekDay = weekDays[day];
    return {
      day: weekDay,
      weatherCondition: getWeatherConditionByCode(item.weather[0].icon),
      tempMin: Math.round(item.temp.min) || 0,
      tempMax: Math.round(item.temp.max) || 0,
    };
  });
};

const filterHourlyWeatherData = (hourlyWeather) => {
  // NOTE: timestamp from the openWeatherMap API is returned in seconds.
  // So we multiply it by 1000 to match JS timestamp format in ms.

  const filteredItems = [];

  // Work with Swedish time regardless of user location.
  const swedishDate = convertToTimeZone(new Date(), { timeZone: 'Europe/Stockholm' });

  hourlyWeather.forEach(item => {
    const itemTime = new Date(item.dt * 1000);

    // Format hour as: 2 PM
    const itemHour = itemTime.toLocaleString('en-US', { hour: 'numeric', hour12: true });

    // Skip outdated items. It's possible due to caching.
    if (itemTime.getTime() - swedishDate.getTime() < 0) {
      return;
    }

    filteredItems.push(
      {
        hour: itemHour,
        isDay: item.weather[0].icon.charAt(2) !== 'n',
        weatherCondition: getWeatherConditionByCode(item.weather[0].icon),
        temperature: Math.round(item.temp) || 0,
      },
    );
  });

  return filteredItems;
}; 

export const mapDataToClient = (weather) => ({
  temperature: Math.round(weather.current.temp) || 0,
  weatherConditions: getWeatherConditionByCode(weather.current.weather[0].icon),
  isDay: weather.current.weather[0].icon.charAt(2) !== 'n',
  windSpeed: Math.round(weather.current.wind_speed) || 0,
  humidity: Math.round(weather.current.humidity) || 0,
  chanceOfPrecipitation: Math.round(weather.hourly[0].pop * 100) || 0,
  daily: filterDailyWeatherData(weather.daily) || [],
  hourly: filterHourlyWeatherData(weather.hourly) || [],
});
