import React from 'react';
import styles from './styles.module.scss';
import { WarningIcon } from '../../../assets/icons/WarningIcon';
import { FormattedMessage } from 'react-intl';

export const ErrorMessage = ({ message }) => (
  <div className={styles.errorMessage}>
    <WarningIcon />

    <FormattedMessage id={message} />
  </div>
);
