import React from 'react';

export const DoorAccessIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.013"
      viewBox="0 0 20 20.013"
    >
        <g
          id="login-3"
          transform="translate(-0.008 0)"
        >
            <path
              id="Path_6443"
              data-name="Path 6443"
              d="M15.683,15.427a.866.866,0,0,0-.9.834v.417a.433.433,0,0,1-.448.417H9.413a.433.433,0,0,1-.448-.417V3.336a.433.433,0,0,1,.448-.417h4.926a.433.433,0,0,1,.448.417v.834a.9.9,0,0,0,1.791,0V2.085a.866.866,0,0,0-.9-.834H8.965V.417A.407.407,0,0,0,8.8.092.473.473,0,0,0,8.42.01L.359,1.678a.425.425,0,0,0-.351.407V17.929a.425.425,0,0,0,.351.408L8.42,20a.479.479,0,0,0,.377-.083.407.407,0,0,0,.167-.325v-.834h6.718a.866.866,0,0,0,.9-.834V16.26A.867.867,0,0,0,15.683,15.427Zm-8.957-5a1.3,1.3,0,0,1-1.344,1.251,1.254,1.254,0,1,1,0-2.5,1.3,1.3,0,0,1,1.344,1.251Z"
              transform="translate(0 0)"
            />

            <path
              id="Path_6444"
              data-name="Path 6444"
              d="M18.371,9.97h-2.58V8.875a.625.625,0,0,0-.973-.52L11.77,10.388a.625.625,0,0,0,0,1.041l3.049,2.033a.625.625,0,0,0,.973-.52V11.846h2.58a.938.938,0,0,0,0-1.876Z"
              transform="translate(0.698 -0.427)"
            />
        </g>
    </svg>
);
