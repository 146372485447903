import React from 'react';

export const HeartRateIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.899" viewBox="0 0 20 16.899">
            <g id="fitness-heart-rate" transform="translate(0 -1.863)">
                <path id="Path_7823" data-name="Path 7823" d="M18.337,11.849H14.057a.417.417,0,0,0-.381.25l-.966,2.172a1.25,1.25,0,0,1-1.132.743h-.013a1.25,1.25,0,0,1-1.131-.723l-1.75-3.741a.209.209,0,0,0-.363-.028l-.515.774a1.25,1.25,0,0,1-1.041.557H2.392a.208.208,0,0,0-.149.353l7.5,7.72a.872.872,0,0,0,1.25,0l7.5-7.72a.208.208,0,0,0-.149-.353Z" transform="translate(-0.364 -1.427)" />
                <path id="Path_7824" data-name="Path 7824" d="M19.468,4.616a5.078,5.078,0,0,0-8.076-1.293L10.281,4.335a.417.417,0,0,1-.562,0L8.609,3.322A5.078,5.078,0,0,0,.532,4.616,4.917,4.917,0,0,0,.28,8.478a.417.417,0,0,0,.393.279H5.961a.417.417,0,0,0,.347-.186l.885-1.328a1.235,1.235,0,0,1,1.135-.553,1.25,1.25,0,0,1,1.038.718L11,10.913a.208.208,0,0,0,.379,0L12.008,9.5a1.25,1.25,0,0,1,1.142-.742h6.176a.417.417,0,0,0,.393-.278,4.917,4.917,0,0,0-.251-3.86Z" transform="translate(0)" />
            </g>
        </svg>
    )
}