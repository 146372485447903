export const maintenanceData = [
  { object: 1024, leaseType: '11', residual: 1647, planned: '2024-11', typename: 'Kök', unitname: 'Köksluckor/Sprutmålning', interval: 13, intervaltype: 'År', performeddate: '2011-11-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2031-11', typename: 'Kök', unitname: 'Köksluckor/Slät med rundad kant', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 844, planned: '2024-11', typename: 'Kök', unitname: 'Köksluckor/Skät med fasettslipad kant', interval: 13, intervaltype: 'År', performeddate: '2011-11-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1456, planned: '2024-12', typename: 'Kök', unitname: 'Köksluckor/Spårfräst med profilerad kant', interval: 15, intervaltype: 'År', performeddate: '2011-12-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2024-12', typename: 'Kök', unitname: 'Kökshandtag', interval: 15, intervaltype: 'År', performeddate: '2012-12-29T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 1700, planned: '2024-12', typename: 'Kök', unitname: 'Bänkskiva', interval: 15, intervaltype: 'År', performeddate: '2020-12-29T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2031-12', typename: 'Kök', unitname: 'Golv/Vinyl', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 1600, planned: '2031-12', typename: 'Kök', unitname: 'Golv/Laminate', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 2000, planned: '2031-12', typename: 'Kök', unitname: 'Golv/Parkett', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 944, planned: '2024-11', typename: 'Kök', unitname: 'Väggar', interval: 13, intervaltype: 'År', performeddate: '2021-11-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2031-11', typename: 'Sovrum', unitname: 'Golv/Linolium', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2031-11', typename: 'Sovrum', unitname: 'Golv/Laminate', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 2000, planned: '2031-11', typename: 'Sovrum', unitname: 'Golv/Parkett', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 844, planned: '2024-11', typename: 'Sovrum', unitname: 'Väggar/Målade', interval: 13, intervaltype: 'År', performeddate: '2011-11-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2031-11', typename: 'Vardagsrum', unitname: 'Golv/Linolium', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 2000, planned: '2031-11', typename: 'Vardagsrum', unitname: 'Golv/Parkett', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
  { object: 1024, leaseType: '11', residual: 866, planned: '2025-11', typename: 'Vardagsrum', unitname: 'Väggar/Målade', interval: 13, intervaltype: 'År', performeddate: '2011-11-29T02:00:00+01:00', measure: 'Målas' },
  { object: 1024, leaseType: '11', residual: 1500, planned: '2032-12', typename: 'Trappa', unitname: 'Golv', interval: 20, intervaltype: 'År', performeddate: '2011-11-01T02:00:00+01:00', measure: 'Utbyte' },
];
