import React from 'react';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';
import { WeekdayPicker } from '../WeekdayPicker';

export const LabeledWeekdayPicker = ({ title, onClick, selectedDays }) => (

  <LabeledElement
    label={title}
  >
    <WeekdayPicker
      onClick={onClick}
      selectedDays={selectedDays}
    />
  </LabeledElement>
);
