import React from 'react';

export const RecepcionBellCallIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.995" viewBox="0 0 20 19.995">
            <g id="reception-bell-call" transform="translate(0 -0.004)">
                <path id="Path_8231" data-name="Path 8231" d="M6.982,16.576a.391.391,0,0,1-.316-.382v-.361a.417.417,0,0,1,.417-.417h.833a.833.833,0,0,0,0-1.666H3.75a.833.833,0,1,0,0,1.666h.833A.417.417,0,0,1,5,15.833v.361a.389.389,0,0,1-.315.382A5.889,5.889,0,0,0,.012,21.32a.833.833,0,0,0,.822.971h10a.833.833,0,0,0,.823-.971,5.892,5.892,0,0,0-4.674-4.744Z" transform="translate(0 -2.292)" />
                <path id="Path_8232" data-name="Path 8232" d="M20.728.078a.827.827,0,0,0-.9.768l-.431,5.7a1.063,1.063,0,0,1-.488.816.218.218,0,0,1-.255-.05c-.4-.313-.827-.81-.8-2.742a.833.833,0,0,0-1.5-.517l-4.175,5.5a.833.833,0,0,1-.567.32.845.845,0,0,1-.624-.174.866.866,0,0,1-.137-1.223l2.23-3.031a.833.833,0,0,0,.025-.951L11.095,1.417a.733.733,0,0,1-.118-.5.833.833,0,0,0-.75-.908.82.82,0,0,0-.908.75A2.38,2.38,0,0,0,9.71,2.348l1.54,2.336a.417.417,0,0,1-.012.475L9.533,7.47a2.529,2.529,0,0,0,.437,3.542,2.49,2.49,0,0,0,1.549.536,2.4,2.4,0,0,0,.3-.018,2.511,2.511,0,0,0,1.688-.955l2.446-3.224a.417.417,0,0,1,.707.072,3.3,3.3,0,0,0,.972,1.2,1.866,1.866,0,0,0,1.951.265,2.724,2.724,0,0,0,1.482-2.21L21.5.971a.833.833,0,0,0-.771-.893Z" transform="translate(-1.501 0)" />
            </g>
        </svg>
    )
}