import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Content = ({ item, selectedItem, children }) => {

  const isOpen = selectedItem === item;

  return (
    <div
      className={classNames(
        styles.contentContainer,
        isOpen && styles.open,
      )}
    >
      <div className={classNames(styles.content)}>
        {children}
      </div>
    </div>
  );
};
