import React from 'react';
import { AppContext } from 'AppContext';
import { DragAndDrop } from 'components/drag-and-drop';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { AppHeader } from 'components/headers/AppHeader';
import { OrderMode } from 'components/headers/OrderMode';
import { ShouldRender } from 'components/ShouldRender';
import { getTenantIDBasedOnURL, tenantConfig } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { BRIKKS } from 'global/brikks';
import { useAgreements } from 'services/agreements/agreements';
import { useLightningGroups } from 'services/lightning/groups';
import { useUserDetails } from 'services/user/details/hook';
import { useLivingInfo } from 'services/living-info/living-info';
import { useOrderModes } from 'services/order/order-mode/hook';
import { useMultiGrid } from 'services/multi-grid/hook';
import { validateBrikks } from 'utils/validation/brikks';

const MainPage = () => {
  const { shouldReorderBrikks, userSettings: { brikksOrder, updateUserSettings } } = React.useContext(AppContext);

  const { selectedGrid } = useMultiGrid();

  const { orderMode } = useOrderModes();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { userDetails } = useUserDetails();
  const { agreements } = useAgreements();
  const { livingInfo } = useLivingInfo();

  // Added this to initially fetch the lightning groups before rendering
  useLightningGroups();

  //Get LKF brikks according to area number
  const brikks = React.useMemo(() => (tenantId === LKF && tenantConfig?.areas[livingInfo.propertyNumber] ? tenantConfig?.areas[livingInfo.propertyNumber]?.brikks
    : tenantConfig?.brikks),
  [tenantId, livingInfo]);

  const tenantBrikks = React.useMemo(() => BRIKKS.filter(brikk => brikks?.find(b => b === brikk.id)), [brikks]);

  const validatedBrikks = validateBrikks(tenantBrikks, userDetails.personalNumber, agreements);
  const selectedGridBrikks = validatedBrikks.filter(brikk => brikk.category === selectedGrid);
  const selectedGridBrikksOrder = brikksOrder[selectedGrid];

  return (
    <div className="mainPage">
      <IntlPageTitle intlTitle="housingOpportunities" />

      <AppHeader />

      {orderMode && <OrderMode />}

      <ShouldRender condition={selectedGridBrikks}>
        <div className="brikks-wrapper">
          <DragAndDrop
            enabled={shouldReorderBrikks}
            draggables={selectedGridBrikks}
            order={selectedGridBrikksOrder}
            onChange={(newBrikksOrder) => updateUserSettings({
              brikksOrder: {
                ...brikksOrder,
                [selectedGrid]: newBrikksOrder,
              },
            })}
          />
        </div>
      </ShouldRender>
    </div>
  );
};

export default MainPage;
