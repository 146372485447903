import { Auth } from 'aws-amplify';
import { isDateInFuture } from 'utils/timeAndDate';

const ID_TOKEN_KEY = 'GmACBc?UIiDMMa!';
const ACCESS_TOKEN_KEY = 'AfEs?e24AfMMAae';

export function setAuthToken(idToken, accessToken) {
  localStorage.setItem(ID_TOKEN_KEY, idToken);
  localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
}

export async function getIdToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (e) {
    return localStorage.getItem(ID_TOKEN_KEY);
  }
}

export async function getAuthToken() {
  try {
    const session = await Auth.currentSession();
    return session.getAccessToken().getJwtToken();
  } catch (e) {
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  }
}

export function removeAuthToken() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(ID_TOKEN_KEY);
}

export function isTokenValid(expirationDate) {
  return isDateInFuture(new Date(expirationDate * 1000));
}
