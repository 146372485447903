import React from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductCost/styles.module.scss';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { TotalCost } from 'components/item/TotalCost';

export const ProductCost = ({ onAddProduct, selectedPurchases }) => {
  return (
    <>
      <div className={styles.productActions}>
        <a href="#maintenanceCost">
          <div className={styles.costContainer}>
            <div className={styles.addItem}>
              <ProceedButton onClick={onAddProduct}>
                <ProceedButton.Button intl="addThis" />
                <ProceedButton.CartIcon />
              </ProceedButton>
            </div>
          </div>
        </a>

        <TotalCost totalCost={Object.values(selectedPurchases).reduce(
          (prevSum, { selectedSurface }) => prevSum + selectedSurface.residualValue, 0
        )} />
      </div>
    </>
  );
};
