export const terminateReasons = {
  lkf: {
    "en-US": [
      "Moves internally within the company",
      "Moves to nursing home",
      "Moves to student housing in Lund municipality",
      "Moves to a condominium / villa in Lund municipality",
      "Moves to safe housing",
      "Moves to a tenancy in Lund municipality",
      "Moves to the rest of the country",
      "Moving abroad",
      "The rent is too high",
      "Changed family relationships",
      "The studies are over",
      "Does not thrive in the area",
      "Wants a different standard in the apartment"
    ],
    "sv-SE": [
      "Flyttar internt inom bolaget",
      "Flyttar till vårdboende",
      "Flyttar till studentboende i Lund kommun",
      "Flyttar till bostadsrätt/villa i Lund kommun",
      "Flyttar till trygghetsboende",
      "Flyttar till hyresrätt i Lund kommun",
      "Flyttar till övriga landet",
      "Flyttar till utlandet",
      "Hyran är för hög",
      "Ändrade familjeförhållanden",
      "Studierna är slut",
      "Trivs inte i området",
      "Vill ha annan standard i lägenheten"
    ]
  },
  vxb: {
    "en-US": [
      "Moves internally within the company",
      "Moves to nursing home",
      "Moves to student housing in Växjö municipality",
      "Moves to a condominium / villa in Växjö municipality",
      "Moves to safe housing",
      "Moves to a tenancy in Växjö municipality",
      "Moves to the rest of the country",
      "Moving abroad",
      "The rent is too high",
      "Changed family relationships",
      "The studies are over",
      "Does not thrive in the area",
      "Wants a different standard in the apartment"
    ],
    "sv-SE": [
      "Flyttar internt inom bolaget",
      "Flyttar till vårdboende",
      "Flyttar till studentboende i Växjö kommun",
      "Flyttar till bostadsrätt/villa i Växjö kommun",
      "Flyttar till trygghetsboende",
      "Flyttar till hyresrätt i Växjö kommun",
      "Flyttar till övriga landet",
      "Flyttar till utlandet",
      "Hyran är för hög",
      "Ändrade familjeförhållanden",
      "Studierna är slut",
      "Trivs inte i området",
      "Vill ha annan standard i lägenheten"
    ]
  }
}
