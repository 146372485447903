const Device = (item) => ({
  deviceId: item.deviceId,
  deviceName: item.deviceName,
  displayName: item.displayName,
  deviceType: item.deviceType,
  status: {
    ...(typeof item.status.isOn === 'boolean' && { isOn: item.status.isOn }),
    ...(item.status.brightness && { brightness: item.status.brightness }),
    ...(Array.isArray(item.status.isOn) && {
      relay1: item.status.isOn[0],
      relay2: item.status.isOn[1],
    }),
  },
});

export const mapDataForClient = (item) => ({
  id: item.id,
  name: item.name,
  enabled: item.enabled,
  cronStart: item.cronStart,
  cronEnd: item.cronEnd,
  lightGroupId: item.lightGroupId,
  lightGroupName: item.lightGroupName,
  devices: item.devices?.map(device => Device(device)),
});
