import React from 'react';
import { Form } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Radio } from 'components/form-elements/radios/Radio';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const OtherPhone = ({ disabled, activeTelephone, onActivate, onChange }) => {
  const [telephone, setTelephone] = React.useState('');

  const intl = useIntl();

  const onChangeTel = React.useCallback((telNumber) => {
    onChange(telNumber, 'other');
    setTelephone(telNumber);
  }, [onChange]);

  React.useEffect(() => {
    if (!activeTelephone) {
      setTelephone('');
    }
  }, [activeTelephone]);

  return (
    <Form.Field disabled={disabled}>
      <div className={styles.otherPhone}>
        <Radio
          toggle
          name="telephone"
          value=""
          label={intl.formatMessage({ id: 'another' })}
          onChange={() => onActivate('other', telephone)}
          checked={activeTelephone === 'other'}
        />

        <ShouldRender condition={activeTelephone === 'other'}>
          <Form.Input
            placeholder=""
            value={telephone}
            name="other-phone-number"
            onChange={(e) => onChangeTel(e.target.value)}
          />
        </ShouldRender>
      </div>
    </Form.Field>
  );
};
