import React, { useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { useWaterUsage } from 'services/water-usage';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { WaterUsageIcon } from 'assets/icons/brikks/WaterUsageIcon';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { FormattedMessage } from 'react-intl';
import ReactTooltip from 'react-tooltip';
import { currentMonthPeriod } from 'utils/water-usage/datePeriods';
import { WaterUsagePreview, WaterUsageModal } from './components';
import styles from 'components/brikks/sustainability/WaterUsage/styles.module.scss';

export const WaterUsage = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const { reportAfter, reportBefore } = currentMonthPeriod();
  const { waterUsage, isLoading, isError } = useWaterUsage(reportAfter, reportBefore);
  const isChromium = !!window.chrome;

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(prevIsInfoModalOpen => !prevIsInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          disabled={!!isError}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<WaterUsageIcon />}
            heading="waterUsageBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <WaterUsagePreview
              waterUsage={waterUsage}
              error={isError}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              disabled={!!isError}
              brikkNameIntl="waterUsageBrikk"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="Water consumption info"
        showModal={isInfoModalOpen}
        onClick={handleInfoModal}
      />

      <BrikkModal
        twoGrid
        isOpen={isModalOpen}
        heading="waterUsage"
        onClose={() => setIsModalOpen(false)}
      >
        <WaterUsageModal />
        <ReactTooltip
          id="waterTrendTooltip"
          offset={isChromium ? { top: 50, left: 50 } : null}
          className={styles.tooltip}
        >
          <FormattedMessage id="waterUsageTrendTooltip" />
        </ReactTooltip>
      </BrikkModal>
    </>
  );
};
