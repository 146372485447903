import { takeLatest, put, call, select } from 'redux-saga/effects';
import {
  SubmitMaintenanceOrderActions,
  submitMaintenanceOrderFail,
  submitMaintenanceOrderSuccess
} from './actions';
import { submitMaintenanceOrderAPI } from './api';

function* submitMaintenanceOrder({ payload }) {
  try {
    const { orderRef, maintenanceOrder } = payload;
    const { locale } = yield select(state => state.locale);
    yield call(submitMaintenanceOrderAPI, orderRef, maintenanceOrder, locale);

    yield put(submitMaintenanceOrderSuccess());
  } catch (e) {
    yield put(submitMaintenanceOrderFail(e.message));
  }
}

export const submitMaintenanceOrderEffects = [
  takeLatest(SubmitMaintenanceOrderActions.SUBMIT_MAINTENANCE_ORDER, submitMaintenanceOrder),
];
