import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { ShieldHome } from 'assets/icons/brikks/ShieldHome';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { Image } from 'components/media/Image';
import { DemoModal } from 'components/modals/DemoModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { MultiStep } from 'components/MultiStep';
import brikkPreviewSrc from './assets/security-brikk-preview.png';
import { SecurityOverview } from './SecurityOverview';
import { SecurityOptions } from './SecurityOptions';

export const Security = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => { 
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<ShieldHome />}
            heading="securityBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <Image src={brikkPreviewSrc} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="security"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Security info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <DemoModal
        isOpen={isModalOpen}
        heading="security"
        onClose={() => setIsModalOpen(false)}
      >
        <MultiStep>
          <MultiStep.Step> 
            <SecurityOverview />
          </MultiStep.Step>

          <MultiStep.Step>
            <SecurityOptions />
          </MultiStep.Step>
        </MultiStep>
      </DemoModal>
    </>
  );
};
 
