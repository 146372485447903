/* eslint-disable max-len */
import customerServiceSrc from 'assets/media/rib/general-info/customerServiceInfoRIB.png';
import MittRiksbyggenSrc from 'assets/media/rib/general-info/MittRiksbyggen.png';
import condominiumAdviceSrc from 'assets/media/rib/general-info/condominiumAdvice.png';
import lampSocketSrc from 'assets/media/rib/general-info/lampSocket.png';
import parkingRIBBrfSrc from 'assets/media/rib/general-info/parkingRIBBrf.png';
import { GeneralInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/RIBGeneralInfo/components/GeneralInfo';

export const GeneralInfoOptions = [
  { 
    menu: 'welcomeToRIB',
    submenus: [
      { title: 'reasonsForRIB', content: GeneralInfo, name: '10 goda skäl att välja Riksbyggen', category: 'welcome' },
      { title: 'customerServiceInfoRIB', content: GeneralInfo, contentHeader: 'customerServiceInfoRIBHeading', intl: 'customerServiceInfoRIBContent', contentImage: customerServiceSrc },
      { title: 'myRIB', content: GeneralInfo, contentHeader: 'myRIBHeading', intl: 'myRIBContent', contentImage: MittRiksbyggenSrc },
    ],
  },
  {
    menu: 'theCondominium',
    submenus: [
      { title: 'condominiumAdvice', content: GeneralInfo, contentHeader: 'condominiumAdviceHeading', intl: 'condominiumAdviceContent', intlSecondHalf: 'condominiumAdviceContent2', contentImage: condominiumAdviceSrc },
      { title: 'tenantOwnerAssociationMember', content: GeneralInfo, name: 'Medlem i bostadsrättsföreningen uppslag', category: 'owners' },
      { title: 'maintenanceResponsibleness', content: GeneralInfo, name: 'Vem svarar för underhållet', category: 'owners' },
      { title: 'catAndDogRights', content: GeneralInfo, name: 'Vett och Etikett om hund och katt', category: 'owners' },
    ],
  },
  {
    menu: 'tenantOwnerAssociationInfo',
    submenus: [
      { title: 'boardRIB', content: GeneralInfo, name: 'RBF Ribbhusen styrelsen', category: 'information' },
    ],
  },
  {
    menu: 'financialPlanAndStatutes',
    submenus: [
      { title: 'economicPlan', content: GeneralInfo, name: 'EkonomiskPlan', category: 'finance' },
      { title: 'statutes', content: GeneralInfo, name: 'Stadgar', category: 'finance' },
    ],
  },
  {
    menu: 'tenantOwnerSupplement',
    submenus: [
      { title: 'infoBRF', content: GeneralInfo, intl: 'infoBRFContent' },
      { title: 'condominiumInsuranceBRF', content: GeneralInfo, name: "Informationsblad BRf rs'kring bostadsrättsförsäkring", category: 'additions' },
      { title: 'contactCenter', content: GeneralInfo, name: 'Kontaktcenter trapphus', category: 'additions' },
    ],
  },
  {
    menu: 'electricalAndFireSafety',
    submenus: [
      { title: 'lampSocket', content: GeneralInfo, intl: 'lampSocketContent', contentImage: lampSocketSrc },
      { title: 'riskOfFire', content: GeneralInfo, name: 'Minska risken för brand', category: 'safety' },
    ],
  },
  {
    menu: 'tagsAndStorage',
    submenus: [
      { title: 'keysAndTagsBRF' },
    ],
  },
  { 
    menu: 'smartHome',
    submenus: [
      { title: 'cisco9120', content: GeneralInfo, name: 'Cisco 9120 - Accesspunkt', category: 'smart-home' },
      { title: 'gigamedia', content: GeneralInfo, name: 'Datablad Gigamedia Väggskåp', category: 'smart-home' },
      { title: 'speaker', content: GeneralInfo, name: 'Högtalare', category: 'smart-home' },
      { title: 'kamera', content: GeneralInfo, name: 'Kamera', category: 'smart-home' },
      { title: 'Larmdon', content: GeneralInfo, name: 'Larmdon', category: 'smart-home' },
      { title: 'intercom', content: GeneralInfo, name: 'Porttelefon', category: 'smart-home' },
      { title: 'motionDetector', content: GeneralInfo, name: 'Rörelsedetektor 1', category: 'smart-home' },
      { title: 'shellyDimmer', content: GeneralInfo, name: 'Shelly dimmer', category: 'smart-home' },
      { title: 'shellyDimmer1', content: GeneralInfo, name: 'Shelly dimmer 1', category: 'smart-home' },
      { title: 'shellyDimmer2', content: GeneralInfo, name: 'Shelly dimmer 2', category: 'smart-home' },
      { title: 'simonVoss', content: GeneralInfo, name: 'Simon Voss cylinder', category: 'smart-home' },
    ],
  },
  {
    menu: 'parkingPlan',
    submenus: [
      { title: 'parkingRIBBrf', content: GeneralInfo, contentHeader: 'parkingRIBBrfHeading', intl: 'parkingRIBBrfContent', contentImage: parkingRIBBrfSrc },
    ],
  },
  {
    menu: 'planetJoin',
    submenus: [
      { title: 'sustainabilitySheetRIB', content: GeneralInfo, name: 'Hållbarhetsblad Ribbhusen', category: 'sustainability' },
      { title: 'sortingGuideEnglish', content: GeneralInfo, name: 'Källsorteringsguide engelska', category: 'sustainability' },
      { title: 'sortingGuideSwedish', content: GeneralInfo, name: 'Källsorteringsguide svenska', category: 'sustainability' },
      { title: 'wheelNSR', content: GeneralInfo, name: 'NSR-Pricka Ratt', category: 'sustainability' },
      { title: 'brfRIBWelcomeOCG', content: GeneralInfo, name: 'OGC Brf Ribbhusen Valkommen', category: 'sustainability' },
      { title: 'brfRIBOCG', content: GeneralInfo, name: 'OGC Brf Ribbhusen', category: 'sustainability' },
    ],
  },
  {
    menu: 'kitchen',
    submenus: [
      { title: 'marbodal' },
    ],
  },
  {
    menu: 'bathToiletLaundry',
    submenus: [
      { title: 'picto' },
      { title: 'handdukshängare' },
      { title: 'towelDryerPaxSamba' },
      { title: 'MonteringsanvisningKrokOchTapapperhallare' },
      { title: 'skötselrådSvedbergs' },
      { title: 'showerWallInstructions' },
      { title: 'toalettpappershallare' },
      { title: 'wcSol' },
    ],
  },
  {
    menu: 'appliancesSerialNo', 
    submenus: [
      { title: 'electroluxDiskmaskinHelintegrerad' },
      { title: 'electroluxFryssk' },
      { title: 'electroluxInbyggnadsugn' },
      { title: 'electroluxInbyggnadsugnRostfri' },
      { title: 'ectroluxInduktionsh' },
      { title: 'ElectroluxKylsk' },
      { title: 'electroluxMikrov' },
      { title: 'electroluxTorktumlare' },
      { title: 'electroluxTvättmaskin' },
      { title: 'electroluxAppStyrdInbyggnadsugnMedKamera' },
      { title: 'apolloTrendBruksanvisning' },
      { title: 'apolloTrendProduktblad' },
      { title: 'samsungMikrovågsugn' },
      { title: 'samsungInbyggnadsh' },
      { title: 'samsungInbyggnadsugn' },
      { title: 'samsungKylOchFrys' },
      { title: 'samsungTorktumlare' },
      { title: 'samsungTvättmaskin' },
      { title: 'simensDiskmaskin' },
      { title: 'vitvarorserienummer' },
    ],
  },
  {
    menu: 'wardarobesAndStorage',
    submenus: [
      { title: 'elfaStedsanvisning' },
      { title: 'hatthyllaProduktblad' },
      { title: 'hatthyllaSkötselanvisning' },
      { title: 'marbodalStedsanvisning' },
      { title: 'medicinskåp' },
    ],
  },
  {
    menu: 'windowsDoorsFittings',
    submenus: [
      { title: 'elitfønsterSkySelvanvisning' },
      { title: 'elslutbleck' },
      { title: 'fensterbankSkøtselOchUnderhåll' },
      { title: 'fensterbankStedsanvisning' },
      { title: 'kodhandtagFönster' },
      { title: 'magnetkontaktProduktblad' },
      { title: 'skötselinstruktionTryckeOchBehör' },
      { title: 'swedoorDriftOgUnderhåll' },
    ],
  },
  {
    menu: 'ceilingsWallsFloors',
    submenus: [
      { title: 'aTABtrappanSkySelvanvisning' },
      { title: 'kakelOchKlinkerSkötselråd' },
      { title: 'kareliaEkNaturalMattlack' },
      { title: 'kareliaEkNaturalVanillaMatt' },
      { title: 'skjøtselTarkettParkett' },
      { title: 'tvättrådFörMåladeVäggar' },
      { title: 'underhållsrådFörMåladeVäggar' },
    ],
  },
  {
    menu: 'patio',
    submenus: [
      { title: 'produktbladklassikplattan' },
      { title: 'skärmtakGodaRådOmGlas' },
      { title: 'skötselMarkstenplattor' },
      { title: 'skøtselrådMarkbetong' },
      { title: 'växtförteckningSkötselanvisningarRib' },
      { title: 'virkeLasyrfärgTrä' },
    ],
  },
];

