import React from 'react';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { NavLink } from 'components/links/NavLink';
import { useScreenSize } from 'utils/screen-size';
import { SCREEN_XL } from 'global/screenBreakpoints';

export const Menu = ({ icon, name, description, to }) => {
  const match = useRouteMatch({ path: to });

  const { width } = useScreenSize();

  return (
    <NavLink
      activeClassName={styles.active}
      to={to}
    >
      <div className={styles.menu}>
        <div className={classNames(styles.icon, !!match && styles.active)}>
          {icon}
        </div>

        <div className={styles.text}>
          <Text
            element="span"
            xs={width <= SCREEN_XL}
            bold
            intl={name}
            primary={!!match}
          />

          <div className={styles.description}>
            <Text
              element="span"
              xs={width <= SCREEN_XL}
              mediumxs
              intl={description}
              primary={!!match}
            />
          </div>
        </div>
      </div>
    </NavLink>
  );
}
