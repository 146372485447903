import React from "react";
import {Grid, Message, Card} from "semantic-ui-react";
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../components/headers/PageHeader';
import classNames from 'classnames';
import styles from './LogIn/styles.module.scss';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';

const ChangePasswordConfirmation = (props) => (
  <>
    <IntlPageTitle intlTitle="passwordRecoverySuccess" />

    <PageHeader transparent noLogin />
    <Grid textAlign='center' verticalAlign='middle' className={classNames('brikks-auth login', styles.login)}>

        <Grid.Column className={classNames('auth-wrapper', styles.grid)}>
            <Card raised>

                <div className='image-column' />

                <div className='form-column'>

                    <Message positive>

                        <Message.Header>
                            <p>
                              <FormattedMessage id="passwordRecoverySuccess" />
                            </p>
                            <p>
                                <a href='/login'
                                   onClick={ (e) => {
                                       e.preventDefault();
                                       props.history.push('/login')
                                   }}
                                >
                                  <FormattedMessage id="backToLogin" />
                                </a>
                            </p>
                        </Message.Header>

                    </Message>
                </div>
            </Card>
        </Grid.Column>

    </Grid>
  </>
);

export default ChangePasswordConfirmation;
