import React from 'react';
import { Onboarding } from 'pages/onboarding/Onboarding';
import { ONBOARDING_WELCOME } from 'global/routes';
import { Route } from 'react-router-dom';
import { CheckYourInfo } from '../OnboardingUserInfo';
import { Welcome } from './components/Welcome';
import { RIBOnboardingRoutes } from './routes';
import { SafestTownhouse } from './components/SafestTownhouse/SafestTownhouse';
import { SimplerEveryday } from './components/SimplerEveryday/SimplerEveryday';
import { OrdinaryHouseButSafer } from './components/OrdinaryHouseButSafer/OrdinaryHouseButSafer';

export const RIBOnboarding = () => (
  <Onboarding>
    <Route
      exact
      path={ONBOARDING_WELCOME}
      component={Welcome}
    />

    <Route
      exact
      path={RIBOnboardingRoutes.SAFEST_TOWNHOUSE}
      component={SafestTownhouse}
    />

    <Route
      exact
      path={RIBOnboardingRoutes.SIMPLER_EVERYDAY}
      component={SimplerEveryday}
    />

    <Route
      exact
      path={RIBOnboardingRoutes.ORDINARY_HOUSE_BUT_SAFER}
      component={OrdinaryHouseButSafer}
    />

    <Route
      exact
      path={RIBOnboardingRoutes.YOUR_INFO}
      component={CheckYourInfo}
    />
  </Onboarding>
);
