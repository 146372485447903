import React from 'react';
import { MediumCard } from 'components/cards/MediumCard';
import { ImageContent } from 'components/layout/ImageContent';

export const ImageContentCard = ({ imageSrc, children }) => (
  <MediumCard>
    <ImageContent imageSrc={imageSrc}>
      {children}
    </ImageContent>
  </MediumCard>
);
