import React from 'react';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { Image } from 'components/media/Image';
import kitchenImg from 'assets/media/kitchen-cleaning-and-maintenance.png';

export const Kitchen = () => {
  return (
    <Brikk.Heading id="kitchen" heading="kitchen">
      <Image src={kitchenImg} />
      <Text size={14} intl={'kitchenDescription'} />
    </Brikk.Heading>
  );
};
