import React, { createContext, useEffect, useState } from 'react';
import { getUserFromToken, isTokenValid, removeAuthToken } from 'utils/auth-token';
import { Auth } from 'aws-amplify';
import { useLocale } from 'services/user/locale/hook';
import { useUserSettings } from 'services/user/settings/hook';
import { TENANT_ID } from 'global/environment';
import { OrderModeProvider } from 'services/order/order-mode/hook';

export const AppContext = createContext(null);

export const ContextProvider = ({ children }) => {
  const [shouldReorderBrikks, setShouldReorderBrikks] = useState(false);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authLoading, setAuthLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [tenant, setTenant] = useState(null);

  const [selectedTheme, setSelectedTheme] = useState(TENANT_ID);

  const userSettings = useUserSettings(isAuthenticated);

  const locale = useLocale(isAuthenticated);

  useEffect(() => {
    async function authorizeUser() {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        if (JSON.parse(cognitoUser.attributes['custom:data'])[TENANT_ID]) {
          setUser(cognitoUser);
          setTenant(JSON.parse(cognitoUser.attributes['custom:data'])[TENANT_ID]?.tenant);
          setIsAuthenticated(true);
        }
      } catch (error) {
        const userAttributes = await getUserFromToken();
        if (userAttributes) {
          if (isTokenValid(userAttributes.exp)) {
            setUser({ attributes: userAttributes });
            setIsAuthenticated(true);
          } else {
            removeAuthToken();
          }
        }
      }
    }
    authorizeUser().then(() => setAuthLoading(false));
  }, []);

  return (
    <AppContext.Provider
      value={{
        shouldReorderBrikks,
        setShouldReorderBrikks,
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        locale,
        userSettings,
        isSideMenuOpen,
        setIsSideMenuOpen,
        selectedTheme, 
        setSelectedTheme,
        authLoading,
        tenant,
        setTenant,
      }}
    >
      <OrderModeProvider>
        {children}
      </OrderModeProvider>
    </AppContext.Provider>
  );
};
