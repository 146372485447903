import React from 'react';

export const HistoricalBuildingIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.997" viewBox="0 0 20 19.997">
            <g id="historical-building-pillar" transform="translate(0.001 0)">
                <path id="Path_7634" data-name="Path 7634" d="M8.5,9.51a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V10.51A1,1,0,0,0,8.5,9.51Z" transform="translate(-1.365 -4.003)" />
                <path id="Path_7635" data-name="Path 7635" d="M12,9.51a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V10.51A1,1,0,0,0,12,9.51Z" transform="translate(-2.001 -4.003)" />
                <path id="Path_7636" data-name="Path 7636" d="M15.5,9.51a1,1,0,0,0-1,1V23a1,1,0,0,0,2,0V10.51A1,1,0,0,0,15.5,9.51Z" transform="translate(-2.638 -4.003)" />
                <path id="Path_7637" data-name="Path 7637" d="M16.248,0H3.749A3.851,3.851,0,0,0,.2,3.027a5.043,5.043,0,0,0,1.252,5,2.891,2.891,0,0,0,1.75.625,1.913,1.913,0,0,0,1.59-.664,2.792,2.792,0,0,0,.683-1.846A1.406,1.406,0,0,0,4.569,4.77a.678.678,0,0,0-.79.586.59.59,0,0,1-.823.388.815.815,0,0,1-.323-.988A1.875,1.875,0,0,1,4.823,3.3,2.873,2.873,0,0,1,6.729,6.146a4.568,4.568,0,0,1-.158,1.2.584.584,0,0,0,.059.448A.4.4,0,0,0,6.966,8h6.064a.4.4,0,0,0,.337-.206.584.584,0,0,0,.059-.449,4.568,4.568,0,0,1-.158-1.2A2.873,2.873,0,0,1,15.174,3.3a1.876,1.876,0,0,1,2.191,1.46.815.815,0,0,1-.323.988.59.59,0,0,1-.823-.388.678.678,0,0,0-.79-.586,1.4,1.4,0,0,0-.91,1.376A2.792,2.792,0,0,0,15.2,8a1.913,1.913,0,0,0,1.59.664,2.891,2.891,0,0,0,1.75-.625A5.041,5.041,0,0,0,19.8,3.03,3.851,3.851,0,0,0,16.248,0Z" transform="translate(0)" />
            </g>
        </svg>
    )
}