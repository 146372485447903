import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CheckMarkIcon = ({ size = 25, active, activeGreen, activeGrey }) => (
  <svg
    style={{ width: size }}
    className={classNames(
      styles.icon, {
        [styles.active]: active,
        [styles.activeGrey]: activeGrey,
        [styles.activeGreen]: activeGreen,
      }
    )}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.977 25.977"
  >
    <path
          d="M12.988,0A12.988,12.988,0,1,0,25.977,12.988,12.988,12.988,0,0,0,12.988,0Zm7.5,8.875L13.077,18.93a1.094,1.094,0,0,1-1.548.2L6.239,14.9a1.083,1.083,0,1,1,1.353-1.691L12,16.742l6.74-9.147a1.082,1.082,0,1,1,1.743,1.28Z"/>
  </svg>
);
