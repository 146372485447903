import { authorize, getRequest } from 'utils/http';
import { mapDataForClient } from './mapData';
import { AWS_API_URL } from 'global/environment';

export async function getAgreements() {
  try {
    const agreements = await getRequest(`${AWS_API_URL}/agreements`, await authorize());
    return Promise.resolve(
      agreements.map((agreement) => mapDataForClient(agreement))
    );
  } catch (error) {
    return Promise.reject(error);
  }
}
