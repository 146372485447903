import React from 'react';
import classNames from 'classnames';
import { FocusableElement } from 'components/FocusableElement';
import styles from './styles.module.scss';

export const Field = ({ onClick, fieldName, field, selectedField }) => (

  <div className={styles.fields}>
      
    <FocusableElement
      className={classNames(styles.item, (field === selectedField) && styles.active)}
      onClick={onClick}
    >
      {fieldName}
        
    </FocusableElement>
  </div>
);
