import { DownloadAgreementActions } from './actions';

const initialState = {
  loading: {},
  error: {},
};

export function downloadAgreementReducer(state = initialState, action) {
  switch (action.type) {
    case DownloadAgreementActions.DOWNLOAD_AGREEMENT:
      return {
        ...state,
        loading: { ...state.loading, [action.payload]: true },
        error: { ...state.error, [action.payload]: '' },
      };
    case DownloadAgreementActions.DOWNLOAD_AGREEMENT_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [action.payload]: false },
      };
    case DownloadAgreementActions.DOWNLOAD_AGREEMENT_FAIL:
      const { error, agreementNumber } = action.payload;
      return {
        ...state,
        loading: { ...state.loading, [agreementNumber]: false },
        error: { ...state.error, [agreementNumber]: error },
      };
    default:
      return state;
  }
}
