import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getLightningDevicesAPI } from './api';

const LIGHTNING_DEVICES = 'lightningDevices';

export function useLightningDevices() {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { data, isLoading, isFetching, isError } = useQuery(
    [LIGHTNING_DEVICES, tenantId],
    () => getLightningDevicesAPI(),
  );

  return {
    lightningDevices: data?.results || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
