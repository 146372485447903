import React from 'react';

export const WifiSignalIcon = () => {
    return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24.595"
          height="18"
          viewBox="0 0 24.595 18"
        >
            <g
              id="wifi-signal-1"
              transform="translate(-0.006 -3.22)"
            >
                <circle
                  id="Ellipse_375"
                  data-name="Ellipse 375"
                  cx="1.538"
                  cy="1.538"
                  r="1.538"
                  transform="translate(10.765 10.682)"
                />

                <path
                  id="Path_7941"
                  data-name="Path 7941"
                  d="M15.579,8.469a1.025,1.025,0,1,0-1.45,1.45,3.081,3.081,0,0,1,0,4.35,1.025,1.025,0,0,0,1.45,1.451,5.135,5.135,0,0,0,0-7.251Z"
                  transform="translate(0.35 0.125)"
                />

                <path
                  id="Path_7942"
                  data-name="Path 7942"
                  d="M9.951,8.469a1.025,1.025,0,0,0-1.45,0,5.135,5.135,0,0,0,0,7.251,1.025,1.025,0,0,0,1.45-1.451,3.081,3.081,0,0,1,0-4.35,1.025,1.025,0,0,0,0-1.45Z"
                  transform="translate(0.177 0.125)"
                />

                <path
                  id="Path_7943"
                  data-name="Path 7943"
                  d="M18.053,5.994a1.025,1.025,0,0,0-1.446,1.448,6.664,6.664,0,0,1,0,9.424,1.025,1.025,0,1,0,1.45,1.45,8.715,8.715,0,0,0,0-12.324Z"
                  transform="translate(0.412 0.064)"
                />

                <path
                  id="Path_7944"
                  data-name="Path 7944"
                  d="M5.551,12.158A6.618,6.618,0,0,1,7.5,7.442,1.025,1.025,0,0,0,6.053,5.994a8.715,8.715,0,0,0,0,12.324A1.025,1.025,0,1,0,7.5,16.868a6.618,6.618,0,0,1-1.948-4.71Z"
                  transform="translate(0.088 0.064)"
                />

                <path
                  id="Path_7945"
                  data-name="Path 7945"
                  d="M20.528,3.52a1.025,1.025,0,1,0-1.45,1.45,10.264,10.264,0,0,1,0,14.5,1.025,1.025,0,1,0,1.45,1.45,12.317,12.317,0,0,0,0-17.4Z"
                  transform="translate(0.475 0)"
                />

                <path
                  id="Path_7946"
                  data-name="Path 7946"
                  d="M5.054,4.97A1.025,1.025,0,1,0,3.6,3.52a12.317,12.317,0,0,0,0,17.4,1.025,1.025,0,1,0,1.45-1.45,10.264,10.264,0,0,1,0-14.5Z"
                  transform="translate(0)"
                />
            </g>
        </svg>
    )
}
