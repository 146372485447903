import React from 'react';
import classNames from 'classnames';
import { Input as SemanticInput } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const Input = ({
  id,
  value,
  name,
  className,
  onChange,
  disabled,
  placeholder,
  autoFocus,
  hasError,
}) => (
  <SemanticInput
    className={classNames(styles.input, className, hasError && styles.error)}
    error={hasError}
    id={id}
    value={value}
    name={name}
    placeholder={placeholder}
    disabled={disabled}
    autoFocus={autoFocus}
    onChange={onChange}
  />
);
