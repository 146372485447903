import React, { useContext } from 'react';
import styles from 'components/dropdowns/Dropdown/components/SubItem/styles.module.scss';
import { DropdownContext } from 'components/dropdowns/Dropdown/index';
import { FocusableElement } from 'components/FocusableElement';
import classNames from 'classnames';

export const SubItem = ({ children, disabled, onClick}) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  const handleOnClick = () => {
    onClick ? onClick() : setIsOpen(!isOpen);
  }

  return (
    <FocusableElement
      className={classNames(styles.subItem, disabled && styles.disabled)}
      onClick={!disabled ? () => handleOnClick() : undefined}
    >
      {children}
    </FocusableElement>
  )
}
