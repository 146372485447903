import React, { useEffect, useState } from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductImages/styles.module.scss';
import { Icon } from 'semantic-ui-react';
import { Image } from 'components/media/Image';
import { FocusableElement } from 'components/FocusableElement';
import { Text } from 'components/typography/Text';

export const ProductImages = ({ selectedProduct, products, selectedProductIndex, setSelectedProductIndex }) => {
  const [selectedImage, setSelectedImage] = useState(selectedProduct?.imageUrls?.[0]);

  useEffect(() => {
    setSelectedImage(selectedProduct?.imageUrls?.[0]);
  }, [selectedProduct]);

  const images = selectedProduct?.imageUrls?.map(image => (
    <FocusableElement
      key={image}
      onClick={() => setSelectedImage(image)}
    >
      <Image
        src={image}
        alt={selectedProduct?.name}
        className={styles.imageOptions}
      />
    </FocusableElement>
  ))

  const leftArrow = (
    <FocusableElement className={styles.iconContainer}>
      <Icon
        className={styles.icon}
        name="chevron left"
        disabled={selectedProductIndex === 0}
        onClick={() => setSelectedProductIndex(selectedProductIndex - 1)}
      />

      <Text
        center
        intl="previousProduct"
      />
    </FocusableElement>
  );

  const rightArrow = (
    <FocusableElement className={styles.iconContainer}>
      <Icon
        className={styles.icon}
        name="chevron right"
        disabled={selectedProductIndex === products.length - 1}
        onClick={() => setSelectedProductIndex(selectedProductIndex + 1)}
      />

      <Text
        center
        intl="nextProduct"
      />
    </FocusableElement>
  )

  return (
    <div className={styles.productImages}>
      <div className={styles.desktopArrow}>
        {leftArrow}
      </div>

      <div className={styles.imagesContainer}>
        <Image
          className={styles.selectedImage}
          src={selectedImage}
          alt={selectedProduct?.name}
        />

        <div className={styles.imageSelector}>
          {images}
        </div>
      </div>

      <div className={styles.desktopArrow}>
        {rightArrow}
      </div>

      <div className={styles.mobileArrows}>
        {leftArrow}
        {rightArrow}
      </div>
    </div>
  );
};
