import React, {useEffect} from 'react';
import Popup from 'reactjs-popup';
import { SCREEN_SM } from 'global/screenBreakpoints';
import { useScreenSize } from 'utils/screen-size';

export const BaseModal = ({ children, isOpen, closeOnOutsideClick, nested, onClose }) => {
  const { width } = useScreenSize();

  useEffect(() => () => {
    document.body.style.overflow = 'auto';
  }, []);

  const overlayStyles = {
    padding: width < SCREEN_SM  ? '15px 15px' :  '15px 50px',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    overflowY: 'auto',
  }

  const contentStyles = {
    width: nested ? 'fit-content' : '100%',
  }

  return (
    <Popup
      modal
      nested
      lockScroll
      overlayStyle={overlayStyles}
      contentStyle={contentStyles}
      closeOnDocumentClick={closeOnOutsideClick}
      closeOnEscape={closeOnOutsideClick}
      onClose={onClose}
      open={isOpen}
    >
      {children}
    </Popup>
  );
}
