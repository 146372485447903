import React from 'react';
import classNames from 'classnames';
import styles from 'components/dropdowns/SingleDropdown/styles.module.scss';
import { Item } from 'components/dropdowns/SingleDropdown/components/Item';
import { Content } from 'components/dropdowns/SingleDropdown/components/Content';

export const SingleDropdown = ({ children }) => (
  <div className={classNames(styles.dropdown)}>
    {children}
  </div>
);

SingleDropdown.Item = Item;
SingleDropdown.Content = Content;
