import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const BaseButton = ({
  children,
  name,
  disabled,
  loading,
  className,
  onClick,
}) => (
  <button
    className={classNames(styles.button, className)}
    name={name}
    disabled={disabled || loading}
    onClick={onClick}
  >
    {children}
  </button>
);
