import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Card } from 'components/cards/Card';
import { Select } from 'components/form-elements/selects/Select';
import { Grid } from 'components/layout/Grid';
import { ShouldRender } from 'components/ShouldRender';
import { useLightningScenarios } from 'services/lightning/scenarios';
import { EditScenario } from 'components/brikks/smartness/Lights/components/LightningScenarios/components/EditScenario';

export const ManageScenarios = () => {
  const intl = useIntl();
  const [selectedScenario, setSelectedScenario] = useState();
  const { lightningScenarios, isLoading } = useLightningScenarios();

  const scenarios = useMemo(() => lightningScenarios.map(item => ({
    value: item.id,
    label: item.name,
    ...item,
  })), [lightningScenarios]);

  return (
    <Grid
      oneColumn
      noPadding
      style={{ gap: 10 }}
    >
      <Card grey>
        <Select
          placeholder={intl.formatMessage({ id: 'selectScenario' })}
          options={scenarios}
          onChange={(value) => setSelectedScenario(value)}
          value={selectedScenario}
          isClearable
          isLoading={isLoading}
        />
      </Card>

      <ShouldRender condition={!!selectedScenario}>
        <EditScenario
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
        />
      </ShouldRender>
    </Grid>
  ); 
};
