import { takeLatest, call, put, delay } from 'redux-saga/effects';
import { ResetPassActions, resetPassFail, resetPassReset, resetPassSuccess } from './actions';
import { resetPassAPI } from './api';
import { confirmEmailSuccess } from '../confirm-email/actions';

function* resetPassEffect({ payload: { email, password, code } }) {
  try {
    yield call(resetPassAPI, email, password, code);
    yield put(resetPassSuccess());

    yield delay(100);
    yield put(resetPassReset());
    yield put(confirmEmailSuccess());
  } catch (e) {
    if (e.code === 'CodeMismatchException') {
      yield put(resetPassFail('invalidCode'));
    } else {
      yield put(resetPassFail('genericErrorMessage'));
    }
  }
}

export const resetPassEffects = [
  takeLatest(ResetPassActions.RESET_PASS, resetPassEffect),
];
