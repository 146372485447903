import React from 'react';
import { Route } from 'react-router-dom';
import { Onboarding } from 'pages/onboarding/Onboarding';
import { ONBOARDING_WELCOME, ONBOARDING_YOUR_INFO } from 'global/routes';
import { CheckYourInfo } from '../OnboardingUserInfo';
import { Welcome } from './components/Welcome';

export const GRTOnboarding = () => (
  <Onboarding> 
    <Route
      exact
      path={ONBOARDING_WELCOME}
      component={Welcome}
    />

    <Route
      exact
      path={ONBOARDING_YOUR_INFO}
      component={CheckYourInfo}
    />
  </Onboarding>
);
