const MEASURES = {
  Exchange: "Utbyte",
  Sanded: "Slipas/lackas",
  Painted: "Målas",
  Change: "Bytes",
  Wallpapered: "Tapetseras"
};

const NOW = new Date();

const advancedMaintenance = (plannedDate) => NOW < new Date(plannedDate);

export { MEASURES, advancedMaintenance }
