import React from 'react';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const Button = ({ intl, title }) => (
  <div className={styles.button}>
    <Text
      dark
      bold
      intl={intl}
    >
      {title}
    </Text>
  </div>
);
