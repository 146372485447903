import React from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary/components/UpgradeCost/styles.module.scss';
import { Text } from 'components/typography/Text';
import { DiscardIcon } from 'assets/icons/DiscardIcon';
import { TotalCost } from 'components/item/TotalCost';
import { formatCurrency } from "utils/format";

export const UpgradeCost = ({ selectedPurchases, setSelectedPurchases }) => {
  const selectedPurchasesKeys = Object.keys(selectedPurchases);

  const onRemoveItem = (itemKey) => {
    setSelectedPurchases(selectedPurchases => {
      const copyOfSelectedPurchases = { ...selectedPurchases };
      delete copyOfSelectedPurchases[itemKey];
      return copyOfSelectedPurchases;
    })
  }

  return (
    selectedPurchasesKeys.length ? (
      <div
        id="maintenanceCost"
        className={styles.maintenanceCost}
      >
        <div className={styles.addedItems}>
          {selectedPurchasesKeys.map(key => (
            <div
              key={key}
              className={styles.addedItem}
            >
              <Text className={styles.itemName}>
                {key} ({selectedPurchases[key]?.selectedProduct?.name} - {selectedPurchases[key]?.selectedProduct?.code})
              </Text>

              <div className={styles.costAndRemove}>
                <Text>
                  {formatCurrency(selectedPurchases[key].selectedSurface.residualValue)} KR
                </Text>

                <DiscardIcon
                  size={22}
                  onClick={() => onRemoveItem(key)}
                />
              </div>
            </div>
          ))}
        </div>

        <TotalCost
          totalCost={Object.values(selectedPurchases).reduce(
          (prevSum, { selectedSurface }) => prevSum + selectedSurface.residualValue, 0
          )}
        />
      </div>
    ) : (
      <Text
        size={18}
        intl="maintenanceNoProducts"
      />
    )
  );
};
