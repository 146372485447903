import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { MyHomeIcon } from 'assets/icons/brikks/MyHomeIcon';
import { AccessAppPreview } from 'components/brikks/home/AccessApp/components/AccessAppPreview';
import { AccessAppModal } from 'components/brikks/home/AccessApp/components/AccessAppModal';

export const AccessApp = ({ position }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Draggable position={position}>
        <Brikk>
          <Brikk.Header
            icon={<MyHomeIcon />}
            heading="accessAppBrikk"
          />

          <Brikk.Content>
            <AccessAppPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={() => setIsModalOpen(true)}
              brikkNameIntl="access"
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkModal
        isOpen={isModalOpen}
        heading="accessApp"
        onClose={() => setIsModalOpen(false)}
      >
        <AccessAppModal />
      </BrikkModal>
    </>
  );
};
