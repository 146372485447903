import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from 'store/reducer';
import { rootSaga } from 'store/effect';
import * as ReactQuery from 'react-query';

const sagaMiddleware = createSagaMiddleware();

const reduxDevTools = (window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

function configureStore(preloadedState) {
  const store = createStore(
    rootReducer,
    preloadedState,
    compose(applyMiddleware(sagaMiddleware), reduxDevTools),
  );

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducer', () => store.replaceReducer(rootReducer))
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

export const store = configureStore();

export const queryClient = new ReactQuery.QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity, // Change to milliseconds in case you want to refetch data in the background
      cacheTime: Infinity, // Change to milliseconds in case you want to garbage collect unused data
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
