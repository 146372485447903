import React, { useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { ChangeAdressModal } from 'components/brikks/home/ChangeAdress/components/ChangeAdressModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { MapIcon } from 'assets/icons/brikks/MapIcon';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { Text } from 'components/typography/Text';
import changeAdressImg from 'assets/media/change-adress.png';

export const ChangeAdress = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<MapIcon />}
            heading="changeAdressBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content>
            <CircularImage src={changeAdressImg} alt="Postal boxes" />
            <Text center size={14} uppercase intl={"changeAddress"} />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="changeAddress"
            />
            <InfoButton showModal={isInfoModalOpen} onClick={handleInfoModal} />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Change address info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="changeAddress"
        onClose={() => setIsModalOpen(false)}
      >
        <ChangeAdressModal />
      </BrikkModal>
    </>
  );
};
