import {
  GeneralInfo,
  BankID
} from "components/brikks/home/MyHome/components/LivingInfoModal/components/LivingGeneralInfo/components";

export const GeneralInfoOptions = [
  {
    menu: 'aboutYourLandlord',
    submenus: [
      { title: 'generalInfo', content: GeneralInfo },
    ],
  },
  {
    menu: 'eIDAndSignatures',
    submenus: [
      { title: 'frejaEID', content: null },
      { title: 'bankID', content: BankID },
    ],
  },
];
