import React from "react";
import { TabMenu } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/Menus/components/TabMenu";
import { MenuContainer } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/Menus/components/MenuContainer";

export const Menus = ({ menus }) => (
  <MenuContainer>
    {Object.keys(menus)?.map((keyName) => (
      <TabMenu
        key={keyName}
        menuName={menus[keyName].menu}
        subMenus={menus[keyName].menuDetails}
        disabled={false}
      />
    ))}
  </MenuContainer>
);
