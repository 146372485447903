import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

export async function getSpacesAPI() {
  const url = `${AWS_API_URL}/get-spaces`;

  const data = await getRequest(url, await authorize());
  return mapDataToClient(data);
}
