const Space = (space) => ({
  id: space.id,
  name: space.name,
  address: space.address,
  type: space.typename,
  key: space.key,
  text: space.name,
  value: space.id,
});

export const mapDataToClient = (space) => ({
  public: space.public?.map(publicSpace => Space(publicSpace)),
  private: space.private?.map(privateSpace => Space(privateSpace)),
  service: space.service?.map(privateSpace => Space(privateSpace)),
});

