import React, { useMemo } from 'react';
import styles from 'components/brikks/society/RSSFeed/components/RSSFeedPreview/styles.module.scss';
import { flatten, sortBy } from 'lodash';
import { Feed } from 'components/feed/Feed';
import { Text } from 'components/typography/Text';

export const RSSFeedPreview = ({ feed, onSelectArticle }) => {
  const articles = useMemo(() => {
    if (feed.length) {
      const articles = flatten(feed.map(feed => feed.articles));
      const sortedArticles = sortBy(articles, article => article.datePublished);
      return sortedArticles.reverse();
    }
  }, [feed]);

  return (
    <div className={styles.feedPreview}>
      {articles ? (
        <Feed
          articles={articles}
          onSelectArticle={onSelectArticle}
        />
      ) : (
        <div className={styles.noFeeds}>
          <Text
            xl
            center
            intl="noFeeds"
          />
        </div>
      )}
    </div>
  )
};
