import React from 'react';

export const KeysIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60.431 60.431"
    >
      <g
        id="Group_6509"
        data-name="Group 6509"
        transform="translate(-737.446 -318.231)"
      >
        <g
          id="browser-user-help-message"
          transform="translate(782.946 351.367)"
        >
          <circle
            id="_13465ec411588c767533ccf3f196d100"
            data-name="13465ec411588c767533ccf3f196d100"
            cx="30.216" cy="30.216" r="30.216"
            transform="translate(-45.5 -33.136)"
            fill="#3a3a3a"/>
        </g>

        <g
          id="login-keys"
          transform="translate(753.032 333.445)"
        >
          <path
            id="Path_8254"
            data-name="Path 8254"
            d="M16.5,11.25a7.468,7.468,0,0,0-1.875-4.955V3.125a3.125,3.125,0,1,0-6.25,0v.651A7.5,7.5,0,0,0,6.5,18.314v.8L5.43,20.182a.628.628,0,0,0,0,.885L6.5,22.134v.732L5.43,23.934a.625.625,0,0,0,0,.884L6.5,25.884V27.5a.625.625,0,0,0,.182.442l1.875,1.875a.625.625,0,0,0,.885,0l1.875-1.875A.625.625,0,0,0,11.5,27.5V18.314A7.523,7.523,0,0,0,16.5,11.25ZM9,7.5a1.25,1.25,0,1,1-1.25,1.25A1.25,1.25,0,0,1,9,7.5Zm2.5-6.25a1.875,1.875,0,0,1,1.875,1.875V5.162a7.485,7.485,0,0,0-2.5-1.175V6.25H9.622V3.125A1.875,1.875,0,0,1,11.5,1.25Z"
            transform="translate(0 0)"
            fill="#00b72b"
          />

          <path
            id="Path_8255"
            data-name="Path 8255"
            d="M25.117,21.244l-6.5-6.5a7.432,7.432,0,0,0-2.654-9.49.625.625,0,0,0-.91.785,9.338,9.338,0,0,1-3.467,11.812A.625.625,0,0,0,11.923,19h.007a7.528,7.528,0,0,0,3.176-.736l.812.721v1.6a.625.625,0,0,0,.625.625h1.607l.267.28v1.595a.625.625,0,0,0,.625.625h1.4l1.146,1.08a.625.625,0,0,0,.429.17h2.657a.625.625,0,0,0,.625-.625V21.685a.625.625,0,0,0-.182-.441Z"
            transform="translate(2.446 1.288)"
            fill="#00b72b"
          />
        </g>
      </g>
    </svg>
  )
};
