import React from 'react';

export const BasketIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.126" viewBox="0 0 20 18.126">
            <g id="shopping-basket-1" transform="translate(0 -1.125)">
                <path id="Path_7817" data-name="Path 7817" d="M18.917,12.875H1.417a.417.417,0,0,0-.407.507l1.534,6.937a1.216,1.216,0,0,0,1.222.89h12.8a1.216,1.216,0,0,0,1.222-.89v-.006l1.532-6.931a.417.417,0,0,0-.407-.507Zm-3.958,2.5v3.333a.625.625,0,0,1-1.25,0V15.375a.625.625,0,0,1,1.25,0Zm-4.167,0v3.333a.625.625,0,0,1-1.25,0V15.375a.625.625,0,0,1,1.25,0Zm-3.75,0v3.333a.625.625,0,0,1-1.25,0V15.375a.625.625,0,0,1,1.25,0Z" transform="translate(-0.167 -1.958)" />
                <path id="Path_7818" data-name="Path 7818" d="M18.75,6.75H17.48a.209.209,0,0,1-.158-.072L12.874,1.489a1.042,1.042,0,1,0-1.583,1.356l3.053,3.561a.208.208,0,0,1-.158.344H5.813a.208.208,0,0,1-.158-.344L8.707,2.845A1.042,1.042,0,0,0,7.124,1.489L2.678,6.677a.209.209,0,0,1-.158.072H1.25A1.25,1.25,0,0,0,0,8v.833a1.25,1.25,0,0,0,1.25,1.25h17.5A1.25,1.25,0,0,0,20,8.833V8A1.25,1.25,0,0,0,18.75,6.75Z" transform="translate(0)" />
            </g>
        </svg>
    )
}