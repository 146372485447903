import React from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/ItemName/styles.module.scss';
import { ShouldRender } from 'components/ShouldRender';

export const ItemName = ({ residualValue, name }) => (
  <div className={styles.ItemName}>
    <ShouldRender condition={residualValue === 0}>
      <div className={styles.greenDot} />
    </ShouldRender>

    {name}
  </div>
);
