import React from 'react';

export const MarketVendorIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.999" viewBox="0 0 20 19.999">
            <g id="farmer-s-market-vendor" transform="translate(0 -0.001)">
                <path id="Path_7775" data-name="Path 7775" d="M18.893,3.14,10.548.093A1.68,1.68,0,0,0,9.44.1L1.119,3.136A1.667,1.667,0,0,0,0,4.71V19.167a.833.833,0,1,0,1.667,0L1.678,5.288a.833.833,0,0,1,.547-.782L9.7,1.767a.833.833,0,0,1,.573,0L17.786,4.51a.833.833,0,0,1,.547.782V19.167a.833.833,0,1,0,1.667,0V4.71a1.667,1.667,0,0,0-1.107-1.57Z" transform="translate(0 0)" />
                <rect id="Rectangle_3607" data-name="Rectangle 3607" width="12.5" height="5" rx="1" transform="translate(3.75 14.999)" />
                <path id="Path_7776" data-name="Path 7776" d="M9.555,12.581A4.058,4.058,0,0,0,7.889,14.15a.417.417,0,0,0,.359.627h6.144a.417.417,0,0,0,.358-.628,4.062,4.062,0,0,0-1.686-1.575.208.208,0,0,1-.018-.363,3.272,3.272,0,1,0-3.472.007.208.208,0,0,1-.017.363Zm.21-3.6a.208.208,0,0,1,.3-.121,4.239,4.239,0,0,0,2.123.583,4,4,0,0,0,.47-.028.208.208,0,0,1,.231.233,1.6,1.6,0,1,1-3.127-.667Z" transform="translate(-1.305 -1.028)" />
            </g>
        </svg>
    )
}