import React from 'react';
import Popup from 'reactjs-popup';
import { Dropdown, Image, Menu } from 'semantic-ui-react';
import classNames from 'classnames';
import { get } from 'lodash';
import userSrc from 'assets/media/user.png';
import { Text } from 'components/typography/Text';
import { Icon } from 'components/form-elements/Icon';
import { useUserAvatar } from 'services/user/avatar/hook';
import { useUserDetails } from 'services/user/details/hook';
import { DropdownMenu } from './components/DropdownMenu';
import styles from './styles.module.scss';

export const UserProfile = () => {
  const { userDetails } = useUserDetails();

  const { avatar } = useUserAvatar();

  return (
    <Menu.Menu
      position="right"
      className={styles.menu}
    >
      <div className={styles.userMenu}>
        <Popup
          trigger={(
            <div className={styles.dropdown}>
              <Text
                size={14}
                className={classNames(styles.userText, styles.menuText)}
              >
                {get(userDetails, 'fullname', '')}

                <Icon
                  name="angle down"
                  className={styles.icon}
                />
              </Text>

              <Image
                alt="User avatar"
                className={styles.avatar}
                src={avatar || userSrc}
                size="tiny"
                circular
              />
            </div>
          )}
          pointing="top right"
          icon={null}
        >
          <Dropdown.Menu>
            <DropdownMenu />
          </Dropdown.Menu>
        </Popup>
      </div>
    </Menu.Menu>
  );
};
