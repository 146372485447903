import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import sassVariables from 'global/_styles.scss';
import {useWaterIconFill} from "utils/hooks-visual/water-icon-fill/useWaterIconFill";

export const TrendArrowIcon = ({direction = 'middle', variant = sassVariables.gray}) => {
  const [fill, gradient] = useWaterIconFill(variant);

  return (
    <svg
      className={classNames(styles.icon, styles[direction])}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50.261"
    >
      <path
        d="M25.046,0A25.232,25.232,0,0,0,.1,20.82a1.05,1.05,0,0,0,1.05,1.22H18.615a1.05,1.05,0,0,0,1.05-1.05V13.434a2.1,2.1,0,0,1,3.411-1.639L37.768,23.549a2.1,2.1,0,0,1,0,3.279L23.075,38.582a2.1,2.1,0,0,1-3.411-1.639V29.386a1.05,1.05,0,0,0-1.05-1.05H1.163a1.062,1.062,0,0,0-1.05,1.224,25.188,25.188,0,0,0,49.957-5.4A25.426,25.426,0,0,0,25.046,0Z"
        transform="translate(-0.087 0)"
      />
      {gradient}
    </svg>
  );
};
