import React, { useContext, useEffect } from 'react';
import styles from './styles.module.scss';
import { InfoMessage } from 'components/messages/InfoMessage';
import { Button } from 'components/form-elements/buttons/Button';
import { BankIDLogo } from 'components/bank-id/BankIDLogo';
import { MultiStepContext } from 'components/MultiStep';
import { useBankIDOrderStatus } from 'services/auth/bank-id-order-status/hook';

export const BankIDProgressInfo = () => {
  const { onPrevStep } = useContext(MultiStepContext);

  const { status, orderRef, onCancelOrder, isCancelling } = useBankIDOrderStatus();

  useEffect(() => {
    if (!orderRef) {
      onPrevStep();
    }
  }, [orderRef, onPrevStep])

  return (
    <div className={styles.bankID}>
      <BankIDLogo />

      <InfoMessage
        loading
        message={status}
      />

      <div className={styles.cancelButton}>
        <Button
          intl="cancel"
          loading={isCancelling}
          onClick={onCancelOrder}
        />
      </div>
    </div>
  );
}
