import React from 'react';
import { WeatherIcon } from 'assets/icons/brikks/WeatherIcon';
import Draggable from 'components/drag-and-drop/Draggable';
import { Brikk } from 'components/Brikk';
import { BrikkModal } from 'components/modals/BrikkModal';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { WeatherPreview, WeatherModal } from 'components/brikks/neighbourhood/Weather/components';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { useWeather } from 'services/weather';

export const Weather = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = React.useState(false);

  const { weather, isLoading, isError } = useWeather();

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          name={name}
          status={status}
        >
          <Brikk.Header
            icon={<WeatherIcon />}
            heading="weatherBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <WeatherPreview
              weather={weather}
              error={isError}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              disabled={!!isError}
              brikkNameIntl="weather"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Weather info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="weather"
        onClose={() => setIsModalOpen(false)}
      >
        <WeatherModal />
      </BrikkModal>
    </>
  );
};
