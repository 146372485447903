import React from 'react';
import { useIntl } from 'react-intl';
import IconCheckGreen from 'assets/icons/check_circle_green.svg';
import CloseIcon from 'assets/icons/close-icon.svg';
import { Text } from 'components/typography/Text';
import { dateMonthNameYear, formatDate } from 'utils/timeAndDate';
import { ShouldRender } from 'components/ShouldRender';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { DONE, ONGOING } from 'utils/types';
import Calendar from './components/Calendar';
import styles from './styles.module.scss';

export const ServiceTicket = ({ data }) => {
  const date = data.date && new Date(data.date);
  const tenantId = getTenantIDBasedOnURL().toLowerCase();
  const intl = useIntl();

  const handleTicketType = () => {
    switch (data.type) {
      case 'contact':
        return 'contactTicketLabel';
      case 'report':
        return 'serviceTicketLabel';
      default:
        return 'otherTicketLabel';
    }
  };

  const handleTicketStatus = () => {
    switch (data.status) {
      case 'reported':
        return 'serviceStatusReported';
      case 'started':
        return 'serviceStatusStarted';
      case 'done':
        return 'serviceStatusDone';
      default:
        return 'serviceStatusReported';
    }
  };

  return (
    <div className={styles.ticket}>
      <ShouldRender condition={!!date}>
        <div className={styles.calendar}>
          <Calendar
            date={date?.getDate()}
            month={date?.getMonth()}
          />
        </div>
      </ShouldRender>

      <div className={styles.text}>
        <div className={styles.ticketId}>
          <Text
            size={18}
            bold
            intl={handleTicketType()}
            intlValues={{ ticketId: data.ticketId }}
          />
        </div>

        <ShouldRender condition={!!date}>
          <div className={styles.date}>
            <Text
              size={18}
              capitalize
              bold
              intl="dateLabel"
              intlValues={{ date: !!date && formatDate(date, dateMonthNameYear) }}
            />
          </div>
        </ShouldRender>

        <div className={styles.status}>
          {
            tenantId === LKF ? (
              <Text
                size={18}
                bold
                intl={handleTicketStatus()}
              />
            ) : (
              <Text
                size={18}
                bold
                intl="statusLabel"
                intlValues={{ status: intl.formatMessage({
                  id: data.status === ONGOING ? 'serviceStatusOngoing' : 'serviceStatusNew',
                }),
                }}
              />
            )
          }
        </div>

        <ShouldRender condition={!!data.title}>
          <div className={styles.title}>
            <Text
              size={16}
              bold
              intl="regardingLabel"
              intlValues={{ regarding: data.title }}
            />
          </div>
        </ShouldRender>

        <div className={styles.description}>
          <Text
            size={16}
            bold
            intl="descriptionLabel"
            intlValues={{ description: data.description }}
          />
        </div>

        <Text
          size={16}
          bold
          intl="sourceLabel"
          intlValues={{ source: data.source }}
        />
      </div>
      
      <div className={styles.statusIcon}>
        {[DONE, ONGOING].includes(data.status)
          ? (
            <img
              src={IconCheckGreen}
              style={{ width: 20, height: 20 }}
              alt="green check icon"
            />
          )
          : (
            <img
              src={CloseIcon}
              alt="red close icon"
            />
          )}
      </div>
    </div>
  );
};
