import React from 'react';

export const FruitScaleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.162" height="20" viewBox="0 0 19.162 20">
            <g id="fruit-scale-apple" transform="translate(-0.5 0.007)">
                <path id="Path_7786" data-name="Path 7786" d="M18.251,16.661h-1.3a.208.208,0,0,1-.208-.208V9.667a.207.207,0,0,1,.117-.187,5,5,0,1,0-4.4,0,.207.207,0,0,1,.117.187v6.786a.208.208,0,0,1-.208.208H6.54a.208.208,0,0,1-.208-.208v-.833a.208.208,0,0,1,.208-.208H9.664a.833.833,0,1,0,0-1.666H1.333a.833.833,0,0,0,0,1.666H4.457a.208.208,0,0,1,.208.208v.833a.208.208,0,0,1-.208.208H1.91a.833.833,0,0,0-.79,1.1l.556,1.666a.833.833,0,0,0,.79.57H17.695a.833.833,0,0,0,.79-.569h0l.555-1.666a.833.833,0,0,0-.789-1.1Zm-3.588-15A3.332,3.332,0,0,1,17.995,5a3.063,3.063,0,0,1-.079.666.207.207,0,0,1-.2.167H15.4a.027.027,0,0,1-.022-.012l-1.25-2.083a.833.833,0,1,0-1.428.858l.553.921a.208.208,0,0,1-.179.316h-1.46a.207.207,0,0,1-.2-.167A3.063,3.063,0,0,1,11.326,5a3.332,3.332,0,0,1,3.337-3.332Z" transform="translate(0 0)" />
                <path id="Path_7787" data-name="Path 7787" d="M5.587,13.591a.207.207,0,0,1,.234,0,2,2,0,0,0,2.857-.949,5.9,5.9,0,0,0,.776-2.51c0-1.5-.981-2.763-2.158-2.763a2.9,2.9,0,0,0-.482.037.208.208,0,0,1-.242-.225c.038-.4.2-.656.833-.783a.833.833,0,1,0-.327-1.633A2.451,2.451,0,0,0,4.9,7.218a.208.208,0,0,1-.246.192,2.823,2.823,0,0,0-.5-.04C2.981,7.373,2,8.629,2,10.132a6.262,6.262,0,0,0,.788,2.531,1.941,1.941,0,0,0,2.8.928Z" transform="translate(-0.25 -0.793)"/>
            </g>
        </svg>
    )
}