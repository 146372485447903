import React from 'react';
import { Form } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Radio } from 'components/form-elements/radios/Radio';
import { Text } from 'components/typography';
import { Label } from 'components/form-elements/labels/Label';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';

const keyOptionsVXB = ['empty', 'Huvudnyckel', 'Nyckel i tub', 'Ring och avtala tid'];
const keyOptionsLKF = ['empty', 'cylinder', 'tube', 'call', 'service', 'info'];

export const Keys = ({ keysDisabled, keys, setKeys, postBody, setPostBody }) => {

  const tenantId = getTenantIDBasedOnURL().toLowerCase();
  const keyOptions = (tenantId === LKF) ? keyOptionsLKF : keyOptionsVXB;

  const intl = useIntl();

  const handleKeyChange = (e, { value, checked }) => {
    setKeys(checked ? value : '');
    setPostBody({
      ...postBody,
      key: value && checked ? { key: value } : { key: keyOptions[0] },
    });
  };

  const handleKeyDescription = () => {
    switch (keys) {
      case 'tube':
      case 'Nyckel i tub':
        return 'keyInTubeDesc';
      case 'service':
        return 'serviceModeDesc';
      case 'cylinder':
      case 'Huvudnyckel':
        return 'masterKeyDesc';
      case 'call':
        return 'callAndMakeAppointmentDesc';
      default:
        return '';
    }
  };

  return (
    <>
      {tenantId !== LKF
      && (
        <Form.Field disabled={keysDisabled}>
          <Label intl="masterKeyLabel" />
    
          <Radio
            toggle
            name="keys"
            value={keyOptions[1]}
            label={intl.formatMessage({ id: 'masterKey' })}
            onChange={handleKeyChange}
            checked={keys === keyOptions[1]}
          />
        </Form.Field>
      )}

      <Form.Field disabled={keysDisabled}>
        <Radio
          toggle
          name="keys"
          value={keyOptions[2]}
          label={intl.formatMessage({ id: 'keyInTube' })}
          onChange={handleKeyChange}
          checked={keys === keyOptions[2]}
        />
      </Form.Field>

      {tenantId === LKF
      && (
        <Form.Field disabled={keysDisabled}>
          <Radio
            toggle
            name="keys"
            value={keyOptions[4]}
            label={intl.formatMessage({ id: 'serviceMode' })}
            onChange={handleKeyChange}
            checked={keys === keyOptions[4]}
          />
        </Form.Field>
      )}

      <Form.Field disabled={keysDisabled}>
        <Radio
          toggle
          name="keys"
          value={keyOptions[3]}
          label={intl.formatMessage({ id: 'callAndMakeAppointment' })}
          onChange={handleKeyChange}
          checked={keys === keyOptions[3]}
        />
      </Form.Field>

      <Text
        intl={handleKeyDescription()}
        intlValues={{
          bold: (...chunks) => (
            <Text
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    </>
  ); 
};
