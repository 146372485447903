import React from 'react';
import { FormattedMessage } from 'react-intl';
import myHomeImgSrc from 'assets/media/my-home-sm.png';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import { Text } from 'components/typography/Text';
import { tenantConfig } from 'global/tenant-config';
import { useLivingInfo } from 'services/living-info/living-info';
import styles from './styles.module.scss';

export const LivingInfoPreview = () => {
  const { livingInfo } = useLivingInfo();

  const livingRoomSrc = tenantConfig.assets.livingRoom;

  return (
    <div>
      <CircularImage
        src={livingRoomSrc || myHomeImgSrc}
        alt="Image of a house"
      />

      <div className={styles.textLine}>
        <FormattedMessage id="address" />: &nbsp; <Text bold>{livingInfo.fullAddress}</Text>
      </div>

      <div className={styles.textLine}>
        <FormattedMessage id="apartment" />: &nbsp; <Text bold>{livingInfo.apartmentNumber}</Text>
      </div>
    </div>
  );
};
