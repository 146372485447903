import React, { useState } from "react";
import { createContainer } from "unstated-next";
import { useSubmitBrikkOrder } from "services/order/create-order/hook";
import { DraggableCard } from "pages/Order/components/DraggableCard";

const useOrderMode = () => {
  const { isSubmitted, onResetOrder } = useSubmitBrikkOrder();

  const [orderMode, setOrderMode] = useState(false);

  const [orderedBrikks, setOrderedBrikks] = useState([]);
  const [orderedBrikksError, setOrderedBrikksError] = useState("");

  const [projectName, setProjectName] = useState("");
  const [projectNameError, setProjectNameError] = useState("");

  const [emailRecipients, setEmailRecipients] = useState([]);
  const [emailRecipientsError, setEmailRecipientsError] = useState("");

  const [position, setPosition] = useState(0);

  const resetErrors = () => {
    setProjectNameError("");
    setEmailRecipientsError("");
    setOrderedBrikksError("");
  };

  const resetOrderFields = () => {
    setOrderedBrikks([]);
    setProjectName("");
    setEmailRecipients([]);
  };

  const isBrikkSelected = (id) => {
    let selected = false;
    orderedBrikks.map((brikk) => brikk.code === id && (selected = true));
    return selected;
  };

  const handleProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const removeBrikk = (id) => {
    // Remove brikk by id and reset the brikks position.
    const removedBrikk = orderedBrikks
      .filter((brikk) => brikk.code !== id)
      .map((brikk, index) =>
        brikk.position ? { ...brikk, position: index } : brikk
      );
    setOrderedBrikks(removedBrikk);
    setPosition(position - 1);
  };

  const handleOrderBrikk = (id, name, status) => {
    let isBrikkSlct = isBrikkSelected(id);
    if (isBrikkSlct) {
      removeBrikk(id);
    } else {
      setOrderedBrikks([
        ...orderedBrikks,
        {
          id: id,
          code: id,
          name: name,
          status: status,
          position: position,
          component: (position) => (
            <DraggableCard
              key={id}
              code={id}
              name={name}
              status={status}
              position={position}
            />
          ),
        },
      ]);
      setPosition(position + 1);
    }
  };

  const handleOrderMode = () => {
    if (isSubmitted && orderMode) {
      resetOrderFields();
      onResetOrder();
    }
    setOrderMode(!orderMode);
  };

  return {
    orderMode,
    orderedBrikks,
    orderedBrikksError,
    emailRecipientsError,
    projectName,
    projectNameError,
    emailRecipients,
    isBrikkSelected,
    handleOrderBrikk,
    handleProjectName,
    handleOrderMode,
    setEmailRecipients,
    removeBrikk,
    setProjectNameError,
    setEmailRecipientsError,
    setOrderedBrikksError,
    setOrderedBrikks,
    resetErrors,
  };
};

const OrderModeContainer = createContainer(useOrderMode);

export const OrderModeProvider = OrderModeContainer.Provider;
export const useOrderModes = OrderModeContainer.useContainer;
