import React from 'react';

export const IFrame = ({ src, width, height, title }) => (
  <iframe
    title={title}
    width={width}
    height={height}
    src={src}
    frameBorder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowFullScreen
  />
);
