import React, { useContext, useEffect, useState } from 'react';
import { BankIDLogo } from 'components/bank-id/BankIDLogo';
import { PrimaryButton } from 'components/form-elements/buttons/PrimaryButton';
import { MultiStepContext } from 'components/MultiStep';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { isValidPersonalNumber } from 'utils/validation/bankID';
import styles from './styles.module.scss';

export const BankIDPersonalNumber = ({ onProceed, loading, name, initSuccess, userVisibleData }) => {
  const [personalNumber, setPersonalNumber] = useState('');

  const { onNextStep } = useContext(MultiStepContext);

  useEffect(() => {
    if (initSuccess) {
      onNextStep();
    }
  }, [initSuccess, onNextStep]);

  return (
    <div className={styles.bankIDPersonalNumber}>

      <BankIDLogo
        className={styles.logo}
        centered
      />

      <LabeledInput
        labelIntl="personalNumber"
        autoFocus
        placeholder="ÅÅÅÅMMDDXXXX"
        value={personalNumber}
        onChange={e => setPersonalNumber(e.target.value)}
      />

      <div className={styles.button}>
        <PrimaryButton
          intl={name === 'bank-id-signing' ? 'signWithBankID' : 'mobile'}
          name={name}
          loading={loading}
          disabled={loading || !isValidPersonalNumber(personalNumber)}
          onClick={() => onProceed(personalNumber.replace('-', ''), userVisibleData)}
        />
      </div>
    </div>
  );
};
