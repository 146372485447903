import { AppTracking } from 'global/appTracking';

export function downloadDocument(name, url) {
  fetch(url).then(response => (
    response.blob()
      .then(blob => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.setAttribute('download', name);
        downloadLink.click();
      })
  ));
}

export function downloadFloorPlan(payload) {
  const downloadLink = document.createElement('a');
  const fileName = 'floorPlan.jpeg';

  downloadLink.href = payload;
  downloadLink.download = fileName;
  downloadLink.click();

  AppTracking.downloadEvent({ action: 'Download Floor Plan' });
}
 
