import { takeLatest, put, call } from 'redux-saga/effects';
import { getLocaleSuccess, LocaleActions } from './actions';
import { getLocaleFromStorage, setLocaleInStorage } from './utils';
import { getLocaleAPI, updateLocaleAPI } from './api';
import { AppTracking } from 'global/appTracking';

function* getLocaleEffect() {
  try {
    const browserLocale = getLocaleFromStorage();
    if (browserLocale) {
      document.documentElement.lang = browserLocale;
      yield put(getLocaleSuccess(browserLocale));
    } else {
      const locale = yield call(getLocaleAPI);
      document.documentElement.lang = locale;
      yield put(getLocaleSuccess(locale))
    }
  } catch (e) {}
}

function* updateLocaleEffect({ payload }) {
  setLocaleInStorage(payload);
  document.documentElement.lang = payload;
  AppTracking.settingsEvent({ action: 'Change Locale', label: payload });
  try {
    yield call(updateLocaleAPI, [{ locale: payload }])
  } catch (e) {}
}

function* updateBrowserLocaleEffect({ payload }) {
  yield setLocaleInStorage(payload);
  AppTracking.settingsEvent({ action: 'Change Locale', label: payload });
}

export const localeEffects = [
  takeLatest(LocaleActions.GET_LOCALE, getLocaleEffect),
  takeLatest(LocaleActions.UPDATE_LOCALE, updateLocaleEffect),
  takeLatest(LocaleActions.UPDATE_BROWSER_LOCALE, updateBrowserLocaleEffect),
];
