import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CircledArrowIcon = ({ left, light, primary, down, x2 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(
        styles.icon,
        left && styles.left,
        down && styles.down,
        light && styles.light,
        primary && styles.primary,
        x2 && styles.x2
    )}
    viewBox="0 0 20.618 20.621"
  >
    <path
          d="M8.591,12.415v3.179a.859.859,0,0,0,.487.773.89.89,0,0,0,.909-.1L15.572,11.8a.859.859,0,0,0,0-1.341L9.988,5.987a.859.859,0,0,0-1.4.672V9.838h-7.3a1.289,1.289,0,1,0,0,2.577Z"
          transform="translate(0 -0.817)"/>
    <path
          d="M10.445,0A10.331,10.331,0,0,0,1.034,6.095.86.86,0,1,0,2.6,6.8a8.591,8.591,0,1,1,.017,7.065.859.859,0,1,0-1.564.711A10.31,10.31,0,1,0,10.445,0Z"
          transform="translate(-0.135)"/>
  </svg>
);
