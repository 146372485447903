import React, { useState } from 'react';
import styles from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/FeedArticle/styles.module.scss';
import { Text } from 'components/typography/Text';
import { DiscardIcon } from 'assets/icons/DiscardIcon';
import { DeleteFeed } from 'components/brikks/society/RSSFeed/components/RSSFeedModal/components/FeedArticles/components/DeleteFeed';
import { InnerModal } from 'components/modals/InnerModal';
import { ShouldRender } from 'components/ShouldRender';
import { FocusableElement } from 'components/FocusableElement';

export const FeedArticle = ({ selectedFeed, feedArticle, setSelectedFeed, selectAllArticles }) => {
  const [deleteFeed, setDeleteFeed] = useState(null);

  const onDelete = () => {
    selectAllArticles();
    setDeleteFeed(null);
  };

  const isFeedSelected = selectedFeed.id ? (
    selectedFeed.id === feedArticle.id
  ) : (
    selectedFeed.title === feedArticle.title
  );


  return (
    <>
      <div
        key={feedArticle.title}
        className={styles.feed}
      >
        <FocusableElement
          onClick={() => setSelectedFeed(feedArticle)}
          element="span"
        >
          <Text
            large
            clickable
            uppercase
            dark
            primary={isFeedSelected}
          >
            {feedArticle.title}
          </Text>
        </FocusableElement>

        <ShouldRender condition={!feedArticle.default}>
          <div className={styles.deleteFeed}>
            <DiscardIcon size={18} onClick={() => setDeleteFeed(feedArticle)} />
          </div>
        </ShouldRender>
      </div>

      <InnerModal
        isOpen={!!deleteFeed}
        onClose={() => setDeleteFeed(null)}
      >
        <DeleteFeed
          feed={deleteFeed}
          onDelete={onDelete}
        />
      </InnerModal>
    </>
  )
};
