import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { useSubscriptions } from 'services/service-report/subscriptions';
import { getSpeeds } from 'utils/internet-providers';
import { getInternetProvidersAPI } from './api';
import { mapDataToClient } from './mapData';

const INTERNET_PROVIDERS = 'internetProviders';

export function useInternetProviders() {
  const tenantId = getTenantIDBasedOnURL();
  const { subscriptions } = useSubscriptions();

  const { data, isLoading, isFetching, isError } = useQuery(
    [INTERNET_PROVIDERS, tenantId],
    () => getInternetProvidersAPI(),
  );

  return {
    internetProviders: mapDataToClient(data?.providers, subscriptions),
    internetSpeeds: getSpeeds(data?.providers),
    activationDate: new Date(data?.activationDate),
    isLoading: isLoading || isFetching,
    isError,
  };
}
