import React, { useState } from "react";
import { Tabs } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/Tabs";
import { Menus } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/Menus";
import { MenuContent } from 'components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo/components/MenuContent';
import { Grid } from "components/layout/Grid";
import { Brikk } from "components/Brikk";
import { CheckListOptions, GeneralInfoOptions } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/tabOptions";

export const CleaningAndMaintenanceInfo = () => {
  const [activeTab, setActiveTab] = useState("checkList");
  const isCheckList = activeTab === "checkList";

  return (
    <>
      <Grid.Column justifySelf="center">
        <Brikk.Heading
          heading="overview"
        >
          <Tabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          <Menus menus = {isCheckList ? CheckListOptions : GeneralInfoOptions } />

        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <MenuContent />
      </Grid.Column>
    </>
  );
};
