import React from 'react';

export const ShieldWarningIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.991" viewBox="0 0 20 19.991">
            <g id="shield-warning" transform="translate(0 -0.006)">
                <path id="Path_7913" data-name="Path 7913" d="M19.517.479A1.618,1.618,0,0,0,18.37.006H1.632A1.632,1.632,0,0,0,.01,1.638L0,7.756A12.623,12.623,0,0,0,9.884,19.98a.833.833,0,0,0,.333,0A12.621,12.621,0,0,0,19.99,7.668L20,1.628A1.615,1.615,0,0,0,19.517.479ZM15.241,12.768a1.213,1.213,0,0,1-1.028.572H5.787a1.213,1.213,0,0,1-1.028-.572,1.194,1.194,0,0,1-.04-1.174L8.932,3.565a1.208,1.208,0,0,1,2.135,0h0l4.213,8.028a1.194,1.194,0,0,1-.04,1.174Z" />
                <circle id="Ellipse_372" data-name="Ellipse 372" cx="0.833" cy="0.833" r="0.833" transform="translate(9.167 10.423)" />
                <path id="Path_7914" data-name="Path 7914" d="M11.875,11.006a.625.625,0,0,0,.625-.625v-2.5a.625.625,0,0,0-1.25,0v2.5A.625.625,0,0,0,11.875,11.006Z" transform="translate(-1.875 -1.208)" />
            </g>
        </svg>
    )
}




