import { LoginEmailActions } from './actions';

const initialState = {
  user: null,
  loading: false,
  error: '',
};

export function loginWithEmailReducer(state, action) {
  switch (action.type) {
    case LoginEmailActions.LOGIN_EMAIL:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case LoginEmailActions.LOGIN_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case LoginEmailActions.LOGIN_EMAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LoginEmailActions.LOGIN_EMAIL_RESET:
    default:
      return initialState;
  }
}
