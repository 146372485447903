import React from 'react';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import brikkPreviewSrc from '../assets/lights-brikk-preview.png';

export const LightsPreview = () => (
  <CircularImage
    src={brikkPreviewSrc}
    alt="Lights preview image"
  />
);
 
