import { InitPassResetActions } from './actions';

const initialState = {
  isInitSuccessful: false,
  loading: false,
  error: '',
};

export function initPassResetReducer(state, action) {
  switch (action.type) {
    case InitPassResetActions.INIT_PASS_RESET:
      return {
        ...state,
        loading: true,
        error: '',
      };
    case InitPassResetActions.INIT_PASS_RESET_SUCCESS:
      return {
        ...state,
        isInitSuccessful: true,
        loading: false,
      };
    case InitPassResetActions.INIT_PASS_RESET_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case InitPassResetActions.INIT_PASS_RESET_RESET:
    default:
      return initialState;
  }
}
