import { VXB } from 'global/tenant-config/tenants';

const callCenterHours = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  2: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  6: null,
};

const callCenterHoursVXB = {
  0: null,
  1: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  2: {
    from: {
      h: 10,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  3: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  4: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  5: {
    from: {
      h: 8,
      m: 0,
    },
    to: {
      h: 16,
      m: 0,
    },
  },
  6: null,
};

export const serviceCallCenterHoursByDays = (tenantId) => {
  switch (tenantId) {
    case VXB:
      return callCenterHoursVXB;
    default:
      return callCenterHours;
  }
};
