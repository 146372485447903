import React, { useEffect } from 'react';
import { AppTracking } from 'global/appTracking';

export const ModalTracking = ({ name }) => {
  useEffect(() => {
    AppTracking.modalview(name);

    return () => AppTracking.pageview(window.location.pathname);
  }, [name]);

  return <></>
};
