import React from 'react';
import { Grid } from 'components/layout/Grid';
import { VideoPreview } from 'components/media/VideoPreview';
import { filmsData } from './filmsData';

export const Boportalen = () => (
  <>
    <Grid
      noPadding
      twoEqualColumns
      style={{ gridGap: 15 }}
    >
      {filmsData?.map((video) => (
        <VideoPreview
          key={video.title}
          intl={video.title} 
          url={video.videUrl}
        />
      ))}
    </Grid>
  </>
);
