import React from 'react';
import styles from './styles.module.scss'

export const MovingOutPreviewIcon = () => (

  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={styles.color}
    width="70"
    height="55"
    viewBox="0 0 48.325 41.005">
    <g id="Group_6422" data-name="Group 6422" transform="translate(-431.024 -250.178)">
      <rect id="Rectangle_453" data-name="Rectangle 453" width="48.325" height="4.393" transform="translate(431.024 286.789)" />
      <g id="truck-moving" transform="translate(438.814 250.18)">
        <path id="Path_8284" data-name="Path 8284" d="M6.339,9.336H9.61a.981.981,0,0,1,.981.981V17.84a.328.328,0,0,0,.327.327h19.3a.981.981,0,0,1,.981.981v2.093A2.156,2.156,0,0,1,29.042,23.4h-.01a.327.327,0,0,1-.313-.234,4.239,4.239,0,0,0-8.129,0,.327.327,0,0,1-.313.234H12.352a.327.327,0,0,1-.313-.234,4.239,4.239,0,0,0-8.129,0A.327.327,0,0,1,3.6,23.4H2.278A2.156,2.156,0,0,1,.125,21.241V15.55A6.221,6.221,0,0,1,6.339,9.336ZM2.741,16.2H6.012a.654.654,0,0,0,.654-.654V12.28a.654.654,0,0,0-.654-.654A3.925,3.925,0,0,0,2.087,15.55.654.654,0,0,0,2.741,16.2Z" transform="translate(-0.125 1.677)" />
        <ellipse id="Ellipse_424" data-name="Ellipse 424" cx="2.944" cy="2.944" rx="2.944" ry="2.944" transform="translate(21.586 23.115)" />
        <ellipse id="Ellipse_425" data-name="Ellipse 425" cx="2.944" cy="2.944" rx="2.944" ry="2.944" transform="translate(4.906 23.115)" />
        <path id="Path_8285" data-name="Path 8285" d="M19.19,9.642H17.555a.327.327,0,0,0-.327.327v8.177a.654.654,0,0,1-.654.654H13.3a.654.654,0,0,1-.654-.654V14.221a1.308,1.308,0,0,0-1.308-1.308H9.378A1.308,1.308,0,0,0,8.07,14.221v3.925a.654.654,0,0,1-.654.654H4.145a.654.654,0,0,1-.654-.654V9.969a.327.327,0,0,0-.327-.327H1.529A.654.654,0,0,1,1.1,8.5h0L9.607,1.239h0a1.308,1.308,0,0,1,1.722,0L19.62,8.495a.654.654,0,0,1-.43,1.147Z" transform="translate(10.245 -0.918)" />
      </g>
    </g>
  </svg>
);
