import React from 'react';
import { Text } from 'components/typography/Text';
import { BrikkPlaceholder } from 'components/brikks/sub-components/BrikkPlaceholder';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF, RIB } from 'global/tenant-config/tenants';
import { useReportedCases } from 'services/reported-cases';
import { OPEN, DONE, FINISHED, CLOSED } from 'utils/types';
import { ServiceTicket } from './components/ServiceTicket';

export const ServiceTicketsList = ({ selectedTab }) => {
  const { reportedCases, loading } = useReportedCases();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const serviceTickets = React.useMemo(() => reportedCases?.filter(
    (ticket) => {
      if (tenantId === RIB) {
        return selectedTab === OPEN ? ticket.status === OPEN : ticket.status === CLOSED;
      } if (tenantId === LKF) {
        return selectedTab === CLOSED ? ticket.status === DONE : ticket.status !== DONE;
      } 
      return selectedTab === OPEN ? ticket.status !== FINISHED : ticket.status === FINISHED;
    },
  ), [reportedCases, tenantId, selectedTab]);

  if (loading) {
    return <BrikkPlaceholder />;
  }

  return (
    <>
      {serviceTickets && serviceTickets.length > 0
        ? serviceTickets.reverse().map(item => (
          <ServiceTicket
            data={item}
            key={`${item.ticketId}-${item.title}-${item.date}`}
          />
        )) : (
          <Text
            secondary
            center
            intl="noTicketsToDisplay"
            size={16}
          />
        )}
    </>
  ); 
};
