import React, { useContext, useState, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form'; 
import FormErrors from 'components/FormErrors';
import { Auth } from 'aws-amplify';
import { Form, Button, Ref, Message } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AppContext } from 'AppContext';
import { AppTracking } from 'global/appTracking';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { PasswordInput } from 'components/form-elements/inputs/PasswordInput';
import { changePasswordSchema } from 'utils/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { ShouldRender } from 'components/ShouldRender';
import { useHistory } from 'react-router-dom';
import {
  FORGOT_PASS_PAGE,
  CHANGE_PASS_CONFIRMATION_PAGE,
} from 'global/routes';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const ChangePassword = () => {

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(changePasswordSchema),
    mode: 'onSubmit',

  });

  const { setUser, setIsAuthenticated } = useContext(AppContext);
  const [cognitoError, setCognitoError] = useState(null);
  
  const history = useHistory();
  const intl = useIntl();
  const { theme } = useTheme();
  const submitRef = useRef(null);
  const userData = watch();

  const onSubmit = async () => {

    setCognitoError(null);
    
    // AWS Cognito integration
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        userData.oldPassword,
        userData.newPassword,
      );
      AppTracking.authEvent({ action: 'Change Password' });
      Auth.signOut().then(() => {
        setIsAuthenticated(false);
        setUser(null); 
        history.push(CHANGE_PASS_CONFIRMATION_PAGE);
      });
    } catch (error) {
      let err = null;
      // eslint-disable-next-line no-unused-expressions
      !error.message ? err = { message: error } : err = error;
      setCognitoError(err);
    }
    
  };

  return (
    <BasicPageLayout intlTitle="changePassword">
      <Form
        size="large"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          element="h1"
          size={32}
          intl="changePassword"
        />

        <div className={styles.subtitle}>
          <Text
            size={16}
            gray
            intl="changePasswordSubtitle"
          />
        </div>

        <FormErrors formerrors={{ cognitoError }} />

        <Controller
          defaultValue=""
          name="oldPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.oldPassword && 'error')}
              fluid
              id="old-password"
              name="old-password"
              label={intl.formatMessage({ id: 'currentPassword' })}
              placeholder={intl.formatMessage({ id: 'currentPassword' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <Controller
          defaultValue=""
          name="newPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.newPassword && 'error')}
              fluid
              id="new-password"
              name="new-password"
              label={intl.formatMessage({ id: 'newPassword' })}
              placeholder={intl.formatMessage({ id: 'newPasswordPlaceholder' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <Controller
          defaultValue=""
          name="confirmNewPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.confirmPassword && 'error')}
              fluid
              id="confirm-password"
              name="confirm-password"
              label={intl.formatMessage({ id: 'confirmNewPassword' })}
              placeholder={intl.formatMessage({ id: 'confirmPassword' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <ShouldRender condition={!!errors.oldPassword || !!errors.newPassword || !!errors.confirmNewPassword}>
          <Message negative>
            <Message.Content>
              <FormattedMessage 
                id={errors.oldPassword?.message || errors.newPassword?.message || errors.confirmNewPassword?.message}
                defaultMessage=""
              />
            </Message.Content>
          </Message>
        </ShouldRender>

        <div className={styles.buttons}>
          <a
            href={FORGOT_PASS_PAGE}
            onClick={(e) => {
              e.preventDefault();
              history.push(FORGOT_PASS_PAGE);
            }}
          >
            <FormattedMessage id="forgotPassword" />
          </a>

          <Ref innerRef={submitRef}>
            <Button
              name="change-password"
              type="submit"
              size="large"
            >
              <FormattedMessage id="send" />
            </Button>
          </Ref>
        </div>
      </Form>
    </BasicPageLayout>
  );
};
