import React, { useMemo } from 'react';
import { Button } from 'components/form-elements/buttons/Button';
import { isEqual } from 'lodash';

export const SaveChanges = ({ oldUserDetails, newUserDetails, isLoading }) => {

  const hasChanges = useMemo(() => (
    !isEqual(oldUserDetails, newUserDetails)
  ), [oldUserDetails, newUserDetails]);

  return (
    <>
      <Button
        type="submit"
        name="submit-user-details"
        disabled={!hasChanges || isLoading}
        loading={isLoading}
        size="large"
        intl="saveChanges"
      />
    </>
  );
};
