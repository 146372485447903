import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import ec2bSrc from 'assets/media/ec2b.png';
import Draggable from 'components/drag-and-drop/Draggable';
import { NewPageButton } from 'components/form-elements/buttons/NewPageButton';
import { MobilityOurGreenCarIcon } from 'assets/icons/brikks/MobilityOurGreenCarIcon';
import { LinkBrikkContent } from "components/brikks/sub-components/LinkBrikkContent";
import { CircularImage } from "components/brikks/sub-components/CircularImage";
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const MobilityEC2B = ({ position, status, code, name }) => {

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }

  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<MobilityOurGreenCarIcon />}
            heading="ec2b"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content>
            <CircularImage
              src={ec2bSrc}
              alt="Illustrations of a car"
            />

            <LinkBrikkContent link="ec2b.se/xplorion" />
          </Brikk.Content>

          <Brikk.Button>
            <NewPageButton
              label="EC2B Link"
              to="https://ec2b.se/xplorion/"
              brikkNameIntl="ec2b"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>
      <BrikkInfoModal
        title="MobilityEC2B info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />
    </>
  )
};
