import React from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductDescription/styles.module.scss';
import { Text } from 'components/typography/Text';
import { ProductInfo } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/ProductDetails/components/ProductInfo';

export const ProductDescription = ({selectedProduct}) => {
  return (
    <div className={styles.details}>
      <Text size={18} intl="facts" />
      <ProductInfo label="productName" value={selectedProduct?.name} />
      <ProductInfo label="productNumber" value={selectedProduct?.code} />
      <Text>
        {selectedProduct?.description}
      </Text>

      <div className={styles.specificationContent}>
        <Text size={18} intl="specifications" />
        <ProductInfo bold label="trademark" value={selectedProduct?.brand} />
      </div>

    </div>
    );
};
