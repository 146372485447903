import React, { useState, useRef } from 'react';
import { Auth } from 'aws-amplify';
import { Form, Button, Message, Ref } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { AppTracking } from 'global/appTracking';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { PasswordInput } from 'components/form-elements/inputs/PasswordInput';
import { useForm, Controller } from 'react-hook-form'; 
import { forgotPasswordVerifySchema } from 'utils/validation/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useHistory } from 'react-router-dom';
import {
  CHANGE_PASS_CONFIRMATION_PAGE, 
} from 'global/routes';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const ForgotPasswordVerify = () => {

  const { control, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: yupResolver(forgotPasswordVerifySchema),
    mode: 'onSubmit',
    
  });
      
  const [loading, setLoading] = useState(false);
  const [cognitoError, setCognitoError] = useState(null);

  const intl = useIntl();
  const { theme } = useTheme();
  const submitRef = useRef(null);
  const history = useHistory();
  const userData = watch();

  // eslint-disable-next-line consistent-return
  const onSubmit = async () => {

    setCognitoError(null);

    // AWS Cognito integration
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(
        userData.email,
        userData.verificationCode,
        userData.newPassword,
      );
      setLoading(false);
      AppTracking.authEvent({ action: 'Account Activated' });
      history.push(CHANGE_PASS_CONFIRMATION_PAGE);
    } catch (error) {
      setLoading(false);
      switch (error.code) {
        case 'CodeMismatchException':
        case 'ExpiredCodeException':
          return setCognitoError('invalidCode');
        case 'NotAuthorizedException':
          return setCognitoError('emailNotFound');
        case 'LimitExceededException':
          return setCognitoError('limitExceeded');
        case 'UserNotFoundException':
          return setCognitoError('emailNotRegistered');
        default:
          return setCognitoError('genericErrorMessage');
      }
    }
  };

  return (
    <BasicPageLayout intlTitle="confirmPassword">
      <Form
        size="large"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Text
          element="h1"
          size={32}
          intl="verifyPasswordTitle"
        />

        <div className={styles.subtitle}>
          <Text
            size={16}
            gray
            intl="verifyPasswordSubtitle"
          />
        </div>

        <ShouldRender condition={!!cognitoError || !!errors.newPassword || !!errors.verificationCode || !!errors.email}>
          <Message negative>
            <Message.Content>
              <FormattedMessage 
                id={cognitoError 
                      || errors.newPassword?.message 
                      || errors.verificationCode?.message 
                      || errors.email?.message}
                defaultMessage=""
              />
            </Message.Content>
          </Message>
        </ShouldRender>

        <Controller
          defaultValue=""
          name="verificationCode"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Input
              className={classNames(theme.textColor, !!errors.verificationCode && 'error')}
              fluid
              id="verificationcode"
              name="verification-code"
              label={intl.formatMessage({ id: 'recoveryCode' })}
              placeholder={intl.formatMessage({ id: 'recoveryCodePlaceholder' })}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          defaultValue=""
          name="email"
          control={control}
          render={({ field: { onChange, value } }) => (
            <Form.Input
              className={classNames(theme.textColor, !!errors.email && 'error')}
              fluid
              id="email"
              name="email"
              label={intl.formatMessage({ id: 'email' })}
              placeholder={intl.formatMessage({ id: 'email' })}
              value={value}
              onChange={onChange}
            />
          )}
        />

        <Controller
          defaultValue=""
          name="newPassword"
          control={control}
          render={({ field: { onChange, value } }) => (
            <PasswordInput
              className={classNames(theme.textColor, !!errors.newPassword && 'error')}
              fluid
              id="newPassword"
              name="newPassword"
              label={intl.formatMessage({ id: 'newPassword' })}
              placeholder={intl.formatMessage({ id: 'newPasswordPlaceholder' })}
              value={value}
              onChange={onChange}
              submitRef={submitRef}
            />
          )}
        />

        <div className="field">
          <Ref innerRef={submitRef}>
            <Button
              loading={loading}
              floated="right"
              type="submit"
              size="large"
              name="change-password"
            >
              <FormattedMessage id="send" />
            </Button>
          </Ref>
        </div>
      </Form>
    </BasicPageLayout>
  );
};

