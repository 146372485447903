import React from 'react';
import styles from './styles.module.scss';

export const CloudDownloadIcon = ({ size = 20 }) => (
  <svg
    className={styles.icon}
    style={{ width: size }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 18.244"
  >
    <g transform="translate(0.004 -1.054)">
      <path
            d="M20,9.06A4.847,4.847,0,0,0,18.52,5.592a4.758,4.758,0,0,0-2.872-1.321.207.207,0,0,1-.159-.1A6.4,6.4,0,0,0,3.74,6.082a.209.209,0,0,1-.189.167A3.824,3.824,0,0,0,0,10.057a3.6,3.6,0,0,0,1.03,2.675,4.607,4.607,0,0,0,3,1.147.833.833,0,0,0,.821-.846.852.852,0,0,0-.846-.821,2.75,2.75,0,0,1-1.81-.667,1.978,1.978,0,0,1-.532-1.489,2.158,2.158,0,0,1,2.6-2.111.833.833,0,0,0,1-.766,4.738,4.738,0,0,1,9.015-1.74.8.8,0,0,0,.784.475,3.072,3.072,0,0,1,2.3.874A3.126,3.126,0,0,1,18.33,9.06a3.2,3.2,0,0,1-2.865,3.159.833.833,0,0,0,.11,1.66.8.8,0,0,0,.109-.007A4.838,4.838,0,0,0,20,9.06Z"
            transform="translate(0 0)"/>
      <path
            d="M14.166,15.945H12.708a.208.208,0,0,1-.208-.208V9.7a1.25,1.25,0,0,0-2.5,0V15.74a.208.208,0,0,1-.208.208H8.333a.833.833,0,0,0-.589,1.422l2.916,2.916a.833.833,0,0,0,1.178,0l2.916-2.916a.833.833,0,0,0-.589-1.422Z"
            transform="translate(-1.252 -1.233)"/>
    </g>
  </svg>
);
