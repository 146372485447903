import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { GRT, RIB } from 'global/tenant-config/tenants';
import { getAgreements } from './api';

const AGREEMENTS_KEY = 'agreements';

export function useAgreements() {
  
  const tenantId = getTenantIDBasedOnURL();
  const { data, isLoading, isError, isFetching } = useQuery(
    AGREEMENTS_KEY, 
    getAgreements, 
    { 
      retryOnMount: false,
      enabled: ![RIB, GRT].includes(tenantId),
    },
  );

  return {
    agreements: data || [],
    loading: isLoading,
    error: isError,
    isFetching,
  };
}
