import React from 'react';
import { convertToTimeZone } from 'date-fns-timezone';
import { checkServiceCallCenterOpenStatus, checkServiceOfficeOpenStatus } from 'utils/timeAndDate';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { StatusText } from 'components/typography/StatusText';

export const OfficeAndCallCenterStatus = () => {
  const tenantId = getTenantIDBasedOnURL();

  const localDate = new Date();
  const seDate = convertToTimeZone(localDate, { timeZone: 'Europe/Stockholm' });

  const callCenterOpen = checkServiceCallCenterOpenStatus(tenantId, seDate);
  const officeOpen = checkServiceOfficeOpenStatus(tenantId, seDate);

  return (
    <>
      <StatusText
        intl={officeOpen ? 'officeStatusOpen' : 'officeStatusClosed'}
        active={!!officeOpen}
      />

      <StatusText
        intl={callCenterOpen ? 'callCenterStatusOpen' : 'callCenterStatusClosed'}
        active={!!callCenterOpen}
      />
    </>
  );
};

