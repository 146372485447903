import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { LightningScenarios } from 'components/brikks/smartness/Lights/components/LightningScenarios';
import { LightningGroups } from 'components/brikks/smartness/Lights/components/LightningGroups';
import { ManageGroups } from 'components/brikks/smartness/Lights/components/LightningGroups/components';

export const LightningControls = () => (
  <MultiTabMenu
    title="overview"
    defaultTab="manageLightSources"
    tabs={{
      manageLightSources: {
        menus: [],
        component: LightningGroups,
      },
      manageGroups: {
        title: 'manageGroups',
        component: ManageGroups,
        tabComponent: LightningScenarios,
        menus: [],
      },
    }}
  /> 
); 
