import React, { createContext, useMemo, useState } from 'react';
import { Step } from './components/Step';
import { useScrollToTop } from 'components/MultiStep/useScrollToTop';

export  const MultiStepContext = createContext(null);

export const MultiStep = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);

  useScrollToTop(activeStep);

  const { childrenAsSteps, numberOfSteps } = useMemo(() => {
      const childrenAsSteps = React.Children.map(children, child => child);
      const numberOfSteps = childrenAsSteps.length - 1;

      return {
        childrenAsSteps,
        numberOfSteps,
      };
    }, [children],
  );

  const childAsActiveStep = childrenAsSteps[activeStep];

  const onNextStep = () => {
    setActiveStep(currentActiveStep => currentActiveStep === numberOfSteps
      ? currentActiveStep : currentActiveStep + 1);
  };

  const onPrevStep = () => {
    setActiveStep(currentActiveStep => currentActiveStep === 0 ? 0 : currentActiveStep - 1);
  };

  return (
    <MultiStepContext.Provider
      value={{
        activeStep,
        onNextStep,
        onPrevStep,
      }}
    >
      {childAsActiveStep}
    </MultiStepContext.Provider>
  )
};

MultiStep.Step = Step;
