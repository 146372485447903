import React from 'react';

export const CursorChooseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.835" viewBox="0 0 20 19.835">
            <g id="cursor-choose-1" transform="translate(0 -0.097)">
                <path id="Path_7582" data-name="Path 7582" d="M4,5.865,7.256,1.523a.833.833,0,1,0-1.333-1L3.243,4.1,1.422,2.277A.833.833,0,1,0,.244,3.455l2.5,2.5A.844.844,0,0,0,4,5.865Z" transform="translate(0 -0.015)" />
                <path id="Path_7583" data-name="Path 7583" d="M20.917,3.14l1.621-1.62A.833.833,0,1,0,21.36.341L19.739,1.962,18.115.341A.833.833,0,1,0,16.937,1.52l1.62,1.62L16.94,4.761a.833.833,0,0,0,0,1.178.846.846,0,0,0,1.178,0l1.621-1.621L21.36,5.94a.847.847,0,0,0,1.178,0,.833.833,0,0,0,0-1.178Z" transform="translate(-2.782 0)" />
                <path id="Path_7584" data-name="Path 7584" d="M15.812,13.133l-4.228-1.519V7.067a1.667,1.667,0,1,0-3.333,0v7.051l-.689-.949a1.288,1.288,0,0,0-1-.465l-.825.017a1.23,1.23,0,0,0-1.083,1.815l3.112,5.644a1.218,1.218,0,0,0,1.068.637h5.322a1.232,1.232,0,0,0,1.149-.825l.495-2.174.7-3.066A1.269,1.269,0,0,0,15.812,13.133Z" transform="translate(-0.751 -0.884)"  />
            </g>
        </svg>
    )
}