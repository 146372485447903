import React from 'react';
import styles from './styles.module.scss';

export const SwedishFlag = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className={styles.icon}
       viewBox="0 0 33.564 19.577">
    <g transform="translate(-512.629 -256.105)">
      <g className="b">
        <rect className={styles.background} width="33.564" height="19.577" transform="translate(512.629 256.105)"/>
        <rect className={styles.lines}  width="3.918" height="19.577" transform="translate(522.418 256.105)"/>
        <rect className={styles.lines}  width="33.564" height="3.915" transform="translate(512.629 263.936)"/>
      </g>
    </g>
  </svg>
);
