import React from 'react';

export const MyHomeIcon = () => (
  <svg xmlns = "http://www.w3.org/2000/svg" width = "20" height = "18" viewBox = "0 0 20 18">
    <desc>Building icon</desc>
    <path
        d="M12,7V3H2V21H22V7ZM6,19H4V17H6Zm0-4H4V13H6Zm0-4H4V9H6ZM6,7H4V5H6Zm4,12H8V17h2Zm0-4H8V13h2Zm0-4H8V9h2Zm0-4H8V5h2ZM20,19H12V17h2V15H12V13h2V11H12V9h8Zm-2-8H16v2h2Zm0,4H16v2h2Z"
        transform="translate(-2 -3)"
    />
  </svg>
);
