import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import enUS from 'date-fns/locale/en-US';
import sv from 'date-fns/locale/sv';
import { ENGLISH, SWEDISH } from 'services/user/locale/utils';
import { useLocale } from 'services/user/locale/hook';
import { daysFormat } from 'utils/timeAndDate';
import styles from './styles.module.scss';

registerLocale(ENGLISH, enUS);
registerLocale(SWEDISH, sv);

export const DatePicker = ({ 
  id, 
  selectedDate,
  minDate,
  maxDate,
  isOpen, 
  isInline, 
  onChange, 
  dateFormat,
  label,
  ...props
}) => {
  const { locale } = useLocale();

  return (
    <ReactDatePicker
      calendarContainer={({ className, children }) => (
        <div
          className={`${className} ${styles.datePicker}`}
        >
          {children}
        </div>
      )}
      className={styles.picker}
      open={isOpen}
      inline={isInline}
      id={id}
      selected={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      onChange={onChange}
      dateFormat={dateFormat || daysFormat}
      locale={locale}
      popperClassName={styles.reactPopper}
      {...props}
    />
  );
};
