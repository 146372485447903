import React from 'react';
import { useLocation } from 'react-router-dom';
import { AppTracking } from 'global/appTracking';

export const PageTracking = () => {
  const location = useLocation();
  AppTracking.pageview(location.pathname);

  return <></>;
};
