import React from 'react';

export const MyInfoIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15.833" viewBox="0 0 20 15.833">
    <desc>Illustration of a user identifier</desc>
    <g transform="translate(-460 -247)">
      <path d="M20,6.667A1.667,1.667,0,0,0,18.333,5H16.875a.208.208,0,0,1-.208-.208V3.333a.833.833,0,0,0-.833-.833H1.667A1.667,1.667,0,0,0,0,4.167v10A.833.833,0,0,0,.833,15H2.292a.208.208,0,0,1,.208.208v1.458a1.667,1.667,0,0,0,1.667,1.667h2.5A.833.833,0,0,0,7.5,17.5a.625.625,0,1,1,1.25,0,.833.833,0,0,0,.833.833h3.333a.833.833,0,0,0,.833-.833.625.625,0,1,1,1.25,0,.833.833,0,0,0,.833.833h2.5A1.667,1.667,0,0,0,20,16.667ZM18.333,16.25a.417.417,0,0,1-.417.417H16.647a.207.207,0,0,1-.189-.122,2.292,2.292,0,0,0-4.167,0,.207.207,0,0,1-.189.122H10.4a.207.207,0,0,1-.189-.122,2.292,2.292,0,0,0-4.167,0,.207.207,0,0,1-.189.122H4.583a.417.417,0,0,1-.417-.417V7.083a.417.417,0,0,1,.417-.417H17.917a.417.417,0,0,1,.417.417Z"
            transform="translate(460 244.5)"/>
      <path d="M14.75,10.625a.625.625,0,0,0,.625.625h3.75a.625.625,0,0,0,0-1.25h-3.75a.625.625,0,0,0-.625.625Z"
            transform="translate(457.541 243.25)"/>
      <path d="M17.458,13H15.375a.625.625,0,1,0,0,1.25h2.083a.625.625,0,0,0,0-1.25Z"
            transform="translate(457.541 242.75)"/>
      <path d="M10.43,12.787c-.012-.063-.011-.167-.016-.177a2.927,2.927,0,0,0,.645-2.029,1.728,1.728,0,1,0-3.452,0,2.874,2.874,0,0,0,.613,1.984.507.507,0,0,1,.016.222c-1.095.408-1.7.716-1.957,1.228a3.508,3.508,0,0,0-.273.927.417.417,0,0,0,.41.491H12.25a.417.417,0,0,0,.41-.491,3.508,3.508,0,0,0-.273-.927C12.129,13.5,11.524,13.195,10.43,12.787Z"
            transform="translate(459 243.457)"/>
    </g>
  </svg>
);
