import React from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl'; 
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Button = (
  {
    children,
    className,
    name,
    disabled,
    intl,
    onClick,
    loading,
    floated,
    size,
    type = 'button',
    ...props
  },
) => (
  <SemanticButton
    className={classNames(type === 'submit' && styles.submit, disabled && styles.disabled, className)}
    name={name}
    disabled={disabled || loading}
    onClick={onClick}
    loading={loading}
    type={type}
    floated={floated}
    size={size}
    {...props}
  >
    { intl ? <FormattedMessage id={intl} /> : { children }}
  </SemanticButton>
);
