import React, { useState } from 'react';
import Draggable from "components/drag-and-drop/Draggable";
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { MovingOutModal } from "components/brikks/home/MovingOut/components/MovingOutModal";
import { MovingOutIcon } from "assets/icons/brikks/MovingOutIcon";
import { MovingOutPreview } from "components/brikks/home/MovingOut/components/MovingOutPreview";
import { useAgreements } from "services/agreements/agreements";
import { BrikkInfoModal } from "components/modals/BrikkInfoModal";

export const MovingOut = ({ position, code, status, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { agreements, loading } = useAgreements();

  const isDisabled = agreements.some(agreement => agreement.status === "active" && agreement.productType === "Lägenhet");

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(!isModalOpen);
  }

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen)
  }

  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} status={status} name={name} isLoading={loading} disabled={isDisabled}>
          <Brikk.Header
            icon={<MovingOutIcon/>}
            heading="movingOutBrikk"
            brikkKey={code}
            status={status}
          />

          <Brikk.Content center>
            <MovingOutPreview
              agreements={agreements}
            />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton onClick={handleOpenModal} brikkNameIntl="movingOut" disabled={isDisabled}/>
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Moving out info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="movingOut"
        onClose={() => setIsModalOpen(false)}
      >
        <MovingOutModal />
      </BrikkModal>
    </>
  )
};
