import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataForClient } from './mapData';

export async function getUserDetailsAPI() {
  const url = `${AWS_API_URL}/my-details`;

  const data = await getRequest(url, await authorize());
  return mapDataForClient(data);
}
