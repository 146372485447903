import { authorize, postRequest } from "utils/http";
import { AWS_API_URL } from "global/environment";

const url = `${AWS_API_URL}/order`;

export async function createOrder(orderedBrikks) {
    return postRequest(
        url,
        orderedBrikks,
        await authorize(),
    );
}
