import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Image } from './components/Image';
import { Icon } from './components/Icon';
import { Content } from './components/Content';
import { Input } from './components/Input';
import { Tooltip } from './components/Tooltip';

export const ContentField = ({ faded, children }) => (
  <div className={classNames(styles.contentField, faded && styles.faded)}>
    {children}
  </div>
);

ContentField.Image = Image;
ContentField.Icon = Icon;
ContentField.Content = Content;
ContentField.Input = Input;
ContentField.Tooltip = Tooltip;
