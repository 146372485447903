import React from 'react';
import classNames from 'classnames';
import { Button } from 'semantic-ui-react';
import { Image } from 'components/media/Image';
import { Heading, Text } from 'components/typography';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { useTheme } from 'global/themes';
import { ArticleDetails } from 'components/feed/ArticleDetails';
import { parseTextToHtml } from 'utils/format';
import styles from './styles.module.scss';

export const SelectedArticle = ({ selectedArticle }) => {
  const [isFontIncreased, setIsFontIncreased] = React.useState(false);

  const { theme } = useTheme();

  if (!selectedArticle) {
    return (
      <div className={classNames(styles.selectedArticlePreview, theme.backgroundColor)}>
        <Heading
          xxl
          normal
        >
          <FormattedMessage id="noArticleSelected" />
        </Heading>
      </div>
    );
  }

  return (
    <div
      id="selectedArticle"
      className={styles.selectedArticle}
    >
      <Button
        className={styles.fontButton}
        onClick={() => setIsFontIncreased(!isFontIncreased)}
      >
        Aa
      </Button>

      <div className={classNames(styles.article, theme.backgroundColor)}>
        <Heading xxl>
          {selectedArticle.title}
        </Heading>

        <div className={styles.feedAndDate}>
          <ArticleDetails
            datePublished={selectedArticle.datePublished}
            feed={selectedArticle.feed}
          />
        </div>

        <ShouldRender condition={!!selectedArticle.image}>
          <div className={styles.articleImage}>
            <Image
              src={selectedArticle.image}
              alt="Article image"
            />
          </div>
        </ShouldRender>

        <div className={styles.content}>
          <Text large={isFontIncreased}>
            {parseTextToHtml(selectedArticle.description)}
          </Text>
        </div>

        <div className={styles.readMore}>
          <a
            href={selectedArticle.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="readMore" />
          </a>
        </div>
      </div>
    </div>
  );
};
