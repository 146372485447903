import React, { useState, useMemo } from 'react';
import { Form } from 'semantic-ui-react';
import contactUsImgSrc from 'assets/media/contact-us.png';
import { Grid } from 'components/layout/Grid';
import { Heading } from 'components/typography/Heading';
import { Text } from 'components/typography/Text';
import { TelephonePicker } from 'components/user-components/TelephonePicker';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { ContactInformation } from 'components/user-components/ContactInformation';
import { useTheme } from 'global/themes';
import { RIB } from 'global/tenant-config/tenants';
import { Submit } from './components';

function ContactUsModal() {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();
  const [description, setDescription] = useState('');
  const [telephone, setTelephone] = useState('');
  const [telType, setTelType] = useState('');

  const { theme } = useTheme();

  const isTelPickerDisabled = useMemo(() => !description.trim(), [description]);

  const handleTelFieldChange = (telNumber, telType) => {
    setTelephone(telNumber);
    setTelType(telType);
  };

  const reset = () => {
    setDescription('');
    setTelephone('');
    setTelType('');
  };

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="contactUs" />

      <Grid.Column>
        <Brikk.Heading
          main
          heading="contactUsTitle"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={contactUsImgSrc}
              alt="Man with headset working in a call center"
            />

            <ModalIntro.Text intl="contactUsDescription" />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="writeUs">
          <Text
            intl="writeUsDescription"
            large
          />

          <br />

          <Form>
            <Form.TextArea
              className={theme.textColor}
              name="description"
              placeholder=""
              value={description}
              rows={8}
              onChange={(e, { value }) => setDescription(value)}
              disabled={tenantId === RIB}
            />
            
            <Heading>
              <Text
                intl="getInTouchLabel"
                uppercase
                xxl1
              />
            </Heading>

            <br />

            <TelephonePicker
              telNumb={telephone}
              disabled={isTelPickerDisabled}
              onChange={handleTelFieldChange}
            />

            <Submit
              description={description}
              telNumber={telephone}
              telType={telType}
              reset={reset}
            />
          </Form>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <ContactInformation />
      </Grid.Column>
    </Grid>
  );
}

export default ContactUsModal;
