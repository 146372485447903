import React, { useState } from 'react';
import Loader from 'components/loaders/Loader';
import { SingleDropdown } from 'components/dropdowns/SingleDropdown';
import { Text } from 'components/typography';
import { LightGroup } from 'components/brikks/smartness/Lights/components/LightningGroups/components';
import { useLightningGroups } from 'services/lightning/groups';

export const LightningGroups = () => {
  const { lightningGroups, isLoading } = useLightningGroups();
  const [selectedGroup, setSelectedGroup] = useState();

  if (isLoading) return <Loader />;

  return (
    <>
      {
        lightningGroups && Object.keys(lightningGroups).length > 0
          ? Object.keys(lightningGroups).map(key => (
            <SingleDropdown key={key}>
              <SingleDropdown.Item
                item={key}
                itemKey={key}
                selectedItem={selectedGroup}
                onClick={() => setSelectedGroup(key !== selectedGroup ? key : null)}
              >
                {key}
              </SingleDropdown.Item>

              <SingleDropdown.Content
                selectedItem={selectedGroup}
                item={key}
              >
                <LightGroup 
                  devices={lightningGroups[key].devices} 
                />
              </SingleDropdown.Content>
            </SingleDropdown> 
          )) : (
            <Text
              secondary
              center
              intl="noGroupsToDisplay"
              size={16}
            />
          )
      }
    </>
  );
};
