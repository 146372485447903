import React from 'react';
import { Text } from 'components/typography/Text';
import { dateAndMonthName, formatDate, hourAndMinute } from 'utils/timeAndDate';

export const ArticleDetails = ({ datePublished, feed }) => (
  <Text xs>
    <strong>{formatDate(datePublished, dateAndMonthName)}</strong>&nbsp;
    {formatDate(datePublished, 'yyyy')}&nbsp;
    <strong>{formatDate(datePublished, hourAndMinute)}</strong> VIA <strong>{feed}</strong>
  </Text>
);
