import { all } from 'redux-saga/effects';
import { userAvatarEffects } from 'services/user/avatar/effects';
import { bankIDLoginEffects } from 'services/auth/bank-id-login/effects';
import { confirmEmailEffects } from 'services/auth/confirm-email/effects';
import { loginEmailEffects } from 'services/auth/login-email/effects';
import { initPassResetEffects } from 'services/auth/init-pass-reset/effects';
import { resetPassEffects } from 'services/auth/reset-pass/effects';
import { downloadAgreementEffects } from 'services/agreements/download-agreement/effects';
import { downloadInvoiceEffects } from 'services/invoices/download-invoice/effects';
import { agreementPropertiesEffects } from 'services/agreements/agreement-properties/effects';
import { localeEffects } from 'services/user/locale/effects';
import { userSettingsEffects } from 'services/user/settings/effects';
import { bankIDSigningEffects } from 'services/auth/bank-id-signing/effects';
import { maintenanceProductsEffects } from 'services/maintenance/maintenance-products/effects';
import { bankIDOrderStatusEffects } from 'services/auth/bank-id-order-status/effects';
import { submitMaintenanceOrderEffects } from 'services/maintenance/submit-maintenance-order/effects';
import { submitCreateOrderEffects } from 'services/order/create-order/effects';

export function* rootSaga() {
  yield all([
    ...userAvatarEffects,
    ...bankIDLoginEffects,
    ...bankIDSigningEffects,
    ...confirmEmailEffects,
    ...loginEmailEffects,
    ...initPassResetEffects,
    ...resetPassEffects,
    ...downloadAgreementEffects,
    ...downloadInvoiceEffects,
    ...agreementPropertiesEffects,
    ...localeEffects,
    ...userSettingsEffects,
    ...maintenanceProductsEffects,
    ...bankIDOrderStatusEffects,
    ...submitMaintenanceOrderEffects,
    ...submitCreateOrderEffects,
  ]);
}
