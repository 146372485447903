import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import sw from 'translations/swedish';
import en from 'translations/english';
import { useMemo } from 'react';
import { AppContext } from 'AppContext';
import { SWEDISH, ENGLISH } from 'services/user/locale/utils';
import { tenantConfig } from 'global/tenant-config';

export const TranslationsProvider = ({ children }) => {
  const { intl: { swedish: tenantSwedish, english: tenantEnglish } } = tenantConfig;

  const languages = useMemo(() => (
    {
      [SWEDISH]: { ...sw, ...tenantSwedish },
      [ENGLISH]: { ...en, ...tenantEnglish }
    }
    ), [tenantSwedish, tenantEnglish]);

  const { locale: { locale } } = useContext(AppContext);

  return (
    <IntlProvider
      locale={locale}
      messages={languages[locale]}
    >
      {children}
    </IntlProvider>
  )
};
