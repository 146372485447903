export function getSpeeds(providers) {
  const data = [...new Set(providers?.map(item => item.upSpeed))];

  return data.sort((a, b) => a - b).map((speed, index) => ({
    id: index,
    upload: speed,
    download: speed,
    description: `description${speed}`,
  }));
}
