import React from 'react';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Text } from 'components/typography/Text';
import { useHistory } from 'react-router-dom';
import { HOME_PAGE } from 'global/routes';
import { Grid } from 'components/layout/Grid';
import { NavigationAnimatedButton } from 'components/form-elements/buttons/NavigationAnimatedButton';
import styles from './styles.module.scss';

export const PageNotFound = () => {
  const history = useHistory(); 

  return (
    <Grid 
      oneColumn
      className={styles.container}
    > 
      <IntlPageTitle intlTitle="pageNotFound" />

      <Grid.Column className={styles.content}>
        
        <Text
          bold
          center
          red
          size={70}
        >
          404
        </Text>
        
        <Text
          size={40}
          bold
          intl="pageNotFoundHeader"
          center
          red
        />

        <Text
          size={20}
          bold
          center
          dark
          intl="pageNotFoundMessage"
        />

        <div className={styles.button}>
          <NavigationAnimatedButton
            intl="goToHomepage"
            onClick={() => history.push(HOME_PAGE)}
          />
        </div>
      </Grid.Column>
    </Grid>
  ); 
};

