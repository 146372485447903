import React from 'react';
import classNames from 'classnames';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';
import styles from './styles.module.scss';

export const OnboardingImage = ({ children, className, imageSrc }) => (
  <ImageWithOverlay
    imageSrc={imageSrc}
    className={classNames(styles.onboardingImage, className)}
  >
    {children}
  </ImageWithOverlay>
);
