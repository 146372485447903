import React, { useContext } from 'react';
import classNames from 'classnames';
import { useTheme } from 'global/themes';
import { AppContext } from 'AppContext';
import styles from './styles.module.scss';

export const PageContainer = ({ children }) => {
  const { theme } = useTheme();

  const { userSettings: { background } } = useContext(AppContext);

  return (
    <div
      className={classNames(styles.pageContainer, theme.pageStyles)}
      style={{ backgroundImage: `url(${background})` }}
    >
      {children}
    </div>
  );
};
