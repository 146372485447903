import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const BgImage = ({ bgImage, className, children }) => (
  <div
    style={{
      background: `url(${bgImage}) center`,
      backgroundSize: 'cover',
    }}
    className={classNames(
      styles.bgImage,
      className,
    )}
  >
    {children}
  </div>
);
