export const filmsData = [
    {
      title: "howToFixTheElectricity",
      videUrl: "https://www.youtube.com/embed/p7DOfEBpjWA",
    },
    {
      title: "goodAirAndHeat",
      videUrl: "https://www.youtube.com/embed/a0rt4CihdIs",
    },
    {
      title: "cleanTheWaterTrap",
      videUrl: "https://www.youtube.com/embed/cW2ifmkPekY",
    },
    {
      title: "cleanTheFloorDrain",
      videUrl: "https://www.youtube.com/embed/w5RG1xesRsA",
    },
    {
      title: "fireSafety",
      videUrl: "https://www.youtube.com/embed/Mzm0_tbEe98",
    },
    {
      title: "laundryRoomEtiquette",
      videUrl: "https://www.youtube.com/embed/kRhLyZmV2Jk",
    },
    {
      title: "cloggedSink",
      videUrl: "https://www.youtube.com/embed/8Vb0RJKu18M",
    },
    {
      title: "yourSafetyDoor",
      videUrl: "https://www.youtube.com/embed/-kOO9sVp7cE",
    },
  ];