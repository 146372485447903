import React, { useCallback } from 'react';
import { FloorPlan } from 'components/user-components/FloorPlan';
import { useFloorPlan } from 'services/living-info/floor-plan';
import { Button, Form } from 'semantic-ui-react';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const PlanOverview = () => {
  const { floorPlan, onDownloadFloorPlan } = useFloorPlan();

  const onDownload = useCallback((e) => {
    e.preventDefault();
    onDownloadFloorPlan();
  }, [onDownloadFloorPlan]);

  return (
    <div className={styles.planOverview}>
      <FloorPlan />

      <ShouldRender condition={floorPlan.type === 'buffer'}>
        <Form className={styles.downloadDocument}>
          <Text
            intl="downloadFloorPlan"
            large
            right
          />

          <br />

          <Button
            className={styles.button}
            type="submit"
            disabled={!floorPlan}
            onClick={onDownload}
            floated="right"
          >
            <FormattedMessage id="download" />
          </Button>
        </Form>
      </ShouldRender>
    </div>
  );
};
