import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import { useIntl } from 'react-intl';
import { ShouldRender } from 'components/ShouldRender';
import { useScreenSize } from 'utils/screen-size';
import { getMonthDate, getMonthDays, monthNumberToName, subtractMonths } from 'utils/timeAndDate';
import { SCREEN_XS, SCREEN_SM, SCREEN_LG, SCREEN_XL, SCREEN_XXL } from 'global/screenBreakpoints';
import { useWaterUsage } from 'services/water-usage';
import classNames from 'classnames';
import { previousMonthPeriod } from 'utils/water-usage/datePeriods';
import { makeChartBarRoundedTop, excludeFutureDates, getOffsetPlugin, processWaterUsage } from './helper';
import styles from './styles.module.scss';
 
export const WaterUsageChart = (
  { 
    waterCategory, 
    period, 
    waterUsage, 
    isLoading, 
    selectedMonthLabel,
    waterSustainabilityLimit, 
  },
) => {
  const intl = useIntl();

  // Chart grid size.
  const [suggestedMax, setSuggestedMax] = useState(300);

  // Currently selected period data.
  const [chartData, setChartData] = useState({ values: [], labels: [] });

  // Previous period data.
  const [chartDataPrev, setChartDataPrev] = useState({ values: [], labels: [] });

  const [barColor, setBarColor] = useState('');

  // Currently selected period average value.
  const [averageValue, setAverageValue] = useState(0);

  // Prev period average value.
  const [averageValuePrev, setAverageValuePrev] = useState(0);

  const { width } = useScreenSize();

  const barThickness = width <= SCREEN_XS ? 3
    : width <= SCREEN_SM ? 4
      : width <= SCREEN_LG ? 6
        : width <= SCREEN_XXL ? 8
          : 10;

  const currentPeriodBarsOffset = Math.round(barThickness * 0.3);

  // Customize chart bars.
  makeChartBarRoundedTop();

  // Get water usage data for previous month.
  const {
    reportAfter: prevMonthReportAfter,
    reportBefore: prevMonthReportBefore,
  } = previousMonthPeriod(period);
  const {
    waterUsage: prevMonthWaterUsage, 
    isLoading: prevMonthLoading,
    isError: prevMonthError,
  } = useWaterUsage(prevMonthReportAfter, prevMonthReportBefore);

  const prevMonthLabel = monthNumberToName(new Date(prevMonthReportAfter).getMonth() + 1);

  // Create a plugin for offsetting chart bars.
  const offsetPlugin = getOffsetPlugin(currentPeriodBarsOffset);
  
  // Process currently selected month water usage.
  useEffect(() => {
    if (!!waterUsage && !isLoading) {

      // Generate label-value pairs for chart (currently selected period).
      const {
        coldWaterUsage,
        hotWaterUsage,
        totalWaterUsage,
      } = processWaterUsage(waterUsage);

      const { coldWater, hotWater, totalWater } = waterUsage;
      const labels = Array.from({ length: getMonthDays(new Date()) }, (_, i) => i + 1);

      // Prepare data for charts in proper format.
      switch (waterCategory) {
        case 'hotWater':
          setChartData({ values: hotWaterUsage, labels });
          setAverageValue(hotWater.total / excludeFutureDates(hotWater.values).length);
          setBarColor('#ff0202');
          break;
        case 'coldWater':
          setChartData({ values: coldWaterUsage, labels });
          setAverageValue(coldWater.total / excludeFutureDates(coldWater.values).length);
          setBarColor('#2185d0');
          break;
        default:
          setChartData({ values: totalWaterUsage, labels });
          setAverageValue(totalWater / excludeFutureDates(coldWater.values).length);
          setBarColor('#55B336');
          break;
      }
    } else if (!waterUsage && !isLoading) {
      setChartData({ values: [], labels: [] });
    }

  }, [isLoading, waterUsage, waterCategory, barColor]);

  // Process previous month water usage independently.
  useEffect(() => {
    if (!!prevMonthWaterUsage && !prevMonthError && !prevMonthLoading) {
      // Generate label-value pairs for chart (previous period).
      const {
        coldWaterUsage: coldWaterUsagePrev,
        hotWaterUsage: hotWaterUsagePrev,
        totalWaterUsage: totalWaterUsagePrev,
      } = processWaterUsage(prevMonthWaterUsage);

      const {
        coldWater: coldWaterPrev,
        hotWater: hotWaterPrev,
        totalWater: totalWaterPrev,
      } = prevMonthWaterUsage;

      const labelsPrev = Array.from({ length: getMonthDays(subtractMonths(new Date(), 1)) }, (_, i) => i + 1);

      // Prepare data for charts in proper format.
      switch (waterCategory) {
        case 'hotWater':
          setChartDataPrev({ values: hotWaterUsagePrev, labels: labelsPrev });
          setAverageValuePrev(hotWaterPrev.total / hotWaterPrev.values.length);
          break;
        case 'coldWater':
          setChartDataPrev({ values: coldWaterUsagePrev, labels: labelsPrev });
          setAverageValuePrev(coldWaterPrev.total / coldWaterPrev.values.length);
          break;
        default:
          setChartDataPrev({ values: totalWaterUsagePrev, labels: labelsPrev });
          setAverageValuePrev(totalWaterPrev / coldWaterPrev.values.length);
          break;
      }
    } else if (!prevMonthWaterUsage && !prevMonthLoading) {
      setChartDataPrev({ values: [], labels: [] });
    }
  }, [prevMonthWaterUsage, prevMonthLoading, waterCategory, prevMonthError]);

  // Set proper chart grid size depending on max value.
  useMemo(() => {
    const maxCurrent = Math.max(...chartData.values) || 0;
    const maxPrev = Math.max(...chartDataPrev.values) || 0;
    const maxValue = waterCategory === 'total' 
      ? Math.max(maxCurrent, maxPrev, waterSustainabilityLimit) : Math.max(maxCurrent, maxPrev);

    // Find maximum value in chart data to set as a chart grid size.
    if (!!maxValue && maxValue > 0) {
      // Ceil value to "20". And set as chart grid size.
      setSuggestedMax(Math.ceil((maxValue + 1) / 20) * 20);
    }
  }, [chartData, chartDataPrev, waterSustainabilityLimit, waterCategory]);

  // Clear chart data when changing period and while water usage is loading.
  useEffect(() => {
    if (isLoading) {
      setChartData({ values: [], labels: [] });
      setAverageValue(0);
    }
    if (prevMonthLoading) {
      setChartDataPrev({ values: [], labels: [] });
      setAverageValuePrev(0);
    }
  }, [period, isLoading, prevMonthLoading]);

  const chartDatasets = {
    // Select labels of month with more days (if prev month has more days, then show them instead of trimming).
    labels: chartData.labels.length > chartDataPrev.labels.length ? chartData.labels : chartDataPrev.labels,
    datasets: [
      {
        backgroundColor: barColor,
        label: `${intl.formatMessage({ id: waterCategory })} (${intl.formatMessage({ id: 'currentPeriod' })})`,
        data: chartData.values,
        yAxisID: 'bar-y-axis1',
        barThickness,
      },
      {
        backgroundColor: '#c6c6c6',
        label: `${intl.formatMessage({ id: waterCategory })} (${intl.formatMessage({ id: 'previousPeriod' })})`,
        data: chartDataPrev.values,
        yAxisID: 'bar-y-axis2',
        barThickness,
      },
    ],
  };

  const chartOptions = {
    scales: {
      xAxes: [
        {
          offset: true,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: '#bbb',
            maxRotation: 0,
            autoSkip: false,
            callback: (value, index) => {
              // Show labels only for first and last day on currently selected month.
              if (index === 0 || index === chartData.labels.length - 1) {
                return `${getMonthDate(new Date(index === 0 ? period.reportAfter : period.reportBefore))} ${
                  intl.formatMessage({ id: selectedMonthLabel })} ${
                  new Date(period.reportAfter).getFullYear()}`;
              }
            },
          },
        },
      ],
      yAxes: [
        {
          id: 'bar-y-axis1',
          stacked: false,
          display: true,
          position: 'left',
          ticks: {
            beginAtZero: true,
            mirror: false,
            suggestedMin: 0,
            suggestedMax,
            fontColor: '#bbb',
            callback: (value) => `${value} ${intl.formatMessage({ id: 'litresSign' })} `,
          },
          gridLines: {
            color: '#bbb',
            lineWidth: 0.5,
          },
        },
        {
          id: 'bar-y-axis2',
          stacked: false,
          display: width > SCREEN_SM,
          position: 'right',
          ticks: {
            beginAtZero: true,
            mirror: false,
            suggestedMin: 0,
            suggestedMax,
            fontColor: '#bbb',
            callback: (value) => `${value} ${intl.formatMessage({ id: 'litresSign' })} `,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        title: (tooltipItems) => `${tooltipItems[0].yLabel} ${intl.formatMessage({ id: 'litres' })}`,
        label: (tooltipItem) => {
          // Show current/previous month labels, depending on dataset.
          const monthLabel = tooltipItem.datasetIndex === 0 ? selectedMonthLabel : prevMonthLabel;
          return ` ${intl.formatMessage({ id: monthLabel })}, ${Number(tooltipItem.xLabel)}`;
        },
      },
    },
    legend: {
      display: true,
      labels: {
        fontColor: '#aaa',
        usePointStyle: true,
      },
    },
    annotation: {
      annotations: [
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'bar-y-axis1',
          value: averageValue,
          borderWidth: 1,
          borderColor: barColor,
          borderDash: [15, 8],
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'bar-y-axis2',
          value: averageValuePrev,
          borderWidth: 1,
          borderColor: '#c6c6c6',
          borderDash: [15, 8],
        },
        {
          type: 'line',
          mode: 'horizontal',
          scaleID: 'bar-y-axis1',
          value: waterCategory === 'total' ? waterSustainabilityLimit : null,
          borderWidth: 2,
          borderColor: '#34a8eb',
          borderDash: [15, 8],
        },
      ],
    },
    cornerRadius: 10,
    responsive: true,
  };

  return (
    <div className={styles.chartWrapper}>

      <ShouldRender condition={isLoading}>
        <div className={classNames(styles.loadingOverlay, styles.faded)}>
          <div className={styles.loadingSpinner} />
        </div>
      </ShouldRender>

      <ShouldRender condition={!isLoading && prevMonthLoading}>
        <div className={styles.loadingOverlay}>
          <div className={styles.loadingSpinner} />
        </div>
      </ShouldRender>

      <Bar
        data={chartDatasets}
        options={chartOptions}
        height={width <= SCREEN_SM ? 80 : width <= SCREEN_XL ? 60 : 50}
        width={100}
        plugins={[ChartAnnotation, offsetPlugin]}
      />
    </div>
  );
};
