import React from 'react';

export const DeliveryIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.541" viewBox="0 0 20 19.541">
            <g id="shipment-upload" transform="translate(-0.5 -0.763)">
                <path id="Path_6418" data-name="Path 6418" d="M13.25,20.817a.218.218,0,0,0,.307.2l8.1-3.68a.87.87,0,0,0,.51-.791V6.853a.217.217,0,0,0-.307-.2L13.38,10.509a.216.216,0,0,0-.128.2Zm4.261-8.369a.435.435,0,0,1,.616,0l1.3,1.3a.435.435,0,0,1-.308.743h-.435a.217.217,0,0,0-.217.217v1.522a.652.652,0,1,1-1.3,0V14.712a.217.217,0,0,0-.217-.217h-.438a.435.435,0,0,1-.308-.743Z" transform="translate(-1.663 -0.766)" />
                <path id="Path_6419" data-name="Path 6419" d="M6.657,9.068a.217.217,0,0,0-.3.2v1.745a.652.652,0,1,1-1.3,0V8.544a.217.217,0,0,0-.134-.2L.8,6.628a.217.217,0,0,0-.2.019.22.22,0,0,0-.1.181v9.692a.87.87,0,0,0,.535.8l8.943,3.728a.217.217,0,0,0,.3-.2V10.723a.218.218,0,0,0-.134-.2Z" transform="translate(0 -0.763)" />
                <path id="Path_6420" data-name="Path 6420" d="M14.066,2.554a.217.217,0,0,0,0-.4L11.023.836a.87.87,0,0,0-.7,0L1.973,4.469a.217.217,0,0,0,0,.4L5.631,6.39a.215.215,0,0,0,.174,0Z" transform="translate(-0.175 0)" />
                <path id="Path_6421" data-name="Path 6421" d="M11.885,8.979a.22.22,0,0,0,.174,0L20.3,5.228a.217.217,0,0,0,0-.4l-3.232-1.4a.219.219,0,0,0-.174,0L8.67,7.245a.217.217,0,0,0,.009.4Z" transform="translate(-1.049 -0.345)" />
            </g>
        </svg>
    )
}