import React, { createContext } from 'react';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { useWaterUsage } from 'services/water-usage';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { getLast30Days, getYesterdayPeriod } from 'utils/water-usage/datePeriods';
import { TrendProvider } from './components/TrendProvider';
import { WaterUsageCards } from './components/WaterUsageCards';
import { ChartWrapper } from './components/ChartWrapper';

export const WaterUsageContext = createContext({
  loading24Hours: false,
  waterUsage24Hours: null,
  loading30Days: false,
  waterUsage30Days: null,
  error24Hours: '',
  error30Days: '',
});

export const WaterUsageModal = () => {
  const { reportAfter: pre24Hours, reportBefore: after24Hours } = getYesterdayPeriod();
  const { waterUsage: waterUsage24Hours, isLoading: loading24Hours, isError: error24Hours } = useWaterUsage(pre24Hours, after24Hours);

  const { reportAfter: pre30Days, reportBefore: after30Days } = getLast30Days();
  const { waterUsage: waterUsage30Days, isLoading: loading30Days, isError: error30Days } = useWaterUsage(pre30Days, after30Days);

  return (
    <WaterUsageContext.Provider value={{
      loading24Hours,
      waterUsage24Hours,
      error24Hours,
      loading30Days,
      waterUsage30Days,
      error30Days,
    }}
    >
      <Grid
        brikkLayout
        twoColumns
      >
        <IntlPageTitle intlTitle="waterUsageBrikk" />

        <Grid.Column>
          <Brikk.Heading
            main
            heading="usage"
          >
            <TrendProvider>
              <WaterUsageCards />
            </TrendProvider>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="timeline">
            <ChartWrapper />
          </Brikk.Heading>
        </Grid.Column>
      </Grid>

    </WaterUsageContext.Provider>
  );
};
