import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { convertToTimestamp } from 'utils/timeAndDate';
import { mapDataToClient } from './mapData';

const url = `${AWS_API_URL}/water-usage`;

export async function getWaterUsageAPI(reportAfter, reportBefore) {

  const data = await getRequest(`${url}?from=${convertToTimestamp(reportAfter)}&to=${convertToTimestamp(reportBefore)}`, await authorize());

  return mapDataToClient(data);
}
  
