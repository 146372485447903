/*
* All user's properties info
 */

import { GetAgreementPropertiesActions } from './actions';

const initialState = {
  properties: {},
  loading: false,
  error: '',
  calledOnce: false,
};

export function agreementPropertiesReducer(state = initialState, action) {
  switch (action.type) {
    case GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES:
      return {
        ...state,
        loading: true,
        error: '',
        calledOnce: true,
      };
    case GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES_SUCCESS:
      return {
        ...state,
        properties: action.payload,
        loading: false,
      };
    case GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
