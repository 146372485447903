import React from 'react';
import Loader from 'components/loaders/Loader';
import { SingleDropdown } from 'components/dropdowns/SingleDropdown';
import { Text } from 'components/typography';
import { useSensors } from 'services/climate/sensors';
import { Field } from 'components/form-elements/Field';
import { ClimateModalContext } from 'components/brikks/smartness/Climate/components/ClimateModal';

export const ApartmentRooms = () => {
  const { sensors, isLoading } = useSensors();
  const [selectedRoom, setSelectedRoom] = React.useState();
  const { selectedSensor, setSelectedSensor } = React.useContext(ClimateModalContext);

  if (isLoading) return <Loader />;

  function onRoomChange(key) {
    setSelectedRoom(key !== selectedRoom ? key : null);
    setSelectedSensor(null);
  }

  return (
    <>
      {
        sensors && Object.keys(sensors).length > 0
          ? Object.keys(sensors)?.map(key => (
            <SingleDropdown key={key}>
              <SingleDropdown.Item
                item={key}
                itemKey={key}
                selectedItem={selectedRoom}
                onClick={() => onRoomChange(key)}
              >
                {key}
              </SingleDropdown.Item>

              <SingleDropdown.Content
                selectedItem={selectedRoom}
                item={key}
              >
                {
                  sensors[key].map((sensor) => (
                    <Field
                      key={sensor?.deviceId}
                      onClick={() => setSelectedSensor(sensor)}
                      field={sensor?.deviceId}
                      fieldName={sensor?.displayName.split('-')[1]?.trim()}
                      selectedField={selectedSensor?.deviceId}
                    />
                  ))
                }
              </SingleDropdown.Content>
            </SingleDropdown>
          )) : (
            <Text
              secondary
              center
              intl="noDevicesToDisplay"
              size={16}
            />
          )
      }
    </>
  );
};
