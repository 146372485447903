export const internetProviderCategories = [
  {
    name: 'noNetwork',
  },
  {
    name: 'slowSpeed',
  },
  {
    name: 'wifiIssue',
  },
  {
    name: 'otherIssues',
  },
];

export const lightsCategories = [
  {
    name: 'controlNotWorking',
  },
  {
    name: 'otherIssues',
  },
];

export const climateCategories = [
  {
    name: 'temperatureNotShown',
  },
  {
    name: 'temperatureNotCorrect',
  },
  {
    name: 'humidityNotShown',
  },
  {
    name: 'otherIssues',
  },
];
  
