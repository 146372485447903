import React from 'react';
import { Grid } from 'components/layout/Grid';
import { FormattedMessage } from 'react-intl';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import waterSavingImage from 'assets/media/brikks/water-saver/water-saving.png';
import { LinkModal } from 'components/modals/LinkModal';
import { WaterUsageModal } from 'components/brikks/sustainability/WaterUsage/components/WaterUsageModal';
import { LinkModalButton } from 'components/form-elements/buttons/LinkModalButton';
import { ShouldRender } from 'components/ShouldRender';
import { RIB } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { WaterTips } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal/components';
import { currentMonthPeriod } from 'utils/water-usage/datePeriods';
import { useWaterUsage } from 'services/water-usage';

export const WaterSavingModal = () => {
  const tenantId = getTenantIDBasedOnURL();

  const { reportAfter, reportBefore } = currentMonthPeriod();
  const { isError } = useWaterUsage(reportAfter, reportBefore);
  
  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="waterSaving" />

      <Grid.Column>
        <div> 
          <Brikk.Heading
            main
            heading="waterSaving"
          >
            <ModalIntro>
              <ModalIntro.Image
                src={waterSavingImage}
                alt="Water saving"
              /> 

              <ModalIntro.Text>
                <FormattedMessage id="waterSavingIntro" />
              </ModalIntro.Text>
             
              <ShouldRender condition={tenantId !== RIB}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <LinkModal
                    isDisabled={!!isError}
                    intl="dataForWaterConsumption"
                    buttonTriggerModal={LinkModalButton}
                    brikkModal={WaterUsageModal}
                  />
                </div>
              </ShouldRender>
            </ModalIntro>
          </Brikk.Heading>
        </div>
      </Grid.Column>

      <WaterTips />
    </Grid>
  ); 
};
