import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography/Text';
import { Profile } from 'components/user-components/Profile';
import { UserDetails } from 'components/user-components/UserDetails';
import { VXBOnboardingRoutes } from 'pages/onboarding/VXBOnboarding/routes';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';

export const ContactDetails = () => {
  const { onRedirect } = useRedirect(VXBOnboardingRoutes.COMPLETED);

  return (
    <ModalCard>
      <Grid
        brikkLayout
        paddingTop
      >
        <Grid.Column>
          <Brikk.Heading main heading="onboardingContactDetailsHeading">
            <Text
              element="h2"
              thin
              xl
              intl="onboardingContactDetailsDescription"
            />
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <UserDetails />
        </Grid.Column>

        <Grid.Column>
          <Profile />
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </Grid>
    </ModalCard>
  );
}
