import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { getUserDetailsAPI } from './api';

const USER_DETAILS = 'userDetails';

export function useUserDetails() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isError, error } = useQuery(
    [USER_DETAILS, tenantId],
    () => getUserDetailsAPI(),
  );

  return {
    userDetails: data || [],
    isLoading,
    isError,
    error: error?.message,
  };
}
