import React from 'react';
import styles from 'components/brikks/home/Invoices/components/Modal/styles.module.scss';
import { Message } from 'semantic-ui-react';
import invoicesSrc from 'assets/media/invoices.png';
import { ShouldRender } from 'components/ShouldRender';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/loaders/Loader';
import { InvoicesTable } from 'components/brikks/home/Invoices/components/Modal/components/InvoicesTable';
import { Grid } from 'components/layout/Grid';
import { Brikk } from 'components/Brikk';
import { useInvoices } from 'services/invoices/invoices/hook';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { ModalIntro } from 'components/layout/ModalIntro';

export const Modal = () => {
  const { invoices, isLoading, isError } = useInvoices();

  return (
    <Grid
      brikkLayout
      twoColumns
    >
      <IntlPageTitle intlTitle="invoices" />

      <Grid.Column justifySelf="center">
        <Brikk.Heading
          main
          heading="invoicesHeading"
        >
          <ModalIntro>
            <ModalIntro.Image
              src={invoicesSrc}
              alt="Desk with various resident documents on it"
            />

            <ModalIntro.Text
              intl="invoicesDescription"
              intlValues={{ bankInfo: invoices[0]?.bankInfo }}
            />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <div className={styles.table}>
          <Brikk.Heading heading="invoices">
            <div className={styles.invoices}>
              <InvoicesTable invoices={invoices} />
            </div>

            <ShouldRender condition={!!isError}>
              <div className={styles.error}>
                <Message negative>
                  <FormattedMessage id="genericErrorMessage" />
                </Message>
              </div>
            </ShouldRender>

            <ShouldRender condition={isLoading}>
              <Loader />
            </ShouldRender>
          </Brikk.Heading>
        </div>
      </Grid.Column>
    </Grid>
  );
};
