import React from 'react';
import styles from './styles.module.scss';
import { Image } from "semantic-ui-react";

export const CircularImage = ({ src, alt, id, objectFit, onError }) => {

  return (
      <Image
        centered
        circular
        className={styles.circularImage}
        id={id}
        src={src}
        alt={alt}
        onError={onError}
        style={{ objectFit }}
    />
  );
};
