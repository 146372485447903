import React from 'react';
import styles from './styles.module.scss';
import { Text } from '../../../typography/Text';
import { Image } from 'semantic-ui-react';

export const ImageText = ({ inversed, text, imageSrc, alt, children }) => (
  <>
    {children ? (
      <Text xl>
        {children}
      </Text>
    ) : (
      <Text
        xl
        intl={text}
      />
    )}

    <Image
      className={styles.image}
      centered
      src={imageSrc}
      alt={alt}
    />
  </>
);
