import React from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography/Text';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const Card = ({ children, transparent, radius, className, grey, header }) => (
  <div className={classNames(
    styles.card,
    grey && styles.grey,
    className,
    styles[radius], {
      [styles.transparent]: transparent,
    },
  )}
  >
    <ShouldRender condition={header}>
      <Text
        intl={header}
        size={14}
        bold
        uppercase
      />
    </ShouldRender>

    {children}
  </div>
);
