import {
  EverydayLife,
  Sustainability,
  Safety,
  Communication,
  Maintenance,
  MovingInOut,
} from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LKFLivingInfo/components';
import {
  ApartmentTable,
  PlanOverview,
} from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingInfo/components';

export const LivingInfoOptions = [
  {
    menu: 'apartmentInfo',
    submenus: [],
    content: ApartmentTable,
  },
  {
    menu: 'planOverview',
    submenus: [],
    content: PlanOverview,
  },
  {
    menu: 'everydayLife',
    submenus: [],
    content: EverydayLife,
  },
  {
    menu: 'sustainability',
    submenus: [],
    content: Sustainability,
  },
  {
    menu: 'safety',
    submenus: [],
    content: Safety,
  },
  {
    menu: 'communication',
    submenus: [],
    content: Communication,
  },
  {
    menu: 'maintenance',
    submenus: [],
    content: Maintenance,
  },
  {
    menu: 'movingInOut',
    submenus: [],
    content: MovingInOut,
  },
];
