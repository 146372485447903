import { ProceedButton } from "components/form-elements/buttons/ProceedButton";
import React from "react";

export const AgreementAction = ({ isActive, onClick }) => {
  return (
    <ProceedButton onClick={onClick}>
      <ProceedButton.Button intl={isActive ? 'terminate' : 'goToDocuments'} />
      <ProceedButton.SignIn />
    </ProceedButton>
  )
}
