export const DownloadAgreementActions = {
  DOWNLOAD_AGREEMENT: '@agreement/download/',
  DOWNLOAD_AGREEMENT_SUCCESS: '@agreement/download/success',
  DOWNLOAD_AGREEMENT_FAIL: '@agreement/download/fail',
};

export function downloadAgreement(agreementNumber) {
  return {
    type: DownloadAgreementActions.DOWNLOAD_AGREEMENT,
    payload: agreementNumber,
  }
}

export function downloadAgreementSuccess(agreementNumber) {
  return {
    type: DownloadAgreementActions.DOWNLOAD_AGREEMENT_SUCCESS,
    payload: agreementNumber
  }
}

export function downloadAgreementFail(error, agreementNumber) {
  return {
    type: DownloadAgreementActions.DOWNLOAD_AGREEMENT_FAIL,
    payload: { error, agreementNumber },
  }
}
