import React from 'react';

export const InsuranceHeartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18.783" height="20" viewBox="0 0 18.783 20">
            <g id="insurance-umbrella-heart" transform="translate(-0.721 0.002)">
                <path id="Path_7791" data-name="Path 7791" d="M5.246,8.328a.416.416,0,1,0,.833,0c0-.286.518-.64,1.222-.865a.416.416,0,0,1,.543.4v2.967a.209.209,0,0,0,.378.122A4.038,4.038,0,0,1,9.319,9.9a.416.416,0,0,0,.19-.35V7.859a.416.416,0,0,1,.543-.4c.7.225,1.222.583,1.222.865a.416.416,0,0,0,.833,0c0-.632.776-1.091,1.845-1.091.93,0,1.844.541,1.844,1.091a.416.416,0,0,0,.833,0V7.952a7.954,7.954,0,1,0-15.907,0v.376a.416.416,0,1,0,.833,0c0-.551.916-1.091,1.844-1.091C4.47,7.237,5.246,7.7,5.246,8.328Z" transform="translate(0)" />
                <path id="Path_7792" data-name="Path 7792" d="M9.478,19.477a3.814,3.814,0,0,1-.412-.482.208.208,0,0,0-.377.122v1.749a.625.625,0,1,1-1.249,0V20.65a.833.833,0,1,0-1.666,0v.218a2.29,2.29,0,0,0,4.581,0v-.316a.416.416,0,0,0-.117-.289Z" transform="translate(-0.844 -3.161)" />
                <path id="Path_7793" data-name="Path 7793" d="M20.8,14.185a2.951,2.951,0,0,0-4.811-.65.416.416,0,0,1-.583,0,2.93,2.93,0,1,0-4.268,4.016L15.4,21.965a.416.416,0,0,0,.6,0l4.253-4.407a2.9,2.9,0,0,0,.55-3.373Z" transform="translate(-1.608 -2.102)"  />
            </g>
        </svg>
    )
}