import { AWS_API_URL } from 'global/environment';
import { authorize, getRequest } from 'utils/http';
import { LKF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';

export async function getAssetsAPI(propertyNumber) {
  const url = `${AWS_API_URL}/assets`;
  const tenantId = getTenantIDBasedOnURL();
  
  return getRequest(url, await authorize({ params: tenantId === LKF && { propertyNumber } }));
}
