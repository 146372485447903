import React, { useState } from 'react';
import { MyInfoIcon } from 'assets/icons/brikks/MyInfoIcon';
import { MyInfoModal } from 'components/brikks/home/MyInfo/components/MyInfoModal';
import Draggable from 'components/drag-and-drop/Draggable';
import { UserDetailsPreview } from 'components/brikks/home/MyInfo/components/UserDetailsPreview';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { useUserDetails } from 'services/user/details';

export const MyInfo = ({ position, code, status, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const { isLoading, isError, error } = useUserDetails();

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={isLoading}
          brikkKey={code}
          status={status}
          name={name}
          error={error}
        >
          <Brikk.Header
            icon={<MyInfoIcon />}
            heading="myInfoBrikk"
            brikkKey={code} 
            status={status}
          />

          <Brikk.Content center={isError}>
            <UserDetailsPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="myInfo"
            />
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="My info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="myInfo"
        onClose={() => setIsModalOpen(false)}
      >
        <MyInfoModal />
      </BrikkModal>
    </>
  );
};
