export const HOME_PAGE = '/';
export const WELCOME_SCREEN = '/welcome';
export const LOGIN_PAGE = '/login';
export const ACTIVATE_ACCOUNT_PAGE = '/activate-account';
export const CREATE_PASS_PAGE = '/create-pass';
export const FORGOT_PASS_PAGE = '/forgot-pass';
export const FORGOT_PASS_VERIFY_PAGE = '/forgot-pass-verify';
export const CHANGE_PASS_CONFIRMATION_PAGE = '/change-pass-confirmation';
export const CHANGE_PASS_PAGE = '/change-pass';
export const ONBOARDING_PAGE = '/onboarding';
export const ONBOARDING_WELCOME = `${ONBOARDING_PAGE}/welcome`;
export const ONBOARDING_YOUR_INFO = `${ONBOARDING_PAGE}/your-info`;
export const ORDER_PAGE = '/order';
export const AGREEMENT = '/agreement/:id?';
export const CONTACT = '/contact'; 
