import React from 'react';
import { Text } from 'components/typography/Text';

export const LinkBrikkContent = ({ link }) => (
  <>
    <Text
      intl="externalLink"
      uppercase
    />

    <Text
      primary
    >
      {link}
    </Text>
  </>
);
