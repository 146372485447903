import React from 'react';
import { getSplashScreenBoolean, setSplashScreenBoolean } from 'utils/local-storage/welcome-screen';
import { useRedirect } from 'utils/redirect';
import { Text } from 'components/typography/Text';
import { FormattedMessage } from 'react-intl';
import { LanguagePicker } from 'components/user-components/LanguagePicker';
import { Navigator } from 'components/links/Navigator';
import { BasicPageLayout } from 'components/layout/BasicPageLayout';
import { HOME_PAGE } from 'global/routes';
import styles from './styles.module.scss';

export const WelcomeScreen = () => {
  const hasSeenSplashScreen = getSplashScreenBoolean();

  useRedirect(HOME_PAGE, hasSeenSplashScreen);

  return (
    <BasicPageLayout intlTitle="housingOpportunities">
      <Text
        element="h1"
        size={42} 
        className={styles.title}
      >
        <FormattedMessage
          id="welcomeTitle"
          values={{
            span: (...chunks) => (
              <Text
                element="span"
                size={42}
                primary
              >
                {chunks}
              </Text>
            ),
          }}
        />
      </Text>
      
      <Text
        gray
        size={18}
        intlValues={{
          a: (...chunks) => (
            <a
              href="https://minasidor.lkf.se/User/MyPagesLogin.aspx?cmguid=c80865e7-dea9-4e5d-ad2c-ca43073f16c4"
              target="_blank"
              rel="noopener noreferrer"
            >
              {chunks}
            </a>
          ),
        }}
        intl="welcomeDescription"
      />

      <div className={styles.footer}>
        <LanguagePicker />

        <div onClick={() => setSplashScreenBoolean(true)}>
          <Navigator
            dark
            to={HOME_PAGE}
          />
        </div>
      </div>
    </BasicPageLayout>
  );
};
