import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'semantic-ui-react';
import { Radio } from 'components/form-elements/radios/Radio';
import { ShouldRender } from 'components/ShouldRender';
import { Grid } from 'components/layout/Grid';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { RIB, BRE, GRT, LKF, VXB } from 'global/tenant-config/tenants';
import { BUILDING, HOME, NEIGHBORHOOD, PRIVATE, PUBLIC, SERVICE } from 'utils/types'; 

export const PropertyTypeButtons = ({
  propertyType,
  onChange,
  reset,
}) => {
  const intl = useIntl();
  const tenantId = getTenantIDBasedOnURL();

  function onToggleButton(type) {
    if (propertyType !== type) {
      onChange(type);
      reset(type);
    }
  }

  return (
    <Form>
      <Grid
        noPadding
        twoColumns
        style={{ gap: 15 }}
      >
        <ShouldRender condition={[LKF, VXB].includes(tenantId)}>
          <Grid.Column>
            <Form.Field>
              <Radio
                toggle
                name="private-space"
                value="private"
                label={intl.formatMessage({ id: 'apartment' })}
                onChange={() => onToggleButton('private')}
                checked={propertyType === PRIVATE}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <Radio
                toggle
                name="public-space"
                value="public"
                label={intl.formatMessage({ id: 'publicSpace' })}
                onChange={() => onToggleButton('public')}
                checked={propertyType === PUBLIC}
              />
            </Form.Field>
          </Grid.Column>
        </ShouldRender>

        <ShouldRender condition={[RIB, BRE, GRT].includes(tenantId)}>
          <Grid.Column>
            <Form.Field>
              <Radio
                toggle
                name="home"
                value="home"
                label={intl.formatMessage({ id: 'apartment' })}
                onChange={() => onToggleButton('home')}
                checked={propertyType === HOME}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <Radio
                toggle
                name="building"
                value="building"
                label={intl.formatMessage({ id: 'building' })}
                onChange={() => onToggleButton('building')}
                checked={propertyType === BUILDING}
              />
            </Form.Field>
          </Grid.Column>
        </ShouldRender>

        <ShouldRender condition={![VXB].includes(tenantId)}>
          <Grid.Column>   
            <Form.Field>
              <Radio
                toggle
                name="neighborhood"
                value="neighborhood"
                label={intl.formatMessage({ id: 'neighbourhood' })}
                onChange={() => onToggleButton('neighborhood')}
                checked={propertyType === NEIGHBORHOOD}
              />
            </Form.Field>
          </Grid.Column>

          <Grid.Column>
            <Form.Field>
              <Radio
                toggle
                name="service"
                value="service"
                label={intl.formatMessage({ id: 'service' })}
                onChange={() => onToggleButton('service')}
                checked={propertyType === SERVICE}
              />
            </Form.Field>
          </Grid.Column>
        </ShouldRender>
      </Grid>
    </Form>
  );
};
