import React from 'react';

export const DrayerIcon = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60.431 60.431"
    >
      <g
        id="Group_6511"
        data-name="Group 6511"
        transform="translate(-737.446 -550.231)"
      >
        <g
          id="browser-user-help-message"
          transform="translate(782.946 583.367)"
        >
          <circle
            id="b33ee74014ea8ed39f03c773f72c8da4"
            cx="30.216"
            cy="30.216"
            r="30.216"
            transform="translate(-45.5 -33.136)"
            fill="#3a3a3a"
          />
        </g>

        <g
          id="laundry-machine-1"
          transform="translate(753 566)"
        >
          <path
            id="Path_8256"
            data-name="Path 8256"
            d="M28.125,0H1.875A1.875,1.875,0,0,0,0,1.875V6.563a.625.625,0,0,0,.625.625h6.1a.281.281,0,0,0,.156-.052,14.675,14.675,0,0,1,16.229,0,.277.277,0,0,0,.155.053h6.1A.625.625,0,0,0,30,6.563V1.875A1.875,1.875,0,0,0,28.125,0ZM22.188,4.688a.938.938,0,1,1,.938-.937A.938.938,0,0,1,22.188,4.688Zm3.75,0a.938.938,0,1,1,.938-.937A.938.938,0,0,1,25.938,4.688Z"
            fill="#00b72b"
          />

          <path
            id="Path_8257"
            data-name="Path 8257"
            d="M29.375,7.742H23.27a2.145,2.145,0,0,1-1.2-.369,13.01,13.01,0,0,0-14.146,0,2.151,2.151,0,0,1-1.2.369H.625A.625.625,0,0,0,0,8.367V26.8a1.875,1.875,0,0,0,1.875,1.875h26.25A1.875,1.875,0,0,0,30,26.8V8.367A.625.625,0,0,0,29.375,7.742ZM15,24.929A8.125,8.125,0,1,1,23.125,16.8,8.125,8.125,0,0,1,15,24.929Z"
            transform="translate(0 1.321)"
            fill="#00b72b"
          />
        </g>
      </g>
    </svg>
  )
};
