import { useMutation, useQueryClient } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { useModal } from 'store/modals';
import { updateUserDetailsAPI } from './api';

const USER_DETAILS = 'userDetails';
const REPORTED_CASES = 'reportedCases';

export function useUpdateUserDetails({ onSuccess }) {
  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL();

  const { openModal } = useModal();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (userDetails) => updateUserDetailsAPI(userDetails),
    {
      onSuccess: () => {
        if (tenantId === LKF) {
          openModal({
            modalType: 'NotificationModal',
            modalProps: {
              type: 'success',
              description: 'updateDetailsSuccess',
            },
          });
          onSuccess();
          queryClient.invalidateQueries([REPORTED_CASES, tenantId]);
        }
        queryClient.invalidateQueries([USER_DETAILS, tenantId]);
      },
    },
  );

  return {
    updateUserDetails: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
