import React, { useContext } from 'react';
import styles from 'components/dropdowns/Dropdown/components/Item/styles.module.scss';
import { DropdownContext } from 'components/dropdowns/Dropdown/index';
import { Icon } from 'semantic-ui-react';
import { DropdownButton } from 'components/form-elements/buttons/DropdownButton';

export const Item = ({ children, intl, onClick, active, isCheckList }) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  const handleOnClick = () => {
    onClick && onClick();
    setIsOpen(!isOpen);
  }

  return (
    <DropdownButton
      intl={intl}
      active={active}
      onClick={() => handleOnClick()}
      isCheckList={isCheckList}
    >
      {children}

      <Icon
        className={styles.icon}
        name={isOpen ? 'angle down' : 'angle right'}
      />
    </DropdownButton>
  )
}
