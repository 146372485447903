import React from 'react';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { FormattedMessage } from 'react-intl';
import { PenWriteUnderlineIcon } from 'assets/icons/brikks/PenWriteUnderlineIcon';
import { formatNumber } from 'utils/format/numbers';
import { BrikkPlaceholder } from 'components/BrikkPlaceholder';

export const TerminatePreview = ({ agreements, loading }) => {

  if (loading) {
    return <BrikkPlaceholder />;
  }

  return (
    <IconNumText
      uppercase
      icon={<PenWriteUnderlineIcon />}
      text={<FormattedMessage id="agreements" />}
      number={agreements ? formatNumber(agreements.length) : '00'}
    />
  );
};
