import React, { useState } from 'react';
import { Draggable } from 'components/drag-and-drop';
import { Brikk } from 'components/Brikk';
import { SchoolBookIcon } from 'assets/icons/brikks/SchoolBookIcon';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { Image } from 'components/media/Image';
import brikkPreviewSrc from './assets/suppliers-brikk-preview.png';
// import brikkModalSrc from './assets/suppliers-modal.png';
import { DemoModal } from 'components/modals/DemoModal';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';

export const SuppliersOverviewStore = ({ position, status, code, name }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const handleInfoModal = (e) => {
        e.stopPropagation();
        setIsInfoModalOpen(!isInfoModalOpen)
    }

    const handleOpenModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    }

    return (
        <>
            <Draggable position={position}>
                <Brikk brikkKey={code} name={name} status={status}>
                    <Brikk.Header
                        icon={<SchoolBookIcon />}
                        heading="suppliersStoreBrikk"
                        brikkKey={code}
                        status={status}
                    />

                    <Brikk.Content center>
                        <Image src={brikkPreviewSrc} />
                    </Brikk.Content>

                    <Brikk.Button>
                        <SeeMoreButton
                            onClick={handleOpenModal}
                            brikkNameIntl="suppliers" />
                        <InfoButton
                            showModal={isInfoModalOpen}
                            onClick={handleInfoModal}
                        />
                    </Brikk.Button>
                </Brikk>
            </Draggable>

            <BrikkInfoModal
                title="Suppliers overview info"
                // info={info}
                showModal={isInfoModalOpen}
                onClick={() => setIsInfoModalOpen(!isInfoModalOpen)} />

            <DemoModal
                isOpen={isModalOpen}
                heading="suppliers"
                onClose={() => setIsModalOpen(false)}
            >
                {/* <Image src={brikkModalSrc} /> */}
            </DemoModal>
        </>
    );
}
