import React from 'react';
import { Text } from 'components/typography';
import { Grid } from 'components/layout/Grid';
import { VideoPreview } from 'components/media/VideoPreview';
import { PDFPreview } from 'components/media/PDFPreview';
import { useAssets } from 'services/assets';
import { filmsData } from './filmsData';

export const FixItYourself = () => {
  const { assets } = useAssets();

  return (
    <>
      <Text
        size={16}
        intl="fixItYourselfDescription"
        intlValues={{
          bold: (...chunks) => (
            <Text
              size={22}
              element="span"
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />

      <Grid
        noPadding
        twoEqualColumns
        style={{ gridGap: 15 }}
      >

        {assets?.maintenance && assets?.maintenance.map((document) => (
          <PDFPreview
            key={document.name}
            name={document.name}
            url={document.url}
          />
        ))}

        {filmsData?.map((video) => (
          <VideoPreview
            key={video.title}
            intl={video.title} 
            url={video.videUrl} 
          />
        ))}
      </Grid>
    </>
  );
};
