import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CircularImage } from 'components/brikks/sub-components/CircularImage';
import intercomImage from 'assets/media/intercom/intercomSmallBrikkImage.png';
import styles from 'components/brikks/home/Intercom/components/IntercomPreview/styles.module.scss';

export const IntercomPreview = () => (
  <div>
    <CircularImage
      src={intercomImage}
      alt="Intercom image"
      className={styles.imageBg}
    />

    <div className={styles.textLine}>
      <FormattedMessage id="axisCompanion" />
    </div>

  </div>
);
