import React from 'react';
import styles from './styles.module.scss';
import sassVariables from 'global/_styles.scss';
import {useWaterIconFill} from "utils/hooks-visual/water-icon-fill/useWaterIconFill";

export const DropletIcon = ({variant = sassVariables.gray}) => {
  const [fill, gradient] = useWaterIconFill(variant);

  return (
    <svg
      className={styles.icon}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 34.696 51.014"
    >
      <path d="M151.823.6,137.817,25.032a17.349,17.349,0,1,0,30.1,0L153.912.6A1.2,1.2,0,0,0,151.823.6Z"
            transform="translate(-135.523 0)"
      />
      {gradient}
    </svg>
  );
};
