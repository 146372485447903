import { Auth } from 'aws-amplify';
import { getUserFromToken } from '../../auth-token';

const USER_ATTRIBUTES_KEY = 'aFACLc?:bIdsMa!';

export async function getUserAttributesFromToken() {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: true });
    return user.attributes;
  } catch (e) {
    return getUserFromToken();
  }
}

export function setUserAttributes(attributes) {
  localStorage.setItem(USER_ATTRIBUTES_KEY, JSON.stringify(attributes));
}

export function getUserAttributes() {
  const userAttributes = JSON.parse(localStorage.getItem(USER_ATTRIBUTES_KEY)) || {};

  if (userAttributes.onboarding_passed) {
    userAttributes.onboardingPassed = userAttributes.onboarding_passed;
  }

  return userAttributes;
}

export function removeUserAttributes() {
  localStorage.removeItem(USER_ATTRIBUTES_KEY);
}
