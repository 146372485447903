import { useMutation, useQueryClient } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useModal } from 'store/modals';
import { ALREADY_EXISTS } from 'utils/http';
import { createLightningGroupAPI } from './api';

const LIGHTNING_GROUPS = 'lightningGroups';

export function useCreateLightningGroup({ onSuccess }) {
  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { openModal } = useModal();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (group) => createLightningGroupAPI(group),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'createGroupSuccess',
          },
        });
        queryClient.invalidateQueries([LIGHTNING_GROUPS, tenantId]);
        onSuccess();
      },
      onError: (error) => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: error.errorMessage === ALREADY_EXISTS ? 'groupAlreadyExists' : 'createGroupFail',
          },
        });
      },
    },
  );

  return {
    createLightningGroup: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
