import React from 'react';
import { MultiTabMenu } from "components/menus/MultiTabMenu";
import { LivingInfoOptions } from "components/brikks/home/MyHome/components/LivingInfoModal/components/LivingInfo";
import { GeneralInfoOptions } from "components/brikks/home/MyHome/components/LivingInfoModal/components/BrikksTabOptions";

export const BrikksLivingInfo = () => (
  <MultiTabMenu
    defaultTab='yourApartment'
    defaultMenu={LivingInfoOptions[0]}
    tabs={{
      yourApartment: { title: 'yourApartment', menus: LivingInfoOptions },
      generalInfo: { title: 'generalInfo', menus: GeneralInfoOptions },
    }}
  />
)
