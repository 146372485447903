import React from 'react';
import styles from './styles.module.scss'

export const ShoppingBasket = () => {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21.751"
      viewBox="0 0 24 21.751"
      className={styles.color}
    >
      <path
        id="Path_8251"
        data-name="Path 8251"
        d="M26.231,60.9h-21a.5.5,0,0,0-.488.608l1.841,8.324A1.459,1.459,0,0,0,8.05,70.9H23.412a1.459,1.459,0,0,0,1.466-1.068V69.83l1.839-8.317a.5.5,0,0,0-.488-.608Zm-4.75,3v4a.75.75,0,0,1-1.5,0v-4a.75.75,0,0,1,1.5,0Zm-5,0v4a.75.75,0,1,1-1.5,0v-4a.75.75,0,0,1,1.5,0Zm-4.5,0v4a.75.75,0,1,1-1.5,0v-4a.75.75,0,0,1,1.5,0Z"
        transform="translate(-3.731 -49.154)"
      />

      <path
        id="Path_8252"
        data-name="Path 8252"
        d="M22.5,12.071H20.976a.251.251,0,0,1-.19-.087L15.449,5.758a1.251,1.251,0,0,0-1.9,1.627l3.663,4.273a.25.25,0,0,1-.19.413H6.976a.25.25,0,0,1-.19-.413l3.663-4.273a1.251,1.251,0,1,0-1.9-1.627L3.214,11.984a.251.251,0,0,1-.19.087H1.5a1.5,1.5,0,0,0-1.5,1.5v1a1.5,1.5,0,0,0,1.5,1.5h21a1.5,1.5,0,0,0,1.5-1.5v-1A1.5,1.5,0,0,0,22.5,12.071Z"
        transform="translate(0 -5.321)"
      />
    </svg>
  )
};
