export const GetAgreementPropertiesActions = {
  GET_AGREEMENT_PROPERTIES: '@agreementProperties/get',
  GET_AGREEMENT_PROPERTIES_SUCCESS: '@agreementProperties/get/success',
  GET_AGREEMENT_PROPERTIES_FAIL: '@agreementProperties/get/fail',
};

export function getAgreementProperties() {
  return {
    type: GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES,
  }
}

export function getAgreementPropertiesSuccess(agreementProperties) {
  return {
    type: GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES_SUCCESS,
    payload: agreementProperties,
  }
}

export function getAgreementPropertiesFail(error) {
  return {
    type: GetAgreementPropertiesActions.GET_AGREEMENT_PROPERTIES_FAIL,
    payload: error,
  }
}
