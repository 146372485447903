import React from 'react';
import { ExternalLink } from 'components/links/ExternalLink';
import { Grid } from 'components/layout/Grid';
import { Card } from 'components/cards/Card';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import Loader from 'components/loaders/Loader';
import { LinkModal } from 'components/modals/LinkModal';
import ServiceReportModal from 'components/brikks/home/ServiceReport/components/ServiceReportModal';
import { LinkModalButton } from 'components/form-elements/buttons/LinkModalButton';
import { Image } from 'components/media/Image';
import { Text } from 'components/typography';
import { StatusText } from 'components/typography/StatusText';
import { tenantConfig } from 'global/tenant-config';
import { useInternetProviders } from 'services/internet-providers/providers';
import { useSubscriptions } from 'services/service-report/subscriptions';
import { useLivingInfo } from 'services/living-info/living-info';

export const SupplierDetails = () => {
  const { internetProviders, isLoading: isProvidersLoading } = useInternetProviders();
  const { subscriptions, isLoading: isSubscriptionsLoading } = useSubscriptions();

  const { livingInfo } = useLivingInfo();

  const updateService = React.useMemo(() => (tenantConfig.areas && tenantConfig.areas[livingInfo.propertyNumber]
    ? tenantConfig.areas[livingInfo.propertyNumber]?.store?.updateService
    : tenantConfig.store?.updateService),
  [livingInfo]);

  const changeSpeed = React.useMemo(() => (tenantConfig.areas && tenantConfig.areas[livingInfo.propertyNumber]
    ? tenantConfig.areas[livingInfo.propertyNumber]?.store?.changeSpeed
    : tenantConfig.store?.changeSpeed),
  [livingInfo]);

  const selectedProvider = React.useMemo(
    () => internetProviders?.find((provider) => provider.id === subscriptions[0]?.productId),
    [internetProviders, subscriptions],
  );

  if (isProvidersLoading || isSubscriptionsLoading) return <Loader />;

  return (
    <Grid
      twoEqualColumns
      noPadding
      style={{ gridGap: 10 }}
    >
      <Card
        header="currentSupplier"
        grey
      >

        <Grid
          noPadding
          oneColumn
          style={{
            justifyItems: 'left',
            gap: 10,
            paddingTop: 5,
          }}
        >
          <Image
            style={{ maxWidth: 130 }}
            src={selectedProvider?.logoUrl}
            alt={selectedProvider?.name}
          />

          <ExternalLink href={selectedProvider?.websiteUrl}>
            <ProceedButton>
              <ProceedButton.Button intl="supplierWebsite" />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink> 

          <ExternalLink href={selectedProvider?.websiteUrl}>
            <ProceedButton>
              <ProceedButton.Button intl="contactSupplier" />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink> 

          <LinkModal
            intl="reportIssue"
            buttonTriggerModal={LinkModalButton}
            brikkModal={ServiceReportModal}
            modalHeading="serviceReport"
          />
        </Grid>
      </Card>

      <Card
        header="currentService"
        grey
      >
        <Grid
          noPadding
          oneColumn
          style={{
            gap: 0,
            paddingTop: 20,
            justifyItems: 'left',
          }}
        >
          <Text
            intl="currentServiceDetails"
            intlValues={{
              supplier: selectedProvider?.name,
              service: `${selectedProvider?.upSpeed} / ${selectedProvider?.downSpeed} Mbps`,
              bold: (...chunks) => (
                <Text
                  element="span"
                  bold
                >
                  {chunks}
                </Text>
              ),
            }}
          />

          <StatusText
            intl="serviceBilling"
            small
          />

          <Text
            intl="serviceCost"
            intlValues={{
              cost: selectedProvider?.cost,
              bold: (...chunks) => (
                <Text
                  element="span"
                  bold
                >
                  {chunks}
                </Text>
              ),
            }}
          />
        </Grid>

        <Grid
          noPadding
          oneColumn
          style={{
            justifyItems: 'left',
            gap: 10,
            paddingTop: 30,
          }}
        >
          <ExternalLink href={updateService}>
            <ProceedButton>
              <ProceedButton.Button intl="updateService" />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink>

          <ExternalLink href={changeSpeed}>
            <ProceedButton>
              <ProceedButton.Button intl="changeSpeed" />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink>
        </Grid>
      </Card>
    </Grid>
  ); 
};
