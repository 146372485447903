import React from 'react';
import { Text } from 'components/typography';
import { VideoPreview } from 'components/media/VideoPreview';

export function GoodNeighbours() { 
  return (
    <>
      <Text
        size={16}
        intl="goodNeighboursDescription"
      />

      <VideoPreview
        key="goodNeighbours"
        intl="goodNeighboursVideo" 
        url="https://www.youtube.com/embed/9HTZA6zcVcc"
      />

      <Text
        size={16}
        intl="goodNeighboursInfo"
        intlValues={{
          number: (...chunks) => (
            <a
              href={`tel:${chunks}`}
            >
              {chunks}
            </a>
          ),
        }}
      />
    </>
  ); 
}
