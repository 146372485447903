import {
  format,
  formatDistanceStrict,
  isBefore,
  isFuture,
  getDaysInMonth,
  subMonths,
  startOfMonth,
  endOfMonth, 
  getDate,
  addMinutes as addMin,
  subMinutes,
  set,
} from 'date-fns';

import { serviceCallCenterHoursByDays, serviceOfficeHoursByDays } from 'utils/customer-service';

export const daysFormat = 'yyyy-MM-dd';

export const dateMonthNameYear = 'dd LLLL yyyy';

export const dateAndMonthName = 'dd LLLL';

export const hourAndMinute = 'HH:mm';

export const year = 'yyyy';

export const initialTime = set(new Date(), { hours: 0, minutes: 0 });

export const setTime = (hours, minutes) => set(new Date(), { hours, minutes });

export const convertToTimestamp = date => (new Date(date).getTime());

export const formatDate = (date, dateFormat = daysFormat) => format(date, dateFormat);

export const distanceInWordsStrict = (firstDate, secondDate) => formatDistanceStrict(firstDate, secondDate);

export const isDateBefore = (date, dateToCompare) => isBefore(date, dateToCompare);

export const isDateInFuture = (date) => isFuture(date);

export const getMonthDays = (date) => getDaysInMonth(date);

export const getMonthDate = (date) => getDate(date);

export const subtractMonths = (date, months) => subMonths(date, months);

export const monthStart = (date) => startOfMonth(date);

export const monthEnd = (date) => endOfMonth(date);

export const addMinutes = (date, amount) => addMin(date, amount);

export const subtractMinutes = (date, amount) => subMinutes(date, amount);

export const dateToString = (date, locale) => date
  .toLocaleDateString(locale, { month: 'long', day: 'numeric', year: 'numeric' });

export const monthNumberToName = (monthNumber) => {
  const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
  return months[parseInt(monthNumber) - 1];
};
 
export const monthNameFromStringDate = (stringDate) => monthNumberToName(new Date(stringDate).getMonth() + 1);

export const checkServiceCallCenterOpenStatus = (tenantId, date) => {
  if (date.getDay() === 0 || date.getDay() === 6) {
    return false;
  }

  const todayCallCenterOpenTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceCallCenterHoursByDays(tenantId)[date.getDay()].from.h,
    serviceCallCenterHoursByDays(tenantId)[date.getDay()].from.m,
  );

  const todayCallCenterCloseTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceCallCenterHoursByDays(tenantId)[date.getDay()].to.h,
    serviceCallCenterHoursByDays(tenantId)[date.getDay()].to.m,
  );

  if (date > todayCallCenterOpenTime && date < todayCallCenterCloseTime) {
    return true;
  }
  return false; 
};

export const checkServiceOfficeOpenStatus = (tenantId, date) => {
  if (date.getDay() === 0 || date.getDay() === 6) {
    return false;
  }

  const todayOfficeOpenTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceOfficeHoursByDays(tenantId)[date.getDay()].from.h,
    serviceOfficeHoursByDays(tenantId)[date.getDay()].from.m,
  );

  const todayOfficeCloseTime = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    serviceOfficeHoursByDays(tenantId)[date.getDay()].to.h,
    serviceOfficeHoursByDays(tenantId)[date.getDay()].to.m,
  );

  if (date > todayOfficeOpenTime && date < todayOfficeCloseTime) {
    return true;
  }
  return false; 

};

