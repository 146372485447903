import React from 'react';
import { Text } from 'components/typography';
import { ExternalLink } from 'components/links/ExternalLink';

export const HomeInsurance = () => (
  <Text
    size={16}
    intl="homeInsuranceDescription"
    intlValues={{
      a: (...chunks) => (
        <ExternalLink
          href="https://www.konsumenternas.se/konsumentstod/jamforelser/boendeforsakringar/jamfor-hemforsakringar/"
        >
          {chunks}
        </ExternalLink>
      )
    }}
  />
);
