import React, { useState } from 'react';
import classNames from 'classnames';
import { Card, Message, Placeholder } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useTheme } from 'global/themes';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { useUserDetails } from 'services/user/details/hook';
import { ShouldRender } from 'components/ShouldRender';
import { Avatar } from './components/Avatar';
import styles from './styles.module.scss';

export const Profile = ({ main }) => {
  const { userDetails, isLoading } = useUserDetails();
  const [avatarError, setAvatarError] = useState('');

  const { theme } = useTheme();

  return (
    <div>
      <Brikk.Heading
        main={main}
        heading="userProfile"
      >
        <ShouldRender condition={avatarError}>
          <Message
            className={styles.errorMessage}
            negative
          >
            <Message.Content>
              <FormattedMessage id={avatarError} />
            </Message.Content>
          </Message>
        </ShouldRender>

        {isLoading ? (
          <Placeholder>
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
            <Placeholder.Line className={classNames(theme.backgroundColor, styles.placeholder)} />
          </Placeholder>
        ) : (
          <Card className={styles.card}>
            <Avatar onError={setAvatarError} />

            <div className={theme.iconBackgroundSecondaryColor}>
              <div className={classNames(theme.textColor, styles.profileInfo)}>
                <Text
                  xl
                  bold
                >
                  {userDetails?.fullname}
                </Text>

                <Text xl>
                  {userDetails?.email}
                </Text>

                <Text xl>
                  {userDetails?.phone}
                </Text>
              </div>
            </div>
          </Card>
        )}
      </Brikk.Heading>
    </div>
  );
};
