import React from 'react';

export const HumidityIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <g id="Group_5681" data-name="Group 5681" transform="translate(448 87)">
                <g id="Group_1687" data-name="Group 1687" transform="translate(-448 -87)">
                    <path id="Path_7013" data-name="Path 7013" d="M12.733,11.817a6.673,6.673,0,0,1-4.817-6.4,6.584,6.584,0,0,1,.625-2.8.418.418,0,0,0-.015-.381A19.4,19.4,0,0,0,7.349.331.911.911,0,0,0,6.063.252a1.014,1.014,0,0,0-.079.079C4.754,1.944,0,11.087,0,13.754,0,17.025,3.177,20,6.667,20s6.667-2.979,6.667-6.25a6.427,6.427,0,0,0-.316-1.653A.418.418,0,0,0,12.733,11.817ZM6.442,17.55a.624.624,0,0,1-.746.472h0A4.363,4.363,0,0,1,2.75,15.7a.625.625,0,1,1,1.115-.565l0,.008A3.113,3.113,0,0,0,5.976,16.8.625.625,0,0,1,6.442,17.55Z" transform="translate(0 -0.004)" />
                    <path id="Path_7014" data-name="Path 7014" d="M16.417,0a5.417,5.417,0,1,0,5.417,5.417A5.416,5.416,0,0,0,16.417,0Z" transform="translate(-1.833)" />
                </g>
                <g id="Group_1686" data-name="Group 1686" transform="translate(-436.81 -85.203)">
                    <path id="Path_7015" data-name="Path 7015" d="M13.428,3.733a1.429,1.429,0,0,1,1.455-1.577,1.41,1.41,0,0,1,1.435,1.577v.186a1.452,1.452,0,0,1-1.459,1.592,1.424,1.424,0,0,1-1.431-1.592Zm3.33,1.577L18.985,2.4a.449.449,0,0,1,.62-.156.38.38,0,0,1,.244.337.545.545,0,0,1-.147.381L17.48,5.8,15.049,8.968a.451.451,0,0,1-.625.152.385.385,0,0,1-.234-.312.545.545,0,0,1,.142-.4ZM14.439,3.885c0,.5.166.781.434.781s.43-.278.43-.781V3.773c0-.5-.166-.772-.43-.772s-.434.263-.434.772Zm3.325,3.407a1.43,1.43,0,0,1,1.46-1.577,1.407,1.407,0,0,1,1.431,1.577v.186A1.449,1.449,0,0,1,19.2,9.07a1.425,1.425,0,0,1-1.436-1.592Zm1.011.152c0,.5.171.782.434.782s.43-.278.43-.782V7.332c0-.5-.161-.771-.43-.771s-.434.263-.434.771Z" transform="translate(-13.428 -2.156)" />
                </g>
            </g>
        </svg>
    )
}