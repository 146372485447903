import React from 'react';

export const FoodDeliveryIcon = () => {
    return (
        <svg id="restaurant-eating-set" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <path id="Path_8126" data-name="Path 8126" d="M19.25,0a.75.75,0,0,0-.75.75V23a1,1,0,0,0,2,0V15a.5.5,0,0,1,.5-.5h1.75A1.243,1.243,0,0,0,24,13.25v-.007C23.936,6.055,21.582,0,19.25,0Z" />
            <path id="Path_8127" data-name="Path 8127" d="M11.75,10a5.75,5.75,0,1,0,5.75,5.75A5.75,5.75,0,0,0,11.75,10Zm0,9.5a3.75,3.75,0,1,1,3.75-3.75,3.75,3.75,0,0,1-3.75,3.75Z" />
            <path id="Path_8128" data-name="Path 8128" d="M7,0A1,1,0,0,0,6,1V6a1.993,1.993,0,0,1-.571,1.4A.251.251,0,0,1,5,7.223V1A1,1,0,1,0,3,1V7.223a.251.251,0,0,1-.429.175A1.993,1.993,0,0,1,2,6V1A1,1,0,1,0,0,1V6A4.009,4.009,0,0,0,2.667,9.772.5.5,0,0,1,3,10.243V23a1,1,0,0,0,2,0V10.243a.5.5,0,0,1,.333-.471A4.009,4.009,0,0,0,8,6V1A1,1,0,0,0,7,0Z" />
        </svg>
    )
}