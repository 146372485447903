import React from 'react';
import styles from './styles.module.scss';
import { Image as SemanticImage } from 'semantic-ui-react';

export const LargeImage = ({ src, alt }) => (
  <SemanticImage
    className={styles.image}
    centered
    src={src}
    alt={alt}
  /> 
);
