import React, { useContext } from 'react';
import { Text } from 'components/typography/Text';
import { ShouldRender } from 'components/ShouldRender';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import { TerminateAgreementModalContext } from 'components/brikks/home/TerminateAgreement/components/TerminateModal/index';
import classNames from 'classnames';
import styles from 'components/brikks/home/TerminateAgreement/components/TerminateModal/components/AgreementsList/Agreement/styles.module.scss';
import { isProductNumberEqual } from "utils/agreement";
import { useAgreements } from "services/agreements/agreements";

export const Agreement = ({ agreement }) => {
  const { selectedAgreements, setSelectedAgreements } = useContext(
    TerminateAgreementModalContext
  );

  const { agreements } = useAgreements();

  const isAgreementSignedBySecondPerson = agreement.status === 'pending' &&
  agreement.secondPersonSignedTermination;

  const isAgreementSelectable = agreement.status === 'active' || isAgreementSignedBySecondPerson;

  const agreementStatus = () => {
    const diff = new Date() - new Date(agreement.movingOutDate);

    if(agreement.status === 'active'){
      return 'active';
    }
    if(agreement.status === 'pending'){
      return 'requestedTerminationInProgress'
    }
    if(agreement.status === 'completed'){
      if(diff > 0){
        return 'terminated';
      } else {
        return 'requestedTermination';
      }
    } 
  }

  return (
    <div
      className={classNames(
        styles.agreement,
        isAgreementSelectable && styles.pointer
      )}
      onClick={isAgreementSelectable ? () => setSelectedAgreements(agreement) : undefined}
    >
      <div className={styles.text}>
        <Text size={14} bold>
          {agreement.productType || '-'} {isProductNumberEqual(agreements, agreement) && `- ${agreement.productNumber}`}
        </Text>

        <div className={styles.inline}>
          <Text intl="agreementStatus" className={styles.paddingRight} size={14} />

          <>
            <Text intl={agreementStatus()} size={14} />
            <span
              className={classNames(styles.statusIcon, {
                [styles.active]: agreement.status === 'active',
                [styles.pending]: agreement.status === 'pending',
                [styles.inactive]: agreement.status === 'completed',
              })}
            />
          </>
        </div>

        <ShouldRender condition={isAgreementSelectable}>
          <div className={styles.inline}>
            <Text
              className={styles.space}
              intl="activeFrom"
              size={14}
            />
            {agreement.agreementFrom ? (
              <Text size={14} className={styles.paddingRight}>
                {agreement.agreementFrom}
              </Text>
            ) : (
              '-'
            )}
          </div>
        </ShouldRender>

        <ShouldRender condition={!isAgreementSelectable}>
          <div className={styles.inline}>
            <Text
              className={styles.space}
              intl="terminationDate"
              size={14}
            />
            {agreement.movingOutDate ? (
              <Text size={14}>
                {agreement.movingOutDate}
              </Text>
            ) : (
              '-'
            )}
          </div>
        </ShouldRender>
      </div>

      <ShouldRender condition={isAgreementSelectable}>
        <div className={styles.statusIcon}>
          <CheckMarkIcon
            activeGreen={
              agreement.agreementNumber === selectedAgreements?.agreementNumber
            }
          />
        </div>
      </ShouldRender>
    </div>
  );
};
