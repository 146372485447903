import React from 'react';
import { IconNumText } from 'components/brikks/sub-components/IconNumText';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as MaintenanceIcon } from 'assets/media/maintenance-icon.svg';
import { formatNumber } from 'utils/format/numbers';
import { BrikkPlaceholder } from 'components/BrikkPlaceholder';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF, RIB } from 'global/tenant-config/tenants';
import { DONE, FINISHED, OPEN } from 'utils/types';

export const ServiceStatusPreview = ({ reportedCases, loading }) => {

  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const openCases = React.useMemo(() => reportedCases?.filter(
    (ticket) => {
      if (tenantId === RIB) {
        return ticket.status === OPEN;
      } if (tenantId === LKF) {
        return ticket.status !== DONE;
      }
      return ticket.status !== FINISHED;
    },
  ), [reportedCases, tenantId]);

  if (loading) {
    return <BrikkPlaceholder />;
  }

  return (
    <IconNumText
      uppercase={false}
      icon={<MaintenanceIcon />}
      text={<FormattedMessage id="activeCases" />}
      number={reportedCases ? formatNumber(openCases.length) : '00'}
    />
  );
};
