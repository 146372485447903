import React from 'react';
import { Range, getTrackBackground } from 'react-range';
import styles from './styles.module.scss';

export const RangeSlider = ({ min = 0, max = 100, value, onChange, onFinalChange }) => (
  <div className={styles.range}>
    <Range
      values={value}
      min={min}
      max={max}
      onChange={onChange}
      onFinalChange={onFinalChange}
      renderTrack={({ props, children }) => (
        <div
          ref={props.ref}
          style={{
            height: '20px',
            width: '100%',
            border: '1px solid #55b336',
            borderRadius: '10px',
            background: getTrackBackground({
              values: value,
              colors: ['#55b336', '#ccc'],
              min,
              max,
            }),
            alignSelf: 'center',
          }}
        >
          {children}
        </div>
      
      )}
      renderThumb={({ props }) => (
        <div
          className={`${value >= max ? styles.thumbMax : ''} ${value <= min ? styles.thumbMin : ''}`}
          {...props}
          style={{
            ...props.style,
            position: 'inherit',
            height: '19px',
            width: '20px',
            borderRadius: '50px',
            backgroundColor: '#FFF',
            boxShadow: '0px 2px 6px #AAA',
          }}
        />
      )}
    />
  </div>
);

