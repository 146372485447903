import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';

export const Step = ({ textIntl, isActive }) => (
  <div
    className={classNames(
      styles.step, {
        [styles.active]: isActive,
      }
    )}
  >
    <Text
      intl={textIntl}
    />

    <CheckMarkIcon
      size={20}
      active={isActive}
    />
  </div>
);
