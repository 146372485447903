import React from 'react';
import styles from './styles.module.scss';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { useClickOutside } from 'utils/hooks/useClickOutside';
import { DiscardCrossIcon } from 'assets/icons/DiscardCrossIcon';
import FocusTrap from 'focus-trap-react';

export const SideMenu = ({ children, shouldShow, onClose }) => {
  const { ref, onClickOutside } = useClickOutside(onClose);

  return (
    ReactDOM.createPortal(
      <FocusTrap active={shouldShow}>
        <div
          className={classNames(
            styles.sideMenuContainer, {
              [styles.open]: shouldShow,
            },
          )}
          onClick={onClickOutside}
        >
          <div
            ref={ref}
            className={classNames(
              styles.sideMenu, {
                [styles.open]: shouldShow,
              }
            )}
          >
            <div className={styles.header}>
              <DiscardCrossIcon
                white
                xl
                ignoreTheme
                onClick={onClose}
              />
            </div>

            <div className={styles.content}>
              {children}
            </div>
          </div>
        </div>
      </FocusTrap>,
        document.body,
      )
  );
}
