import React from 'react';
import { upgrades } from "components/brikks/home/Upgrades/components/UpgradesModal/data";
import { UpgradesItem } from "components/brikks/home/Upgrades/components/UpgradesModal/components/UpgradesItem";

export const UpgradesList = () => {

  return (
    upgrades.map( upgrade =>
      <UpgradesItem upgrade={upgrade}/>
    )
  )
};
