export const Article = (article) => ({
  title: article.title,
  link: article.link,
  datePublished: new Date(article.datePublished),
  description: article.description,
  image: article.image,
  feed: article.feed,
  feedId: article.feedId,
});

export const mapDataToClient = (feed) => ({
  id: feed.id,
  default: feed.default,
  url: feed.url,
  title: feed.title,
  articles: feed.articles ? feed.articles.map(article => Article({
    ...article,
    feed: feed.title,
    feedId: feed.id,
  })) : [],
}); 
