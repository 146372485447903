import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getInvoicesAPI } from './api';

const INVOICES = 'invoices';

export function useInvoices() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [INVOICES, tenantId],
    () => getInvoicesAPI(),
  );

  return {
    invoices: data || [],
    isLoading: isLoading || isFetching,
    isError,
  }; 
}
