import React from 'react';
import styles from './styles.module.scss';
import { Article } from './components/Article';

export const Feed = ({ articles, onSelectArticle = () => {}, rightBorder }) => (
  <div className={styles.feed}>
    {articles.map(article => (
      <Article
        key={`${article.feedId}-${article.title}-${article.datePublished}`}
        rightBorder={rightBorder}
        article={article}
        onSelect={onSelectArticle}
      />
    ))}
  </div>
);
