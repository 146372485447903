export const mapDataForBackend = (agreement) => ({
    bankId: agreement.orderRef,
    terminationDate: new Date(agreement.selectedDate).getTime(),
    endDate: new Date(agreement.endDate).getTime(),
    errorReportLink: `${window.location.origin}/contact`,
    terminateAgreementLink: `${window.location.origin}/agreement/${agreement.agreementNumber}`,
    language: agreement.locale,
    reason: agreement.reason,
    address: agreement.address,
    city: agreement.city,
    postcode: agreement.postcode,
    ...(agreement.earlierRequestedDate && {earlierRequestedDate: new Date(agreement.earlierRequestedDate).getTime()})
  });
