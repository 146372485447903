import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { submitInternetSubscriptionAPI } from './api';

const SUBSCRIPTIONS = 'subscriptions';
export function useSubmitInternetSubscription() {

  const { openModal } = useModal();
  const tenantId = getTenantIDBasedOnURL();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (subscription) => submitInternetSubscriptionAPI(subscription),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'internetSubscriptionSuccess',
          },
        });

        queryClient.invalidateQueries([SUBSCRIPTIONS, tenantId]);
      },
      onError: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: 'internetSubscriptionFail',
          },
        });
      },
    },
  );

  return {
    submitInternetSubscription: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
