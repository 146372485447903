import React, { createContext, useState } from 'react';
import styles from 'components/dropdowns/Dropdown/styles.module.scss'
import classNames from 'classnames';
import { Item } from 'components/dropdowns/Dropdown/components/Item';
import { Content } from 'components/dropdowns/Dropdown/components/Content';
import { SubItem } from 'components/dropdowns/Dropdown/components/SubItem';

export const DropdownContext = createContext(null);

export const Dropdown = ({ open, children, subDropdown }) => {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div className={classNames(styles.dropdown, subDropdown && styles.subDropdown)}>
        {children}
      </div>
    </DropdownContext.Provider>
  )
}

Dropdown.Item = Item;
Dropdown.SubItem = SubItem;
Dropdown.Content = Content;
