import React from 'react';
import { Icon as SemanticIcon } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const Icon = ({ name }) => (
  <SemanticIcon
    className={styles.icon}
    name={name}
    size="big"
  />
);
