import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import { LivingInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingInfo';
import { GeneralInfoOptions } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingGeneralInfo';

export const LKFLivingInfo = () => (
  <MultiTabMenu
    defaultTab="myHome" 
    defaultMenu={LivingInfoOptions[0]} 
    tabs={{
      myHome: { title: 'myHome', menus: LivingInfoOptions }, 
      generalInfo: { title: 'generalInfo', menus: GeneralInfoOptions },
    }}
  />
);
