import React from 'react';
import { useDevices } from 'services/service-report/devices/hook';
import Loader from 'components/loaders/Loader';
import { Field } from 'components/form-elements/Field';

export const Units = ({ selectedSpace, selectedUnit, onSelectUnit }) => {
  const { devices, loading } = useDevices(selectedSpace);
  
  if (loading) return <Loader />;

  return (
    <>
      { devices?.map(unit => (
        <Field 
          key={unit.id}
          onClick={() => onSelectUnit(unit)}
          field={unit.id}
          fieldName={unit.name}
          selectedField={selectedUnit}
        />
      ))}
    </>
  );
};
