import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const CircledCloseIcon = ({ size = 31, disabled, black, onClick }) => (
  <svg
    className={
      classNames(styles.icon, {
        [styles.disabled]: disabled,
        [styles.black]: black,
      }) 
    }
    style={{ width: size }}
    onClick={onClick}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
  >
    <g id="Group_6327" data-name="Group 6327" transform="translate(18577.5 23259.5)">
      <circle id="Ellipse_353" data-name="Ellipse 353" cx="15" cy="15" r="15" transform="translate(-18577 -23259)" stroke="rgba(0,0,0,0)" strokeWidth="1"/>
      <path id="Icon_ionic-ios-close-circle" data-name="Icon ionic-ios-close-circle" d="M15.375,3.375a12,12,0,1,0,12,12A12,12,0,0,0,15.375,3.375Zm3.04,16.344-3.04-3.04-3.04,3.04a.922.922,0,1,1-1.3-1.3l3.04-3.04-3.04-3.04a.922.922,0,0,1,1.3-1.3l3.04,3.04,3.04-3.04a.922.922,0,0,1,1.3,1.3l-3.04,3.04,3.04,3.04a.926.926,0,0,1,0,1.3A.916.916,0,0,1,18.415,19.719Z" transform="translate(-18577.375 -23259.375)" fill="#fff"/>
    </g>
  </svg>
);