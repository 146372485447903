import React, { useContext } from 'react';
import styles from 'components/menus/SideMenu/components/ClientTheme/styles.module.scss';
import { Text } from 'components/typography/Text';
import { Radio } from 'components/form-elements/radios/Radio';
import { tenantConfig } from 'global/tenant-config';
import { AppContext } from 'AppContext';

export const ClientTheme = () => {
  const {
    userSettings: { updateUserSettings },
    selectedTheme,
    setSelectedTheme,
  } = useContext(AppContext);

  const themes = tenantConfig?.additionalThemes;

  return (
    <div className={styles.themeSettings}>
      <div className={styles.headingWrapper}>
        <Text
          element="h3"
          thin
          medium
          uppercase
          white
          intl="theme"
          className={styles.heading}
        />
      </div>

      <div className={styles.settings}>
        {Object.keys(themes).map((tenantId) => (
          <div className={styles.checkbox} key={tenantId}>
            <Text white intl={themes[tenantId].name} />
            <Radio
              toggle
              checked={selectedTheme === tenantId}
              onChange={() => {
                setSelectedTheme(tenantId);
                updateUserSettings({ background: themes[tenantId].background });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
