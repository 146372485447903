import React from 'react';
import { Table } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useLivingInfo } from 'services/living-info/living-info';
import { ShouldRender } from 'components/ShouldRender';
import { LKF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';

export const ApartmentTable = () => {
  const { livingInfo } = useLivingInfo();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  return (
    <Table
      singleLine
      striped
      unstackable
    >
      <Table.Body>
        <Table.Row>
          <Table.Cell>
            <FormattedMessage id="address" />
          </Table.Cell>
          <Table.Cell>{livingInfo.fullAddress}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>
            <FormattedMessage id="apartment" />
          </Table.Cell>
          <Table.Cell>{livingInfo.apartmentNumber}</Table.Cell>
        </Table.Row>

        <Table.Row>
          <Table.Cell>
            <FormattedMessage id="stairs" />
          </Table.Cell>
          <Table.Cell>{livingInfo.floor}</Table.Cell>
        </Table.Row>

        <ShouldRender condition={tenantId !== LKF}>
          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="rent" />
            </Table.Cell>
            <Table.Cell>{livingInfo.rent ? `${livingInfo.rent} kr/mån` : '-'}</Table.Cell>
          </Table.Row>
        </ShouldRender>
      
        <ShouldRender condition={tenantId === LKF}>
          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="objectNumber" />
            </Table.Cell>
            <Table.Cell>{livingInfo.objectNumber}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="postalZipCode" />
            </Table.Cell>
            <Table.Cell>{livingInfo.postalCode}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="postAddress" />
            </Table.Cell>
            <Table.Cell>{livingInfo.postalAddress}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="propertySize" />
            </Table.Cell>
            <Table.Cell>{livingInfo.propertySize ? `${livingInfo.propertySize} kvadratmeter` : '-'}</Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <FormattedMessage id="roomNumber" />
            </Table.Cell>
            <Table.Cell>{livingInfo.numberOfRooms}</Table.Cell>
          </Table.Row>
        </ShouldRender>

      </Table.Body>
    </Table>
  );
};
