import React, { useContext } from 'react';
import { Placeholder } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { BrikkContext } from 'components/Brikk/index';
import styles from './styles.module.scss';

export const Content = ({ center, children, className }) => {
  const { isLoading, error } = useContext(BrikkContext);

  return (
    <div
      className={classNames(
        styles.brikkContent, {
          [styles.center]: center,
        }, className,
      )}
    >
      {error ? (
        <div className={styles.error}>
          <FormattedMessage id={error} />
        </div>
      ) : (
        isLoading ? (
          <div className={styles.loading}>
            <Placeholder>
              <Placeholder.Line className={styles.line} />
              <Placeholder.Line className={styles.line} />
              <Placeholder.Line className={styles.line} />
              <Placeholder.Line className={styles.line} />
            </Placeholder>
          </div>
        ) : children
      )}
    </div>
  );
};
