import React from 'react';
import styles from './styles.module.scss';
import Loader from '../Loader';

export const PageLoader = () => (
  <div className={styles.container}>
    <Loader />
  </div>
);

export default Loader;
