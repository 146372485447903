import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'semantic-ui-react';
import { useIntl } from 'react-intl';
import { Grid } from 'components/layout/Grid';
import { ShouldRender } from 'components/ShouldRender';
import { TelephonePicker } from 'components/user-components/TelephonePicker';
import { MultipleImageUpload } from 'components/form-elements/MultipleImageUpload';
import { useSubmitServiceReport } from 'services/service-report/submit-service-report';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { BrikkPlaceholder } from 'components/brikks/sub-components/BrikkPlaceholder';
import {
  Spaces,
  Keys,
  PropertyTypeButtons,
  ColumnSubHeading,
  Subscriptions,
} from 'components/brikks/home/ServiceReport/components/ServiceReportModal/components';
import { BUILDING, HOME, NEIGHBORHOOD, PRIVATE, PUBLIC, SERVICE } from 'utils/types';
import { Button } from 'components/form-elements/buttons/Button';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { Label } from 'components/form-elements/labels/Label';
import { useUserDetails } from 'services/user/details/hook';
import { useSubscriptions } from 'services/service-report/subscriptions';
import styles from './styles.module.scss';

function ServiceReportModal({ placeholder }) {
  const { userDetails } = useUserDetails();
  const { subscriptions } = useSubscriptions();

  const intl = useIntl();

  // API data.
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [, setPets] = useState('');
  const [keys, setKeys] = useState('');

  // Form states.
  const [descriptionDisabled, setDescriptionDisabled] = useState(true);
  const [telDisabled, setTelDisabled] = useState(true);
  const [petsDisabled, setPetsDisabled] = useState(true);
  const [keysDisabled, setKeysDisabled] = useState(true);
  const [propertyType, setPropertyType] = useState();

  // Form fields.
  const [telephone, setTelephone] = useState('');
  const [description, setDescription] = useState('');

  const [postBody, setPostBody] = useState({});

  const initialPostBody = useMemo(() => ({
    area: userDetails.area,
    publicSpaceId: null,
    realestate: userDetails.realestate,
    building: userDetails.building,
    stairwell: userDetails.stairwell,
    object: userDetails.object,
    email: userDetails.email,
    description: '',
    motivation: '',
    pets: '',
    space: null,
    spaceName: '',
    unitName: '',
  }), [userDetails]);

  useEffect(() => {
    if (Object.keys(userDetails).length && !Object.keys(postBody).length) {
      setPostBody(initialPostBody);
    }
    //eslint-disable-next-line
  }, [userDetails]);

  const resetPropertyTypes = () => {
    setPropertyType(null);
  };

  const resetSelectedSpace = (type) => {
    setSelectedSpace(null);
    setSelectedUnit(null);
    setSelectedService(null);
    setSelectedCategory(null);
    setDescription('');
    setDescriptionDisabled(![HOME, BUILDING, NEIGHBORHOOD].includes(type));
    setTelDisabled(true);
    setTelephone('');
    setKeys('');
    setKeysDisabled(true);
    setPets('');
    setPetsDisabled(true);
    setPostBody(initialPostBody);
  };

  const { isLoading, submitServiceReport } = useSubmitServiceReport({
    onSuccess: () => {
      resetPropertyTypes();
      resetSelectedSpace();
    },
  });

  function onSubmit(postBody) {
    submitServiceReport({
      ...postBody,
      propertyType,
    });
  }

  // If this code changes, the event tracking effect in the submit-service-report
  // will likely need to change as well
  const handleObjectSelect = (space) => {
    if (space && space?.id !== selectedSpace) {
      setPostBody({
        ...postBody,
        [propertyType === PRIVATE ? 'space' : 'publicSpaceId']: space.id,
        [propertyType === PRIVATE ? 'publicSpaceId' : 'space']: null,
        spaceName: space?.name,
        unit: null,
        unitName: null,
      });
      setSelectedSpace(space.id);
      setDescriptionDisabled(false);
      setSelectedUnit(null);
    } else {
      resetSelectedSpace();
    }
  };

  const handleUnitSelect = (unit) => {
    if (unit) {
      setSelectedUnit(unit.id);
      setPostBody({
        ...postBody,
        unit: unit.id,
        unitName: unit.name,
      });
    }
  };

  const handleCategorySelect = (category) => {
    if (category) {
      setSelectedCategory(category.name);
      setPostBody({
        ...postBody,
        category: intl.formatMessage({ id: category.name }), 
      });
    }
  };

  const handleServiceSelect = (service) => {
    if (service && selectedService !== service) {
      setSelectedService(service);
      setPostBody({
        ...postBody,
        service,
        category: null,
      });
    } else {
      resetSelectedSpace();
    }
    setSelectedCategory(null);
    setDescriptionDisabled(!!(service && selectedService === service && subscriptions.length > 0));
  };

  const handleDescriptionChange = (e, { value }) => {
    setDescription(value);

    setPostBody({
      ...postBody,
      description: value,
    });

    setTelDisabled(false);
    setPetsDisabled(false);
    setKeysDisabled(false);
  };

  const onAttachImage = useCallback((images) => {
    setPostBody(prevState => ({
      ...prevState,
      attachedFiles: images,
    }));
  }, [setPostBody]);

  const handleTelFieldChange = useCallback((telNumber, telType) => {
    setPostBody({
      ...postBody,
      telNumber,
      telType,
    });

    setTelephone(telNumber);
  }, [postBody]);

  const handlePetsChange = (e, { value }) => {
    setPets(value);
    setPostBody({
      ...postBody,
      pets: value || '',
    });
  };

  const isSavedDisabled = useMemo(() => {
    if (propertyType !== PRIVATE) {
      return !description || !telephone;
    }
    return !keys || !telephone;

  }, [description, telephone, keys, propertyType]);

  if (userDetails.loading) {
    return <BrikkPlaceholder />;
  }

  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="serviceReport" />

      <Grid.Column>
        <div>
          <Brikk.Heading
            main
            heading="chooseReport"
          >
            <ColumnSubHeading intl="chooseReportSubtitle" />

            <div className={styles.form}>
              <PropertyTypeButtons
                propertyType={propertyType}
                onChange={setPropertyType}
                reset={resetSelectedSpace}
              />

              <ShouldRender condition={[PRIVATE, PUBLIC].includes(propertyType)}>
                <Spaces
                  selectedSpace={selectedSpace}
                  selectedUnit={selectedUnit}
                  isPrivateProperty={propertyType === PRIVATE}
                  isPublicProperty={propertyType === PUBLIC}
                  onSelectSpace={handleObjectSelect}
                  onSelectUnit={handleUnitSelect}
                />
              </ShouldRender>

              <ShouldRender condition={propertyType === SERVICE}>
                <Subscriptions
                  isServiceProperty={propertyType === SERVICE}
                  selectedService={selectedService}
                  onServiceSelect={handleServiceSelect}
                  selectedCategory={selectedCategory}
                  onCategorySelect={handleCategorySelect}
                  setDescriptionDisabled={setDescriptionDisabled}
                />
              </ShouldRender>
            </div>
          </Brikk.Heading>
        </div>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="additionalInfo">
          <ColumnSubHeading intl="additionalInfoSubtitle" />

          <div>
            <Form>
              <Label intl="additionalInfoLabel" />

              <Form.TextArea
                name="additional-info"
                placeholder={placeholder && intl.formatMessage({ id: placeholder })}
                value={description}
                rows={8}
                disabled={descriptionDisabled}
                onChange={handleDescriptionChange}
                maxLength={1800}
              />
            </Form>
          </div>

          <br />

          <MultipleImageUpload
            disabled={descriptionDisabled}
            onUpload={onAttachImage}
            reset={propertyType}
          />
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="contactInformation">
          <ColumnSubHeading intl="contactInformationSubtitle" />

          <div className={styles.body}>
            <Form>
              <TelephonePicker
                disabled={telDisabled}
                onChange={handleTelFieldChange}
              />

              <ShouldRender condition={propertyType === PRIVATE || propertyType === HOME}>
                <LabeledInput
                  name="pets"
                  disabled={petsDisabled}
                  labelIntl="contactInformationLabel"
                  placeholder=""
                  onChange={handlePetsChange}
                />
              </ShouldRender>

              <br />

              <ShouldRender condition={propertyType === PRIVATE}>
                <Keys
                  keysDisabled={keysDisabled}
                  keys={keys}
                  setKeys={setKeys}
                  postBody={postBody}
                  setPostBody={setPostBody}
                />
              </ShouldRender>
            </Form>

            <Button
              className={styles.submitButton}
              name="submit-report"
              type="submit"
              loading={isLoading}
              floated="right"
              disabled={isSavedDisabled}
              onClick={() => onSubmit(postBody)}
              intl="send"
            />
          </div>
        </Brikk.Heading>
      </Grid.Column>
    </Grid>
  );
}

export default ServiceReportModal;
