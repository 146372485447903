import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from 'components/brikks/neighbourhood/Weather/components/WeatherSlide/styles.module.scss';
import { ReactComponent as ClearIcon } from 'assets/icons/weatherConditions/clear.svg';
import { ReactComponent as ClearIconNight } from 'assets/icons/weatherConditions/clear_night.svg';
import { ReactComponent as FewCloudsIcon } from 'assets/icons/weatherConditions/few_clouds.svg';
import { ReactComponent as FewCloudsIconNight } from 'assets/icons/weatherConditions/few_clouds_night.svg';
import { ReactComponent as CloudIcon } from 'assets/icons/weatherConditions/cloud.svg';
import { ReactComponent as CloudsIcon } from 'assets/icons/weatherConditions/clouds.svg';
import { ReactComponent as ShowerRainIcon } from 'assets/icons/weatherConditions/shower_rain.svg';
import { ReactComponent as RainIcon } from 'assets/icons/weatherConditions/rain.svg';
import { ReactComponent as RainIconNight } from 'assets/icons/weatherConditions/rain_night.svg';
import { ReactComponent as ThunderIcon } from 'assets/icons/weatherConditions/thunder.svg';
import { ReactComponent as SnowIcon } from 'assets/icons/weatherConditions/snow.svg';
import { ReactComponent as MistIcon } from 'assets/icons/weatherConditions/mist.svg';

export const WeatherSlide = ({ icon, value, valuePrefix, label, small, medium, large }) => (
  <div className={classNames(
    styles.WeatherSlide,
    small && styles.small,
    medium && styles.medium,
    large && styles.large,
  )}
  >
    <div className={styles.icon}>
      {icon}
    </div>

    <div className={styles.ValueWrapper}>
      <span className={styles.Value}>
        {value}
      </span>

      <span className={styles.ValuePrefix}>
        {valuePrefix}
      </span>
    </div>

    <div className={styles.LabelWrapper}>
      {!!label && <FormattedMessage id={label} />}
    </div>
  </div>
);

export const getIconForWeather = (weatherConditions, isDay = true) => {
  switch (weatherConditions) {
    case 'clearSky':
      return !isDay ? <ClearIconNight /> : <ClearIcon />;
    case 'fewClouds':
      return !isDay ? <FewCloudsIconNight /> : <FewCloudsIcon />;
    case 'scatteredClouds':
      return <CloudIcon />;
    case 'brokenClouds':
      return <CloudsIcon />;
    case 'showerRain':
      return <ShowerRainIcon />;
    case 'rain':
      return !isDay ? <RainIconNight /> : <RainIcon />;
    case 'thunderstorm':
      return <ThunderIcon />;
    case 'snow':
      return <SnowIcon />;
    case 'mist':
      return <MistIcon />;
    default:
      return <FewCloudsIcon />;
  }
};
