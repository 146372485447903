import React, { useContext } from 'react';
import { UpgradesModalContext } from "components/brikks/home/Upgrades/components/UpgradesModal/index";
import { Text } from 'components/typography/Text';
import styles from 'components/brikks/home/Upgrades/components/UpgradesModal/components/CheckoutList/styles.module.scss';
import { DiscardIcon } from 'assets/icons/DiscardIcon';

export const CheckoutList = () => {
  const { selectedUpgrades, setSelectedUpgrades } = useContext(
    UpgradesModalContext
  );

  const availableUpgrades = selectedUpgrades.filter(item => item.status === 'available');

  const baseCost = availableUpgrades.reduce(function (accumulator, item) {
      return accumulator + item.baseCost;
    }, 0);

  const monthlyCost = availableUpgrades.reduce(function (accumulator, item) {
      return accumulator + item.monthlyCost;
    }, 0);

  const removeItem = (id) => {
    setSelectedUpgrades(selectedUpgrades.filter(item => item.id !== id));
  }

  return (
    <div className={styles.checkout}>
      <div className={styles.items}>
        {
          selectedUpgrades.map( upgrade =>
            <div className={styles.item} key={upgrade.id}>
              <Text
                size={16}
              >
                {upgrade.name}
              </Text>

              <div className={styles.value}>
                {
                  upgrade.status === 'upcoming' &&
                  <Text
                    size={16}
                    className={styles.price}
                    intl="examination"
                    intlValues={{ value: '0 kr' }}
                  />
                }

                {
                  upgrade.status === 'available' && upgrade.monthlyCost &&
                  <Text
                    size={16}
                    className={styles.price}
                    intl="monthlyIncrease"
                    intlValues={{ value: `${upgrade.monthlyCost} kr` }}
                  />
                }

                {
                  upgrade.status === 'available' && upgrade.baseCost &&
                  <Text
                    size={16}
                    className={styles.price}
                    intl="oneTimeCost"
                    intlValues={{ value: `${upgrade.baseCost} kr` }}
                  />
                }
                <DiscardIcon size={20} onClick={() => removeItem(upgrade.id)}/>
              </div>
            </div>
          )
        }
      </div>

      <div className={styles.payment}>
        <Text
          intl="monthlyRentIncrease"
          size={18}
          white
        />

        <Text
          size={16}
          white
        >
          {monthlyCost}kr
        </Text>
      </div>

      <div className={styles.payment}>
        <Text
          intl="payment"
          size={18}
          white
        />

        <Text
          size={16}
          white
        >
          {baseCost} kr
        </Text>
      </div>
    </div>
  )
}
