import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMaintenanceProducts } from "./actions";
import { createProductKey } from "./reducer";
import { getTenantIDBasedOnURL } from "global/tenant-config";
import { maintenanceProductsData } from "services/maintenance/maintenance-products/mockData";

export function useMaintenanceProducts(type, unit, measure) {
  const dispatch = useDispatch();
  const productKey = createProductKey({ type, unit, measure });
  const { products, loading, error, calledOnce } = useSelector(state => state.maintenanceProducts);
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  useEffect(() => {
    if (!calledOnce[productKey] && type && unit && measure) {
      if(tenantId !== 'lkf') dispatch(getMaintenanceProducts({ type, unit, measure }));
    }
  }, [calledOnce, dispatch, type, unit, measure, productKey, tenantId]);

  return {
    products: tenantId !== 'lkf' ? (products[productKey] || []) :
      (maintenanceProductsData.filter(item => item.typeName === type && item.unitName === unit)[0]?.data || []),
    loading: tenantId !== 'lkf' ? (loading[productKey] || (type && unit && measure && !calledOnce[productKey])) : false,
    error: error[productKey],
  };
}
