import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const ArrangeIcon = ({ isActive, disabled }) => {

  return (
    <svg className={classNames(styles.icon, isActive && styles.active, disabled && styles.deactive)} xmlns="http://www.w3.org/2000/svg" width="30.49" height="30.51" viewBox="0 0 30.49 30.51">
      <g transform="translate(-563.5 -217.493)">
        <g transform="translate(563.5 217.493)">
          <path className={classNames(styles.path, disabled && styles.disabled)}
                d="M563.888,217.881a1.322,1.322,0,0,0-.388.937v14.6a1.326,1.326,0,0,0,1.326,1.326h10.605a1.326,1.326,0,0,0,1.326-1.326V218.839a1.326,1.326,0,0,0-1.326-1.326l-10.605-.02A1.322,1.322,0,0,0,563.888,217.881Z"
                transform="translate(-563.5 -217.493)"/>
          <path className={classNames(styles.path, disabled && styles.disabled)}
                d="M588.431,217.493l-10.605.019a1.326,1.326,0,0,0-1.326,1.326v6.628a1.326,1.326,0,0,0,1.326,1.326h10.605a1.326,1.326,0,0,0,1.326-1.326v-6.647A1.326,1.326,0,0,0,588.431,217.493Z"
                transform="translate(-559.266 -217.493)"/>
          <path className={classNames(styles.path, disabled && styles.disabled)}
                d="M576.368,242.4a1.322,1.322,0,0,0,.388-.937v-6.628a1.326,1.326,0,0,0-1.326-1.326H564.826a1.326,1.326,0,0,0-1.326,1.326v6.608a1.326,1.326,0,0,0,1.326,1.326l10.605.019A1.329,1.329,0,0,0,576.368,242.4Z"
                transform="translate(-563.5 -212.278)"/>
          <path className={classNames(styles.path, disabled && styles.disabled)}
                d="M588.431,227.508H577.826a1.326,1.326,0,0,0-1.326,1.326v14.582a1.326,1.326,0,0,0,1.326,1.326l10.605-.019a1.326,1.326,0,0,0,1.326-1.326V228.834A1.326,1.326,0,0,0,588.431,227.508Z"
                transform="translate(-559.266 -214.232)"/>
        </g>
      </g>
    </svg>
  );
}
