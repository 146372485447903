export const LoginEmailActions = {
  LOGIN_EMAIL: '@login/email',
  LOGIN_EMAIL_SUCCESS: '@login/email/success',
  LOGIN_EMAIL_FAIL: '@login/email/fail',

  LOGIN_EMAIL_RESET: '@login/email/reset',
};

export function loginEmail(email, password) {
  return {
    type: LoginEmailActions.LOGIN_EMAIL,
    payload: { email, password },
  }
}

export function loginEmailSuccess(user) {
  return {
    type: LoginEmailActions.LOGIN_EMAIL_SUCCESS,
    payload: user,
  }
}

export function loginEmailFail(error) {
  return {
    type: LoginEmailActions.LOGIN_EMAIL_FAIL,
    payload: error,
  }
}

export function loginEmailReset() {
  return {
    type: LoginEmailActions.LOGIN_EMAIL_RESET,
  }
}
