import React from 'react';

export const HeatingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.612"
      height="17.5"
      viewBox="0 0 14.612 17.5"
    >
      <g
        id="Group_6518"
        data-name="Group 6518"
        transform="translate(-543.694 -1608)"
      >
        <g id="sauna-heat-stone" transform="translate(539 1608)">
          <path
            id="Path_8947"
            data-name="Path 8947"
            d="M6.307,11.775a2.767,2.767,0,0,1-.379,1.28,1,1,0,1,0,1.756.955,4.671,4.671,0,0,0,.623-2.235,9.223,9.223,0,0,0-.878-3.4,7.7,7.7,0,0,1-.735-2.653A4.1,4.1,0,0,1,8.015,3.207,1,1,0,1,0,6.6,1.793,6.069,6.069,0,0,0,4.694,5.726a9.228,9.228,0,0,0,.878,3.4,7.715,7.715,0,0,1,.735,2.649Z"
          />
          <path
            id="Path_8948"
            data-name="Path 8948"
            d="M17.306,11.775a2.765,2.765,0,0,1-.378,1.281,1,1,0,0,0,.4,1.356.986.986,0,0,0,.476.122,1,1,0,0,0,.879-.523,4.675,4.675,0,0,0,.623-2.236,9.218,9.218,0,0,0-.878-3.4,7.725,7.725,0,0,1-.735-2.654,4.1,4.1,0,0,1,1.32-2.518A1,1,0,0,0,17.6,1.794a6.056,6.056,0,0,0-1.906,3.932,9.223,9.223,0,0,0,.878,3.4,7.7,7.7,0,0,1,.734,2.649Z"
          />
          <path
            id="Path_8949"
            data-name="Path 8949"
            d="M10.293,17.207a1,1,0,0,0,1.414,0A7.275,7.275,0,0,0,14,12.5a11.276,11.276,0,0,0-1.071-4.12A9.692,9.692,0,0,1,12,5a5.128,5.128,0,0,1,1.707-3.293A1,1,0,1,0,12.293.293,7.274,7.274,0,0,0,10,5a11.287,11.287,0,0,0,1.071,4.122A9.676,9.676,0,0,1,12,12.5a5.128,5.128,0,0,1-1.707,3.294,1,1,0,0,0,0,1.413Z"
          />
        </g>
      </g>
    </svg>
  );
};
