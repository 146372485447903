import React from 'react';
import { Agreement } from 'components/brikks/home/TerminateAgreement/components/TerminateModal/components/AgreementsList/Agreement';

export const AgreementList = ({ agreements }) => {
  return agreements?.map((agreement) => (
    <Agreement
      key={agreement.agreementNumber}
      agreement={agreement}
    />
  ));
};
