import drinkingWaterSrc from 'assets/media/brikks/water-saver/water-usage.jpg';
import waterUsageSrc from 'assets/media/brikks/water-saver/water-facts.jpg';
import { WaterSavingTip } from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal/components/WaterSavingTip';

export const GeneralTabOptions = [
  {
    menu: 'waterFacts',
    submenus: [
      { title: 'aboutDrinkingWater', content: WaterSavingTip, intl: 'drinkingWaterDescription', contentImage: drinkingWaterSrc },
      { title: 'waterUsage', content: WaterSavingTip, intl: 'waterUsageDescription', contentImage: waterUsageSrc },
    ],
  },
];
