import React from 'react';
import Popup from 'reactjs-popup';
import { InfoIcon } from 'assets/icons/InfoIcon';
import { CircledCloseIcon } from 'assets/icons/CircledCloseIcon';
import { Text } from 'components/typography';
import { Grid } from 'components/layout/Grid';
import { Image } from 'components/media/Image';
import styles from './styles.module.scss';

export const TooltipModal = ({ content, image }) => (
  <Popup
    modal
    trigger={(
      <div>
        <InfoIcon
          width={18}
          gray
        /> 
      </div>
)}
    contentStyle={{ 
      background: 'rgb(40 34 34 / 78%)', 
      borderRadius: 20,
      border: 'unset', 
      width: '60vw',
      height: '60vh',
      overflow: 'auto',
    }}
    overlayStyle={{ background: 'rgba(0,0,0,0.3)' }}
  >
        
    {close => (
      <>
        <div className={styles.closeButton}>
          <CircledCloseIcon
            black
            onClick={close}
          />
        </div>

        <Grid
          twoEqualColumns
          noPadding
          style={{ padding: '3rem', minHeight: '50vh' }}

        >
          <Image
            src={image}
            className={styles.image}
          />

          <Text
            dark
            white
            size={16}
          >
            {content}
          </Text>
        </Grid>
      </>
    )}
  </Popup>
);
