export const GrtLivingInfoOptions = [
  {
    menu: 'internet',
    submenus: [],
  },
  {
    menu: 'wifi',
    submenus: [],
  },
  {
    menu: 'otherServices',
    submenus: [],
  },
];
