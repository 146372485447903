import React, { useContext } from 'react';
import { Card } from 'semantic-ui-react';
import { Text } from 'components/typography';
import styles from './styles.module.scss';
import classNames from 'classnames';
import { BrikkContext } from 'components/Brikk/index';
import { useOrderModes } from 'services/order/order-mode/hook';
import { CheckMarkOutlineIcon } from 'assets/icons/CheckMarkOutlineIcon';


export const Header = ({ icon, heading, status, brikkKey }) => {
  const { disabled } = useContext(BrikkContext);
  const { orderMode, isBrikkSelected } = useOrderModes();

  return (
    <Card.Content
      className={classNames(
        styles.brikkHeader, {
        [styles.disabled]: disabled,
      },
        orderMode && (status === 'Ready to integrate' && (styles.integratedBrikk)),
        orderMode && (status === 'Concept and Design' && (styles.designedBrikk)),
        orderMode && (styles.svgOrderMode)
      )}
    >
      <div className={classNames(styles.icon, styles.svgOrderMode)} >
        {icon}
      </div>

      <div className={styles.textContainer}>
        <Text
          bold
          element="h3"
          size={12}
          intl={heading}
          className={classNames(styles.heading,
            orderMode && (styles.headingOrderMode))}
        />
        {orderMode && isBrikkSelected(brikkKey) && (
          <CheckMarkOutlineIcon />
        )}
      </div>
    </Card.Content>
  );
};
