import React from 'react';
import { Grid } from 'components/layout/Grid';
import { FormattedMessage } from 'react-intl';
import { ServiceTickets } from 'components/brikks/home/ServiceStatus/components/ServiceStatusModal/components/ServiceTickets';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import serviceStatusImage from 'assets/media/service-status.png';
import { ContactInformation } from 'components/user-components/ContactInformation';
import { tenantConfig } from 'global/tenant-config';

export const ServiceStatusModal = () => (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="serviceStatus" />

      <Grid.Column>
        <div>
          <Brikk.Heading main heading="serviceStatus">
            <ModalIntro>
              <ModalIntro.Image
                  src={tenantConfig?.serviceStatus?.assets?.modalImage || serviceStatusImage}
                  alt="A hand with a drill that resemble home repair works"
              />

              <ModalIntro.Text>
                <FormattedMessage
                    id="serviceStatusIntro"
                />
              </ModalIntro.Text>
            </ModalIntro>
          </Brikk.Heading>
        </div>
      </Grid.Column>

      <Grid.Column noOverflow>
        <ServiceTickets />
      </Grid.Column>

      <Grid.Column>
        <ContactInformation />
      </Grid.Column>
    </Grid>
);

