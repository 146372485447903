import React from 'react';
import styles from './styles.module.scss';

export const CartIcon = () => (
  <svg
    className={styles.icon}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16.741 15.565"
  >
    <g transform="translate(16.741 15.565) rotate(180)">
      <path className={styles.cart}
            d="M13.6,0H.349A.349.349,0,0,0,.007.419L.9,4.883A1.643,1.643,0,0,0,2.627,5.929h8.7A1.643,1.643,0,0,0,13.05,4.883L13.944.419A.349.349,0,0,0,13.6,0Z"
            transform="translate(1.395 9.624)"/>
      <path className={styles.cart}
            d="M15.346,5.788h-.661a.174.174,0,0,1-.156-.1L11.941.516a.872.872,0,1,0-1.56.779L12.5,5.536a.174.174,0,0,1-.156.252H4.395a.174.174,0,0,1-.156-.252L6.359,1.3A.872.872,0,1,0,4.8.516L2.212,5.692a.174.174,0,0,1-.156.1H1.4a1.4,1.4,0,1,0,0,2.79H15.346a1.4,1.4,0,1,0,0-2.79Z"/>
    </g>
  </svg>
);
