import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { getLaundryLinkAPI } from './api';

const LAUNDRY_LINK = 'laundryLink';

export function useLaundryLink() {
  const tenantId = getTenantIDBasedOnURL();
  
  const { data, isLoading, isFetching, isError } = useQuery(
    [LAUNDRY_LINK, tenantId],
    () => getLaundryLinkAPI(),
  );

  return {
    laundryLink: data?.link,
    isLoading,
    isFetching,
    isError,
  };
}
