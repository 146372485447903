export const mapDataToClient = (providers, subscriptions) => {
  if (!providers) {
    return [];
  }

  if (subscriptions?.length) {
    return providers;
  }
  
  return providers
    .filter(provider => !providers.some(item => item.campaign && item.campaign.fallbackProductId === provider.id))
    .map(obj => ({
      id: obj?.id,
      customerType: obj?.customerType,
      tenantId: obj?.tenantId,
      name: obj?.name,
      productTypeId: obj?.productTypeId,
      wholesaleProductId: obj?.wholesaleProductId,
      orderable: obj?.orderable,
      websiteUrl: obj?.websiteUrl,
      upSpeed: obj?.upSpeed,
      downSpeed: obj?.downSpeed,
      entryFee: obj?.entryFee,
      cost: obj?.cost,
      duration: obj?.duration,
      noticePeriod: obj?.noticePeriod,
      campaign: providers?.find(o => o.id === obj.campaign?.fallbackProductId) ? { 
        durationOfCampaign: obj?.duration,
        defaultCost: providers?.find(o => o.id === obj.campaign?.fallbackProductId)?.cost,
        defaultEntryFee: providers?.find(o => o.id === obj.campaign?.fallbackProductId)?.entryFee,
      } : null,
      logoUrl: obj?.logoUrl,
      smallLogoUrl: obj?.smallLogoUrl,
      description: obj?.description,
      termsAndConditionsUrl: obj?.termsAndConditionsUrl,
      campaignDescription: obj[`campaignDescription${obj?.upSpeed}`],
    }));
};
  
