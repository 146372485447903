import React from 'react';

export const SchoolBookIcon = () => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.25" height="20" viewBox="0 0 16.25 20">
            <g id="school-book" transform="translate(-2.25)">
                <path id="Path_8215" data-name="Path 8215" d="M12.177,7.4a.417.417,0,0,0-.417.416v1.17a.417.417,0,1,0,.833,0V7.819a.417.417,0,0,0-.416-.419Z" transform="translate(-1.656 -1.347)" />
                <path id="Path_8216" data-name="Path 8216" d="M7.51,18.486a.416.416,0,0,0-.416.416v.585a.417.417,0,1,0,.833,0V18.9a.418.418,0,0,0-.417-.414Z" transform="translate(-0.843 -3.277)" />
                <path id="Path_8217" data-name="Path 8217" d="M17.167,3.367a.417.417,0,0,1-.333-.408V1.667A1.667,1.667,0,0,0,15.167,0H4.75a2.5,2.5,0,0,0-2.5,2.457c0,.014,0,15.043,0,15.043A2.5,2.5,0,0,0,4.75,20H16.833A1.667,1.667,0,0,0,18.5,18.333V5a1.667,1.667,0,0,0-1.334-1.633ZM3.917,2.5a.833.833,0,0,1,.833-.833h10a.417.417,0,0,1,.417.417v.833a.417.417,0,0,1-.417.417h-10A.833.833,0,0,1,3.917,2.5ZM8.231,13.807a1.588,1.588,0,0,1-.333.972,1.59,1.59,0,0,1,.333.972v.486a1.6,1.6,0,0,1-1.6,1.6H5.662a.625.625,0,0,1-.625-.625V12.349a.625.625,0,0,1,.625-.625h.972a1.6,1.6,0,0,1,1.6,1.6Zm3.888-3.4a.625.625,0,1,1-1.25,0V9.432a.347.347,0,0,0-.694,0V10.4a.625.625,0,0,1-1.25,0V6.516a1.6,1.6,0,0,1,3.194,0Zm3.264,6.178a.625.625,0,0,1,0,1.25,2.573,2.573,0,0,1-2.569-2.57v-.969a2.573,2.573,0,0,1,2.569-2.57.625.625,0,0,1,0,1.25,1.321,1.321,0,0,0-1.319,1.32v.972A1.321,1.321,0,0,0,15.383,16.583Z" transform="translate(0 0)" />
                <path id="Path_8218" data-name="Path 8218" d="M7.51,15.569a.416.416,0,0,0-.416.416v.585a.417.417,0,1,0,.833,0v-.583a.418.418,0,0,0-.417-.418Z" transform="translate(-0.843 -2.76)" />
            </g>
        </svg>
    )
}