import { useQuery } from 'react-query';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { useLivingInfo } from 'services/living-info/living-info';
import { getAssetsAPI } from './api';

const ASSETS = 'assets';

export function useAssets() {
  const tenantId = getTenantIDBasedOnURL();
  const { livingInfo } = useLivingInfo();

  const { data, isLoading, isError } = useQuery(
    [ASSETS, tenantId !== LKF ? tenantId : `${tenantId}-${livingInfo?.propertyNumber}`],
    () => getAssetsAPI(livingInfo?.propertyNumber),
    {
      enabled: tenantId === LKF ? !!livingInfo?.propertyNumber : true,
    },
  );

  return {
    assets: data || [],
    isLoading,
    isError,
  };
}
