import * as Yup from 'yup';
import { PASSWORD_PATTERN } from 'utils/patterns';

export const forgotPasswordVerifySchema = Yup.object().shape({
  verificationCode: Yup.string()
    .required('requiredverificationCode'),

  email: Yup.string()
    .email('invalidEmailError')
    .max(255)
    .required('requiredEmail'),

  newPassword: Yup.string()
    .required('requiredNewPassword')
    .matches(
      PASSWORD_PATTERN,
      ('passPatternError'),
    ),
});
