import React from 'react';
import { Text } from 'components/typography/Text';
import styles from './styles.module.scss';

export const ColumnSubHeading = ({ intl }) => (
  <div className={styles.columnHeading}>
    <Text
      thin
      element="h3"
      intl={intl}
    />
  </div>
);
