import parser from 'cron-parser';
import { setTime } from 'utils/timeAndDate';

const INITIAL_CRON = '0 0 * * *';

export function convertToCron(date, dayOfWeek) {
  const interval = parser.parseExpression(INITIAL_CRON);
  const fields = JSON.parse(JSON.stringify(interval.fields)); // Fields is immutable

  const cronExpression = parser.fieldsToExpression(
    {
      hour: [date.getHours()],
      minute: [date.getMinutes()],
      second: [0],
      month: fields.month,
      dayOfMonth: fields.dayOfMonth,
      dayOfWeek,
    },
  );
  return cronExpression.stringify();
}

export function getCronDays(cron) {
  const interval = parser.parseExpression(cron);
  const fields = JSON.parse(JSON.stringify(interval.fields));

  return fields.dayOfWeek;
}

export function convertCronToTime(cron) {
  const interval = parser.parseExpression(cron);
  const fields = JSON.parse(JSON.stringify(interval.fields));

  return setTime(fields.hour, fields.minute);
}
