import React from 'react';
import { MultiStep } from 'components/MultiStep';
import { ShouldRender } from 'components/ShouldRender';
import { InternetProviders } from './components/InternetProviders';
import { InternetProvidersOverview } from './components/InternetProvidersOverview';
import { SubscriptionDetails } from './components/SubscriptionDetails';
import { Checkout } from './components/Checkout';

export const InternetProviderContext = React.createContext();

export const InternetProvidersModal = ({ selectedProvider }) => {
  const [internetProvider, setInternetProvider] = React.useState();
  const [selectedSpeed, setSelectedSpeed] = React.useState();

  return (
    <InternetProviderContext.Provider 
      value={{ internetProvider, setInternetProvider, selectedSpeed, setSelectedSpeed }}
    >
      <ShouldRender condition={!selectedProvider}>
        <MultiStep>
          <MultiStep.Step> 
            <InternetProvidersOverview />
          </MultiStep.Step>

          <MultiStep.Step>
            <InternetProviders />
          </MultiStep.Step>

          <MultiStep.Step>
            <Checkout />
          </MultiStep.Step>
        </MultiStep>
      </ShouldRender>

      <ShouldRender condition={!!selectedProvider}>
        <SubscriptionDetails />
      </ShouldRender>
    </InternetProviderContext.Provider>
  );
};
