import React from 'react';
import { Card } from 'components/cards/Card';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography';
import { StatusDot } from 'components/StatusDot';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export const SecurityControlCard = ({ controlIcon, controlType, controlName, sensorStatus, alarmStatus }) => (
  <Card
    grey
  >
    <Grid
      twoColumns
      noPadding
      style={{ gridGap: 5 }}
    >

      {controlIcon}

      <Text
        size={12}
        bold
        dark
        uppercase
        right
      >
        {controlType}
      </Text>
    </Grid>
      
    <Text
      bold
      right
      dark
    >
      {controlName}
    </Text>

    <div className={styles.statusLine}>
      <Text
        dark
        intl="sensorStatus"
        intlValues={{
          status: sensorStatus,
          bold: (...chunks) => (
            <Text
              element="span"
              dark
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    
      <StatusDot
        active
        small
      />
    </div>

    <div className={styles.statusLine}>
      <Text
        dark
        intl="alarmDetected"
        intlValues={{
          alarm: alarmStatus,
          bold: (...chunks) => (
            <Text
              element="span"
              dark
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    
      <StatusDot
        active
        small
      />
    </div>

    <ShouldRender condition={controlType === 'camera'}>
      <Text
        dark
        intl="cameraConsumption"
        intlValues={{
          bold: (...chunks) => (
            <Text
              element="span"
              dark
              bold
            >
              {chunks}
            </Text>
          ),
        }}
      />
    </ShouldRender>
    
  </Card>
);
