import React, { useState } from 'react';
import { Brikk } from 'components/Brikk';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { ThingsAndResourcesPreview } from 'components/brikks/home/ThingsAndResources/components/ThingsAndResourcesPreview';
import { Image } from 'components/media/Image';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { InsuranceHouseIcon } from 'assets/icons/brikks/InsuranceHouseIcon';
import { DemoModal } from 'components/modals/DemoModal';
import brikkModalSrc from 'components/brikks/home/ThingsAndResources/assets/things-and-resources-modal.png';

export const ThingsAndResources = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk brikkKey={code} name={name} status={status}>
          <Brikk.Header
            icon={<InsuranceHouseIcon />}
            heading="thingsAndResourcesBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content center>
            <ThingsAndResourcesPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              onClick={handleOpenModal}
              brikkNameIntl="thingsAndResources"
            />
            <InfoButton showModal={isInfoModalOpen} onClick={handleInfoModal} />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="Things and Resources info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <DemoModal
        isOpen={isModalOpen}
        heading="thingsAndResources"
        onClose={() => setIsModalOpen(false)}
      >
        <Image src={brikkModalSrc} />
      </DemoModal>
    </>
  );
};
