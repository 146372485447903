import React from 'react';
import { internetProviderCategories, climateCategories, lightsCategories } from 'utils/subscriptions';
import { Field } from 'components/form-elements/Field';
import { useIntl } from 'react-intl';
import { INTERNET_PROVIDER, LIGHTS, CLIMATE } from 'utils/types';

export const SubscriptionCategories = ({ item, onCategorySelect, selectedCategory }) => {
  const intl = useIntl();

  const subscriptionCategories = React.useMemo(() => {
    switch (item) {
      case INTERNET_PROVIDER:
        return internetProviderCategories;
      case LIGHTS:
        return lightsCategories;
      case CLIMATE:
        return climateCategories;
      default: 
        return [];
    }
  }, [item]);
  
  return (
    <>
      { subscriptionCategories?.map((category, i) => (

        <Field 
          key={i}
          onClick={() => onCategorySelect(category)}
          field={category.name}
          fieldName={intl.formatMessage({ id: category.name })}
          selectedField={selectedCategory}
        />
      ))}
    </>
  );
};
