import React from 'react';
import { Text } from 'components/typography/Text';
import { Button, Icon } from 'semantic-ui-react';
import { DeleteIcon } from 'assets/icons/DeleteIcon';
import styles from './styles.module.scss';

export const DeleteButton = ({ intl, onClick }) => (
  <Button
    type="button"
    icon
    labelPosition="left"
    onClick={onClick}
    className={styles.button}
    id={styles.deleteButton}
  >
    <Icon className={styles.button}>
      <DeleteIcon />
    </Icon>

    <Text
      size={14}
      red
      bold
      underline
      intl={intl}
    />   
  </Button>
);
