import { DownloadInvoiceActions } from './actions';

const initialState = {
  loading: {},
  error: {},
};

export function downloadInvoiceReducer(state = initialState, action) {
  switch (action.type) {
    case DownloadInvoiceActions.DOWNLOAD_INVOICE:
      return {
        ...state,
        loading: { ...state.loading, [action.payload.invoiceNumber]: true },
        error: { ...state.error, [action.payload.invoiceNumber]: '' },
      };
    case DownloadInvoiceActions.DOWNLOAD_INVOICE_SUCCESS:
      return {
        ...state,
        loading: { ...state.loading, [action.payload.invoiceNumber]: false },
      };
    case DownloadInvoiceActions.DOWNLOAD_INVOICE_FAIL:
      const { error, invoiceNumber } = action.payload;
      return {
        ...state,
        loading: { ...state.loading, [invoiceNumber]: false },
        error: { ...state.error, [invoiceNumber]: error },
      };
    default:
      return state;
  }
}
