import { chain } from 'lodash';
import { daysFormat, formatDate } from 'utils/timeAndDate';

const mapInvoice = invoice => ({
  invoiceNumber: invoice.invoiceNumber,
  period: invoice.period,
  status: invoice.status,
  dueDate: invoice.dueDate ? formatDate(new Date(invoice.dueDate), daysFormat) : '-',
  latestPaymentDate: invoice.latestPaymentDate ? formatDate(new Date(invoice.latestPaymentDate), daysFormat) : '-',
  remainingBalance: invoice.remainingBalanceText,
  totalBalance: invoice.totalBalanceText,
  type: invoice.type,
  bankInfo: invoice.bankAccount,
  ocrRef: invoice.ocrRef,
});

// show all invoices
export function mapDataToClient(invoices) {
  return chain(invoices)
    .map(mapInvoice)
    .value();
}
