import React from 'react';
import styles from './styles.module.scss';
import { Placeholder } from 'semantic-ui-react';
import { useTheme } from 'global/themes';

export const BrikkPlaceholder = () => {
  const { theme } = useTheme();

  return (
    <Placeholder className={styles.placeholder}>
      <Placeholder.Line className={theme.backgroundColor} />
      <Placeholder.Line className={theme.backgroundColor}  />
      <Placeholder.Line className={theme.backgroundColor}  />
      <Placeholder.Line className={theme.backgroundColor} />
    </Placeholder>
  );
};
