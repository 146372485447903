import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { Grid } from 'components/layout/Grid';
import welcomeScreenSrc from 'assets/media/welcome-screen.png';
import introVideoSrc from 'assets/media/vidingehem/intro-video.mp4';
import { Text } from 'components/typography/Text';
import { AppTracking } from 'global/appTracking';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { VXBOnboardingRoutes } from 'pages/onboarding/VXBOnboarding/routes';
import { OnboardingImage } from 'pages/onboarding/OnboardingImage';
import { FormattedMessage } from 'react-intl';

export const Welcome = () => {
  const { onRedirect } = useRedirect(VXBOnboardingRoutes.CONTACT_DETAILS);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <OnboardingImage imageSrc={welcomeScreenSrc}>
            <ImageWithOverlay.MainOverlay intl="onboardingWelcomeTitle" />
          </OnboardingImage>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingWelcomeHeading">
            <Text size={18}>
              <FormattedMessage
                id="onboardingWelcomeDescription"
                values={{
                  video: (...chunks) => (
                    <video
                      width="100%"
                      height={300}
                      controls
                      onPlay={() => AppTracking.onboardingEvent({ action: 'Welcome Video Played' })}
                    >
                      {chunks}
                      <source src={introVideoSrc} />
                    </video>
                  )
                }}
              />
            </Text>
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
}
