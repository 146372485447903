import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getRSSFeedAPI } from './api';

const RSS_FEED = 'rssFeed';

export function useRSSFeed() {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { data, isLoading, isFetching } = useQuery(
    [RSS_FEED, tenantId],
    () => getRSSFeedAPI(),
  );

  return {
    rssFeed: data || [],
    isLoading: isLoading || isFetching,
  };
}
