import { getAuthToken } from 'utils/auth-token';

export async function authorize(options = {}) {
  const jwtToken = await getAuthToken();

  const authHeader = {
    Authorization: jwtToken,
  };

  return { ...options, headers: { ...options.headers, ...authHeader } };
}
