import React from 'react';
import { Button } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Text } from 'components/typography';
import { Card } from 'components/cards/Card';
import { Grid } from 'components/layout/Grid';
import { Form } from 'components/form-elements/Form';
import { LabeledDatepicker } from 'components/form-elements/pickers/LabeledDatepicker';
import { LinkRadio } from 'components/form-elements/radios/LinkRadio';
import { ShouldRender } from 'components/ShouldRender';
import { dateToString } from 'utils/timeAndDate';
import { useLocale } from 'services/user/locale/hook';
import { useSubmitInternetSubscription } from 'services/internet-providers/submit-internet-subscription';
import { submitInternetSubscriptionSchema, submitInternetSubscriptionSchemaFree } from 'utils/validation/schemas';
import styles from './styles.module.scss';

export function InternetConfirmation({ internetProvider }) {
  const { locale } = useLocale();

  const initialInternetSubscription = {
    productId: internetProvider.id,
    activationDate: internetProvider.activationDate,
    termsAndConditions: false,
  };

  const { submitInternetSubscription, isLoading } = useSubmitInternetSubscription();

  const { control, handleSubmit, watch, formState } = useForm({
    mode: 'onChange',
    resolver: yupResolver(internetProvider.termsAndConditionsUrl ? submitInternetSubscriptionSchema : submitInternetSubscriptionSchemaFree),
    defaultValues: initialInternetSubscription,
  });

  const internetSubscription = watch();

  function onSubmit(data) {
    submitInternetSubscription(data);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Grid
        oneColumn
        noPadding
        style={{ gridGap: 25, justifyItems: 'left' }}
      >
        <Card grey>
          <Text
            size={16}
            intl="possibleStartDate"
          />

          <Text
            bold
            size={20}
          >
            {dateToString(internetProvider.activationDate, locale)}
          </Text>
        </Card>

        <Controller
          name="activationDate"
          control={control}
          render={({ field }) => (
            <LabeledDatepicker
              intl="startService"
              minDate={internetProvider.activationDate}
              onChange={(date) => field.onChange(!date ? internetProvider.activationDate : date)}
              selectedDate={field.value}
            />
          )}
        />

        <Text
          size={16}
          intl="signToNotice"
        />

        <Text
          bold
          size={20}
          intl="broadbandConfirmation"
          intlValues={{
            provider: internetProvider.name,
            date: dateToString(internetSubscription.activationDate, locale),
            active: (...chunks) => (
              <Text
                element="span"
                size={20}
                bold
                className={styles.active}
              >
                {chunks}
              </Text>
            ),
          }}
        />

        <Text
          size={16}
          uppercase
          intl="confirmTheOrder"
        />

        <ShouldRender condition={internetProvider.termsAndConditionsUrl}>
          <Controller
            name="termsAndConditions"
            control={control}
            render={({ field }) => (
              <LinkRadio
                toggle
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
                intl="termsAndConditions"
                link={internetProvider.termsAndConditionsUrl}
              />
            )}
          />
        </ShouldRender>

        <Button
          name="submit-internet-subscription"
          type="submit"
          loading={isLoading}
          disabled={!formState.isValid}
        >
          <Text
            dark
            bold
            intl="confirmSupplier"
            intlValues={{ supplier: internetProvider.name }}
          />
        </Button>
      </Grid>
    </Form>
  );
}
