import { useContext, useMemo } from 'react';
import flatTheme from './flat.module.scss';
import darkTheme from './dark.module.scss';
import transparentTheme from './transparent.module.scss';
import { AppContext } from 'AppContext';
import { tenantConfig } from 'global/tenant-config';
import { getTenantIDBasedOnURL } from "global/tenant-config";
import { useMultiGrid } from 'services/multi-grid/hook';

export const FLAT_THEME = 'solid';
export const TRANSPARENT_THEME = 'transparent';
export const DARK_THEME = 'dark';

export const VXB = 'vxb';
export const VXJ = 'vxj';
export const LKF = 'lkf';
export const BRE = 'bre';
export const WXT = 'wxt';
export const SFG_SOLID = 'sfg';
export const SFG_TRANSPARENT = 'sfgBlue';


export function useTheme() {
  const { userSettings: { theme, updateUserSettings }, selectedTheme } = useContext(AppContext);
  const { selectedGrid } = useMultiGrid();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const themeStyles = useMemo(() => {
    switch (theme) {
      case 'light':
      case FLAT_THEME:
        return flatTheme;
      case DARK_THEME:
        return darkTheme;
      case TRANSPARENT_THEME:
        return transparentTheme;
      default:
        return flatTheme;
    }
  }, [theme]);

  const images = useMemo(() => {
    const images = {
      logo: tenantConfig.assets.logoWhite,
      toolUnderline: './brikks-icons/inner/tool_underline_dark.png',
    };

    processThemeSizes(tenantConfig.sizes);

    if(tenantConfig.gridThemes) {
      if(tenantConfig.gridThemes[selectedGrid]) {
        if(tenantConfig.gridThemes[selectedGrid].condition?.tenantThemeIsIn.includes(selectedTheme)) {
          images.logo = tenantConfig.gridThemes[selectedGrid].logo
          images.toolUnderline = './brikks-icons/inner/tool_underline.png';
          processThemeColors(tenantConfig.gridThemes[selectedGrid].theme.solid.colors);
          return images
        }
      }
    }

    if (selectedTheme === tenantId) {
      switch (theme) {
        case 'light':
        case FLAT_THEME:
          images.logo = tenantConfig.assets.logoDark;
          images.toolUnderline = './brikks-icons/inner/tool_underline.png';
          processThemeColors(tenantConfig.themes.solid.colors);
          break;
        case TRANSPARENT_THEME:
          images.logo = tenantConfig.assets.logoDark;
          images.toolUnderline = './brikks-icons/inner/tool_underline.png';
          processThemeColors(tenantConfig.themes.transparent.colors);
          break;
        case DARK_THEME:
          processThemeColors(tenantConfig.themes.dark.colors);
          break;
        default: break;
      }
    }
    else {
      if(tenantConfig.additionalThemes) {
        images.logo = tenantConfig.additionalThemes[selectedTheme].logo
        images.toolUnderline = './brikks-icons/inner/tool_underline.png';
        processThemeColors(tenantConfig.additionalThemes[selectedTheme].theme.solid.colors);
      }
    }

    return images;
  }, [theme, selectedTheme, selectedGrid, tenantId]);

  return {
    theme: themeStyles,
    currentTheme: theme,
    setTheme: (theme) => updateUserSettings({ theme }),
    images
  }
}

function processThemeColors(themeColors) {
  for (const [key, value] of Object.entries(themeColors)) {
    if (!!value) {
      document.documentElement.style.setProperty(`--color--${key}`, value.toString());
    }
  }
}

function processThemeSizes(themeSizes) {
  for (const [key, value] of Object.entries(themeSizes)) {
    if (!!value) {
      document.documentElement.style.setProperty(`--size--${key}`, value.toString());
    }
  }
}
