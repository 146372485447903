import React from 'react';
import { MultiTabMenu } from 'components/menus/MultiTabMenu';
import {
  GrtLivingInfoOptions,
} from 'components/brikks/home/MyHome/components/LivingInfoModal/components/LivingInfo/grtLivingInfoOptions';

export const GRTLivingInfo = () => (
  <MultiTabMenu
    defaultTab="yourApartment"
    defaultMenu={GrtLivingInfoOptions[0]}
    tabs={{
      yourApartment: { title: 'yourApartment', menus: GrtLivingInfoOptions },
      generalInfo: { title: 'generalInfo', menus: [] },
    }}
  />
);
