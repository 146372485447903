import React from 'react';
import { Text as GlobalText } from 'components/typography';

export const Text = ({ children, intl, intlValues }) => (
  <GlobalText
    size={18}
    intl={intl}
    intlValues={intlValues}
  >
    {children}
  </GlobalText>
);
