import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import styles from './styles.module.scss';

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 100,
  }),
};

export const Select = ({
  placeholder = '',
  options, 
  onChange, 
  className,
  components,
  hasError, 
  isDisabled, 
  isClearable,
  name,
  value,
  isLoading,
  allowSelectAll,
  closeMenuOnSelect,
  hideSelectedOptions,
  isMulti,
}) => {
  const intl = useIntl();

  return (
    <ReactSelect
      className={classNames(styles.select, className, hasError && styles.error)}
      components={components}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      isDisabled={isDisabled}
      isClearable={isClearable}
      name={name}
      value={value}
      isLoading={isLoading}
      allowSelectAll={allowSelectAll}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      isMulti={isMulti}
      styles={customStyles}
      blurInputOnSelect={false}
      noOptionsMessage={() => intl.formatMessage({ id: 'noOptions' })}
      loadingMessage={() => intl.formatMessage({ id: 'loading' })}
    />
  ); 
};

