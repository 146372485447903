export const MultiGridActions = {
  SET_MULTIGRID: '@multiGrid/set',
}

export function setMultiGrid(selectedGrid) {
  return {
    type: MultiGridActions.SET_MULTIGRID,
    payload: selectedGrid,
  }
}
