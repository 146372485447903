import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { Button, Message, Ref } from 'semantic-ui-react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoginWithEmail } from 'services/auth/login-email/hook';
import { ShouldRender } from 'components/ShouldRender';
import classNames from 'classnames';
import { AppContext } from 'AppContext';
import { useRedirect } from 'utils/redirect';
import { Link, useLocation } from 'react-router-dom';
import { PasswordInput } from 'components/form-elements/inputs/PasswordInput';
import { TENANT_ID } from 'global/environment';
import styles from '../../styles.module.scss';

export const PasswordLogin = ({ email, emailError, setEmailError }) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const intl = useIntl();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirect = query.get('redirect_url');

  const { user, loading, error, onEmailLogin } = useLoginWithEmail();

  const { setUser, setIsAuthenticated, setTenant } = useContext(AppContext);

  const { onRedirect } = useRedirect(redirect || '/');

  const submitRef = useRef(null);

  useEffect(() => {
    if (user) {
      setUser(user);
      setTenant(JSON.parse(user.attributes['custom:data'])[TENANT_ID]?.tenant);
      setIsAuthenticated(true);
      onRedirect();
    }
  }, [user, setUser, setIsAuthenticated, setTenant, onRedirect]);

  const onChangePassword = useCallback((e) => {
    setPassword(e.target.value);
    setPasswordError('');
  }, [setPassword, setPasswordError]);

  const onLogin = useCallback(() => {
    const passwordError = !password.trim().length;
    const emailError = !email.trim().length;

    if (passwordError && emailError) {
      setPasswordError('fieldRequired');
      setEmailError('fieldsRequired');
    } else if (passwordError) {
      setPasswordError('fieldRequired');
    } else if (emailError) {
      setEmailError('fieldRequired');
    } else {
      onEmailLogin(email, password);
    }
  }, [password, email, setPasswordError, setEmailError, onEmailLogin]);

  return (
    <>
      <PasswordInput
        fluid
        className={classNames(styles.field, passwordError && 'error')}
        id="password"
        name="password"
        label={intl.formatMessage({ id: 'password' })}
        placeholder={intl.formatMessage({ id: 'passwordPlaceholder' })}
        value={password}
        onChange={onChangePassword}
        submitRef={submitRef}
      />

      <div className={styles.actions}>
        <Ref innerRef={submitRef}>
          <Button
            className={styles.login}
            loading={loading}
            disabled={loading}
            onClick={onLogin}
          >
            <FormattedMessage id="login" />
          </Button>
        </Ref>

        <Link to="/forgot-pass">
          <FormattedMessage id="forgotPassword" />
        </Link>
      </div>

      <ShouldRender condition={(!!passwordError || !!error) && !emailError}>
        <Message
          negative
          className={styles.field}
        >
          <FormattedMessage id={passwordError || error} />
        </Message>
      </ShouldRender>
    </>
  );
};
