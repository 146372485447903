import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import welcomeImgSrc from 'assets/media/grt/onboarding-welcome.png'; 
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { ImageOverlay } from 'pages/onboarding/ImageOverlay';
import { ONBOARDING_YOUR_INFO } from 'global/routes';

export const Welcome = () => {
  const { onRedirect } = useRedirect(ONBOARDING_YOUR_INFO);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <ImageOverlay
            imageSrc={welcomeImgSrc}
            overlayIntl="onboardingWelcomeTitle"
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingWelcomeHeading"> 
            <Text
              intl="onboardingWelcomeDescription"
              size={18}
              intlValues={{
                bold: (...chunks) => (
                  <Text
                    element="span"
                    size={18}
                    bold
                  >
                    {chunks}
                  </Text>
                ),
              }}
            />
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
};
