import React from 'react';
import classNames from 'classnames';
import { Image } from 'components/media/Image';
import { Text } from 'components/typography/Text';
import { useTheme } from 'global/themes';
import { FocusableElement } from 'components/FocusableElement';
import { parseTextToHtml } from 'utils/format';
import { ArticleDetails } from '../../../ArticleDetails';
import styles from './styles.module.scss';

export const Article = ({ article, onSelect, rightBorder }) => {
  const { image, title, feed, datePublished, description } = article;

  const { theme } = useTheme();

  return (
    <a href="#selectedArticle">
      <FocusableElement
        className={classNames(styles.article, rightBorder && styles.rightBorder, theme.backgroundColor)}
        onClick={() => onSelect(article)}
      >
        <div className={styles.imageAndFeed}>
          <div className={styles.image}>
            <Image
              circle
              src={image}
              alt={title}
            />
          </div>
        </div>

        <div className={styles.articleInfo}>
          <div className={styles.title}>
            <Text
              xl
              bold
            >
              {title}
            </Text>
          </div>

          <div className={styles.preview}>
            <Text small>
              {parseTextToHtml(description)}
            </Text>
          </div>

          <div className={styles.date}>
            <ArticleDetails
              datePublished={datePublished}
              feed={feed}
            />
          </div>
        </div>
      </FocusableElement>
    </a>
  );
};
