import React from 'react';
import { Text } from 'components/typography/Text';
import { Image as SemanticImage } from 'semantic-ui-react';
import { ShouldRender } from 'components/ShouldRender';
import styles from 'components/brikks/sustainability/WaterSaving/components/WaterSavingModal/components/WaterSavingTip/styles.module.scss';

export const WaterSavingTip = ({ intl, contentImage }) => (
  <>
    <ShouldRender condition={!!contentImage}>
      <SemanticImage
        src={contentImage}
        className={styles.image}
      />
    </ShouldRender>
    
    <Text
      size={16}
      intl={intl}
    />
  </> 
);
