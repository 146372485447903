import React, { useContext } from "react";
import { Button } from "semantic-ui-react";
import styles from "./styles.module.scss";
import { CheckMarkIcon } from "assets/icons/CheckMarkIcon";
import classNames from "classnames";
import { useUserSettings } from "services/user/settings/hook";
import { AppContext } from "AppContext";
import { useAgreements } from "services/agreements/agreements";

export const ChecklistButton = ({ intl, className }) => {

  let { movingOutCheckList } = useUserSettings();
  const { agreements } = useAgreements();

  const {
    userSettings: { updateUserSettings },
  } = useContext(AppContext);

  const handleClick = (e, value) => {
    e.stopPropagation();
    if(!movingOutCheckList) movingOutCheckList = {};

    if(movingOutCheckList[value]?.checked) {
      movingOutCheckList[value].checked = !movingOutCheckList[value].checked;
    } else {
      movingOutCheckList[value] = { checked: true};
    }
    updateUserSettings({ movingOutCheckList: movingOutCheckList })
  }

  const isChecked = () => {
      return intl !== 'terminateAgreements' ?
        movingOutCheckList && movingOutCheckList[intl]?.checked : !agreements.some(item => item.status === 'active')
  }
  return (
    <Button
      className={classNames(styles.actionButton, className)}
      onClick={intl !== 'terminateAgreements' ?
        (e) => handleClick(e, intl) : undefined}
    >
      <CheckMarkIcon
        activeGrey={!isChecked()}
        activeGreen={isChecked()}
      />
    </Button>
  )
 }
