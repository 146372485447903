import React from 'react';
import { useTable } from 'react-table';
import classNames from 'classnames';
import { CheckMarkIcon } from 'assets/icons/CheckMarkIcon';
import { ShouldRender } from 'components/ShouldRender';
import styles from './styles.module.scss';

export function Table({
  data,
  columns,
  activationDate,
  clickedRow,
  onRowClick,  
}) {

  const tableData = React.useMemo(() => data, [data]);
  const tableColumns = React.useMemo(() => columns, [columns]);

  const tableContext = useTable({ columns: tableColumns, data: tableData });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableContext;

  return (
    <table
      className={styles.table}
      {...getTableProps()}
    >
      <thead className={styles.tHeader}>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {
              headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))
}
          </tr>
        ))}
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              className={classNames(styles.tableRow, clickedRow?.id === row.original.id ? styles.activeRow : null,
                row.original.campaign ? styles.campaign : null)}
              {...row.getRowProps()}
              onClick={() => { onRowClick({ ...row.original, activationDate }); }}
            >

              {row.cells.map((cell, index) => (
                <td
                  className={index === 0 && row.original.campaign ? styles.first : null}
                  {...cell.getCellProps()}
                >{cell.render('Cell')}
                </td>
              ))}

              <td className={classNames(styles.checkmark, row.original.campaign ? styles.last : null)}>
                <ShouldRender condition={clickedRow?.id === row.original.id}>
                  <CheckMarkIcon
                    size={15}
                    active
                  />
                </ShouldRender>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

