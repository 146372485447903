import React from 'react';
import { Text } from 'components/typography/Text';
import { Icon, Segment } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import styles from './styles.module.scss';

export const SigningConfirmation = () => {
    return (
        <>
            <Text intl="signYourOrder" size={22} bold />
            <Segment className={styles.warningBox}>
                <Text size={16}>
                    <Icon name='warning' color="black" />
                    <FormattedMessage
                        id="signingConfirmation"
                        values={{
                            bold: (...chunks) => (
                                <Text
                                    size={16}
                                    element="span"
                                    bold >
                                    {chunks}
                                </Text>
                            )
                        }}
                    />
                </Text>
            </Segment>
        </>
    )
}