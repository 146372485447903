import React from 'react';

export const SoundIcon = () => {
    return (
        <svg id="music-sound" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
            <path id="Path_7972" data-name="Path 7972" d="M11.833,0A.833.833,0,0,0,11,.833V19.167a.833.833,0,1,0,1.667,0V.833A.833.833,0,0,0,11.833,0Z" transform="translate(-1.833 0)" />
            <path id="Path_7973" data-name="Path 7973" d="M7.833,2A.833.833,0,0,0,7,2.833v15a.833.833,0,0,0,1.667,0v-15A.833.833,0,0,0,7.833,2Z" transform="translate(-1.167 -0.333)" />
            <path id="Path_7974" data-name="Path 7974" d="M3.833,6A.833.833,0,0,0,3,6.833v8.333a.833.833,0,1,0,1.667,0V6.833A.833.833,0,0,0,3.833,6Z" transform="translate(-0.5 -1)" />
            <path id="Path_7975" data-name="Path 7975" d="M.833,10A.833.833,0,0,0,0,10.833V12.5a.833.833,0,0,0,1.667,0V10.833A.833.833,0,0,0,.833,10Z" transform="translate(0 -1.667)" />
            <path id="Path_7976" data-name="Path 7976" d="M15.833,2A.833.833,0,0,0,15,2.833v15a.833.833,0,0,0,1.667,0v-15A.833.833,0,0,0,15.833,2Z" transform="translate(-2.5 -0.333)" />
            <path id="Path_7977" data-name="Path 7977" d="M19.833,6A.833.833,0,0,0,19,6.833v8.333a.833.833,0,1,0,1.667,0V6.833A.833.833,0,0,0,19.833,6Z" transform="translate(-3.167 -1)" />
            <path id="Path_7978" data-name="Path 7978" d="M22.833,10a.833.833,0,0,0-.833.833V12.5a.833.833,0,0,0,1.667,0V10.833A.833.833,0,0,0,22.833,10Z" transform="translate(-3.667 -1.667)" />
        </svg>
    )
}