import React from 'react';
import styles from './styles.module.scss';

export const WarningIcon = () => (
  <svg className={styles.icon} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.137 31.904">
    <path
          d="M33.457,28.707,19.809,2.643h0a2.92,2.92,0,0,0-5.173,0L.986,28.707a2.92,2.92,0,0,0,2.587,4.275h27.3a2.92,2.92,0,0,0,2.587-4.275Zm-17.7-16.9a1.46,1.46,0,0,1,2.92,0v8.761a1.46,1.46,0,1,1-2.92,0Zm1.533,16.806h-.041a2.231,2.231,0,0,1-2.222-2.146,2.155,2.155,0,0,1,2.114-2.234h.041a2.23,2.23,0,0,1,2.225,2.142,2.154,2.154,0,0,1-2.117,2.238Z"
          transform="translate(-0.653 -1.078)"/>
  </svg>
);
