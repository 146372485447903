import React from 'react';
import { Grid } from 'components/layout/Grid';

export const OnboardingGrid = ({ children }) => (
  <Grid
    transparent
    paddingTop
    brikkLayout
  >
    {children}
  </Grid>
);
