import React from 'react';
import styles from './styles.module.scss';
import { Text } from 'components/typography/Text';
import { formatCurrency } from "utils/format";

export const TotalCost = ({ totalCost }) => (
  <div className={styles.totalCost}>
    <Text
      white
      size={18}
      className={styles.amountLabel}
      intl="amount"
    />

    <Text
      white
      size={18}
    >
      {formatCurrency(totalCost)} KR
    </Text>
  </div>
);
