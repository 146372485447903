import React from 'react';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography/Text';
import { Label } from 'components/form-elements/labels/Label';
import styles from './styles.module.scss';

export const LabeledElement = ({
  id,
  label,
  description,
  children,
}) => (
  <div className={styles.element}>
    <Label
      intl={label}
      id={id}
    />

    {children}

    <ShouldRender condition={!!description}>
      <Text
        intl={description}
        element="span"
        size={12}
      />
    </ShouldRender>
  </div>
);

