import React from 'react';

export const PowerSupplyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19">
  <g id="car-tool-battery-bolt" transform="translate(0 -2.5)">
    <path id="Path_7995" data-name="Path 7995" d="M24,8a1.5,1.5,0,0,0-1.5-1.5H1.5A1.5,1.5,0,0,0,0,8V20a1.5,1.5,0,0,0,1.5,1.5h21A1.5,1.5,0,0,0,24,20ZM7.523,15a.565.565,0,0,1-.469-.987l4.418-5.421a.249.249,0,0,1,.194-.092H16.2a.25.25,0,0,1,.183.42l-2.942,3.16a.249.249,0,0,0,.183.42h3.014c.333,0,.44.162.475.259s.055.291-.2.5L9.435,19.443a.254.254,0,0,1-.159.057.25.25,0,0,1-.241-.316l1.053-3.868A.249.249,0,0,0,9.847,15Z" fill="black"/>
    <path id="Path_7996" data-name="Path 7996" d="M3.75,5.5h4a.5.5,0,0,0,.5-.5V3.5a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1V5A.5.5,0,0,0,3.75,5.5Z" fill="black"/>
    <path id="Path_7997" data-name="Path 7997" d="M16.25,5.5h4a.5.5,0,0,0,.5-.5V3.5a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1V5A.5.5,0,0,0,16.25,5.5Z" fill="black"/>
  </g>
</svg>
)