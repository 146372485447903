import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getLivingInfoAPI } from './api';

const LIVING_INFO = 'livingInfo';

export function useLivingInfo() {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { data, isLoading, isFetching, isError } = useQuery(
    [LIVING_INFO, tenantId],
    () => getLivingInfoAPI(),
  );

  return {
    livingInfo: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
