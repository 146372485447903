import React from 'react';

export const RentIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <desc>Illustration of an invoice</desc>
    <g transform="translate(-819 -604)">
      <path d="M10.687,9.909,7.77,8.45a.417.417,0,0,0-.373,0L4.48,9.909a.417.417,0,0,0-.23.372v7.292a.417.417,0,0,0,.417.417H6.194a.417.417,0,0,0,.417-.417V16.531a.417.417,0,0,1,.417-.417H8.139a.417.417,0,0,1,.417.417v1.042a.417.417,0,0,0,.417.417H10.5a.417.417,0,0,0,.417-.417V10.281a.417.417,0,0,0-.23-.372ZM9.042,14.1H6.125a.417.417,0,0,1,0-.833H9.042a.417.417,0,0,1,0,.833Zm0-2.431H6.125a.417.417,0,0,1,0-.833H9.042a.417.417,0,1,1,0,.833Z"
            transform="translate(818.292 602.599)"/>
      <path d="M17.333,4.424l-2.4,2.2a.417.417,0,0,1-.6-.032l-.515-.589a1.042,1.042,0,1,0-1.569,1.37L13.416,8.7a1.542,1.542,0,0,0,2.206.123l3.127-2.868a1.044,1.044,0,0,0-1.417-1.535Z"
            transform="translate(817.001 603.309)"/>
      <path d="M17.917,0H2.083A2.083,2.083,0,0,0,0,2.083V17.917A2.083,2.083,0,0,0,2.083,20H14.167a.84.84,0,0,0,.59-.245l5-5A.917.917,0,0,0,20,14.167V2.083A2.083,2.083,0,0,0,17.917,0Zm.417,12.917a.417.417,0,0,1-.417.417H15A1.667,1.667,0,0,0,13.333,15v2.917a.417.417,0,0,1-.417.417H2.083a.417.417,0,0,1-.417-.417V2.083a.417.417,0,0,1,.417-.417H17.917a.417.417,0,0,1,.417.417Z"
            transform="translate(819 604)"/>
    </g>
  </svg>
);
