import { useDispatch, useSelector } from 'react-redux';
import { submitCreateOrder, submitCreateOrderReset } from './actions';

export const useSubmitBrikkOrder = () => {
    const { isSubmitted, loading, error } = useSelector(state => state.createOrder);

    const dispatch = useDispatch();

    return {
        onSubmitCreateOrder: (
            projectName,
            emailRecipients,
            orderedBrikks) => dispatch(submitCreateOrder({ projectName, emailRecipients, orderedBrikks })),
        onResetOrder: () => dispatch(submitCreateOrderReset()),
        isSubmitted,
        loading,
        error,
    }
}