import { get } from 'lodash'; 

export function getWaterUsage(water) {
  if (water) {
    return { total: Math.round(water.total || 0), values: water.values || [] };
  }

  return { total: 0, values: [] };
}

export function getTotalWaterUsage(waterUsage) {
  return Math.round((get(waterUsage, 'coldWaterUsage.total') || 0) + (get(waterUsage, 'hotWaterUsage.total') || 0));
}

// Create a unique key to identify waterUsage for selected period.
export const createDateKey = ({ reportAfter, reportBefore }) => `${reportAfter}__${reportBefore}`;
