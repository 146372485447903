import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography/Text';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import { ModalIntro } from 'components/layout/ModalIntro';
import { Image } from 'components/media/Image';
import { ExternalLink } from 'components/links/ExternalLink';
import adressandringImg from 'assets/media/adressandring.png';
import skatteverketImg from 'assets/media/skatteverket.png';
import changeAdressImg from 'assets/media/change-adress.png';
import styles from 'components/brikks/home/ChangeAdress/components/ChangeAdressModal/styles.module.scss';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';

export const ChangeAdressModal = () => {
  return (
    <Grid brikkLayout>
      <IntlPageTitle intlTitle="changeAddress" />

      <Grid.Column>
        <Brikk.Heading main heading="changeAddress">
          <ModalIntro>
            <ModalIntro.Image src={changeAdressImg} alt="Postal boxes" />
            <ModalIntro.Text intl="changeAdressDescription" />
          </ModalIntro>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="taxAuthority">
          <Text intl={'taxAuthorityDescription'} size={18} />
          <Image src={skatteverketImg} alt={"skatteverket"} className={styles.image} />
          <ExternalLink href={'https://skatteverket.se/privat/folkbokforing/flyttanmalan.4.76a43be412206334b89800018531.html'}>
            <ProceedButton>
              <ProceedButton.Button intl={'goToExternalLink'} />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink>
        </Brikk.Heading>
      </Grid.Column>

      <Grid.Column>
        <Brikk.Heading heading="postalService">
          <Text intl={'postalServiceDescription'} size={18} />
          <Image src={adressandringImg} alt={"adressandring"} className={styles.image} />
          <ExternalLink href={'https://www.adressandring.se/'}>
            <ProceedButton>
              <ProceedButton.Button intl={'goToExternalLink'} />
              <ProceedButton.Redirect />
            </ProceedButton>
          </ExternalLink>
        </Brikk.Heading>
      </Grid.Column>
    </Grid>
  );
};
