import React from 'react';
import { Text } from 'components/typography/Text';
import { Label as SemanticLabel } from 'semantic-ui-react';
import styles from './styles.module.scss';

export const Label = ({ intl, children }) => (
  <SemanticLabel
    className={styles.label}
  >
    <Text
      as="span"
      fontSize="14px"
      intl={intl}
    >
      {children}
    </Text>
  </SemanticLabel>
);
