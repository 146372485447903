import { BankIDSigningActions } from 'services/auth/bank-id-signing/actions';
import { BankIDOrderStatusActions } from './actions';
import { BankIDLoginActions } from '../bank-id-login/actions';

const initialState = {
  orderRef: null,
  status: 'bankID-RFA1',
  isCancelling: false,
}

export function bankIDOrderStatusReducer(state = initialState, action) {
  switch (action.type) {
    case BankIDSigningActions.BANK_ID_SIGNING_INIT_SUCCESS:
    case BankIDLoginActions.BANK_ID_LOGIN_INIT_SUCCESS:
      return {
        ...state,
        orderRef: action.payload,
      }
    case BankIDOrderStatusActions.BANK_ID_UPDATE_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    case BankIDOrderStatusActions.BANK_ID_ORDER_CANCEL:
      return {
        ...state,
        isCancelling: true,
      }
    case BankIDLoginActions.BANK_ID_LOGIN_SUCCESS:
    case BankIDLoginActions.BANK_ID_LOGIN_FAIL:
    case BankIDSigningActions.BANK_ID_SIGNING_SUCCESS:
    case BankIDSigningActions.BANK_ID_SIGNING_FAIL:
    case BankIDOrderStatusActions.BANK_ID_ORDER_FAIL:
      return initialState;
    default:
      return state;
  }
}
