import { LKF } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useMutation, useQueryClient } from 'react-query';
import { useModal } from 'store/modals';
import { submitContactInfoAPI } from './api';
import { mapDataForBackend, mapDataForBackendLKF } from './mapData';

const REPORTED_CASES = 'reportedCases';

export function useSubmitContactInfo({ onSuccess }) {

  const { openModal } = useModal();

  const queryClient = useQueryClient();
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (contactInfo) => submitContactInfoAPI(
      (tenantId === LKF) ? mapDataForBackendLKF(contactInfo) : mapDataForBackend(contactInfo),
    ),
    {
      onSuccess: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'success',
            description: 'contactUsSuccess',
          },
        });
        queryClient.invalidateQueries([REPORTED_CASES, tenantId]);
        onSuccess();
      },
      onError: () => {
        openModal({
          modalType: 'NotificationModal',
          modalProps: {
            type: 'error',
            description: 'contactUsFail',
          },
        });
      },
    },
  );

  return {
    submitContactInfo: mutate,
    isLoading,
    isSuccess,
    isError,
  };
}
