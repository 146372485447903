import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Card } from 'components/cards/Card';
import { Form } from 'components/form-elements/Form';
import { SubmitButton } from 'components/form-elements/buttons/SubmitButton';
import { useIntl } from 'react-intl';
import { MultiSelect } from 'components/form-elements/selects/MultiSelect';
import { LabeledSelect } from 'components/form-elements/selects/LabeledSelect';
import { Grid } from 'components/layout/Grid';
import { useLightningGroups } from 'services/lightning/groups';
import { HOME } from 'utils/types';
import { useLightningDevices } from 'services/lightning/devices';
import { useUpdateLightningGroup } from 'services/lightning/update-group';
import { useDeleteLightningGroup } from 'services/lightning/delete-group';
import { useModal } from 'store/modals';
import Loader from 'components/loaders/Loader';
import { DeleteButton } from 'components/form-elements/buttons/DeleteButton';

const initialManageGroupValues = {
  group: null,
  sources: null,
};

export const EditGroup = () => {
  
  const intl = useIntl();
  const { lightningGroups, isLoading: isLoadingGroups } = useLightningGroups();
  const { lightningDevices, isLoading: isLoadingDevices } = useLightningDevices();
  const { openModal } = useModal();

  const { control, handleSubmit, formState: { errors }, reset, setValue, getValues } = useForm({
    mode: 'onSubmit',
    defaultValues: initialManageGroupValues,
  });

  const { updateLightningGroup, isLoading: isLoadingUpdate } = useUpdateLightningGroup({
    onSuccess: () => {
      reset();
    },
  });

  const { deleteLightningGroup, isLoading: isLoadingDelete } = useDeleteLightningGroup({
    onSuccess: () => {
      reset();
    },
  });

  const groups = useMemo(() => Object.keys(lightningGroups)
    .filter((key) => key !== HOME)
    .map(key => ({
      value: lightningGroups[key].id,
      label: key,
    })), [lightningGroups]);

  const sources = useMemo(() => lightningDevices.map(item => ({
    value: item.deviceId,
    label: item.displayName,
  })), [lightningDevices]);
  
  function onChangeGroup(group) {
    const groupSources = lightningGroups[group.label]?.devices;
    setValue('sources', groupSources.length > 0 ? groupSources.map(item => ({
      value: item.deviceId,
      label: item.displayName,
    })) : []);
  }

  function onSubmit(data) {
    updateLightningGroup(data);
  }
  
  function onDeleteGroup() {
    const group = getValues('group');

    if (!group) {
      openModal({
        modalType: 'NotificationModal',
        modalProps: {
          type: 'warning',
          description: 'selectGroupRequired',
        },
      });
    } else {
      openModal({
        modalType: 'ConfirmationModal',
        modalProps: {
          title: intl.formatMessage(
            {
              id: 'deleteGroupConfirm',
            },
            {
              group: group.label,
            },
          ),
          onConfirm: () => deleteLightningGroup(group.value),
        },
      });
    }
  }

  if (isLoadingDelete) return <Loader />;
  
  return (
    <Card 
      grey
      header="manageGroup"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          oneColumn
          noPadding
        >
          <DeleteButton
            intl="deleteGroup"
            onClick={() => onDeleteGroup()}
          />

          <Controller
            name="group"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <LabeledSelect
                placeholder={intl.formatMessage({ id: 'chooseGroup' })}
                options={groups}
                title="selectExistingGroup"
                description="selectExistingGroupDesc"
                value={field.value}
                onChange={(group) => { field.onChange(group); onChangeGroup(group); }}
                hasError={!!errors.group}
                isLoading={isLoadingGroups}
              />
            )}
          />

          <Controller
            name="sources"
            control={control}
            render={({ field }) => (
              <MultiSelect
                placeholder={intl.formatMessage({ id: 'chooseSourcePlaceholder' })}
                options={sources}
                title="chooseSourceTitle"
                description="chooseSourceDesc"
                value={field.value}
                onChange={field.onChange}
                isLoading={isLoadingDevices}
              />
            )}
          />

          <SubmitButton isLoading={isLoadingUpdate} />
        </Grid>
      </Form>
    </Card>
  );
};
