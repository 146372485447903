import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import welcomeImgSrc from 'assets/media/rib/onboarding-welcome.png'; 
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { FormattedMessage } from 'react-intl';
import { IFrame } from 'components/media/IFrame';
import { ImageOverlay } from 'pages/onboarding/ImageOverlay';
import { RIBOnboardingRoutes } from '../../routes';

export const Welcome = () => {
  const { onRedirect } = useRedirect(RIBOnboardingRoutes.SAFEST_TOWNHOUSE);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <ImageOverlay
            imageSrc={welcomeImgSrc}
            overlayIntl="onboardingWelcomeTitle"
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingWelcomeHeading">
            <Text size={18}>
              <FormattedMessage
                id="onboardingWelcomeDescription"
                values={{
                  video: () => (
                    <IFrame
                      width="100%"
                      height={300}
                      src="https://www.youtube.com/embed/S-7xq4a1dEM"
                      title="Welcome video"
                    />
                  ),
                }}
              />
            </Text>
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
};
