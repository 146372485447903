import React, { createContext, useState } from "react";
import { Grid } from "components/layout/Grid";
import { Brikk } from "components/Brikk";
import { IntlPageTitle } from "components/intl/IntlPageTitle";
import { ModalIntro } from "components/layout/ModalIntro";
import cleaningImg from "assets/media/cleaning-and-maintenance.png";
import { CleaningAndMaintenanceInfo } from "components/brikks/home/CleaningAndMaintenance/components/CleaningAndMaintenanceModal/components/CleaningAndMaintenanceInfo";

export const CleaningAndMaintenanceModalContext = createContext();

export const CleaningAndMaintenanceModal = () => {
  const [activeMenu, setActiveMenu] = useState('kitchen');

  return (
    <CleaningAndMaintenanceModalContext.Provider value={{ activeMenu, setActiveMenu }}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="cleaningAndMaintenance" />

        <Grid.Column>
          <Brikk.Heading main heading="cleaningAndMaintenance">
            <ModalIntro>
              <ModalIntro.Image
                src={cleaningImg}
                alt="Cleaning and maintenance"
              />

              <ModalIntro.Text intl="cleaningAndMaintenanceDescription" />
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <CleaningAndMaintenanceInfo />
      </Grid>
    </CleaningAndMaintenanceModalContext.Provider>
  );
};
