import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Item } from 'components/brikks/home/Intercom/components/IntercomModal/components/Manuals/Item';

export const Manuals = () => (
  <Grid
    twoEqualColumns
    style={{
      gridGap: 15,
      paddingLeft: 5,
      paddingRight: 5,
      paddingBottom: 5,
      paddingTop: 0,
    }}
  >
    <Item
      intl="manualAxisCompanionSWE"
      name="Manual Axis Companion (swe)"
    />
    <Item
      intl="manualAxisCompanionENG"
      name="Manual Axis Companion (eng)"
    />
  </Grid>
);
