import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getLightningScenariosAPI } from './api';
import { mapDataForClient } from './mapData';

const LIGHTNING_SCENARIOS = 'lightningScenarios';

export function useLightningScenarios() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [LIGHTNING_SCENARIOS, tenantId],
    () => getLightningScenariosAPI(),
  );

  return {
    lightningScenarios: data?.scenarios?.map((scenario) => mapDataForClient(scenario)) || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
