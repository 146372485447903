import React, { useContext } from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/styles.module.scss';
import { Grid } from 'components/layout/Grid';
import { IntlPageTitle } from 'components/intl/IntlPageTitle';
import { Brikk } from 'components/Brikk';
import maintenanceImage from 'assets/media/maintenance.png';
import { FormattedMessage } from 'react-intl';
import { ModalIntro } from 'components/layout/ModalIntro';
import { FloorPlan } from 'components/user-components/FloorPlan';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { MultiStepContext } from 'components/MultiStep';
import { UpgradeItemsPreview } from "components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview";
import { StepProgress } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/StepProgress';
import { tenantConfig } from 'global/tenant-config';
import { ExternalLink } from 'components/links/ExternalLink';

export const MaintenanceUpgrade = () => {
  const { onNextStep } = useContext(MultiStepContext);

  return (
    <div className={styles.maintenanceUpgrade}>
      <Grid brikkLayout>
        <IntlPageTitle intlTitle="maintenance"/>

        <Grid.Column>
          <Brikk.Heading main heading="maintenance">
            <ModalIntro>
              <ModalIntro.Image
                src={maintenanceImage}
                alt="Paintbrushes and wallpapers that resemble home renovation"
              />

              <ModalIntro.Text>
                <FormattedMessage
                  id="upgradeDescription"
                  values={{
                    a: (...chunks) => (
                      <ExternalLink
                        href={tenantConfig?.upgrade?.links?.info}
                      >
                        {chunks}
                      </ExternalLink>
                    ),
                    dot: (...chunks) => (
                      <span className={styles.greenDot}>
                        {chunks}
                      </span>
                    ),
                  }}
                />
              </ModalIntro.Text>
            </ModalIntro>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="floorPlan">
            <div className={styles.floorPlan}>
              <FloorPlan />
            </div>
          </Brikk.Heading>
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="overview">
            <UpgradeItemsPreview/>
          </Brikk.Heading>
        </Grid.Column>
      </Grid>

      <NextStep onClick={onNextStep} />

      <StepProgress />
    </div>
  );
}
