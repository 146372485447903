import React from 'react';

export const MonitorSmileIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.583" viewBox="0 0 20 19.583">
            <g id="desktop-monitor-smiley-1" transform="translate(0 -0.251)">
                <path id="Path_7556" data-name="Path 7556" d="M9.083,7.709a.833.833,0,0,0,.833-.833V5.833a.833.833,0,1,0-1.667,0V6.875a.833.833,0,0,0,.833.834Z" transform="translate(-1.375 -0.792)" />
                <path id="Path_7557" data-name="Path 7557" d="M14.583,7.709a.833.833,0,0,0,.833-.833V5.833a.833.833,0,1,0-1.667,0V6.875a.833.833,0,0,0,.833.834Z" transform="translate(-2.292 -0.792)" />
                <path id="Path_7558" data-name="Path 7558" d="M13.847,10.633a3.75,3.75,0,0,1-5.3,0,.834.834,0,1,0-1.178,1.182,5.417,5.417,0,0,0,7.659,0,.833.833,0,1,0-1.178-1.178Z" transform="translate(-1.187 -1.69)" />
                <path id="Path_7559" data-name="Path 7559" d="M18.333.251H1.667A1.667,1.667,0,0,0,0,1.918V13.584a1.667,1.667,0,0,0,1.667,1.667H18.333A1.667,1.667,0,0,0,20,13.584V1.918A1.667,1.667,0,0,0,18.333.251Zm-.417,13.333H2.083a.417.417,0,0,1-.417-.417V2.334a.417.417,0,0,1,.417-.417H17.917a.417.417,0,0,1,.417.417V13.168A.417.417,0,0,1,17.917,13.584Z" transform="translate(0)" />
                <path id="Path_7560" data-name="Path 7560" d="M13.016,20.727a.417.417,0,0,1-.32-.351l-.1-.76a.417.417,0,0,0-.417-.365H10.008a.417.417,0,0,0-.417.365l-.1.76a.417.417,0,0,1-.321.351,3.534,3.534,0,0,0-2.549,1.7A.417.417,0,0,0,7.013,23H15.18a.417.417,0,0,0,.386-.574A3.531,3.531,0,0,0,13.016,20.727Z" transform="translate(-1.099 -3.167)" />
            </g>
        </svg>
    )
}