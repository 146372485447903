import React, { useContext, useMemo } from 'react';
import { PDFIcon } from 'assets/icons/PDFIcon';
import { PlayIcon } from 'assets/icons/brikks/PlayIcon';
import { Text } from 'components/typography/Text';
import { DocumentPreviewContext } from '../../index';
import styles from './styles.module.scss';

export const DocumentTitle = ({ type }) => {
  const { name, intl } = useContext(DocumentPreviewContext);

  const icon = useMemo(() => {
    switch (type) {
      case 'pdf':
        return <PDFIcon />;
      case 'video':
        return <PlayIcon />;
      default:
        return null;
    }
  }, [type]);

  return (
    <div className={styles.documentTitle}>
      {icon}

      <Text
        intl={intl}
        className={styles.title}
        size={12}
        bold
      >
        {name}
      </Text>
    </div>
  );
};
