import React from 'react';
import { CameraIcon } from 'assets/icons/brikks/CameraIcon';
import { DoorAccessIcon } from 'assets/icons/brikks/DoorAccessIcon';
import { FireSafetyIcon } from 'assets/icons/brikks/FireSafetyIcon';
import { PowerSupplyIcon } from 'assets/icons/brikks/PowerSupplyIcon';
import { SirenIcon } from 'assets/icons/brikks/SirenIcon';
import { WifiSignalIcon } from 'assets/icons/brikks/WifiSignalIcon';
import { Grid } from 'components/layout/Grid';
import { SecurityControlCard } from '../SecurityControlCard';

const data = [
  {
    id: 0,
    controlIcon: <WifiSignalIcon />,
    controlName: 'Front',
    type: 'motion detector',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 1,
    controlIcon: <WifiSignalIcon />,
    controlName: 'Back',
    type: 'motion detector',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 2,
    controlIcon: <FireSafetyIcon />,
    controlName: 'Ground floor',
    type: 'smoke detector',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 3,
    controlIcon: <FireSafetyIcon />,
    controlName: 'First floor',
    type: 'smoke detector',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 4,
    controlIcon: <DoorAccessIcon />,
    controlName: 'Front',
    type: 'door sensor',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 5,
    controlIcon: <DoorAccessIcon />,
    controlName: 'Back',
    type: 'door sensor',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 6,
    controlIcon: <CameraIcon />,
    controlName: 'Doorbell',
    type: 'camera',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 7,
    controlIcon: <CameraIcon />,
    controlName: 'Living Room',
    type: 'camera',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 8,
    controlIcon: <SirenIcon />,
    controlName: 'Ground floor',
    type: 'siren',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
  {
    id: 9,
    controlIcon: <PowerSupplyIcon />,
    controlName: 'Ground floor',
    type: 'power supply',
    sensorStatus: 'Active',
    alarmStatus: 'None',
  },
];

export const SecurityControls = () => (
  <Grid
    twoEqualColumns
    noPadding
    style={{ gridGap: 10 }}
  >

    {
         data.map((item) => (
           <SecurityControlCard
             key={item.id}
             controlIcon={item.controlIcon}
             controlName={item.controlName}
             controlType={item.type}
             sensorStatus={item.sensorStatus}
             alarmStatus={item.alarmStatus}
           />
         ))
      }
  </Grid>
);
