import React from 'react';
import { LabeledElement } from 'components/form-elements/labels/LabeledElement';
import { Radio } from 'components/form-elements/radios/Radio';

export const LabeledRadio = ({ intl, toggle, checked, onChange, name, value, label, disabled }) => (
  <LabeledElement label={intl}>
    <Radio
      toggle={toggle}
      checked={checked}
      onChange={onChange}
      name={name}
      value={value}
      label={label}
      disabled={disabled}
    />
  </LabeledElement>
);
