import React from 'react';
import { Onboarding } from 'pages/onboarding/Onboarding';
import { ONBOARDING_WELCOME } from 'global/routes';
import { Route } from 'react-router-dom';
import { CheckYourInfo } from '../OnboardingUserInfo';
import { Welcome } from './components/Welcome';
import { CitizenHub } from './components/CitizenHub';
import { LKFOnboardingRoutes } from './routes';
import { ScienceVillage } from './components/ScienceVillage';
import { Xplorion } from './components/Xplorion';

export const LKFOnboarding = () => (
  <Onboarding>
    <Route
      exact
      path={ONBOARDING_WELCOME}
      component={Welcome}
    />

    <Route
      exact
      path={LKFOnboardingRoutes.CITIZEN_HUB}
      component={CitizenHub}
    />

    <Route
      exact
      path={LKFOnboardingRoutes.SCIENCE_VILLAGE}
      component={ScienceVillage}
    />

    <Route
      exact
      path={LKFOnboardingRoutes.XPLORION}
      component={Xplorion}
    />

    <Route
      exact
      path={LKFOnboardingRoutes.YOUR_INFO}
      component={CheckYourInfo}
    />
  </Onboarding>
);
