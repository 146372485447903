import React from 'react';

export const EventIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19.167" height="20" viewBox="0 0 19.167 20">
            <g id="calendar-favorite" transform="translate(-0.5)">
                <path id="Path_7622" data-name="Path 7622" d="M18,2.5H15.708a.208.208,0,0,1-.208-.208V.833a.833.833,0,0,0-1.667,0V4.792a.625.625,0,0,1-1.25,0V2.917a.417.417,0,0,0-.417-.417H6.958a.208.208,0,0,1-.208-.208V.833a.833.833,0,1,0-1.667,0V4.792a.625.625,0,0,1-1.25,0V2.917A.417.417,0,0,0,3.417,2.5H2.167A1.667,1.667,0,0,0,.5,4.167V18.333A1.667,1.667,0,0,0,2.167,20H18a1.667,1.667,0,0,0,1.667-1.667V4.167A1.667,1.667,0,0,0,18,2.5Zm0,15.417a.417.417,0,0,1-.417.417h-15a.417.417,0,0,1-.417-.417v-10A.417.417,0,0,1,2.583,7.5h15A.417.417,0,0,1,18,7.917Z" transform="translate(0 0)" />
                <path id="Path_7623" data-name="Path 7623" d="M14.523,13.065H12.815l-.9-2.094-.008-.017a.829.829,0,0,0-1.485.017l-.9,2.094H7.806a.788.788,0,0,0-.75.488.814.814,0,0,0,.2.9l1.493,1.333-.833,1.911a.8.8,0,0,0,.2.93.823.823,0,0,0,.965.107l2.081-1.172,2.073,1.167a.818.818,0,0,0,.965-.107.8.8,0,0,0,.2-.93l-.833-1.911,1.51-1.339a.81.81,0,0,0,.193-.883.791.791,0,0,0-.753-.488Z" transform="translate(-1.083 -1.75)" />
            </g>
        </svg>
    )
}