import React from 'react';
import { Text } from 'components/typography/Text';
import { Image } from 'components/media/Image';
import entryfyImgSrc from 'assets/media/access-app/entryfy.png';
import iosDownloadBadge from 'assets/media/ios-download-badge.svg';
import androidDownloadBadge from 'assets/media/google-play-badge.png';
import { ExternalLink } from 'components/links/ExternalLink';
import styles from 'components/brikks/home/AccessApp/components/AccessAppModal/components/Download/styles.module.scss';

export const Download = () => (
  <>
    <Text
      intl="accessAppDownloadDescription"
      size={18}
    />

    <Image src={entryfyImgSrc} />

    <div className={styles.downloadBadges}>
      <ExternalLink href="https://apps.apple.com/se/app/entryfy/id1497879952">
        <Image
          src={iosDownloadBadge}
          className={styles.icon}
        />
      </ExternalLink>
      <ExternalLink href="https://play.google.com/store/apps/details?id=com.entryfy&hl=en_US&gl=US">
        <Image
          src={androidDownloadBadge}
          className={styles.iconAndroid}
        />
      </ExternalLink>
    </div>
  </>
);
