import React, { useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { useWaterUsage } from 'services/water-usage';
import { monthNumberToName } from 'utils/timeAndDate';
import { useTheme } from 'global/themes';
import classNames from 'classnames';
import { currentMonthPeriod } from 'utils/water-usage/datePeriods';
import { get } from 'lodash';
import { MonthSelector } from './components/MonthSelector';
import { WaterUsageChart } from './components/WaterUsageChart';
import styles from './styles.module.scss';

export const ChartWrapper = () => {
  const { theme } = useTheme();

  const currentPeriod = currentMonthPeriod();
  const [period, setPeriod] = useState({
    reportAfter: currentPeriod.reportAfter,
    reportBefore: currentPeriod.reportBefore,
  });

  const { waterUsage, isLoading } = useWaterUsage(
    period.reportAfter,
    period.reportBefore,
  );

  const [waterCategory, setWaterCategory] = useState('total');

  const [selectedMonthLabel, setSelectedMonthLabel] = useState('');

  const [waterSustainabilityLimit] = useState();

  useEffect(() => {
    if (period.reportAfter.length) {
      const reportAfterDate = new Date(period.reportAfter);
      const monthIntlId = monthNumberToName(reportAfterDate.getMonth() + 1);
      setSelectedMonthLabel(monthIntlId);
    }
  }, [period]);

  return (
    <div className={styles.chartWrapper}>
      <div className={classNames(styles.chartBox, theme.backgroundColor)}>
        <div className={styles.filtersWrapper}>
          <MonthSelector
            period={period}
            setPeriod={setPeriod}
            selectedMonthLabel={selectedMonthLabel}
          />
        </div>
        
        <WaterUsageChart
          waterCategory={waterCategory}
          period={period}
          waterUsage={waterUsage}
          loading={isLoading}
          selectedMonthLabel={selectedMonthLabel}
          waterSustainabilityLimit={waterSustainabilityLimit}
        />
      </div>

      <Button.Group
        basic
        className={classNames(styles.buttonGroup, theme.basicButtonGroup)}
      >
        <Button
          active={waterCategory === 'total'}
          className={styles.button}
          onClick={() => setWaterCategory('total')}
          disabled={isLoading}
        >
          <FormattedMessage id="total" />
        </Button>

        <Button
          active={waterCategory === 'hotWater'}
          className={styles.button}
          onClick={() => setWaterCategory('hotWater')}
          disabled={isLoading || !(get(waterUsage, 'hotWater.total', '') > 0)}
        >
          <FormattedMessage id="hotWater" />
        </Button>

        <Button
          active={waterCategory === 'coldWater'}
          className={styles.button}
          onClick={() => setWaterCategory('coldWater')}
          disabled={isLoading || !(get(waterUsage, 'coldWater.total', '') > 0)}
        >
          <FormattedMessage id="coldWater" />
        </Button>
      </Button.Group>
    </div>
  );
};
