import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { ClickableIcon } from 'assets/icons/ClickableIcon';

export const DiscardIcon = ({ size = 44, onClick = () => {} }) => (
  <ClickableIcon
    size={size}
    onClick={onClick}
  >
    <svg
      style={{ width: size }}
      className={classNames(
        styles.icon,
      )}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
    >
      <path className={styles.discard}
            d="M15.556,0A15.556,15.556,0,1,0,31.113,15.556,15.557,15.557,0,0,0,15.556,0Zm9.26,17.408H17.408v7.408H13.7V17.408H6.3V13.7H13.7V6.3h3.7V13.7h7.408Z"
            transform="translate(0 22) rotate(-45)"/>
    </svg>
  </ClickableIcon>
);
