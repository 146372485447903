import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ImageOverlay } from 'pages/onboarding/ImageOverlay';
import { useRedirect } from '../../../../../utils/redirect';
import { RIBOnboardingRoutes } from '../../routes/index';
import { ModalCard } from '../../../../../components/cards/ModalCard';
import { OnboardingGrid } from '../../../OnboardingGrid';
import { Grid } from '../../../../../components/layout/Grid';
import simplerEverydayImage from '../../../../../assets/media/rib/simpler-everyday.jpg';
import { Brikk } from '../../../../../components/Brikk';
import { Text } from '../../../../../components/typography';
import { NextStep } from '../../../../../components/form-elements/buttons/NextStep';

export const SimplerEveryday = () => {
  const { onRedirect } = useRedirect(RIBOnboardingRoutes.ORDINARY_HOUSE_BUT_SAFER);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <ImageOverlay
            imageSrc={simplerEverydayImage}
            overlayIntl="onboardingSimplerEverydayTitle"
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingSimplerEverydayHeading">
            <Text size={18}>
              <FormattedMessage id="onboardingSimplerEverydayDescription" />
            </Text>
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()} />
      </OnboardingGrid>
    </ModalCard>
  );
};
