import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

export const DeleteIcon = ({ onClick, disabled }) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" 
        width="27.213" 
        height="29.213" 
        viewBox="0 0 27.213 29.213"
        onClick={!disabled ? onClick : undefined}
        className={classNames(styles.path, disabled && styles.disabled)}
    >
    <path id="circle-with-plus" 
        d="M9.621,0a9.621,9.621,0,1,0,9.621,9.621A9.622,9.622,0,0,0,9.621,0Zm5.727,10.767H10.767v4.582H8.476V10.767H3.894V8.476H8.476V3.894h2.291V8.476h4.582Z" 
        transform="translate(0 10.507) rotate(-45)" 
        fill="#fd2b2b"
    />
    </svg>
  );
};
