import React, { useContext } from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeItems/components/ItemContent/styles.module.scss';
import { Text } from 'components/typography/Text';
import { advancedMaintenance, MEASURES, shouldDisplayWallpaperInfo } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeItems/components/ItemContent/helper';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { ShouldRender } from 'components/ShouldRender';
import { Table } from "semantic-ui-react";
import { FormattedMessage } from "react-intl";
import { formatCurrency } from "utils/format";

export const ItemContent = ({ maintenanceItem }) => {
  const { selectedSurface, setSelectedSurface } = useContext(UpgradeContext);

  const onSelectSurface = maintenanceItem => {
    setSelectedSurface(maintenanceItem);
  };

  const isSandingMeasure = maintenanceItem.measure === MEASURES.Sanded;

  return (
    <div className={styles.itemContent}>
      <div className={styles.content}>
        <div className={styles.surfaceDetailsTable}>
          <Table singleLine striped unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <FormattedMessage id="status" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="residualValue" />
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <FormattedMessage id="timeForChange" />
                </Table.HeaderCell>
                <Table.HeaderCell/>
              </Table.Row>
            </Table.Header>

            <Table.Body>
                <Table.Row>
                  <Table.Cell>
                    <div className={styles.unitName}>
                      <ShouldRender condition={maintenanceItem.residualValue === 0}>
                        <div className={styles.greenDot} />
                      </ShouldRender>
                      {maintenanceItem.nextStatus}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{maintenanceItem.residualValue === 0 ? '-' : `${formatCurrency(maintenanceItem.residualValue)} kr`}</Table.Cell>
                  <Table.Cell>{maintenanceItem.plannedDate}</Table.Cell>
                </Table.Row>
            </Table.Body>
          </Table>

        </div>

        <ShouldRender condition={!isSandingMeasure || (isSandingMeasure && !advancedMaintenance(maintenanceItem.plannedDate))}>
          { !(selectedSurface?.type === maintenanceItem.type && selectedSurface?.surface === maintenanceItem.surface)
            ? // Show select button only if the item is not already selected.
              <a href="#maintenanceProducts" className={styles.selectButton}>
                <ProceedButton onClick={() => onSelectSurface(maintenanceItem)}>
                  <ProceedButton.Text intl="selectSurface" />
                  <ProceedButton.CircledArrow dark />
                </ProceedButton>
              </a>
            :
              <Text className={styles.selectedLabel} intl="selected"/>
          }
        </ShouldRender>
      </div>

      <ShouldRender condition={isSandingMeasure && advancedMaintenance(maintenanceItem.plannedDate)}>
        <Text
          className={styles.additionalInfo}
          secondary
          intl="maintenanceSandingInfo"
          size={10}
        />
      </ShouldRender>

      <ShouldRender condition={shouldDisplayWallpaperInfo(maintenanceItem.type, maintenanceItem.measure)}>
        <Text
          className={styles.additionalInfo}
          secondary
          intl="maintenanceWallpaperInfo"
          size={10}
        />
      </ShouldRender>
    </div>
  );
};
