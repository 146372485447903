import React from 'react';
import { ImageWithOverlay } from 'components/media/ImageWithOverlay';
import { OnboardingImage } from 'pages/onboarding/OnboardingImage';

export const ImageOverlay = ({ imageSrc, overlayIntl }) => (
  <OnboardingImage
    imageSrc={imageSrc}
  >
    <ImageWithOverlay.MainOverlay
      intl={overlayIntl}
      bgColor="rgba(256, 256, 256, 0.8)"
      textIsWhite
    />
  </OnboardingImage>
);
