import { getTenantIDBasedOnURL, tenantConfig } from 'global/tenant-config';

export const TENANT_ID = getTenantIDBasedOnURL();
export const AWS_API_URL = `${process.env.REACT_APP_awsApiUri}/${TENANT_ID}`;
export const AWS_REGION = process.env.REACT_APP_region;
export const USER_POOL = process.env.REACT_APP_userPool;
export const CLIENT_ID = process.env.REACT_APP_clientId;
export const BID_AGGREGATOR_KEY = process.env.REACT_APP_BID_AGGREGATOR_KEY;
export const BID_AGGREGATOR_BASE_URL = process.env.REACT_APP_BID_AGGREGATOR_BASE_URL;
export const BID_AGGREGATOR_PROVIDER = process.env.NODE_ENV !== 'production' ? 'test-client' : tenantConfig.rpName;
export const BID_AGGREGATOR_POLICY = process.env.REACT_APP_BID_AGGREGATOR_POLICY;
export const GA_TRACKING = process.env.NODE_ENV !== 'production' ? 'UA-170515459-1' : tenantConfig.gaTracking;
