import React, { useContext } from 'react';
import classNames from 'classnames';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary/styles.module.scss';
import { UpgradeCost } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/UpgradePlan/components/UpgradeSummary/components/UpgradeCost';
import { Brikk } from 'components/Brikk';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { useTheme } from 'global/themes';

export const UpgradeSummary = ({ onNextStep }) => {
  const { selectedPurchases, setSelectedPurchases } = useContext(UpgradeContext);

  const { theme } = useTheme();

  return (
    <>
      <Brikk.Heading
        className={classNames(styles.column, theme.cardBackgroundColor)}
        heading="costs"
      >
        <UpgradeCost
          selectedPurchases={selectedPurchases}
          setSelectedPurchases={setSelectedPurchases}
        />
      </Brikk.Heading>
    </>
  );
}
