import React from 'react';
import { createContainer } from 'unstated-next';

function useModalState() {
  const initialState = {
    modalType: null,
    modalProps: null,
  };

  const [modal, setModal] = React.useState(initialState);

  return {
    modalType: modal.modalType,
    modalProps: modal.modalProps,
    openModal: (modalProps) => setModal(modalProps),
    closeModal: () => setModal(initialState),
  };
}

const Modal = createContainer(useModalState);

export const ModalProvider = Modal.Provider;
export const useModal = Modal.useContainer;
