import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'global/themes';

import styles from 'components/brikks/home/ServiceStatus/components/ServiceStatusModal/components/ServiceTicketsList/components/ServiceTicket/components/Calendar/styles.module.scss';

const Calendar = (props) => {

  let months = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

  const { theme } = useTheme();

  return (
    <div className={classNames(styles.calendar, theme.boxShadow)}>
      <div className={styles.header}>
        {months[parseInt(props.month)]}
      </div>

      <div className={classNames(styles.body, theme.iconBackgroundSecondaryColor)}>
        <div className={classNames(styles.date, theme.textColor)}>
          {props.date}
        </div>
      </div>
    </div>
  );
};

export default Calendar;
