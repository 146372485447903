import { daysFormat, formatDate } from 'utils/timeAndDate';
import { getStreetAddress } from 'utils/living-info/streetAdress';
  
export const mapDataToClient = (livingInfo) => ({
  apartmentNumber: livingInfo.apartmentNumber,
  objectNumber: livingInfo.objectNumber,
  area: livingInfo.area,
  floor: livingInfo.floor,
  fullAddress: getStreetAddress(livingInfo.fullAddress),
  rent: livingInfo.productPrice,
  agreementName: livingInfo.groupName,
  startDate: livingInfo.lastStartDateLease ? formatDate(new Date(livingInfo.lastStartDateLease), daysFormat) : '-', 
  postalAddress: livingInfo.postalAddress,
  postalCode: livingInfo.postalCode,
  propertySize: livingInfo.propertySize,
  numberOfRooms: livingInfo.type,
  propertyNumber: livingInfo.propertyNumber,
});

