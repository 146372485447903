import React from 'react';
import generalInfoSrc from 'assets/media/lkf/MyHome/generalInfo.png';
import { Grid } from 'components/layout/Grid';
import { LinkModal } from 'components/modals/LinkModal';
import { ExternalLink } from 'components/links/ExternalLink';
import Loader from 'components/loaders/Loader';
import { LinkModalButton } from 'components/form-elements/buttons/LinkModalButton';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { Image } from 'components/media/Image';
import { Card } from 'components/cards/Card';

const ServiceReport = React.lazy(() => import('components/brikks/home/ServiceReport/components/ServiceReportModal'));
const ContactUs = React.lazy(() => import('components/brikks/home/ContactUs/components/ContactUsModal'));

export function ReportLink({ externalLink, externalLinkIntl }) {
  return (
    <Card grey>
      <Grid
        noPadding
        oneColumn
        style={{ justifyItems: 'center', gap: 15 }}
      >
        <Image
          src={generalInfoSrc}
          style={{ width: 80 }}
        />

        <ExternalLink href={externalLink}>
          <ProceedButton>
            <ProceedButton.Button intl={externalLinkIntl} />
            <ProceedButton.Redirect />
          </ProceedButton>
        </ExternalLink>

        <React.Suspense fallback={<Loader />}>
          <LinkModal
            intl="contact"
            buttonTriggerModal={LinkModalButton}
            brikkModal={ContactUs}
            modalHeading="contactUs"
          />
        </React.Suspense>

        <React.Suspense fallback={<Loader />}>
          <LinkModal
            intl="reportIssue"
            buttonTriggerModal={LinkModalButton}
            brikkModal={ServiceReport}
            modalHeading="serviceReport"
          />
        </React.Suspense>
      </Grid>
    </Card>
  );
}
