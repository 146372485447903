import React from 'react';

export const PenWriteIcon = () => {
  return (
    <svg
      id="pen-write"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20.001"
      viewBox="0 0 20 20.001"
    >
      <path
        id="Path_8280"
        data-name="Path 8280"
        d="M15.834,10.834a.833.833,0,0,0-.833.833V17.5a.833.833,0,0,1-.833.833H2.5a.833.833,0,0,1-.833-.833V2.5A.833.833,0,0,1,2.5,1.667H8.334A.833.833,0,1,0,8.334,0H2.5A2.5,2.5,0,0,0,0,2.5v15A2.5,2.5,0,0,0,2.5,20H14.167a2.5,2.5,0,0,0,2.5-2.5V11.667A.833.833,0,0,0,15.834,10.834Z"
      />
      <path
        id="Path_8281"
        data-name="Path 8281"
        d="M8.333,12.518a.417.417,0,0,0-.294.3l-.464,1.864L6.531,15.726a.625.625,0,0,0,0,.884.633.633,0,0,0,.883,0l1.053-1.052,1.839-.463a.416.416,0,0,0,.194-.7L8.739,12.626A.417.417,0,0,0,8.333,12.518Z"
        transform="translate(-1.058 -2.083)"
      />
      <path
        id="Path_8282"
        data-name="Path 8282"
        d="M21.7.244a.833.833,0,0,0-1.178,0l-.75.75a1.676,1.676,0,0,0-1.278.173l-.043-.043a2.5,2.5,0,0,0-3.529.005l-3.01,3a.833.833,0,0,0,0,1.178.844.844,0,0,0,1.179,0L16.112,2.3a.833.833,0,0,1,1.173.006l-.589.589a.417.417,0,0,0,0,.589L18.46,5.25a.417.417,0,0,0,.589,0l1.473-1.473a1.667,1.667,0,0,0,.431-1.608l.75-.75a.833.833,0,0,0,0-1.174Z"
        transform="translate(-1.944 0)"
      />
      <path
        id="Path_8283"
        data-name="Path 8283"
        d="M11.964,12.642a.417.417,0,0,0,.294-.123l5.3-5.3a.417.417,0,0,0,0-.589L15.789,4.866a.428.428,0,0,0-.589,0l-5.3,5.3a.417.417,0,0,0,0,.589l1.768,1.768A.417.417,0,0,0,11.964,12.642Z"
        transform="translate(-1.63 -0.791)"
      />
    </svg>
  );
};
