import React from 'react';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography';
import { LightningDevice } from 'components/brikks/smartness/Lights/components/LightningGroups/components';

export const LightGroup = ({ devices }) => (
  <Grid
    oneColumn
    noPadding
    style={{ gap: 10, paddingTop: 5 }}
  >
    {devices && devices.length > 0
      ? devices.map((source, index) => (
        <LightningDevice
          key={index}
          source={source}
        />
      )) : (
        <Text
          secondary
          center
          intl="noSourcesSelected"
          size={16}
        />
      )}
  </Grid>
);
