import React from 'react';
import { ClimateModalContext } from 'components/brikks/smartness/Climate/components/ClimateModal/index';

export function ClimateNotifications() {

  const { setSelectedSensor } = React.useContext(ClimateModalContext);
  setSelectedSensor(null);

  return (<></>);
}
