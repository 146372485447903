import React from 'react';
import Popup from 'reactjs-popup';
import { isMobile } from 'react-device-detect';
import { CircledCloseIcon } from 'assets/icons/CircledCloseIcon';
import { ExternalLink } from 'components/links/ExternalLink';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import styles from 'components/ContentField/components/TooltipModal/styles.module.scss';
import { Grid } from 'components/layout/Grid';
import { Text } from 'components/typography';
import Loader from 'components/loaders/Loader';
import { useLaundryLink } from 'services/laundry/link';

function LaundryServiceModal({ isOpen, onClose }) {
  const { laundryLink, isFetching } = useLaundryLink();
  
  return (
    <Popup
      modal
      open={isOpen}
      onClose={onClose}
      className={styles.modal}
      contentStyle={{
        background: '#ffffff',
        borderRadius: 20,
        width: isMobile ? '80vw' : '35vw',
        height: 250,
        justifyContent: 'center',
        border: '1px solid rgba(0, 0, 0, 0.18)',
      }}
    >

      <div className={styles.closeButton}>
        <CircledCloseIcon
          black
          onClick={onClose}
        />
      </div>

      <Grid
        oneColumn
        noPadding
        style={{ padding: '3rem', justifyItems: 'center' }}
      >
        {
              isFetching ? <Loader /> : (
                <>
                  <Text
                    dark
                    bold
                    size={20}
                    intl="generatedLink"
                  />

                  <ExternalLink href={laundryLink}>
                    <ProceedButton onClick={onClose}>
                      <ProceedButton.Button intl="laundryBrikk" />
                      <ProceedButton.Redirect />
                    </ProceedButton>
                  </ExternalLink>
                </>
              )
            }
      </Grid>
    </Popup>
  );
}

export default LaundryServiceModal;
