import { useSelector, useDispatch } from 'react-redux';
import {useEffect} from "react";
import { getUserAvatar, uploadUserAvatar } from "./actions";

export const useUserAvatar = () => {
  const { avatar, loading, error, calledOnce } = useSelector(state => state.userAvatar);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!calledOnce) {
      dispatch(getUserAvatar());
    }
  }, [calledOnce, dispatch]);

  return {
    avatar,
    loading,
    error,
    uploadUserAvatar: (avatar) => dispatch(uploadUserAvatar(avatar))
  }
};
