import React from 'react';
import styles from './styles.module.scss';

export const InsuranceHouseUnderlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="54"
      viewBox="0 0 48.325 36.681"
      className={styles.color}
    >
      <rect
        id="Rectangle_453"
        data-name="Rectangle 453"
        width="48.325"
        height="3.4"
        transform="translate(0 33)"
      />
      <g id="real-estate-insurance-house" transform="translate(10 0)">
        <rect
          id="Rectangle_3917"
          data-name="Rectangle 3917"
          width="5"
          height="11.25"
          rx="1"
          transform="translate(0 17.573)"
        />
        <path
          id="Path_8259"
          data-name="Path 8259"
          d="M28.406,20.069a.625.625,0,0,0,.161-1,4.045,4.045,0,0,0-4.353-1.413c-.073.021-2.459.785-2.53.808a.625.625,0,0,0-.435.606,2.813,2.813,0,0,1-2.811,2.863H11.875a.625.625,0,1,1,0-1.25h6.563a1.563,1.563,0,0,0,0-3.125l-4.446.009a.618.618,0,0,1-.34-.1c-.153-.1-.358-.25-.535-.375a8.545,8.545,0,0,0-4.991-2.034h-2.5A.625.625,0,0,0,5,15.683v7.375a.625.625,0,0,0,.424.593c.883.3,1.676.575,2.4.825,3.28,1.135,5.11,1.76,6.773,1.76,2.03,0,3.811-.928,7.674-2.983C23.868,22.4,25.862,21.342,28.406,20.069Z"
          transform="translate(1.25 3.765)"
        />
        <path
          id="Path_8260"
          data-name="Path 8260"
          d="M18.785,3.649a.625.625,0,0,0,1.04-.468V1.25A1.25,1.25,0,0,0,18.575,0h-2.25a.625.625,0,0,0-.415,1.093Z"
          transform="translate(3.926 0)"
        />
        <path
          id="Path_8261"
          data-name="Path 8261"
          d="M5.625,9.375h2.5A.625.625,0,0,1,8.75,10v6.25A1.25,1.25,0,0,0,10,17.5h1.875a1.25,1.25,0,0,0,1.25-1.25V13.125a.625.625,0,0,1,.625-.625h2.5a.625.625,0,0,1,.625.625v3.125a1.25,1.25,0,0,0,1.25,1.25H20a1.25,1.25,0,0,0,1.25-1.25V10a.625.625,0,0,1,.625-.625h2.5a.625.625,0,0,0,.415-1.093L15.83.315h0a1.25,1.25,0,0,0-1.66,0L5.21,8.283a.625.625,0,0,0,.415,1.093Z"
          transform="translate(1.25 0)"
        />
      </g>
    </svg>
  );
};
