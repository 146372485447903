import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getUserSettings, updateUserSettings } from './actions';
import { UserSettings } from './models/UserSettings';

export function useUserSettings(shouldFetch = false) {
  const {
    theme,
    background,
    onboardingPassed,
    runOnboarding,
    brikksOrder,
    loading,
    calledOnce,
    movingOutCheckList
  } = useSelector(state => state.userSettings);

  const dispatch = useDispatch();

  useEffect(() => {
    if (shouldFetch && !calledOnce) {
      dispatch(getUserSettings())
    }
  }, [calledOnce, shouldFetch, dispatch]);

  return {
    theme,
    background,
    onboardingPassed,
    runOnboarding,
    brikksOrder,
    movingOutCheckList,
    loading: loading || !calledOnce,
    updateUserSettings: (userSettings) => dispatch(updateUserSettings(new UserSettings(userSettings))),
  }
}
