import React from 'react';

export const AlarmBellIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24.006" viewBox="0 0 24 24.006">
            <g id="alarm-bell-ring-1" transform="translate(0 0.006)">
                <path id="Path_8130" data-name="Path 8130" d="M4.5,7.5a1.015,1.015,0,0,0,.873-.505A5.372,5.372,0,0,1,7.457,4.9a1,1,0,1,0-.975-1.746A7.335,7.335,0,0,0,3.628,6.013a1,1,0,0,0,.384,1.361A.968.968,0,0,0,4.5,7.5Z" />
                <path id="Path_8131" data-name="Path 8131" d="M6.432.711A1,1,0,0,0,5.186.044,7.492,7.492,0,0,0,.043,5.187,1,1,0,0,0,.711,6.434,1.038,1.038,0,0,0,1,6.477a1,1,0,0,0,.957-.712A5.5,5.5,0,0,1,5.764,1.958,1,1,0,0,0,6.432.711Z"  />
                <path id="Path_8132" data-name="Path 8132" d="M19.146,4.386a7.391,7.391,0,0,0-1.628-1.228A1,1,0,1,0,16.543,4.9a5.372,5.372,0,0,1,2.085,2.087,1.015,1.015,0,0,0,1.36.383,1,1,0,0,0,.384-1.361A7.438,7.438,0,0,0,19.146,4.386Z" />
                <path id="Path_8133" data-name="Path 8133" d="M23.957,5.187A7.492,7.492,0,0,0,18.814.044a1,1,0,1,0-.578,1.914,5.5,5.5,0,0,1,3.807,3.807,1.018,1.018,0,0,0,1.246.669,1,1,0,0,0,.668-1.247Z" />
                <path id="Path_8134" data-name="Path 8134" d="M13.5,21.5h-3a.5.5,0,0,0-.5.5,2,2,0,0,0,4,0A.5.5,0,0,0,13.5,21.5Z" />
                <path id="Path_8135" data-name="Path 8135" d="M18.5,13.647V13c0-3.493-2.079-6.479-5-7.27V4.5a1.5,1.5,0,1,0-3,0V5.73c-2.921.791-5,3.777-5,7.27v.646a13.771,13.771,0,0,1-1.447,6.13A.5.5,0,0,0,4.5,20.5h15a.5.5,0,0,0,.447-.724A13.771,13.771,0,0,1,18.5,13.647Z"  />
            </g>
        </svg>
    )
}