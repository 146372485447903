import React, { useState } from 'react';
import Draggable from 'components/drag-and-drop/Draggable';
import { SeeMoreButton } from 'components/form-elements/buttons/SeeMoreButton';
import { BrikkModal } from 'components/modals/BrikkModal';
import { Brikk } from 'components/Brikk';
import { MyDocumentsIcon } from 'assets/icons/brikks/MyDocumentsIcon';
import { useAgreements } from 'services/agreements/agreements';
import { BrikkInfoModal } from 'components/modals/BrikkInfoModal';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { LKF } from 'global/tenant-config/tenants';
import { InfoButton } from 'components/form-elements/buttons/InfoButton';
import { MyDocumentsPreview } from './components/MyDocumentsPreview';
import { MyDocumentsModal } from './components/MyDocumentsModal';

export const MyDocuments = ({ position, status, code, name }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { agreements, loading } = useAgreements();

  const handleInfoModal = (e) => {
    e.stopPropagation();
    setIsInfoModalOpen(!isInfoModalOpen);
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setIsModalOpen(true);
  };

  return (
    <>
      <Draggable position={position}>
        <Brikk
          isLoading={loading}
          brikkKey={code}
          name={name}
          status={status}
          disabled={tenantId === LKF}

        >
          <Brikk.Header
            icon={<MyDocumentsIcon />}
            heading="myDocumentsBrikk"
            status={status}
            brikkKey={code}
          />

          <Brikk.Content>
            <MyDocumentsPreview />
          </Brikk.Content>

          <Brikk.Button>
            <SeeMoreButton
              disabled={!agreements.length || tenantId === LKF}
              onClick={handleOpenModal}
              brikkNameIntl="myDocuments"
            />
        
            <InfoButton
              showModal={isInfoModalOpen}
              onClick={handleInfoModal}
            />
          </Brikk.Button>
        </Brikk>
      </Draggable>

      <BrikkInfoModal
        title="My documents info"
        // info={info}
        showModal={isInfoModalOpen}
        onClick={() => setIsInfoModalOpen(!isInfoModalOpen)}
      />

      <BrikkModal
        isOpen={isModalOpen}
        heading="myDocuments"
        onClose={() => setIsModalOpen(false)}
      >
        <MyDocumentsModal />
      </BrikkModal>
    </>
  );
};
