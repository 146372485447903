import React, { useState } from "react";
import { BrikkModal } from "../BrikkModal";

export const LinkModal = ({
  buttonTriggerModal,
  brikkName,
  modalHeading,
  isDisabled,
  brikkModal,
  isActive,
  intl,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const TriggerModalComponent = buttonTriggerModal;
  const BrikkModalComponent = brikkModal;

  return (
    <>
      <TriggerModalComponent
        onClick={() => setIsModalOpen(true)}
        disabled={isDisabled}
        brikkNameIntl={brikkName}
        isActive={isActive}
        intl={intl}
      />
      <BrikkModal
        isOpen={isModalOpen}
        heading={modalHeading}
        onClose={() => setIsModalOpen(false)}
      >
        <BrikkModalComponent {...props} />
      </BrikkModal>
    </>
  );
};
