import React from 'react';
import { Button } from 'semantic-ui-react';
import { hourAndMinute, addMinutes, subtractMinutes } from 'utils/timeAndDate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './styles.module.scss';

export const TimePicker = ({ caption, value, onChange, timeInterval }) => (
  <div className={styles.timeWrapper}>
    <Button
      icon="plus"
      onClick={(e) => {
        e.preventDefault();
        onChange(addMinutes(value, timeInterval));
      }}
    />

    <DatePicker
      selected={value}
      onChange={onChange}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={timeInterval}
      timeCaption={caption}
      dateFormat={hourAndMinute}
      timeFormat={hourAndMinute}
      wrapperClassName={styles.timePicker}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    />

    <Button
      icon="minus"
      onClick={(e) => {
        e.preventDefault();
        onChange(subtractMinutes(value, timeInterval));
      }}
    />
  </div>
);
