export const VXB = 'vxb';
export const LKF = 'lkf';
export const BRE = 'bre';
export const RIB = 'rib';
export const GRT = 'grt';
export const GRT_LIF = 'grt-lif';

export const Tenants = {
  LKF: {
    aliases: ['lkf'],
    tenantId: 'lkf',
  },
  VXB: {
    aliases: ['vxb', 'vaxjobostader', 'vidingehem'],
    tenantId: 'vxb',
  },
  BRIKKS: {
    aliases: ['boportalen.brikks', 'living.brikks'],
    tenantId: 'bre',
  },
  RIB: {
    aliases: ['rib', 'riksbyggen'],
    tenantId: 'rib',
  },
  GRT: {
    aliases: ['grt', 'granitor'],
    tenantId: 'grt',
  },
};

