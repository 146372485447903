import React from 'react';
import styles from './styles.module.scss'

export const UpgradesPreviewIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg"
       className={styles.color}
       width="70"
       height="54"
       viewBox="0 0 48.325 42.421"
  >
    <g
      id="Group_6437"
      data-name="Group 6437"
      transform="translate(-431.024 -248.762)"
    >
      <rect
        id="Rectangle_453"
        data-name="Rectangle 453"
        width="48.325" height="4.393"
        transform="translate(431.024 286.789)"
      />

      <g
        id="tags-upload"
        transform="translate(438.805 248.762)"
      >
        <path
          id="Path_8286"
          data-name="Path 8286"
          d="M22.256,12.372a.634.634,0,0,0,.465-.19L24.21,10.66a4.805,4.805,0,0,0,1.25-3.055V2.535A2.535,2.535,0,0,0,22.924,0h0l-5.4.006a4.75,4.75,0,0,0-3.06,1.268L.742,15a2.535,2.535,0,0,0,0,3.585l6.13,6.133a2.5,2.5,0,0,0,1.782.738h.015a2.5,2.5,0,0,0,1.787-.761l1.313-1.34a.634.634,0,0,0,.18-.468A10.11,10.11,0,0,1,22.256,12.372Zm-6.3-5.4a2.535,2.535,0,1,1,2.535,2.535,2.535,2.535,0,0,1-2.535-2.535Z"
          transform="translate(0)"
        />

        <path
          id="Path_8287"
          data-name="Path 8287"
          d="M19.239,11a8.239,8.239,0,1,0,8.239,8.239A8.239,8.239,0,0,0,19.239,11ZM17.972,23.042V19.556a.317.317,0,0,0-.317-.317H16.7a.634.634,0,0,1-.5-1.031l2.535-3.169a.659.659,0,0,1,.991,0l2.535,3.169a.634.634,0,0,1-.5,1.031h-.951a.317.317,0,0,0-.317.317v3.486a1.268,1.268,0,0,1-2.535,0Z"
          transform="translate(2.944 2.943)"
        />
      </g>
    </g>
  </svg>
);
