import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { Grid } from 'components/layout/Grid';
import { ImageGalleryOverlay } from '../../components/ImageGalleryOverlay';
import xplorionSrc from 'assets/media/lkf/xplorion.png';
import { Text } from 'components/typography/Text';
import { Brikk } from 'components/Brikk';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { useRedirect } from 'utils/redirect';
import { LKFOnboardingRoutes } from 'pages/onboarding/LKFOnboarding/routes';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';

export const Xplorion = () => {
  const { onRedirect } = useRedirect(LKFOnboardingRoutes.YOUR_INFO);

  return (
    <ModalCard>
      <OnboardingGrid>
        <Grid.Column>
          <ImageGalleryOverlay
            imageSrc={xplorionSrc}
            overlayIntl="onboardingXplorionTitle"
          />
        </Grid.Column>

        <Grid.Column>
          <Brikk.Heading heading="onboardingXplorionHeading">
            <Text
              size={18}
              intl="onboardingXplorionDescription"
            />
          </Brikk.Heading>
        </Grid.Column>

        <NextStep onClick={() => onRedirect()}/>
      </OnboardingGrid>
    </ModalCard>
  );
}
