import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getSpacesAPI } from './api';

const SPACES = 'spaces';

export function useSpaces() {
  const tenantId = getTenantIDBasedOnURL();

  const { data, isLoading, isFetching, isError } = useQuery(
    [SPACES, tenantId],
    () => getSpacesAPI(),
  );

  return {
    spaces: data || [],
    isLoading: isLoading || isFetching,
    isError,
  };
}
