import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card } from 'components/cards/Card';
import { Form } from 'components/form-elements/Form';
import { LabeledInput } from 'components/form-elements/inputs/LabeledInput';
import { SubmitButton } from 'components/form-elements/buttons/SubmitButton';
import { LabeledSelect } from 'components/form-elements/selects/LabeledSelect';
import { Grid } from 'components/layout/Grid';
import { useLightningGroups } from 'services/lightning/groups';
import { useCreateLightningScenario } from 'services/lightning/create-scenario';
import { lightningScenarioSchema } from 'utils/validation/schemas/lightningScenario';
import { HOME } from 'utils/types';

const initialScenarioValues = {
  name: '',
  group: null,
};

export const AddScenario = () => {

  const intl = useIntl();
  const { lightningGroups, isLoading: isLoadingGroups } = useLightningGroups();

  const groups = useMemo(() => Object.keys(lightningGroups)
    .filter((key) => key !== HOME)
    .map(key => ({
      value: lightningGroups[key].id,
      label: key,
    })), [lightningGroups]);

  const { control, handleSubmit, formState: { errors }, reset } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(lightningScenarioSchema),
    defaultValues: initialScenarioValues,
  });

  const { createLightningScenario, isLoading: isLoadingCreate } = useCreateLightningScenario({
    onSuccess: () => {
      reset();
    },
  });

  function onSubmit(data) {
    createLightningScenario(data);
  }
  
  return (
    <Card
      grey
      header="createScenario"
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          oneColumn
          noPadding
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <LabeledInput
                labelIntl="createNewScenario"
                description="createScenarioDesc"
                placeholder={intl.formatMessage({ id: 'name' })}
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.name}
              />
            )}
          />

          <Controller
            name="group"
            control={control}
            render={({ field }) => (
              <LabeledSelect
                placeholder={intl.formatMessage({ id: 'chooseGroup' })}
                options={groups}
                title="selectGroup"
                description="selectGroupDesc"
                value={field.value}
                onChange={field.onChange}
                hasError={!!errors.group}
                isLoading={isLoadingGroups}
              />
            )}
          />

          <SubmitButton isLoading={isLoadingCreate} />
        </Grid>
      </Form>
    </Card>
  );
};

