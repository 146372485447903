import { authorize, getRequest } from 'utils/http';
import { AWS_API_URL } from 'global/environment';
import { mapDataToClient } from './mapData';

export async function getApartmentDrawingAPI() {
  const url = `${AWS_API_URL}/apartment-drawing`;

  const data = await getRequest(url, await authorize());

  return mapDataToClient(data.data);
}
