import React from 'react';
import styles from './styles.module.scss';

export const PenWriteUnderlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="54"
      viewBox="0 0 48.325 36.681"
      className={styles.color}
    >
      <g
        id="Group_6421"
        data-name="Group 6421"
        transform="translate(-1693.104 -579.32)"
      >
        <rect
          id="Rectangle_453"
          data-name="Rectangle 453"
          width="48.325"
          height="3.4"
          transform="translate(1693.104 611.608)"
        />
        <path
          id="Path_8280"
          data-name="Path 8280"
          d="M21.749,14.881A1.145,1.145,0,0,0,20.6,16.026v8.013a1.145,1.145,0,0,1-1.145,1.145H3.434a1.145,1.145,0,0,1-1.145-1.145V3.434A1.145,1.145,0,0,1,3.434,2.289h8.013a1.145,1.145,0,1,0,0-2.289H3.434A3.434,3.434,0,0,0,0,3.434v20.6a3.434,3.434,0,0,0,3.434,3.434H19.46a3.434,3.434,0,0,0,3.434-3.434V16.026A1.145,1.145,0,0,0,21.749,14.881Z"
          transform="translate(1704.129 579.32)"
        />
        <path
          id="Path_8281"
          data-name="Path 8281"
          d="M9.075,12.524a.572.572,0,0,0-.4.413L8.033,15.5,6.6,16.931a.859.859,0,0,0,0,1.215.87.87,0,0,0,1.213,0L9.26,16.7l2.526-.635a.572.572,0,0,0,.267-.958L9.632,12.671A.572.572,0,0,0,9.075,12.524Z"
          transform="translate(1705.047 581.129)"
        />
        <path
          id="Path_8282"
          data-name="Path 8282"
          d="M25.449.335a1.145,1.145,0,0,0-1.619,0L22.8,1.366a2.3,2.3,0,0,0-1.755.238l-.06-.058a3.434,3.434,0,0,0-4.848.007L12,5.679A1.145,1.145,0,0,0,12,7.3a1.16,1.16,0,0,0,1.62,0L17.771,3.16a1.145,1.145,0,0,1,1.611.008l-.809.809a.572.572,0,0,0,0,.809L21,7.212a.572.572,0,0,0,.809,0L23.83,5.188a2.289,2.289,0,0,0,.592-2.209l1.03-1.03a1.145,1.145,0,0,0,0-1.613Z"
          transform="translate(1705.817 579.32)"
        />
        <path
          id="Path_8283"
          data-name="Path 8283"
          d="M12.779,15.591a.572.572,0,0,0,.4-.168l7.276-7.275a.572.572,0,0,0,0-.809L18.033,4.909a.588.588,0,0,0-.809,0L9.948,12.185a.572.572,0,0,0,0,.809l2.428,2.428A.572.572,0,0,0,12.779,15.591Z"
          transform="translate(1705.544 580.007)"
        />
      </g>
    </svg>
  );
};
