export const MultiGrid = {
  Home: 'home',
  Neighbourhood: 'neighbourhood',
  Society: 'society',
  Sustainability: 'sustainability',
  Smartness: 'smartness',
  Care: 'care',
  Finances: 'finances',
  Store: 'store',
};
