import React from 'react';
import { useModal } from 'store/modals';
import { Button } from 'components/form-elements/buttons/Button';
import { Icon, Message, Modal, Header } from 'semantic-ui-react';
import { ShouldRender } from 'components/ShouldRender';
import { Text } from 'components/typography';
import { useTheme } from 'global/themes';

export const NotificationModal = ({ type, description }) => {
  const { closeModal } = useModal();
  const { theme } = useTheme();

  return (
    <Modal
      size="small"
      centered
      open
      className={theme.backgroundColor}
      dimmer="inverted"
    >
      <Modal.Header className={theme.cardBackgroundColor}>
        <Header textAlign="center">
          <ShouldRender condition={type === 'success'}>
            <Icon
              name="check circle"
              color="green"
              size="big"
            />
          </ShouldRender>

          <ShouldRender condition={type === 'error'}>
            <Icon
              name="warning circle"
              color="red"
              size="big"
            />
          </ShouldRender>

          <ShouldRender condition={type === 'warning'}>
            <Icon
              name="warning circle"
              color="yellow"
              size="big"
            />
          </ShouldRender>
        </Header>
      </Modal.Header>

      <Modal.Content className={theme.cardBackgroundColor}>
        <Message
          positive={type === 'success'}
          negative={type === 'error'}
          warning={type === 'warning'}
          size="large"
        >
          <Message.Header>
            <Text
              dark
              center
              intl={description}
            />
          </Message.Header>
        </Message>
      </Modal.Content>

      <Modal.Actions className={theme.cardBackgroundColor}>
        <Button
          name="close"
          intl="close"
          floated="right"
          onClick={closeModal}
        />
      </Modal.Actions>
    </Modal>
  );
};
