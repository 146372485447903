import grtEnglish from 'translations/grt/english.json';
import grtSwedish from 'translations/grt/swedish.json';
import grtLogo from 'assets/media/grt/logo.png';
import grtFavicon from 'assets/media/grt/favicon.ico';
import grtWelcome from 'assets/media/grt/welcome.png';
import grtHome from 'assets/media/grt/home.png';
import grtThemes from 'global/tenant-themes/grt-themes.json';
import { MultiGrid } from 'services/multi-grid/multiGrid';
import { GRTOnboarding } from 'pages/onboarding/GRTOnboarding';
import { GRT_BG } from 'services/user/settings/utils';
import { GRTLivingInfo } from 'components/brikks/home/MyHome/components/LivingInfoModal/components/GRTLivingInfo';

export const grtTenant = {
  assets: {
    logoWhite: grtLogo,
    logoDark: grtLogo,
    favicon: grtFavicon,
    livingRoom: grtHome,
    welcome: grtWelcome,
  },
  intl: {
    english: grtEnglish,
    swedish: grtSwedish,
  },
  components: {
    onboarding: GRTOnboarding,
  },
  initialBackground: GRT_BG,
  themes: grtThemes,
  sizes: {
    brikk: '300px',
    'brikks-spacing': '12px',
  },
  store: {
    updateService: 'https://bredband-properties.granitor.se/',
    changeSpeed: 'https://bredband-properties.granitor.se/',
  },
  multiGrids: [MultiGrid.Home, MultiGrid.Neighbourhood, MultiGrid.Society, MultiGrid.Store],
  brikks: ['A01', 'A02', 'A06', 'A07', 'A08', 'B09', 'C07', 'H10'],
  myHome: {
    components: {
      livingInfo: GRTLivingInfo,
    },
    generalInfo: {
      link: '',
      title: 'Granitor',
    },
  },
  tenants: {
    'grt-lif': {
      city: 'Lund',
      openweathermapLat: '55.7058',
      openweathermapLon: '13.1932',
    },
    'grt-par': {
      city: 'Bjärred',
      openweathermapLat: '55.7167',
      openweathermapLon: '13.0167',
    },
    'grt-res': {
      city: 'Bjärred',
      openweathermapLat: '55.7167',
      openweathermapLon: '13.0167',
    },
  },
  rpName: '',
  gaTracking: '',
};
