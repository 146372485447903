import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { useQuery } from 'react-query';
import { getReportedCasesAPI } from './api';

const REPORTED_CASES = 'reportedCases';

export function useReportedCases() {
  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const { data, isLoading, isFetching, isError } = useQuery(
    [REPORTED_CASES, tenantId],
    () => getReportedCasesAPI(),
  );

  return {
    reportedCases: data || [],
    loading: isLoading || isFetching,
    error: isError,
  };
}
