const MaintenanceInfo = (maintenanceInfo) => ({
  interval: maintenanceInfo.interval,
  intervalType: maintenanceInfo.intervaltype,
  measure: maintenanceInfo.measure,
  plannedDate: maintenanceInfo.planned,
  performedDate: maintenanceInfo.performeddate,
  residualValue: maintenanceInfo.residual || 0,
  type: maintenanceInfo.typename,
  unit: maintenanceInfo.unitname,
  surface: maintenanceInfo.unitname.split(',')[0],
  currentStatus: `${maintenanceInfo.unitname.split(',')[0]} - ${maintenanceInfo.measure}`,
  nextStatus: maintenanceInfo.unitname.split(',')[1],
});

function getUpcomingMaintenance(maintenance) {
  if (maintenance?.length) {
    maintenance.sort((a, b) => ((a.planned > b.planned) ? 1 : -1));
    let hasPlannedDate = false;
    let upcomingMaintenance;
    let maintenanceIterator = 0;
    while (!hasPlannedDate) {
      upcomingMaintenance = maintenance[maintenanceIterator++];
    
      if (upcomingMaintenance.planned) {
        hasPlannedDate = true;
      }
    }
    
    return MaintenanceInfo(upcomingMaintenance);
  }
    
  return null;
}

export const mapDataToClient = (maintenance) => ({
  plannedMaintenance: maintenance.map(maintenance => MaintenanceInfo(maintenance)),
  upcomingMaintenance: getUpcomingMaintenance(maintenance),
});

