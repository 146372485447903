import React, { useContext } from 'react';
import styles from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/UpgradeTable/components/ItemSelectButton/styles.module.scss';
import { advancedMaintenance, MEASURES } from 'components/brikks/home/Upgrade/components/UpgradeModal/components/MaintenanceUpgrade/components/UpgradeItemsPreview/components/UpgradeTable/components/ItemSelectButton/helper';
import { UpgradeContext } from 'components/brikks/home/Upgrade/components/UpgradeModal/index';
import { ProceedButton } from 'components/form-elements/buttons/ProceedButton';
import { MultiStepContext } from "components/MultiStep";

export const ItemSelectButton = ({ maintenanceItem }) => {
  const { setSelectedSurface } = useContext(UpgradeContext);
  const { onNextStep } = useContext(MultiStepContext);

  const onSelectSurface = maintenanceItem => {
    onNextStep();
    setSelectedSurface(maintenanceItem);
  };

  const isSandingMeasure = maintenanceItem.measure === MEASURES.Sanded;

  return (
    <div className={styles.ItemSelectButton}>
      <div className={styles.content}>
        {(!isSandingMeasure || (isSandingMeasure && !advancedMaintenance(maintenanceItem.plannedDate))) &&
          <a href="#maintenanceProducts">
            <ProceedButton onClick={() => onSelectSurface(maintenanceItem)}>
              <ProceedButton.CircledArrow light />
            </ProceedButton>
          </a>
        }
      </div>
    </div>
  );
};
