export const SubmitCreateOrderActions = {
    SUBMIT_CREATE_ORDER: '@createOrder/submit/',
    SUBMIT_CREATE_ORDER_SUCCESS: '@createOrder/submit/success',
    SUBMIT_CREATE_ORDER_FAIL: '@createOrder/submit/fail',
    SUBMIT_CREATE_ORDER_RESET: '@createOrder/submit/reset',
  };
  
  export function submitCreateOrder(orderedBrikks) {
    return {
      type: SubmitCreateOrderActions.SUBMIT_CREATE_ORDER,
      payload: orderedBrikks,
    }
  }
  
  export function submitCreateOrderSuccess() {
    return {
      type: SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_SUCCESS,
    }
  }
  
  export function submitCreateOrderFail(error) {
    return {
      type: SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_FAIL,
      payload: error,
    }
  }

  export function submitCreateOrderReset() {
    return {
      type: SubmitCreateOrderActions.SUBMIT_CREATE_ORDER_RESET,
    }
  }
  