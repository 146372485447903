import * as Yup from 'yup';
import { convertToTimestamp, setTime } from 'utils/timeAndDate';

export const updateLightningScenarioSchema = Yup.object().shape({
  onTime: Yup.date()
    .required()
    .test(
      (value, context) => {
        const onTime = value;
        const offTime = context?.parent?.offTime;

        return convertToTimestamp(setTime(offTime.getHours(), offTime.getMinutes())) 
          > convertToTimestamp(setTime(onTime.getHours(), onTime.getMinutes()));
      },
    ),
  offTime: Yup.date()
    .required()
    .test(
      (value, context) => {
        const offTime = value;
        const onTime = context?.parent?.onTime;

        return convertToTimestamp(setTime(offTime.getHours(), offTime.getMinutes()))
          > convertToTimestamp(setTime(onTime.getHours(), onTime.getMinutes())); 
      },
    ),
  days: Yup.array()
    .required()
    .min(1),
});
