import React from "react";
import { TabMenu } from "components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/Menus/components/TabMenu";
import { MenuContainer } from "components/brikks/home/MovingOut/components/MovingOutModal/components/MovingOutInfo/components/Menus/components/MenuContainer";

export const Menus = ({ menus, isCheckList= false }) => (
  <MenuContainer>
    {Object.keys(menus)?.map((keyName) => (
      <TabMenu
        key={keyName}
        menuName={menus[keyName].menu}
        subMenus={menus[keyName].submenus}
        disabled={false}
        isCheckList={isCheckList}
      />
    ))}
  </MenuContainer>
);
