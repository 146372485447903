import React from 'react';
import { ModalCard } from 'components/cards/ModalCard';
import { NextStep } from 'components/form-elements/buttons/NextStep';
import { PrevStep } from 'components/form-elements/buttons/PrevStep';
import { MultiStepContext } from 'components/MultiStep';
import { OnboardingGrid } from 'pages/onboarding/OnboardingGrid';
import { Image } from 'components/media/Image';
import SecurityOverviewSrc from '../assets/safetyOptions.png';

export const SecurityOptions = () => {
  const { onPrevStep } = React.useContext(MultiStepContext);

  return (
    <ModalCard>
      <Image
        src={SecurityOverviewSrc}
      />

      <OnboardingGrid>
        <PrevStep onClick={onPrevStep} />

        <NextStep
          disabled
        />
      </OnboardingGrid>
    </ModalCard>
  );
};
