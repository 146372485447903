import React from 'react';

export const SocketIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15.833" height="20" viewBox="0 0 15.833 20">
            <g id="Group_1623" data-name="Group 1623" transform="translate(-449 -525)">
                <g id="charger-1" transform="translate(449 525)">
                    <path id="Path_6874" data-name="Path 6874" d="M17.083,5H15.208A.208.208,0,0,1,15,4.792V1.25a1.25,1.25,0,0,0-2.5,0V4.792A.208.208,0,0,1,12.292,5H8.542a.208.208,0,0,1-.208-.208V1.25a1.25,1.25,0,1,0-2.5,0V4.792A.208.208,0,0,1,5.625,5H3.75a1.25,1.25,0,1,0,0,2.5H17.083a1.25,1.25,0,0,0,0-2.5Z" transform="translate(-2.5 0)" />
                    <path id="Path_6875" data-name="Path 6875" d="M15.917,10.5h-10a.417.417,0,0,0-.417.417V13a3.74,3.74,0,0,0,3.157,3.683.208.208,0,0,1,.176.206V18A1.25,1.25,0,0,0,9.9,19.237a.208.208,0,0,1,.178.207v1.473a.833.833,0,1,0,1.667,0V19.444a.209.209,0,0,1,.178-.207A1.25,1.25,0,0,0,13,18V16.89a.208.208,0,0,1,.176-.206A3.74,3.74,0,0,0,16.333,13V10.917A.417.417,0,0,0,15.917,10.5Z" transform="translate(-3 -1.75)" />
                </g>
            </g>
        </svg>
    )
}