import React, { useMemo } from 'react';
import { useSpaces } from 'services/service-report/spaces';
import Loader from 'components/loaders/Loader';
import { SingleDropdown } from 'components/dropdowns/SingleDropdown';
import { LKF, RIB } from 'global/tenant-config/tenants';
import { getTenantIDBasedOnURL } from 'global/tenant-config';
import { Units } from '../Units';
import styles from './styles.module.scss';

export const Spaces = ({ 
  selectedSpace, 
  selectedUnit, 
  isPrivateProperty,
  isPublicProperty,
  onSelectSpace,
  onSelectUnit, 
}) => {
  const { spaces, isLoading } = useSpaces();

  const selectedSpaces = useMemo(() => {
    if (isPrivateProperty) {
      return spaces.private; 
    } if (isPublicProperty) {
      return spaces.public;
    } return [];
  },
  [isPrivateProperty, isPublicProperty, spaces]);

  const tenantId = getTenantIDBasedOnURL().toLowerCase();

  const handleSpaceChange = (space) => {
    onSelectSpace(space);
    onSelectUnit(null);
  };

  if (isLoading) return <Loader />;

  return (
    <div className={styles.items}>
      {
        selectedSpaces && selectedSpaces.map(space => (
          <SingleDropdown key={space.id}>
            <SingleDropdown.Item
              item={space.id}
              itemKey={[LKF, RIB].includes(tenantId) ? space.key : space.id}
              selectedItem={selectedSpace}
              onClick={() => handleSpaceChange(space)}
            >
              {space.name}
            </SingleDropdown.Item>

            <SingleDropdown.Content
              item={space.id}
              selectedItem={selectedSpace}
            >
              <Units
                selectedSpace={tenantId === LKF
                  ? selectedSpaces.find(item => item.id === selectedSpace)?.key : selectedSpace}
                selectedUnit={selectedUnit}
                onSelectUnit={onSelectUnit}
              />
            </SingleDropdown.Content>
          </SingleDropdown>
        ))
      }
    </div>
  );
};
