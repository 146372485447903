import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import styles from './styles.module.scss';
import { LoginContext } from '../../../../index';
import { Button, Form, Message, Ref } from 'semantic-ui-react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { Text } from 'components/typography/Text';
import { useResetPass } from 'services/auth/reset-pass/hook';
import { ShouldRender } from 'components/ShouldRender';
import { PasswordInput } from "components/form-elements/inputs/PasswordInput";

export const ActivationForm = () => {
  const { setShouldActivateAccount, email } = useContext(LoginContext);

  const [activationCode, setActivationCode] = useState('');
  const [codeError, setCodeError] = useState('');

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');

  const intl = useIntl();

  const { isResetSuccessful, loading, error, onResetPass } = useResetPass();

  const submitRef = useRef(null);

  useEffect(() => {
    if (isResetSuccessful) {
      setShouldActivateAccount(false);
    }
  }, [isResetSuccessful, setShouldActivateAccount]);

  const onChangeCode = useCallback((e) => {
    setActivationCode(e.target.value);
    setCodeError('');
  }, [setActivationCode, setCodeError]);

  const onChangePassword = useCallback((e) => {
    setNewPassword(e.target.value);
    setNewPasswordError('');
  }, [setNewPassword, setNewPasswordError]);

  const onReset = useCallback(() => {
    const passError = !newPassword.trim().length;
    const codeError = !activationCode.trim().length;

    if (passError && codeError) {
      setCodeError('fieldsRequired');
      setNewPasswordError('fieldRequired');
    } else if (newPassword.trim().length < 10 || newPassword.trim().length > 99) {
      setNewPasswordError('invalidPassword');
    } else if (codeError) {
      setCodeError('fieldRequired');
    } else {
      onResetPass(email, newPassword, activationCode);
    }
  }, [activationCode, setNewPasswordError, setCodeError, onResetPass, email, newPassword]);

  return (
    <div className={styles.activationForm}>
      <Text large intl="activationCodeMessage" />

      <Form.Input
        className={classNames(styles.field, codeError && 'error')}
        id="verificationCode"
        fluid
        label={intl.formatMessage({ id: 'activationCode' })}
        value={activationCode}
        onChange={onChangeCode}
      />

      <PasswordInput
          className={classNames(styles.field, newPasswordError && 'error')}
          fluid
          label={intl.formatMessage({ id: 'newPassword' })}
          value={newPassword}
          onChange={onChangePassword}
          submitRef={submitRef}
      />

      <Ref innerRef={submitRef}>
        <Button
          className={styles.login}
          loading={loading}
          disabled={loading}
          onClick={onReset}
        >
          <FormattedMessage id="savePassword" />
        </Button>
      </Ref>

      <ShouldRender condition={!!codeError || !!newPasswordError || !!error}>
        <Message
          negative
          className={styles.field}
        >
          <FormattedMessage id={codeError || newPasswordError || error} />
        </Message>
      </ShouldRender>
    </div>
  )
};
